import { isNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import { usePartnerContext } from '../../../context'

/**
 * Setting for standard culture fit profile - pre defined for all career
 */
const CultureFitCompany = () => {
  const { t } = useTranslation()
  const { partner, refetchPartner } = usePartnerContext()

  const cultural_fit_partner = JSON.parse(
    partner.app_settings?.cultural_fit || '{}'
  )

  const hasPartnerCultureFitProfile = (cultural_fit) => {
    return (
      isNumber(cultural_fit.HEROS_JOURNEY_score_coopcomp) &&
      isNumber(cultural_fit.HEROS_JOURNEY_score_orderchaos)
    )
  }

  return (
    <div>
      {/* <CulturalFitQuestions
        state={cultureFitState}
        setState={setCultureFitState}
        culturalFitDimensions={cultural_fit_dimensions}
        setOpenDimensionsDescription={setOpenDimensionsDescription}
      /> */}
    </div>
  )
}

export default CultureFitCompany
