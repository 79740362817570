import PropTypes from 'prop-types'
import { useLocation } from 'react-router'

import Header from '../joyride/header'
import Joyride from '../joyride/joyride'
import Paragraph from '../joyride/paragraph'

const Tutorials = ({
  displayCareersTutorial,
  onCloseCareersTutorial,
  displayTalentsTutorial,
  onCloseTalentsTutorial
}) => {
  const { pathname } = useLocation()

  const careers_steps = [
    {
      content: (
        <>
          <Header>trial.careers_steps.step_1.title</Header>
          <div className='h-4'></div>
          <Paragraph className='mt-2'>
            trial.careers_steps.step_1.paragraph_1
          </Paragraph>
          <Paragraph className='mt-2'>
            trial.careers_steps.step_1.paragraph_2
          </Paragraph>
        </>
      ),
      placement: 'center',
      target: 'body'
    },
    {
      content: (
        <>
          <Header>trial.careers_steps.step_2.title</Header>
          <div className='h-4'></div>
          <Paragraph className='mt-2'>
            trial.careers_steps.step_2.paragraph_1
          </Paragraph>
        </>
      ),
      placement: 'bottom',
      target: '#btn-new-career'
    },
    {
      content: (
        <>
          <Header>trial.careers_steps.step_3.title</Header>
          <div className='h-4'></div>
          <Paragraph className='mt-2'>
            trial.careers_steps.step_3.paragraph_1
          </Paragraph>
        </>
      ),
      placement: 'right',
      target: '#link-to-talents'
    },
    {
      content: (
        <>
          <Header>trial.careers_steps.step_4.title</Header>
          <div className='h-4'></div>
          <Paragraph className='mt-2'>
            trial.careers_steps.step_4.paragraph_1
          </Paragraph>
        </>
      ),
      placement: 'right',
      target: '#guide'
    }
  ]

  const talents_steps = [
    {
      content: (
        <>
          <Header>trial.talents_steps.step_1.title</Header>
          <div className='h-4'></div>
          <Paragraph className='mt-2'>
            trial.talents_steps.step_1.paragraph_1
          </Paragraph>
          <Paragraph className='mt-2'>
            trial.talents_steps.step_1.paragraph_2
          </Paragraph>
        </>
      ),
      placement: 'center',
      target: 'body'
    },
    {
      content: (
        <>
          <Header>trial.talents_steps.step_2.title</Header>
          <div className='h-4'></div>
          <Paragraph className='mt-2'>
            trial.talents_steps.step_2.paragraph_1
          </Paragraph>
        </>
      ),
      placement: 'bottom',
      target: '#btn-invite-talent'
    },
    {
      content: (
        <>
          <Header>trial.talents_steps.step_3.title</Header>
          <div className='h-4'></div>
          <Paragraph className='mt-2'>
            trial.talents_steps.step_3.paragraph_1
          </Paragraph>
        </>
      ),
      placement: 'right',
      target: '#link-to-careers'
    },
    {
      content: (
        <>
          <Header>trial.talents_steps.step_4.title</Header>
          <div className='h-4'></div>
          <Paragraph className='mt-2'>
            trial.talents_steps.step_4.paragraph_1
          </Paragraph>
        </>
      ),
      placement: 'right',
      target: '#guide'
    }
  ]

  if (pathname === '/careers' && displayCareersTutorial) {
    return <Joyride steps={careers_steps} onFinish={onCloseCareersTutorial} />
  }

  if (pathname === '/talents' && displayTalentsTutorial) {
    return <Joyride steps={talents_steps} onFinish={onCloseTalentsTutorial} />
  }

  return null
}

Tutorials.propTypes = {
  displayCareersTutorial: PropTypes.bool.isRequired,
  onCloseCareersTutorial: PropTypes.func.isRequired,
  displayTalentsTutorial: PropTypes.bool.isRequired,
  onCloseTalentsTutorial: PropTypes.func.isRequired
}

export default Tutorials
