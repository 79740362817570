import { findSmartPredictStatus } from '../constants/smart-predict'
import {
  ASSESSMENT_SPACE_STATES,
  SMART_PREDICT_SPACE_STATES
} from '../constants/space-states'

const SIX_MONTH_BACK = Date.now() - 1000 * 60 * 60 * 24 * 30 * 6

const SPACE_STATES = {
  ...ASSESSMENT_SPACE_STATES,
  ...SMART_PREDICT_SPACE_STATES
}

const EMPTY_STATE = {
  key: 'EMPTY_STATE',
  status: { filterValue: 'empty', sortWeight: '9', color: 'grey' }
}

export const getSpacesTableData = ({ spaces = [], user_id }) => {
  return spaces.map((space) => mapSpaceForSpacesTable({ space, user_id }))
}

const mapSpaceForSpacesTable = ({ space, user_id }) => {
  const result = { ...space }

  const {
    id,
    partner_user_id,
    person,
    context,
    career_id,
    favored_by_user_id
  } = space

  const smart_predict_status = getSmartPredictStatus({ space })
  const assessment_status = getAssessmentStatus({
    space,
    spaceHasSmartPredictStatus: smart_predict_status.key === 'SMART_PREDICT'
  })

  result.score = getScore(space)
  result.assessment_status = assessment_status
  result.smart_predict_status = smart_predict_status
  result.status = { assessment_status, smart_predict_status }
  result.identification = partner_user_id || id
  result.via = 'LINK'

  if (context === 'SELECT') result.assessment_id = career_id
  else if (context === 'ATTRACT') result.assessment_id = 'ATTRACT'
  else if (context === 'TALENTPOOL') result.assessment_id = 'TALENTPOOL'

  result.created_status = {
    createdAt: space.createdAt,
    wasCreatedBefore6Month: Date.parse(space.createdAt) < SIX_MONTH_BACK
  }

  result.favored_by = {
    isFavored: !!favored_by_user_id?.includes(user_id),
    action: 'updateFavoredByUserId'
  }

  result.more = [
    result.favored_by.isFavored && 'IS_FAVORED',
    result.created_status.wasCreatedBefore6Month && 'BEFORE_6_MONTH'
  ].filter((item) => item)

  if (!person) return result

  // pbc-space could come with for example person: { firstname: 'Max' }
  const space_has_email = !!(person.email || person.invitation_email)

  result.firstname = person.firstname || person.invitation_firstname
  result.lastname = person.lastname || person.invitation_lastname

  const { firstname, lastname } = result
  const email = person.email || person.invitation_email

  result.email = email
  result.identification = email
  result.phone = person.phone
  result.via = space_has_email ? 'EMAIL' : 'LINK'
  result.name = getName(firstname, lastname)
  result.initials = getInitials(result.name)
  result.person = space.person

  return result
}

const getSmartPredictStatus = ({ space }) => {
  const { application_status } = space

  const status = findSmartPredictStatus(application_status)
  const result = status ? { key: 'SMART_PREDICT', status } : EMPTY_STATE

  if (application_status === 'ASSESSMENT_COMPLETED') {
    result.action = 'setSpaceForStateCTA'
  }

  return result
}

const getAssessmentStatus = ({ space, spaceHasSmartPredictStatus }) => {
  const { progress, person, application_status, expiresAt } = space

  const space_has_email = !!(person?.email || person?.invitation_email)

  if (application_status === 'INTEGRATION_SUCCESS') {
    return {
      key: 'INTEGRATION_SUCCESS',
      status: SPACE_STATES.INTEGRATION_SUCCESS
    }
  }

  if (application_status === 'INTEGRATION_ERROR') {
    return {
      key: 'INTEGRATION_ERROR',
      status: SPACE_STATES.INTEGRATION_ERROR
    }
  }

  // SOFT_DELETED spaces are at /recover/talents

  if (application_status === 'RECOVERED') {
    return {
      key: 'RECOVERED',
      status: SPACE_STATES.RECOVERED
    }
  }

  if (
    application_status === 'ASSESSMENT_COMPLETED' ||
    spaceHasSmartPredictStatus
  ) {
    return {
      key: 'ASSESSMENT_COMPLETED',
      status: SPACE_STATES.ASSESSMENT_COMPLETED
    }
  }

  if (application_status === 'EMAIL_BOUNCED') {
    return {
      key: 'EMAIL_BOUNCED',
      status: SPACE_STATES.EMAIL_BOUNCED
    }
  }

  if (expiresAt && new Date() > new Date(expiresAt)) {
    return {
      key: space_has_email ? 'EXPIRED_VIA_EMAIL' : 'EXPIRED_VIA_LINK',
      status: SPACE_STATES.EXPIRED
    }
  }

  if (application_status === 'REMINDED') {
    return {
      key: 'REMINDED',
      status: SPACE_STATES.REMINDED
    }
  }

  if (application_status === 'RESET_EXPIRED') {
    return {
      key: space_has_email
        ? 'RESET_EXPIRED_VIA_EMAIL'
        : 'RESET_EXPIRED_VIA_LINK',
      status: SPACE_STATES.RESET
    }
  }

  if (application_status === 'ASSESSMENT_STARTED') {
    return {
      key: space_has_email ? 'STARTED_VIA_EMAIL' : 'STARTED_VIA_LINK',
      status: { progress, ...SPACE_STATES.ASSESSMENT_STARTED }
    }
  }

  return {
    key: space_has_email ? 'INVITED_VIA_EMAIL' : 'INVITED_VIA_LINK',
    status: SPACE_STATES.TALENT_INVITED
  }
}

const getInitials = (name) => {
  if (!name) return ''

  return name.includes(' ') ? name : name[0]
}

const getName = (firstname, lastname) => {
  if (!firstname && !lastname) return undefined
  if (firstname && !lastname) return firstname
  if (!firstname && lastname) return lastname

  return `${firstname} ${lastname}`
}

const getScore = (space) => {
  return typeof space.matching_score_to_space_career === 'number'
    ? Number(space.matching_score_to_space_career.toFixed(0))
    : '-'
}
