import clsx from 'clsx'
import PropTypes from 'prop-types'

const VersionSwitch = ({ versions = [] }) => {
  return (
    <div className='flex space-x-1 rounded-lg bg-gray-100 p-0.5'>
      {versions.map(({ version, active, handleChange }, index) => (
        <button
          key={index}
          className={clsx(
            active ? 'bg-white shadow' : '',
            'group flex items-center rounded-md px-4 py-2'
          )}
          onClick={active ? () => undefined : handleChange}
        >
          <span
            className={clsx(
              'text-sm font-semibold',
              active
                ? 'text-gray-900 underline decoration-sky-500 decoration-2 underline-offset-2'
                : 'text-gray-500'
            )}
          >
            {`v${version}`}
          </span>
          {/* 
            <span
              className={clsx(
                'ml-1.5 text-sm font-semibold',
                active ? 'text-gray-900' : 'text-gray-700'
              )}
            >
              {text}
            </span> 
          */}
        </button>
      ))}
    </div>
  )
}

VersionSwitch.propTypes = { versions: PropTypes.array }

export default VersionSwitch
