import {
  AdjustmentsVerticalIcon,
  BellIcon,
  BuildingOfficeIcon,
  CodeBracketIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  PaperAirplaneIcon,
  PlayIcon,
  ShareIcon,
  UserIcon,
  UsersIcon
} from '@heroicons/react/24/solid'

export const tabsConfig = (t, hash) => [
  {
    id: 'PERSONAL',
    name: t('components.settings.user_profile.settings_container_title'),
    icon: UserIcon,
    current: hash === '#personal',
    to: '#personal'
  },
  {
    id: 'NOTIFICATIONS',
    name: t('settings.notifications.settings_container_title'),
    icon: BellIcon,
    current: hash === '#notifications',
    to: '#notifications'
  },
  {
    id: 'COMPANY',
    name: t('components.settings.company_profile.settings_container_title'),
    icon: BuildingOfficeIcon,
    current: hash === '#company',
    to: '#company'
  },
  {
    id: 'USER',
    name: t('settings.user_management.settings_container_title'),
    icon: UsersIcon,
    current: hash === '#user',
    to: '#user',
    withNewBadge: true
  },
  {
    id: 'TEMPLATES',
    name: t('settings.mail_templates.title'),
    icon: PaperAirplaneIcon,
    current: hash === '#templates',
    to: '#templates'
  },
  {
    id: 'ASSESSMENT',
    name: t('settings.assessment.title'),
    icon: AdjustmentsVerticalIcon,
    current: hash === '#assessment',
    to: '#assessment'
  },
  {
    id: 'RESULTREPORT',
    name: t('components.settings.result_reports.title'),
    icon: DocumentChartBarIcon,
    current: hash === '#resultreport',
    to: '#resultreport'
  },
  {
    id: 'PLAN',
    name: t('components.settings.payment_plan.title'),
    icon: CreditCardIcon,
    current: hash === '#plan',
    to: '#plan'
  },
  {
    id: 'INTEGRATIONS',
    name: t('settings.integrations.settings_container_title'),
    icon: ShareIcon,
    current: hash === '#integrations',
    to: '#integrations'
  },
  {
    id: 'ATS',
    name: t('components.settings.api_keys.settings_container_title'),
    icon: CodeBracketIcon,
    current: hash === '#ats',
    to: '#ats'
  },
  {
    id: 'AUTOMATIONS',
    name: t('settings.automations.tab_title'),
    icon: PlayIcon,
    current: hash === '#automations',
    to: '#automations'
  }
]
