import PropTypes from 'prop-types'
import Lottie from 'react-lottie-player'

import MatrixIndicator from '../../../assets/animations/matrix-indicator.json'
import { classNames } from '../../../helpers'

const HerosJourneyMatrix = ({ className, labels, pointPosition }) => {
  const { x, y } = labels

  return (
    <div className={classNames('relative', className)}>
      {/* y-axis */}
      <div
        style={{ background: '#B6C7F2' }}
        className='absolute bottom-0 left-1/2 top-0 w-0.5 -translate-x-1/2 transform'
      />

      {/* x-axis */}
      <div
        style={{ background: '#B6C7F2' }}
        className='absolute left-0 right-0 top-1/2 h-0.5 -translate-y-1/2 transform'
      />

      <span className='absolute -bottom-1 left-1/2 -mb-4 -translate-x-1/2 transform text-sm text-gray-700'>
        {y.minus}
      </span>
      <span className='absolute -top-1 left-1/2 -mt-4 -translate-x-1/2 transform text-sm text-gray-700'>
        {y.plus}
      </span>

      <span className='absolute right-0 top-1/2 translate-y-1 transform text-sm text-gray-700'>
        {x.plus}
      </span>
      <span className='absolute left-0 top-1/2 translate-y-1 transform text-sm text-gray-700'>
        {x.minus}
      </span>

      <div className='flex w-full justify-center'></div>

      <div className='absolute' style={pointPosition}>
        <Lottie
          style={{ width: 32, height: 32 }}
          animationData={MatrixIndicator}
          play
          loop
        />
      </div>
    </div>
  )
}

HerosJourneyMatrix.propTypes = {
  className: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  pointPosition: PropTypes.object.isRequired
}

export default HerosJourneyMatrix
