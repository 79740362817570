import { EnvelopeIcon, LinkIcon } from '@heroicons/react/20/solid'
import {
  ArrowDownTrayIcon as ArrowDownTrayIconOutline,
  CubeTransparentIcon
} from '@heroicons/react/24/outline'
import {
  ArrowDownTrayIcon as ArrowDownTrayIconSolid,
  EllipsisHorizontalIcon,
  Square3Stack3DIcon,
  UserGroupIcon
} from '@heroicons/react/24/solid'
import { rankItem } from '@tanstack/match-sorter-utils'
import { formatDistance, subSeconds } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { PublicInvitationModal, Seo } from '../components'
import CSVExport from '../components/actions/csv-export'
import DeleteSpace from '../components/actions/delete-space'
import ResetExpired from '../components/actions/reset-expired'
import SendReminderBulk from '../components/actions/send-reminder-bulk'
import SetSpaceState from '../components/actions/set-space-state'
import ActivityIndicator from '../components/activity-indicator'
import CustomerEffortScore from '../components/customer-effort-score'
import HelpSupportHint from '../components/HelpSupportHint'
import NetworkError from '../components/network-error'
import Table from '../components/react-table/table'
import Button from '../components/tailwind/Button'
import Dropdown from '../components/tailwind/dropdown'
import MenuButton from '../components/tailwind/MenuButton'
import PageHeading from '../components/tailwind/PageHeading'
import CreateSpaceModal from '../components/talents/create-space-modal'
import CrossmodeSlideover from '../components/talents/CrossmodeSlideover'
import ReinviteSpaceModal from '../components/talents/reinvite-space-modal'
import StorePartnerLogoModal from '../components/talents/store-partner-logo-modal'
import trackEvents from '../constants/track-events'
import {
  useAivyContext,
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  usePaymentContext,
  useProductTourContext,
  useUserContext
} from '../context'
import { query } from '../graphql'
import { S3_AIVY_LOGO, classNames, uuidSmall } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import { createTalentTableColumns } from '../helpers/spaces-table-columns'
import { useMount } from '../hooks/use-mount'
import { useSetState } from '../hooks/use-set-state'
import { useSpaceActions } from '../hooks/use-space-actions'
import { useTalentsQuery } from '../hooks/use-talents-query'
import { useUpdateFavoredByUserId } from '../hooks/use-update-favored-by-user-id'

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const I18N = 'pages.talents.'
const locale = { de, en: enUS }

const Talents = () => {
  const { t } = useTranslation()

  const { language } = useLanguageContext()
  const { is_trial, products } = usePaymentContext()
  const { partner } = usePartnerContext()
  const { active_space } = useUserContext()

  const { adminInsights } = useAivyContext()
  const { alert } = useNotificationContext()
  const { state: navigationState } = useLocation()

  const [isCustomerEffortScoreVisible, setIsCustomerEffortScoreVisible] =
    useState(false)

  // use ref because more than one request could be in process
  const crossmodeLoading = useRef([])

  // const [guided_steps] = useState(talents_steps)

  const navigate = useNavigate()

  useEffect(() => {
    const storageSearchParams = localStorage.getItem('/talents')

    if (storageSearchParams) {
      navigate({ search: storageSearchParams }, { replace: true })
    }
  }, [navigate])

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const [selectionState, setSelectionState] = useSetState({
    spaces: [],
    career_ids: []
  })

  const [time, setTime] = useState(null)
  const [showRefetch, setShowRefetch] = useState(false)
  const [showCreateSpaceModal, setShowCreateSpaceModal] = useState(false)
  const [showStorePartnerLogo, setShowStorePartnerLogo] = useState(false)
  const [invitationType, setInvitationType] = useState(null)
  const [showReinviteSpaceModal, setShowReinviteSpaceModal] = useState(null)
  const [isPublicInvitationModalVisible, setIsPublicInvitationModalVisible] =
    useState(false)
  const [crossmode, setCrossmode] = useState(false)
  const [matchBatchIfYouCan, setMatchBatchIfYouCan] = useState({})
  const [resetTableKey, setResetTableKey] = useState(uuidSmall())
  const [spaceForStateCTA, setSpaceForStateCTA] = useState(null)
  const [rerender, setRerender] = useState(uuidSmall())
  const [csvExportModalState, setCsvExportModalState] = useSetState({
    open: false,
    state: null
  })

  const {
    actions: spaceActions,
    displaySendReminder,
    setDisplaySendReminder,
    displaySetSpaceState,
    setDisplaySetSpaceState,
    displayDeleteSpace,
    setDisplayDeleteSpace,
    displayResetExpired,
    setDisplayResetExpired
  } = useSpaceActions({ spaces: selectionState.spaces })

  const { updateFavoredByUserId, FavoredSpaceHint } = useUpdateFavoredByUserId()

  useEffect(() => {
    if (navigationState === null) return

    if (navigationState.inviteTalentpool === true) {
      setTimeout(() => {
        setShowCreateSpaceModal(true)
      }, 300)
    }
  }, [navigationState])

  useEffect(() => {
    // fetch only if crossmode gets active
    if (!crossmode) return

    // or a career gets added / removed
    if (!selectionState.career_ids.length) return

    const fetchCrossmode = () => {
      crossmodeLoading.current.push('fetching')
      setRerender(uuidSmall())

      query({
        query: 'matchBatchIfYouCan',
        variables: {
          careers: selectionState.career_ids,
          users: selectionState.spaces
            .map(({ owner }) => owner)
            .filter((owner) => owner)
        }
      }).then((response) => {
        setMatchBatchIfYouCan(JSON.parse(response))
        crossmodeLoading.current.pop()
        setRerender(uuidSmall())
      })
    }

    fetchCrossmode()
  }, [selectionState, crossmode])

  const { status, data, refetch, dataUpdatedAt, fetchStatus } =
    useTalentsQuery()

  const fetchingSubtitle = useMemo(() => {
    if (status === 'error') return null

    // data updated at defaults to 0 for an empty cache
    if (!dataUpdatedAt) {
      return t('talents.fetching_subtitle.is_loading')
    }

    if (fetchStatus === 'fetching') {
      return t('talents.fetching_subtitle.is_updating')
    }

    return t('talents.fetching_subtitle.last_updated_at', {
      time, // only used here for the use memo dependencies
      distance_sub_seconds: formatDistance(
        subSeconds(new Date(dataUpdatedAt), 3),
        new Date(),
        {
          locale: locale[language]
        }
      )
    })
  }, [status, dataUpdatedAt, fetchStatus, time, language, t])

  useEffect(() => {
    // update fetching subtitle every minute
    const interval = setInterval(() => setTime(Date.now()), 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!dataUpdatedAt) return

    const pastDate = new Date(dataUpdatedAt)

    // 2. Erstelle ein aktuelles Date-Objekt
    const currentDate = new Date()

    // 3. Berechne die Differenz in Millisekunden
    const diffInMilliseconds = currentDate - pastDate

    // 4. Wandle die Differenz in Minuten um
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60))
    if (diffInMinutes >= 5 && showRefetch === false) {
      setShowRefetch(true)
    }

    if (showRefetch === true && diffInMinutes < 5) {
      setShowRefetch(false)
    }
  }, [time, dataUpdatedAt, showRefetch])

  const careers_map = useMemo(() => {
    return new Map([
      ...(data ? data.careers : []).map((career) => [career.id, career]),
      ['TALENTPOOL', { title: t('talents.talentpool_title') }],
      ['ATTRACT', { title: 'Attract' }]
    ])
  }, [data, t])

  const handleNavigate = useCallback(
    (cell) => {
      const { id: space_id } = cell.row.original

      navigate(`/talent/${space_id}`)
    },
    [navigate]
  )

  const columns = useMemo(
    () =>
      createTalentTableColumns({
        adminInsights,
        careers_map,
        data,
        handleNavigate,
        language,
        locale,
        partner,
        t,
        actions: {
          setSpaceForStateCTA: (space) => {
            setSpaceForStateCTA(space)
            trackEvent(trackEvents.PRESS_SPACE_SET_STATE_TALENTTABLE)
          },
          updateFavoredByUserId
        },
        displayIntegrationFilterOptions: products.includes('INTEGRATIONS')
      }),
    [
      data,
      careers_map,
      handleNavigate,
      adminInsights,
      t,
      partner,
      language,
      updateFavoredByUserId,
      products
    ]
  )

  const globalFilterFn = useCallback(
    (row, columnId, value, addMeta) => {
      let columnValue = row.getValue(columnId)

      if (columnId === 'assessment_id') {
        columnValue = (careers_map.get(columnValue) || {}).title
      }

      const itemRank = rankItem(columnValue, value)

      addMeta({ itemRank })

      return itemRank.passed
    },
    [careers_map]
  )

  const selectionCallback = useCallback(
    (table) => {
      setSelectionState({
        spaces: table
          .getSelectedRowModel()
          .rows.map(({ original, toggleSelected }) => ({
            ...original,
            removeSelected: () => toggleSelected(false)
          }))
      })
    },
    [setSelectionState]
  )

  if (status === 'pending') {
    return <ActivityIndicator />
  }

  // rerender is always set, we only need a trigger
  if (status === 'error' || !rerender) {
    return (
      <div className='w-full'>
        <Seo title={t('seo.titles.talents')} />
        <NetworkError />
      </div>
    )
  }

  const handleOnClickInviteSpace = ({ event, type }) => {
    setInvitationType(type)
    trackEvent(event)

    if (
      !active_space.has_seen_store_partner_logo_hint &&
      partner.logo === S3_AIVY_LOGO
    ) {
      setShowStorePartnerLogo(true)
      return
    }

    setShowCreateSpaceModal(true)
  }

  const closeStorePartnerLogoModal = () => {
    setShowStorePartnerLogo(false)
    setTimeout(() => setShowCreateSpaceModal(true), 0.3 * 1000)
  }

  const pageHeadingParams = {
    title: t('seo.titles.talents'),
    subtitle: fetchingSubtitle,
    withRefetch: showRefetch ? refetch : undefined,
    withActions: (
      <div className='flex flex-wrap items-center gap-x-2'>
        <Button.SecondaryXL
          text={t('career.assessmentsettings.public_link.title')}
          onClick={() => {
            trackEvent('pressedPublicPartnerInvitationButton')
            setIsPublicInvitationModalVisible(true)
          }}
        />
        <MenuButton
          cta={t(I18N + 'open_create_space_modal')}
          menuItems={[
            {
              text: t(I18N + 'open_create_space_modal_viaemail'),
              onClick: () =>
                handleOnClickInviteSpace({
                  event: trackEvents.INVITE_VIA_EMAIL,
                  type: 'EMAIL'
                }),
              Icon: EnvelopeIcon
            },
            {
              text: t(I18N + 'open_create_space_modal_vialink'),
              onClick: () =>
                handleOnClickInviteSpace({
                  event: trackEvents.INVITE_VIA_LINK,
                  type: 'LINK'
                }),
              Icon: LinkIcon
            }
          ]}
        />
      </div>
    )
  }

  if (!data.spaces.length) {
    return (
      <>
        <Seo title={t('seo.titles.talents')} />
        <PageHeading {...pageHeadingParams} />
        <div className='flex flex-col items-center justify-center px-4'>
          <UserGroupIcon
            className='mx-auto mt-24 h-16 w-16 text-gray-700'
            aria-hidden='true'
          />
          <h3 className='mt-4 text-center text-xl font-medium text-gray-900'>
            {t(I18N + 'title')}
          </h3>
          <p className='mt-4 max-w-xl whitespace-pre-line text-center text-sm text-gray-700'>
            {t(I18N + 'introduction')}
          </p>
        </div>
        <CreateSpaceModal
          data={{ ...data, careers_map }}
          refetchSpaces={refetch}
          open={showCreateSpaceModal}
          invitationType={invitationType}
          closeCreateSpaceModal={() => setShowCreateSpaceModal(false)}
          openCreateSpaceModal={() => setShowCreateSpaceModal(true)}
        />
        <StorePartnerLogoModal
          open={showStorePartnerLogo}
          setOpen={closeStorePartnerLogoModal}
        />
        <PublicInvitationModal
          open={isPublicInvitationModalVisible}
          setOpen={setIsPublicInvitationModalVisible}
        />
      </>
    )
  }

  const displayDefaultActionNotAvailableHint = () => {
    alert(
      t('talents.action_not_availabe.modal_title'),
      [t('talents.action_not_availabe.modal_text')],
      [
        {
          text: t('talents.action_not_availabe.modal_close_action'),
          style: 'cancel'
        }
      ]
    )
  }

  const displayCrossmodeActionNotAvailableHint = () => {
    alert(
      t('talents.crossmode_not_availabe.modal_title'),
      [t('talents.crossmode_not_availabe.modal_text')],
      [
        {
          text: t('talents.crossmode_not_availabe.modal_close_action'),
          style: 'cancel'
        }
      ]
    )
  }

  const displayCrossmodeMax18SpacesHint = () => {
    alert(
      t('talents.crossmode_max_18.modal_title'),
      [t('talents.crossmode_max_18.modal_text')],
      [
        {
          text: t('talents.crossmode_max_18.modal_close_action'),
          style: 'cancel'
        }
      ]
    )
  }

  return (
    <>
      <Seo title={t('seo.titles.talents')} />
      <PageHeading {...pageHeadingParams} />

      <Table
        key={resetTableKey}
        data={data.spaces}
        columns={columns}
        globalFilterFn={globalFilterFn}
        initialState={{ sorting: [{ id: 'updatedAt', desc: true }] }}
        withActions={
          <motion.div
            variants={container}
            initial='hidden'
            animate='visible'
            className='mr-8 flex items-center gap-x-2'
          >
            {[
              {
                id: 'action-crossmode',
                onClick: () => {
                  const { spaces: s } = selectionState
                  if (s.length > 18) displayCrossmodeMax18SpacesHint()
                  else setCrossmode(true)
                },
                available: partner.isScoreVisible,
                handleActionNotAvailable:
                  displayCrossmodeActionNotAvailableHint,
                icons: { outline: CubeTransparentIcon },
                label: t('react_table.select_bar.action_crossmode')
              },
              {
                id: 'csv-export',
                onClick: () => {
                  setCsvExportModalState({ open: true, state: 'selected' })
                },
                label: t('talents.options.csv_export_title'),
                available: true,
                icons: { outline: ArrowDownTrayIconOutline }
              },
              ...spaceActions.map((action) => ({
                ...action,
                handleActionNotAvailable: displayDefaultActionNotAvailableHint
              }))
            ].map(
              (
                {
                  id,
                  onClick,
                  available,
                  handleActionNotAvailable,
                  icons,
                  label
                },
                index
              ) => {
                const Icon = icons.outline

                return (
                  <div key={index}>
                    <motion.button
                      id={id}
                      data-tooltip-id={id}
                      className={classNames(
                        'group flex h-8 items-center justify-center rounded-full px-1',
                        'cursor-pointer hover:bg-blue-700'
                      )}
                      onClick={available ? onClick : handleActionNotAvailable}
                      variants={item}
                    >
                      <Icon
                        className={classNames(
                          'h-6 w-6',
                          available ? 'text-white' : 'text-blue-400'
                        )}
                      />
                    </motion.button>
                    <ReactTooltip
                      id={id}
                      className='rounded-full font-medium'
                      content={label}
                      style={{ backgroundColor: '#182033' }}
                      place='top'
                      variant='info'
                    />
                  </div>
                )
              }
            )}
          </motion.div>
        }
        selectionCallback={selectionCallback}
        disableVirtual={is_trial}
        displayNumberOfEntries
        withOptions={
          <Dropdown
            MenuButtonX={() => (
              <div className='group flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200'>
                <EllipsisHorizontalIcon className='h-5 w-5 text-gray-700 group-hover:text-gray-900' />
              </div>
            )}
            actions={[
              {
                key: 'csv-export',
                label: t('talents.options.csv_export_title'),
                available: true,
                Icon: ArrowDownTrayIconSolid,
                onClick: () =>
                  setCsvExportModalState({ open: true, state: 'all' })
              },
              {
                key: 'recover-talents',
                label: t('talents.options.recover_talents_title'),
                available: true,
                Icon: Square3Stack3DIcon,
                onClick: () => navigate('/recover/talents')
              }
            ]}
          />
        }
      />

      <CrossmodeSlideover
        open={crossmode}
        handleClose={() => setCrossmode(false)}
        {...{
          data,
          setSelectionState,
          crossmodeLoading,
          selectionState,
          matchBatchIfYouCan,
          setShowReinviteSpaceModal,
          careers_map
        }}
      />
      <CreateSpaceModal
        data={{ ...data, careers_map }}
        refetchSpaces={refetch}
        open={showCreateSpaceModal}
        invitationType={invitationType}
        closeCreateSpaceModal={() => setShowCreateSpaceModal(false)}
        openCreateSpaceModal={() => setShowCreateSpaceModal(true)}
      />
      {showReinviteSpaceModal && (
        <ReinviteSpaceModal
          space={selectionState.spaces.find(
            ({ id }) => id === showReinviteSpaceModal
          )}
          updateHandler={refetch}
          open={!!showReinviteSpaceModal}
          setOpen={() => {
            setCrossmode(true)
            setShowReinviteSpaceModal(null)
          }}
        />
      )}
      <PublicInvitationModal
        open={isPublicInvitationModalVisible}
        setOpen={setIsPublicInvitationModalVisible}
      />
      <SetSpaceState
        spaces={selectionState.spaces}
        updateHandler={() => {
          refetch()
          setResetTableKey(uuidSmall())
        }}
        open={displaySetSpaceState}
        setOpen={setDisplaySetSpaceState}
        setIsCustomerEffortScoreVisible={setIsCustomerEffortScoreVisible}
      />
      <SetSpaceState
        spaces={spaceForStateCTA ? [spaceForStateCTA] : []}
        updateHandler={() => {
          refetch()
          setResetTableKey(uuidSmall())
        }}
        open={!!spaceForStateCTA}
        setOpen={() => setSpaceForStateCTA(null)}
        setIsCustomerEffortScoreVisible={setIsCustomerEffortScoreVisible}
      />
      <CustomerEffortScore
        open={isCustomerEffortScoreVisible}
        setOpen={setIsCustomerEffortScoreVisible}
        context='TALENT-STATE'
      />
      <DeleteSpace
        spaces={[...selectionState.spaces]}
        updateHandler={() => {
          refetch().then(() => {
            setResetTableKey(uuidSmall())
            trackEvent(trackEvents.DELETE_TALENTS)
          })
        }}
        open={displayDeleteSpace}
        setOpen={setDisplayDeleteSpace}
      />
      <SendReminderBulk
        data={{ spaces: selectionState.spaces }}
        updateHandler={() => {
          refetch()
          setResetTableKey(uuidSmall())
        }}
        open={displaySendReminder}
        setOpen={setDisplaySendReminder}
      />
      <ResetExpired
        spaces={selectionState.spaces}
        updateHandler={({ onSuccess }) =>
          refetch().then(() => {
            onSuccess()
            setResetTableKey(uuidSmall())
          })
        }
        open={displayResetExpired}
        setOpen={setDisplayResetExpired}
      />
      <CSVExport
        state={csvExportModalState}
        setState={setCsvExportModalState}
        spaces={
          selectionState.spaces.length ? selectionState.spaces : data.spaces
        }
        careers_map={careers_map}
      />
      <HelpSupportHint />
      <StorePartnerLogoModal
        open={showStorePartnerLogo}
        setOpen={closeStorePartnerLogoModal}
      />
      <FavoredSpaceHint />
    </>
  )
}

export default Talents
