import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ClockIcon,
  ShieldCheckIcon,
  VariableIcon
} from '@heroicons/react/24/outline'
import { getAppSettings } from '../../constants/app-settings'
import { useAivyContext, useLanguageContext } from '../../context'
import { convertNumberToTime } from '../../helpers'
import {
  getDifficultyOptions,
  mapChallengesToCoveredDimensions,
  testBatteryConfig
} from '../../helpers/diagnostic'
import { useCreateCareerNavigation } from '../../hooks/use-create-career-navigation'
import { useSetState } from '../../hooks/use-set-state'
import Button from '../tailwind/Button'
import DividerWithText from './adjustment/divider-with-text'
import ChallengeCard from './pieces/challenge-card'

export const I18N_PREFIX = 'create_career.confirm_testbattery.'

const ConfirmTestbattery = ({ career, careerMutation }) => {
  const { t } = useTranslation()
  const { system } = useAivyContext()
  const { language } = useLanguageContext()

  const career_analyse = JSON.parse(career.career_analyse)
  const system_dimensions_data = JSON.parse(system.dimensions_pdf_reports.data)

  const {
    isLoadingBack,
    isLoadingNext,
    handleBackNavigation,
    handleNextNavigation
  } = useCreateCareerNavigation({ career, careerMutation })

  const configuration = testBatteryConfig(career_analyse.scores)

  const [testbattery] = useState(
    Object.keys(configuration).filter(
      (key) => configuration[key].include === true
    )
  )

  const app_settings_challenges = JSON.parse(
    career.app_settings?.challenges || '[]'
  ).map(({ exam_id }) => exam_id)

  // inside this success screen
  // we want only the optional challenges to be additionally selectable
  // moreChallenges = { [exam_id]: true/false } / useSetState!
  const [moreChallenges, setMoreChallenges] = useSetState(
    Object.keys(configuration)
      .filter((key) => configuration[key].optional === true)
      .filter((exam_id) => !testbattery.includes(exam_id))
      .reduce(
        (object, exam_id) => ({
          ...object,
          [exam_id]: app_settings_challenges.includes(exam_id)
            ? 'selected'
            : 'not_selected'
        }),
        {}
      )
  )

  const [isLoadingExpertMode, setIsLoadingExpertMode] = useState(false)

  const getDuration = () => {
    const timeToComplete = testbattery
      .map((exam_id) => system.challenges[exam_id].timeToComplete)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

    const { minutes: duration } = convertNumberToTime(timeToComplete / 60000)
    return { duration }
  }

  const getSelectionDifficulty = () => {
    const selection_difficulty = career_analyse.selection_difficulty ?? 'NORMAL'

    const option = getDifficultyOptions().find(
      ({ difficulty }) => difficulty === selection_difficulty
    )

    return t(option.label)
  }

  const mandatoryCriterions = Object.entries(career_analyse.scores)
    .filter(([_, value]) => value.mandatoryCriterion === true)
    .map(([key]) => key)

  const getMandatoryCriterions = () => {
    const { dimensions_pdf_reports: dimensions } = system

    return mandatoryCriterions
      .map((dimension_key) => dimensions[dimension_key].property[language])
      .join(', ')
  }

  const stats = [
    {
      name: t(I18N_PREFIX + 'duration_title'),
      stat: t(I18N_PREFIX + 'duration_value', getDuration()),
      icon: ClockIcon
    },
    {
      name: t(I18N_PREFIX + 'selection_difficulty_label'),
      stat: getSelectionDifficulty(),
      icon: VariableIcon
    },
    !!mandatoryCriterions.length && {
      name: t(I18N_PREFIX + 'must_criteria_label'),
      stat: getMandatoryCriterions(),
      icon: ShieldCheckIcon
    }
  ].filter((item) => !!item)

  const handleNext = ({ status }) => {
    const { currentChallengeConfigId } = system

    const {
      career_analyse,
      challenge_config_id,
      app_settings,
      customer_dashboard_meta
    } = career

    const challenges = [
      ...testbattery.map((exam_id) => ({ exam_id })),
      ...Object.entries(moreChallenges)
        .filter(([_, value]) => value === 'selected')
        .map(([key]) => ({ exam_id: key }))
    ]

    const dimensions = mapChallengesToCoveredDimensions({
      challenges,
      dimensions: Object.values(system_dimensions_data)
    })

    const updatedOverrideDefaults = {
      selected_challenges: challenges,
      dimensions,
      selection_difficulty: 'NORMAL',
      mandatory_criteria: mandatoryCriterions
    }

    const meta = JSON.parse(customer_dashboard_meta || '{}')
    const parsed_career_analyse = { ...JSON.parse(career_analyse) }

    if (status === 'WAIT_CONFIG') {
      meta.expert_mode_used = true
      parsed_career_analyse.override_defaults = updatedOverrideDefaults
    }

    if (status === 'WAIT_ACTIVE') {
      meta.expert_mode_used = false
      parsed_career_analyse.dimensions = dimensions
    }

    const input = {
      challenge_config_id: challenge_config_id || currentChallengeConfigId,
      app_settings: getAppSettings(app_settings, challenges),
      career_analyse: JSON.stringify(parsed_career_analyse),
      customer_dashboard_meta: JSON.stringify(meta)
    }

    handleNextNavigation({ input, nextStatus: status })
  }

  return (
    <>
      <div>
        <span className='text-4xl font-extrabold tracking-tight text-blue-600'>
          {t(I18N_PREFIX + 'title')}
        </span>
        <p className='mt-4 w-full text-sm text-gray-900 md:w-8/12'>
          {t(I18N_PREFIX + 'description')}
        </p>
      </div>
      <div className='my-8 flex flex-col gap-x-8 gap-y-4'>
        {stats.map((item, index) => (
          <div key={index} className='flex'>
            <div className='h-12 w-12 rounded-full bg-blue-500 p-3'>
              <item.icon className='h-6 w-6 text-white' aria-hidden='true' />
            </div>
            <div className='ml-4 flex flex-col justify-center'>
              <span className='whitespace-nowrap text-sm text-gray-500'>
                {item.name}
              </span>
              <span className='xtext-sm -mt-0.5 font-semibold text-gray-900'>
                {item.stat}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className='mt-8 flex items-center'>
        <span className='text-xl font-medium tracking-tight text-gray-900'>
          {t(I18N_PREFIX + 'testbattery_title')}
        </span>
      </div>
      <div className='mt-4 grid grid-cols-1 gap-4 xl:grid-cols-2'>
        {testbattery
          .map((exam_id) => ({
            exam_id,
            ...system.challenges[exam_id],
            ...configuration[exam_id]
          }))
          .map((challenge, index) => (
            <ChallengeCard
              key={index}
              {...{
                challenge,
                className: 'h-full col-span-1 w-full max-w-xl mx-auto'
              }}
            />
          ))}
      </div>

      {!!Object.keys(moreChallenges).length && (
        <DividerWithText
          className='mt-8'
          text={t(I18N_PREFIX + 'more_challenges_title')}
        />
      )}

      <div className='mt-8 grid grid-cols-1 gap-4 xl:grid-cols-2'>
        {Object.keys(moreChallenges)
          .map((exam_id) => ({
            exam_id,
            ...system.challenges[exam_id],
            ...configuration[exam_id]
          }))
          .map((challenge, index) => {
            const { exam_id } = challenge
            const selected = moreChallenges[exam_id] === 'selected'

            const onClick = () => {
              setMoreChallenges({
                [exam_id]: selected ? 'not_selected' : 'selected'
              })
            }

            return (
              <ChallengeCard
                key={index}
                {...{
                  challenge,
                  onClick,
                  selected,
                  className: clsx(
                    'hover:ring-1 hover:ring-indigo-600 hover:border-indigo-600',
                    selected && 'ring-1 ring-indigo-600 !border-indigo-600'
                  )
                }}
              />
            )
          })}
      </div>

      <div className='mt-24 flex justify-end gap-x-2'>
        <Button.Text
          className='mr-2'
          onClick={() => {
            setIsLoadingExpertMode(true)
            handleNext({ status: 'WAIT_CONFIG' })
          }}
          text={t(I18N_PREFIX + 'expertmode_action')}
          isLoading={isLoadingExpertMode}
        />
        <Button.SecondaryXL
          onClick={() => handleBackNavigation({})}
          isLoading={isLoadingBack}
          text={t('create_career.back_action')}
        />
        <Button.PrimaryXL
          text={t('create_career.next_action')}
          isLoading={!isLoadingExpertMode && isLoadingNext}
          className='bg-green-600 hover:bg-green-500'
          onClick={() => handleNext({ status: 'WAIT_ACTIVE' })}
        />
      </div>
    </>
  )
}

ConfirmTestbattery.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired
}

export default ConfirmTestbattery
