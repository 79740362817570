import { PencilIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import trackEvents from '../../constants/track-events'
import { classNames } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import InputFormik from '../input-formik'

const DD_TEXT = 'mt-1 text-md font-medium text-gray-900'

const SpaceDetailItem = ({ term, details = '', onClick, input }) => {
  const { t } = useTranslation()

  return (
    <div
      onClick={onClick}
      className={classNames('sm:col-span-1', onClick && 'group cursor-pointer')}
    >
      <dt className='text-sm text-gray-500'>{term}</dt>
      {input ? (
        <>
          {input.formik.status[input.id] ? (
            <form
              onBlur={input.formik.resetForm}
              onSubmit={input.formik.handleSubmit}
            >
              <InputFormik ref={(ref) => ref && ref.focus()} {...input} />
            </form>
          ) : (
            <button
              className={classNames(
                'group flex items-center text-left hover:text-blue-600',
                DD_TEXT
              )}
              onClick={() => {
                trackEvent(trackEvents.EDIT_SPACE_PERSON)
                input.formik.setStatus({ [input.id]: true })
              }}
            >
              {details && <span>{details}</span>}
              {!details && (
                <span className='text-sm font-normal italic'>
                  {t('pages.talent.input_empty_action')}
                </span>
              )}
              <PencilIcon
                className={classNames(
                  'ml-1 hidden h-3 w-auto',
                  details && 'group-hover:inline-block'
                )}
              />
            </button>
          )}
        </>
      ) : (
        <dd className={classNames('group-hover:text-blue-600', DD_TEXT)}>
          {details}
        </dd>
      )}
    </div>
  )
}

SpaceDetailItem.propTypes = {
  term: PropTypes.string.isRequired,
  details: PropTypes.any,
  onClick: PropTypes.func,
  input: PropTypes.object
}

SpaceDetailItem.defaultProps = {
  onClick: undefined,
  input: undefined
}

export default SpaceDetailItem
