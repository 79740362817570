import { useTranslation } from 'react-i18next'

import Divider from '../divider'
import SEO from '../seo'
import DeleteSpaces from './automations/delete-spaces'
import RemindSpaces from './automations/remind-spaces'

const Automations = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('settings.automations.tab_title')} />
      <RemindSpaces />
      <Divider />
      <DeleteSpaces />
    </>
  )
}

export default Automations
