import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip
} from 'chart.js'
import PropTypes from 'prop-types'
import { Radar } from 'react-chartjs-2'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

const RadarChart = ({ data, options }) => {
  return <Radar data={data} options={options} />
}

RadarChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object
}

export default RadarChart
