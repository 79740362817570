import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import { JSONTree as ReactJSONTree } from 'react-json-tree'

// chatgpt
function parseJSONStringsAndRemoveNulls(obj) {
  if (typeof obj === 'string') {
    try {
      // try to parse the string
      const parsed = JSON.parse(obj)

      // if parsed is an object or array, recurse into it
      if (typeof parsed === 'object' && parsed !== null) {
        return parseJSONStringsAndRemoveNulls(parsed)
      } else {
        return parsed
      }
    } catch (e) {
      // if parsing fails, return the original string
      return obj
    }
  } else if (Array.isArray(obj)) {
    // if it's an array, parse each element and filter out null values
    return obj
      .map((item) => parseJSONStringsAndRemoveNulls(item))
      .filter((item) => item !== null)
  } else if (typeof obj === 'object' && obj !== null) {
    // if it's an object, parse each value and filter out null values
    return Object.keys(obj).reduce((result, key) => {
      const parsedValue = parseJSONStringsAndRemoveNulls(obj[key])
      if (parsedValue !== null) {
        result[key] = parsedValue
      }
      return result
    }, {})
  }

  // if it's neither a string, object, or array, return as is
  return obj
}

const AivyJSONTree = (props) => {
  return (
    <div
      style={{ backgroundColor: 'rgb(0, 43, 54)' }}
      className='mt-1 rounded-xl p-3 pt-1.5'
    >
      <ReactJSONTree
        {...{
          ...props,
          data: parseJSONStringsAndRemoveNulls(cloneDeep(props.data))
        }}
      />
    </div>
  )
}

AivyJSONTree.propTypes = { data: PropTypes.any.isRequired }

export default AivyJSONTree
