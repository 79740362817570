import { EditorContent, useEditor } from '@tiptap/react'
import PropTypes from 'prop-types'

import { extensions } from '../../constants/tiptap'
import MenuBar from './menu-bar'
import './styles.scss'

const Tiptap = ({
  inputContainerStyle,
  initialContent,
  macros,
  onCreate,
  onUpdate
}) => {
  const editor = useEditor({
    extensions,
    content: initialContent,
    onUpdate,
    onCreate
  })

  return (
    <div className='rounded-xl border border-gray-200'>
      <MenuBar editor={editor} macros={macros} />
      <div style={inputContainerStyle}>
        <EditorContent className='p-3' editor={editor} />
      </div>
    </div>
  )
}

Tiptap.propTypes = {
  inputContainerStyle: PropTypes.object,
  initialContent: PropTypes.object,
  macros: PropTypes.array,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func
}

Tiptap.defaultProps = {
  inputContainerStyle: undefined,
  initialContent: null,
  macros: null,
  onCreate: () => undefined,
  onUpdate: () => undefined
}

export default Tiptap

/** stash

<FloatingMenu editor={editor}>This is the floating menu</FloatingMenu>
<BubbleMenu editor={editor}>This is the bubble menu</BubbleMenu>
<div className='flex gap-x-16 items-center'>
  {editor && (
    <pre className='text-xs'>
      {JSON.stringify(editor.getJSON(), null, 2)}
    </pre>
  )}
  {editor && (
    <pre className='text-xs'>
      {JSON.stringify(editor.getHTML(), null, 2)}
    </pre>
  )}
</div>

*/
