import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon
} from '@heroicons/react/24/solid'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { Tooltip } from 'react-tooltip'

import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import trackEvents from '../../constants/track-events'
import { useLanguageContext } from '../../context'
import { startStrWithCapital } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import SlideOverDimensionDescription from './SlideOverDimensionDescription'

const SmallDivider = ({ percentage }) => {
  return (
    <div
      style={{ left: `calc(${percentage}% - 1px)`, bottom: -6 }}
      className='absolute h-4 w-0.5 rounded bg-gray-200' // height: 16px
    />
  )
}

SmallDivider.propTypes = { percentage: PropTypes.number.isRequired }

const BigDivider = ({ percentage }) => {
  return (
    <div
      style={{ left: `calc(${percentage}% - 2px)`, bottom: -10 }}
      className='absolute h-6 w-1 rounded bg-gray-200' // height: 24px
    />
  )
}

BigDivider.propTypes = { percentage: PropTypes.number.isRequired }

const Average = ({ percentage }) => {
  return (
    <div
      style={{ left: `calc(${percentage}% - 2px)`, bottom: -10 }}
      className='absolute h-6 w-1 rounded bg-orange-300' // height: 32px
    />
  )
}

Average.propTypes = { percentage: PropTypes.number.isRequired }

const Range = ({ from, to, average }) => {
  return (
    <>
      <div
        style={{
          left: `calc(${from}%)`,
          width: `calc(${to - from}%)`,
          bottom: 0
        }}
        className='absolute h-1 rounded bg-blue-300' // height: 32px
      />
      <div
        style={{ left: `calc(${average}% - 2px)`, bottom: -10 }}
        className='absolute h-6 w-1 rounded bg-blue-400' // height: 16px
      />
    </>
  )
}

Range.propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  average: PropTypes.number.isRequired
}

function calculateAverage(numbers) {
  if (numbers.length === 0) {
    return 0 // Return 0 if the array is empty to avoid division by zero
  }

  const sum = numbers.reduce((acc, num) => acc + num, 0)
  return sum / numbers.length
}

const getBackgroundColorPassung = (value, alpha) => {
  const match = Math.round(value)

  if (match >= 71 && match <= 100) {
    return alpha ? 'rgba(41, 204, 199, 0.5)' : '#29CCC7'
  } else if (match >= 41 && match < 71) {
    return alpha ? 'rgba(255, 154, 59, 0.5)' : '#FF9A3B'
  } else {
    return alpha ? 'rgba(255, 138, 176, 0.5)' : '#ff8ab0'
  }
}

const LinearDimension = ({ dimension }) => {
  const { language } = useLanguageContext()

  const [openDimensionDescription, setOpenDimensionsDescription] =
    useState(false)

  const {
    property,
    scaleEndpoints: { left, right, description },
    datasets,
    dataset_career
  } = dimension

  const { min, max, optimal } = dataset_career?.data || {}

  return (
    <div className='rounded-xl border border-gray-200 bg-gradient-to-r from-white to-gray-50 p-4'>
      <div className='flex items-start'>
        <div
          className='flex cursor-pointer flex-row items-center hover:opacity-60'
          onClick={() => {
            trackEvent(trackEvents.CLICK_DIMENSION_TOOLTIP)
            setOpenDimensionsDescription(dimension)
          }}
        >
          <span className='text-md block font-semibold text-gray-900'>
            {property[language]}
          </span>
          <div className='ml-1 inline-block'>
            <QuestionMarkCircleIcon className='h-5 w-5 text-gray-900' />
          </div>
        </div>
      </div>

      <div className='mt-5 flex items-center justify-center'>
        <div
          className='relative h-1 w-full rounded bg-gray-200' // height: 4px
        >
          {[10, 20, 30, 40].map((percentage, index) => (
            <SmallDivider key={index} {...{ percentage }} />
          ))}
          <BigDivider percentage={50} />
          {[60, 70, 80, 90].map((percentage, index) => (
            <SmallDivider key={index} {...{ percentage }} />
          ))}
          {!dataset_career && datasets.length > 1 && (
            <Average
              percentage={calculateAverage(
                datasets.map((item) => item.data.result)
              )}
            />
          )}
          {dataset_career && <Range from={min} to={max} average={optimal} />}
          {datasets.map((item, index) => {
            const {
              id,
              data: { result },
              label
            } = item

            const match = dataset_career
              ? 100 - Math.abs(result - optimal)
              : result

            const borderColor = dataset_career
              ? getBackgroundColorPassung(match, false)
              : item.borderColor

            const backgroundColor = dataset_career
              ? getBackgroundColorPassung(match, true)
              : item.backgroundColor

            return (
              <Fragment key={index}>
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  style={{
                    left: `calc(${result}% - 10px)`,
                    bottom: -8,
                    backgroundColor: 'white'
                  }}
                  className='absolute h-5 w-5 rounded-full' // height: 20px
                  data-tooltip-id={id}
                  data-tooltip-content={label}
                  data-tooltip-place='top'
                >
                  <div
                    style={{ backgroundColor }}
                    className='h-5 w-5 rounded-full p-1'
                  >
                    <div
                      style={{ backgroundColor: borderColor }}
                      className='h-3 w-3 rounded-full'
                    />
                  </div>
                </motion.div>
                <Tooltip
                  id={id}
                  offset={16}
                  className='z-30 text-sm font-semibold'
                />
              </Fragment>
            )
          })}
        </div>
      </div>
      <div className='mt-6 flex justify-between'>
        <div
          onClick={() => {
            trackEvent(trackEvents.CLICK_DIMENSION_TOOLTIP)
            setOpenDimensionsDescription({
              ...dimension,
              scaleEndpoints: description
                ? { description }
                : { left, right: null }
            })
          }}
          className='flex cursor-pointer flex-row items-center hover:opacity-60'
        >
          <ChevronDoubleLeftIcon className='h-5 w-5 text-slate-900' />
          <span className='ml-1 block text-sm font-medium'>
            {startStrWithCapital(left.title[language])}
          </span>
          <QuestionMarkCircleIcon className='ml-1 h-5 w-5 text-gray-900' />
        </div>
        <div
          onClick={() => {
            trackEvent(trackEvents.CLICK_DIMENSION_TOOLTIP)
            setOpenDimensionsDescription({
              ...dimension,
              scaleEndpoints: description
                ? { description }
                : { left: null, right }
            })
          }}
          className='flex cursor-pointer flex-row items-center hover:opacity-60'
        >
          <QuestionMarkCircleIcon className='mr-1 h-5 w-5 text-gray-900' />
          <span className='mr-1 block text-right text-sm font-medium'>
            {startStrWithCapital(right.title[language])}
          </span>
          <ChevronDoubleRightIcon className='h-5 w-5 text-slate-900' />
        </div>
      </div>
      <SlideOverDimensionDescription
        open={openDimensionDescription}
        setOpen={setOpenDimensionsDescription}
      />
    </div>
  )
}

LinearDimension.propTypes = { dimension: PropTypes.object.isRequired }

export default LinearDimension
