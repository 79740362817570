import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Alert from '../../alert'

const ExternalIdAlert = ({
  className,
  old_career_title,
  new_career_title,
  external_custom_id
}) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(className)}>
      <Alert
        type='warning'
        title={t(
          'components.careers.create_career_dialog.external_custom_id_already_in_use_title'
        )}
        description={t(
          'components.careers.create_career_dialog.external_custom_id_already_in_use_hint',
          { old_career_title, new_career_title, external_custom_id }
        )}
      />
    </div>
  )
}

export default ExternalIdAlert
