import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Seo } from '../components'
import ChallengeMaterialCard from '../components/challenge-material-card'
import PageHeading from '../components/tailwind/PageHeading'
import { useAivyContext, useLanguageContext } from '../context'

const TestLibrary = () => {
  const { t } = useTranslation()
  const { system } = useAivyContext()
  const { language } = useLanguageContext()

  const [categoriesFilter, setCategoriesFilter] = useState([])

  const parsed_categories = JSON.parse(system.categories.data)

  const challenges = Object.values(JSON.parse(system.challenges.data))
  const categories = Object.keys(parsed_categories).map((key) => ({
    key,
    ...parsed_categories[key]
  }))

  const filters = [
    {
      id: 'categories',
      name: t('test_library.categories_filter_label'),
      // { value: 'white', label: 'White' },
      options: categories.map(({ key, name }) => ({
        value: key,
        label: name[language]
      }))
    }
  ]

  return (
    <>
      <Seo title={t('seo.titles.test_library')} />
      <PageHeading title={t('seo.titles.test_library')} />
      <main className='px-4 pb-24 pt-8 sm:px-6 xl:max-w-7xl xl:px-8'>
        <div className='border-b border-gray-200 pb-8'>
          <p className='max-w-xl text-sm text-gray-700'>
            {t('test_library.description')}
          </p>
        </div>

        <div className='pt-10 xl:grid xl:grid-cols-4 xl:gap-x-8'>
          <aside>
            <div className='hidden xl:block'>
              <form className='space-y-10 divide-y divide-gray-200'>
                {filters.map((section, sectionIdx) => (
                  <div
                    key={section.id}
                    className={sectionIdx === 0 ? null : 'pt-10'}
                  >
                    <fieldset>
                      <legend className='block text-sm font-medium text-gray-900'>
                        {section.name}
                      </legend>
                      <div className='space-y-3 pt-6'>
                        {section.options.map((option, optionIdx) => (
                          <div key={optionIdx} className='flex items-center'>
                            <input
                              defaultValue={option.value}
                              id={`${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type='checkbox'
                              onChange={(event) =>
                                setCategoriesFilter(
                                  event.target.checked
                                    ? categoriesFilter.concat([option.value])
                                    : categoriesFilter.filter(
                                        (value) => value !== option.value
                                      )
                                )
                              }
                              className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                            />
                            <label
                              htmlFor={`${section.id}-${optionIdx}`}
                              className='ml-3 text-sm text-gray-600'
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                ))}
              </form>
            </div>
          </aside>

          <div className='mt-6 xl:col-span-3 xl:mt-0'>
            <div className='flex flex-wrap gap-4'>
              {challenges
                .filter(({ active }) => active)
                .filter(
                  categoriesFilter.length
                    ? ({ category_id }) =>
                        categoriesFilter.includes(String(category_id))
                    : () => true
                )
                .map((challenge, index) => (
                  <ChallengeMaterialCard key={index} challenge={challenge} />
                ))}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default TestLibrary
