/* eslint-disable react/prop-types */
/**
 * /**
 * TailwindUI Application UI / Elements / Buttons
 * https://tailwindui.com/components/application-ui/elements/buttons
 */
import ActivityIndicator from 'react-spinners/BeatLoader'

import { classNames } from '../../helpers'

const Base = (
  customClassname,
  {
    id,
    text,
    onClick,
    fullWidth,
    className,
    disabled = false,
    backgroundColor,
    type = 'button',
    isLoading,
    activityIndicatorColor
  }
) => (
  <button
    id={id}
    className={classNames(
      customClassname,
      fullWidth && 'w-full',
      disabled && 'opacity-40',
      'relative whitespace-nowrap',
      isLoading && 'cursor-default',
      className
    )}
    style={{ minWidth: 100 }}
    onClick={isLoading ? undefined : onClick}
    type={type}
    disabled={isLoading || disabled}
  >
    <span className={classNames(isLoading ? 'invisible' : 'visible')}>
      {text}
    </span>
    {isLoading && (
      <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center'>
        <ActivityIndicator color={activityIndicatorColor || 'white'} />
      </div>
    )}
  </button>
)

const Text = (props) =>
  Base('text-sm font-semibold text-blue-500 hover:text-blue-600', {
    ...props,
    activityIndicatorColor: '#374151' // gray-700
  })

const PrimaryXS = (props) =>
  Base(
    'rounded-full bg-blue-600 py-1 px-2.5 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
    { ...props, activityIndicatorColor: 'white' }
  )

const PrimarySM = (props) =>
  Base(
    'rounded-full bg-blue-600 py-1 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
    { ...props, activityIndicatorColor: 'white' }
  )

const PrimaryBase = (props) =>
  Base(
    'rounded-full bg-blue-600 py-1.5 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
    { ...props, activityIndicatorColor: 'white' }
  )

const PrimaryLG = (props) =>
  Base(
    'rounded-full bg-blue-600 py-2 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
    { ...props, activityIndicatorColor: 'white' }
  )

const PrimaryXL = (props) =>
  Base(
    'rounded-full bg-blue-600 py-2.5 px-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
    { ...props, activityIndicatorColor: 'white' }
  )

const SecondaryXS = (props) =>
  Base(
    'rounded-full bg-white py-1 px-2.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SecondarySM = (props) =>
  Base(
    'rounded-full bg-white py-1 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SecondaryBase = (props) =>
  Base(
    'rounded-full bg-white py-1.5 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SecondaryLG = (props) =>
  Base(
    'rounded-full bg-white py-2 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SecondaryXL = (props) =>
  Base(
    'rounded-full bg-white py-2.5 px-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SoftXS = (props) =>
  Base(
    'rounded-full bg-blue-50 py-1 px-2 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SoftSM = (props) =>
  Base(
    'rounded-full bg-blue-50 py-1 px-2 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SoftBase = (props) =>
  Base(
    'rounded-full bg-blue-50 py-1.5 px-2.5 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SoftLG = (props) =>
  Base(
    'rounded-md bg-blue-50 py-2 px-3 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const SoftXL = (props) =>
  Base(
    'rounded-full bg-blue-50 py-2.5 px-3.5 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100',
    { ...props, activityIndicatorColor: '#374151' } // gray-700
  )

const WarningLG = (props) =>
  Base(
    'rounded-full bg-red-600 py-2 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600',
    { ...props, activityIndicatorColor: 'white' }
  )

const WarningText = (props) =>
  Base('text-sm font-semibold text-red-500 hover:text-red-600', {
    ...props,
    activityIndicatorColor: '#374151' // gray-700
  })

export default {
  Text,
  PrimaryXS,
  PrimarySM,
  PrimaryBase,
  PrimaryLG,
  PrimaryXL,
  SecondaryXS,
  SecondarySM,
  SecondaryBase,
  SecondaryLG,
  SecondaryXL,
  SoftXS,
  SoftSM,
  SoftBase,
  SoftLG,
  SoftXL,
  WarningLG,
  WarningText
}
