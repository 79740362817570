export const INTEGRATION = `{
  id
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  partner_id
  integration_settings {
    url
    email
    token
    aivy_api_key
    client_secret
    client_id
    dvinci_api_user
    dvinci_api_token
    external_customer_id
    username
    password
    assessment_status_reference
    assessment_test_reference
  }
  remote_service
  remote_service_name
  api_call_count
  createdAt
  updatedAt
}`
