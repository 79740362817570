import PropTypes from 'prop-types'
import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { getJobCategory } from '../../constants/job-categories'
import trackEvents from '../../constants/track-events'
import {
  useLanguageContext,
  useNotificationContext,
  usePaymentContext
} from '../../context'
import { query } from '../../graphql'
import { classNames } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { sortCrossComparison } from '../../helpers/sortings'
import Divider from '../divider'
import LockedIcon from '../icons/locked-icon'
import Paywall from '../paywall'
import Table from '../react-table/table'
import Button from '../tailwind/Button'
import Panel from '../tailwind/Panel'
import ReinviteSpaceModal from '../talents/reinvite-space-modal'
import Tooltip from '../tooltip'
import VersionSwitch from '../VersionSwitch'

const TRANSLATION_LOCATION = 'pages.talent.'

const {
  CLICK_REQUEST_CHALLENGES_TALENT_DETAILS,
  CAREERS_MATCHING_SHOW_DIAGNOSTIC_VERSION_1,
  CAREERS_MATCHING_SHOW_DIAGNOSTIC_VERSION_2
} = trackEvents

const CareersMatching = ({ space, refetchSpace, data }) => {
  const { t } = useTranslation()
  const { error } = useNotificationContext()
  const { language } = useLanguageContext()
  const { crossComparison, showPaywall } = usePaymentContext()

  const matchMeIfYouCan = data || []

  const hasV1Careers = matchMeIfYouCan.some(
    ({ diagnostic_version }) => diagnostic_version === 1
  )

  const hasV2Careers = matchMeIfYouCan.some(
    ({ diagnostic_version }) => diagnostic_version === 2
  )

  // switch is only displayed if both versions are available
  const [activeDiagnosticVersion, setActiveDiagnosticVersion] = useState(2)
  const [showReinviteSpaceModal, setShowReinviteSpaceModal] = useState(false)

  const generatePDF = ({ career_id, diagnostic_version }) => {
    const variables = { career_id, language }

    if (diagnostic_version === 2) {
      variables.space_id = space.id
      variables.type = 'MATCHINGPROFILE'
    }

    const { partner_user_id, owner } = space
    if (partner_user_id) variables.partner_user_id = partner_user_id
    else variables.user_id = owner

    const pdfDocument = window.open('', '_blank')
    pdfDocument.document.write(t(TRANSLATION_LOCATION + 'pdf_preparation'))

    query({ query: 'generatePdf', variables })
      .then((response) => {
        pdfDocument.location.href = JSON.parse(response).headers.Location
      })
      .catch((err) => {
        error()
        console.error(err)
        pdfDocument.close()
      })
  }

  const columns = [
    {
      accessorKey: 'title',
      enableSorting: crossComparison,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('pages.talent.careers_matching_careers_table_column_careers')}
        </span>
      ),
      cell: ({ cell }) => {
        const { title, external_custom_id, score } = cell.row.original

        const career_title = external_custom_id
          ? `${title} | ${external_custom_id}`
          : title

        return (
          <div className='flex items-center space-x-3'>
            <div
              className='h-3 w-3 flex-shrink-0 rounded-full'
              aria-hidden='true'
              style={{ backgroundColor: score.color }}
            />
            <span className='text-sm font-medium text-gray-900'>
              {career_title}
            </span>
          </div>
        )
      }
    },
    {
      accessorKey: 'score',
      enableSorting: crossComparison,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('pages.talent.careers_matching_careers_table_column_score')}
        </span>
      ),
      sortingFn: sortCrossComparison,
      cell: ({ getValue }) => {
        const { complete_dataset, value } = getValue() || {}
        const displayTooltip = !complete_dataset && crossComparison

        return (
          <div className='flex items-center'>
            <span
              className={classNames(
                'text-sm text-gray-500',
                complete_dataset ? null : 'italic',
                crossComparison ? null : 'blur'
              )}
            >
              {crossComparison ? value : 'blur'}
            </span>
            {displayTooltip && (
              <div className='ml-2'>
                <Tooltip
                  id='complete_dataset_false_tooltip'
                  place='top'
                  tip={t('talents.crossmode.complete_dataset_false_tooltip')}
                />
              </div>
            )}
          </div>
        )
      }
    },
    {
      accessorKey: 'category',
      enableSorting: crossComparison,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('pages.talent.careers_matching_careers_table_column_category')}
        </span>
      ),
      cell: ({ getValue }) => (
        <span className='text-sm text-gray-500'>
          {getValue() ? t(getJobCategory(getValue())) : ''}
        </span>
      )
    },
    {
      accessorKey: 'id',
      enableSorting: false,
      header: (
        <span className='ml-auto text-sm font-semibold text-gray-900'>
          {t('pages.talent.careers_matching_careers_table_column_result')}
        </span>
      ),
      cell: ({ cell }) => {
        const {
          id: career_id,
          complete_dataset,
          diagnostic_version
        } = cell.row.original

        const available = crossComparison && complete_dataset

        return (
          <span
            className={classNames(
              'flex items-center justify-end text-sm',
              available
                ? 'cursor-pointer text-blue-500 hover:text-blue-600'
                : 'italic text-gray-500'
            )}
            onClick={
              available
                ? () => generatePDF({ career_id, diagnostic_version })
                : undefined
            }
          >
            {t(
              TRANSLATION_LOCATION +
                (available
                  ? 'careers_matching_careers_table_column_result_action'
                  : 'careers_matching_careers_table_column_result_not_available')
            )}
          </span>
        )
      }
    }
  ]

  const { all_challenges_unlocked } = space
  const matchings_have_not_complete_dataset = matchMeIfYouCan.some(
    ({ complete_dataset }) => complete_dataset === false
  )

  const alreadyReinvited =
    all_challenges_unlocked && matchings_have_not_complete_dataset

  const showReinviteOption =
    !all_challenges_unlocked && matchings_have_not_complete_dataset

  const filteredMatchMeIfYouCan =
    hasV1Careers && hasV2Careers
      ? matchMeIfYouCan.filter(({ diagnostic_version }) => {
          return diagnostic_version === activeDiagnosticVersion
        })
      : matchMeIfYouCan

  return (
    <>
      <Panel>
        <div className='w-full flex-1'>
          <div className='flex flex-col gap-2 border-b border-gray-200 pb-5 xl:flex-row xl:items-end'>
            <div>
              <div className='flex flex-row items-center'>
                <h2 className='text-lg font-medium text-gray-900'>
                  {t(TRANSLATION_LOCATION + 'cross_matching_title')}
                </h2>
                {!crossComparison && (
                  <div
                    className='cursor-pointer'
                    onClick={() => showPaywall('general')}
                  >
                    <LockedIcon
                      place={'left'}
                      text={t('tooltip.locked.redirect_talent_url')}
                    />
                  </div>
                )}
              </div>
              <p className='mt-1 max-w-4xl text-sm text-gray-500'>
                {t(TRANSLATION_LOCATION + 'cross_matching_description')}
              </p>
            </div>
            <div className='ml-auto flex items-center gap-x-4'>
              {showReinviteOption && (
                <Button.SecondaryBase
                  onClick={() => {
                    trackEvent(CLICK_REQUEST_CHALLENGES_TALENT_DETAILS)
                    setShowReinviteSpaceModal(true)
                  }}
                  text={t('talents.open_reinvite_space_modal_action')}
                />
              )}
              {alreadyReinvited && (
                <span className='text-sm italic text-gray-500'>
                  {t('talents.already_reinvited_hint')}
                </span>
              )}
              {hasV1Careers && hasV2Careers && (
                <VersionSwitch
                  versions={[
                    {
                      version: 1,
                      active: activeDiagnosticVersion === 1,
                      handleChange: () => {
                        trackEvent(CAREERS_MATCHING_SHOW_DIAGNOSTIC_VERSION_1)
                        setActiveDiagnosticVersion(1)
                      }
                    },
                    {
                      version: 2,
                      active: activeDiagnosticVersion === 2,
                      handleChange: () => {
                        trackEvent(CAREERS_MATCHING_SHOW_DIAGNOSTIC_VERSION_2)
                        setActiveDiagnosticVersion(2)
                      }
                    }
                  ]}
                />
              )}
            </div>
          </div>
          {crossComparison && matchMeIfYouCan.length > 0 && (
            <Table
              className='bg-gray-50'
              classNameTableHeader=''
              columns={columns}
              data={filteredMatchMeIfYouCan}
              initialState={{ sorting: [{ id: 'score', desc: false }] }}
              disableHover
              disableVirtual
              disableFilter
            />
          )}
          {crossComparison && !matchMeIfYouCan.length && (
            <span className='mt-4 block text-sm italic text-gray-500'>
              {t(TRANSLATION_LOCATION + 'careers_matching_no_active_careers')}
            </span>
          )}
          {!crossComparison && matchMeIfYouCan.length > 0 && (
            <>
              <Table
                className='bg-gray-50'
                classNameTableHeader=''
                columns={columns}
                data={filteredMatchMeIfYouCan}
                initialState={{ sorting: [{ id: 'score', desc: false }] }}
                disableHover
                disableVirtual
                disableFilter
              />
              <Divider marginY={32} />
              <div className='mb-2 mt-4'>
                <Paywall type='static' paywall='general' />
              </div>
            </>
          )}
          {!crossComparison && !matchMeIfYouCan.length && (
            <div className='mb-2 mt-8'>
              <Paywall type='static' paywall='general' />
            </div>
          )}
        </div>
      </Panel>
      <ReinviteSpaceModal
        space={space}
        updateHandler={refetchSpace}
        open={!!showReinviteSpaceModal}
        setOpen={() => setShowReinviteSpaceModal(false)}
      />
    </>
  )
}

CareersMatching.propTypes = {
  space: PropTypes.object.isRequired,
  refetchSpace: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
}

export default CareersMatching
