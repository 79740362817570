import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { getJobCategory } from '../../../constants/job-categories'
import { useLanguageContext, useNotificationContext } from '../../../context'
import { query } from '../../../graphql'
import Button from '../../tailwind/Button'
import Modal from '../../tailwind/Modal'

const ModalSelectCareer = ({
  career,
  careers,
  open,
  closeModal,
  refetchCareerAnalyses
}) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()
  const { success } = useNotificationContext()

  const [selectedCareerId, setSelectedCareerId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const copyCareerAnalyses = async () => {
    setIsLoading(true)

    try {
      await query({
        query: 'careerControl',
        variables: {
          action: 'copyCareerAnalysesFromCareerToCareer',
          from_career_id: selectedCareerId,
          to_career_id: career.id
        }
      })

      refetchCareerAnalyses()
      success(t('create_career.career_analyse.select_career.success'))
      setIsLoading(false)

      closeModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal open={open} setOpen={closeModal} size='3xl'>
      <div className='p-4'>
        <div className='mt-2'>
          <span className='text-3xl font-extrabold tracking-tight text-blue-600'>
            {t('create_career.career_analyse.select_career.title')}
          </span>
        </div>
        <div className='mt-4 max-w-lg'>
          <p className='text-sm text-gray-900'>
            {t('create_career.career_analyse.select_career.description')}
          </p>
        </div>
        <ul role='list' className={clsx('mt-6 divide-y divide-gray-200')}>
          {careers.map((item, index) => (
            <li
              key={index}
              onClick={() => setSelectedCareerId(item.id)}
              className='group relative flex cursor-pointer gap-x-6 px-4 py-3 hover:bg-gray-50'
            >
              <div className='flex gap-x-4 pr-6 sm:w-1/2 sm:flex-none'>
                <div className='flex flex-col justify-center'>
                  <p className='max-w-48 truncate text-sm font-semibold leading-6 text-gray-900 sm:max-w-96'>
                    {item.title}
                  </p>
                  <p className='flex whitespace-nowrap text-xs text-gray-500'>
                    {t(getJobCategory(item.category || '-1'))}
                  </p>
                </div>
              </div>
              <div className='flex w-full items-center justify-between gap-x-4'>
                <div className='ml-auto hidden lg:block'>
                  <span className='text-xs italic text-gray-500'>
                    {t(
                      'create_career.career_analyse.select_career.last_updated_at'
                    )}
                  </span>
                  <span className='ml-1 text-xs text-gray-700'>
                    {format(new Date(item.updatedAt), 'd. MMMM yyyy', {
                      locale: { de, en }[language]
                    })}
                  </span>
                </div>
                <div className='ml-auto lg:ml-0'>
                  {selectedCareerId === item.id ? (
                    <CheckCircleIcon
                      className='h-6 w-6 flex-none text-green-500'
                      aria-hidden='true'
                    />
                  ) : (
                    <>
                      <div className='h-6 w-6 flex-none rounded-full bg-gray-200 p-1 group-hover:hidden'>
                        <div className='h-4 w-4 flex-none rounded-full bg-white'></div>
                      </div>
                      <CheckCircleIcon
                        className='hidden h-6 w-6 flex-none text-gray-300 group-hover:block'
                        aria-hidden='true'
                      />
                    </>
                  )}
                </div>
              </div>
            </li>
          ))}
          {!careers.length && (
            <p className='max-w-lg pt-2 text-sm italic text-gray-700'>
              {t(
                'create_career.career_analyse.select_career.empty_careers_hint'
              )}
            </p>
          )}
        </ul>
        <div className='mt-12 flex justify-end gap-x-2'>
          <Button.SecondaryXL
            text={t(
              'components.career.invite_career_analyse_dialog.dialog_cancel_action'
            )}
            onClick={closeModal}
          />
          <Button.PrimaryXL
            text={t(
              'create_career.career_analyse.select_career.copy_analyses_action'
            )}
            onClick={copyCareerAnalyses}
            isLoading={isLoading}
            disabled={!selectedCareerId}
          />
        </div>
      </div>
    </Modal>
  )
}

ModalSelectCareer.propTypes = {
  career: PropTypes.object.isRequired,
  careers: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  refetchCareerAnalyses: PropTypes.func.isRequired
}

export default ModalSelectCareer
