import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import trackEvents from '../../constants/track-events'
import { useNotificationContext } from '../../context'
import { mutation } from '../../graphql'
import { trackEvent } from '../../helpers/analytics'
import { useCreateLog } from '../../hooks/use-create-log'
import Datepicker from '../react-datepicker/datepicker'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'

const ResetExpired = ({ spaces, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()

  const space = spaces.length === 1 ? spaces[0] : null

  const { createSpaceHistoryLog } = useCreateLog()

  const [isLoading, setIsLoading] = useState(false)
  const [expiresAt, setExpiresAt] = useState(
    space && new Date(space.expiresAt) > new Date()
      ? new Date(space.expiresAt)
      : new Date(Date.now() + 12096e5) // fortnight away
  )

  const updateExpiresAt = async () => {
    setIsLoading(true)

    const event = { context: 'SYSTEM', status: 'RESET_EXPIRED' }

    const onSuccess = () => {
      success(t('space_actions.reset_expired_success'))
      trackEvent(trackEvents.RESET_EXPIRED_TALENTS)
      setIsLoading(false)
      setOpen(false)
    }

    expiresAt.setHours(23)
    expiresAt.setMinutes(59)
    expiresAt.setSeconds(59)

    try {
      for (const space of spaces) {
        const { id: space_id, career_id } = space

        await createSpaceHistoryLog({ space_id, career_id, ...event })
        await mutation({
          mutation: 'updateSpace',
          input: { id: space_id, expiresAt: expiresAt.toISOString() }
        })
      }

      updateHandler({ onSuccess })
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal open={open} setOpen={() => setOpen(false)} size='2xl'>
      <div className='mt-3 text-center sm:mt-5'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
          {t('space_actions.modal_reset_expired_title')}
        </h2>
        {spaces.length > 1 && (
          <p className='mx-auto mt-2 max-w-lg text-sm text-gray-700'>
            {t('space_actions.modal_reset_expired_description', {
              number: spaces.length
            })}
          </p>
        )}
      </div>
      <div className='mx-4 mt-12'>
        <p className='block text-sm font-medium text-gray-700'>
          {t('space_actions.reset_expired_title')}
        </p>
        <div className='mt-2'>
          <Datepicker
            selected={expiresAt}
            onChange={(date) => setExpiresAt(date)}
            minDate={new Date()}
          />
        </div>
        <p className='mt-2 text-xs italic text-gray-500'>
          {t('space_actions.reset_expired_description')}
        </p>
      </div>

      <div className='mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 md:flex md:justify-center'>
        <Button.SecondaryXL
          text={t('space_actions.modal_reset_expired_cancel_action')}
          onClick={() => setOpen(false)}
        />
        <Button.PrimaryXL
          text={t('space_actions.modal_reset_expired_submit_action')}
          onClick={updateExpiresAt}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}

ResetExpired.propTypes = {
  spaces: PropTypes.array.isRequired,
  updateHandler: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default ResetExpired
