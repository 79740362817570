import { Switch } from '@headlessui/react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import trackEvents from '../../constants/track-events'
import { useNotificationContext } from '../../context'
import { classNames } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { useSetState } from '../../hooks/use-set-state'
import Checkbox from '../checkbox'
import InputFormik from '../input-formik'
import Datepicker from '../react-datepicker/datepicker'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'

const EXPORTABLES = {
  identification: {
    label: 'spaces.actions.csv_export.label_identification',
    field: 'identification',
    required: true
  },
  name: {
    label: 'spaces.actions.csv_export.label_name',
    field: 'name',
    enabled: true
  },
  source: {
    label: 'spaces.actions.csv_export.label_source',
    field: 'career_title'
  },
  progress: {
    label: 'spaces.actions.csv_export.label_progress',
    field: 'progress'
  },
  status: {
    label: 'spaces.actions.csv_export.label_status',
    field: 'status_label'
  },
  score: {
    label: 'spaces.actions.csv_export.label_score',
    field: 'score'
  },
  updatedAt: {
    label: 'spaces.actions.csv_export.label_updated_at',
    field: 'updatedAt'
  },
  createdAt: {
    label: 'spaces.actions.csv_export.label_created_at',
    field: 'createdAt'
  }
}

const Toggle = ({ enabled, setEnabled, disabled }) => {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      disabled={disabled}
      className='group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
    >
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className='pointer-events-none absolute h-full w-full rounded-md bg-white'
      />
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'bg-blue-600' : 'bg-gray-200',
          disabled && 'opacity-40',
          'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
        )}
      />
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}

Toggle.propTypes = {
  enabled: PropTypes.bool.isRequired,
  setEnabled: PropTypes.func,
  disabled: PropTypes.bool
}

Toggle.defaultProps = { setEnabled: () => undefined, disabled: false }

const CSVExport = ({
  spaces,
  careers_map,
  state: { open, state },
  setState
}) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()

  const [dates, setDates] = useSetState({
    // initialize datepicker with default values
    fromDate: new Date(),
    toDate: new Date(),
    minDate: new Date(),
    maxDate: new Date()
  })

  const [toggleAllState, setToggleAllState] = useState(true)

  useEffect(() => {
    // if spaces change e.g. with a selection of spaces
    // update from and to date states

    const from = new Date(
      [...spaces].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )[0].createdAt
    )

    const to = new Date(
      [...spaces].sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      )[0].updatedAt
    )

    setDates({ fromDate: from, toDate: to, minDate: from, maxDate: to })
  }, [spaces, setDates])

  const initialValues = useMemo(() => {
    const currentDate = new Date()

    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // adding 1 because months are zero-indexed
    const day = currentDate.getDate()

    const exportables = Object.keys(EXPORTABLES).reduce((acc, key) => {
      // use required || false for formik
      acc[key] = !!EXPORTABLES[key].required || !!EXPORTABLES[key].enabled

      return acc
    }, {})

    return {
      filename: `talents-export_${year}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
      }`,
      ...exportables,
      only_completed_talents: false
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      filename: Yup.string().required(
        t('spaces.actions.csv_export.validation_required')
      )
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)

      await new Promise((resolve) => setTimeout(resolve, 1000 * 0.8))

      const selectedExportables = Object.keys(EXPORTABLES)
        .filter((key) => values[key])
        .map((key) => EXPORTABLES[key])

      const filtered_spaces = spaces
        .map((space) => {
          const { progress, assessment_id, status } = space
          const { assessment_status, smart_predict_status } = status

          return {
            ...space,
            career_title: careers_map.get(assessment_id)?.title,
            status_label:
              progress === 100
                ? t(smart_predict_status.status.label)
                : t(assessment_status.status.label, { progress })
          }
        })
        .filter((space) => {
          if (values.only_completed_talents) {
            return space.progress === 100
          }

          return true
        })
        .filter((space) => {
          if (state === 'all') {
            // be aware of datepicker values

            const createdAt = new Date(space.createdAt)
            const updatedAt = new Date(space.updatedAt)

            // createdAt should be bigger than fromDate
            // updatedAt should be smaller than toData
            return createdAt >= dates.fromDate && updatedAt <= dates.toDate
          }

          return true
        })

      const titles = selectedExportables.map(({ label }) => t(label))
      const refined = filtered_spaces.map((space) =>
        selectedExportables.map(({ field, extract }) => space[field] || '-')
      )

      let csvContent = ''

      csvContent += titles.join(',') + '\n'
      refined.forEach((row) => {
        csvContent += row.join(',') + '\n'
      })

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
      const objUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.setAttribute('href', objUrl)
      link.setAttribute('download', `${values.filename}.csv`)
      link.click()

      setSubmitting(false)

      setState({ open: false })
      success(t('spaces.actions.csv_export.success_message'))
      trackEvent(trackEvents.COMPLETE_CSV_EXPORT)

      // await modal transition
      setTimeout(() => {
        resetForm()
        setState({ state: null })
      }, 1000 * 0.8)
    }
  })

  const close = () => {
    trackEvent(trackEvents.ABORT_CSV_EXPORT)
    setState({ open: false })

    // await modal transition
    setTimeout(() => {
      formik.resetForm()
      setState({ state: null })
    }, 1000 * 0.8)
  }

  const { fromDate, toDate, minDate, maxDate } = dates

  const toggleAll = () => {
    Object.keys(EXPORTABLES).forEach((key) => {
      if (EXPORTABLES[key].required) return
      formik.setFieldValue(key, toggleAllState)
    })

    setToggleAllState(!toggleAllState)
  }

  return (
    <Modal open={open} setOpen={close} size='xl'>
      <div className='mt-3 sm:mt-5'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
          {t('spaces.actions.csv_export.modal_title')}
        </h2>
        <p className='mt-2 max-w-lg text-sm text-gray-700'>
          {t('spaces.actions.csv_export.modal_description', {
            number: spaces.length
          })}
        </p>
        <div style={{ height: '22rem' }} className='overflow-y-auto'>
          <div className='mt-6'>
            <InputFormik
              {...{
                id: 'filename',
                label: t('spaces.actions.csv_export.filename_label'),
                placeholder: formik.initialValues.filename,
                formik
              }}
            />
          </div>
          <div className='py-8'>
            <div className='flex items-center justify-between'>
              <div className='text-sm font-medium text-gray-700'>
                {t('spaces.actions.csv_export.data_colums_title')}
              </div>
              <Button.Text
                text={t('spaces.actions.csv_export.trigger_all_action')}
                onClick={toggleAll}
              />
            </div>
            <div className='mt-3 grid grid-cols-1 gap-y-3'>
              {Object.keys(EXPORTABLES).map((key, index) => {
                const { label, required } = EXPORTABLES[key]

                return (
                  <div key={index} className='flex items-center'>
                    <span className='whitespace-nowrap text-sm text-gray-900'>
                      {t(label)}
                    </span>
                    <div className='mx-5 h-px w-full bg-gray-200/80' />
                    <Toggle
                      enabled={formik.values[key]}
                      setEnabled={() =>
                        formik.setFieldValue(key, !formik.values[key])
                      }
                      disabled={required}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div
            className={classNames(
              'mt-10 flex max-w-md',
              state === 'all' ? 'block' : 'hidden'
            )}
          >
            <Checkbox
              id='only_completed_talents'
              onChange={formik.handleChange}
              checked={formik.values.only_completed_talents}
              label={t(
                'spaces.actions.csv_export.only_completed_talents_title'
              )}
              description={t(
                'spaces.actions.csv_export.only_completed_talents_description'
              )}
            />
          </div>
          <div
            className={classNames('mt-8', state === 'all' ? 'block' : 'hidden')}
          >
            <div className='text-sm font-medium text-gray-700'>
              {t('spaces.actions.csv_export.from_to_title')}
            </div>
            <div className='mt-2 flex gap-x-2'>
              <Datepicker
                selected={fromDate}
                onChange={(date) => setDates({ fromDate: date })}
                minDate={minDate}
                maxDate={toDate}
              />
              <Datepicker
                selected={toDate}
                onChange={(date) => setDates({ toDate: date })}
                minDate={fromDate}
                maxDate={maxDate}
              />
            </div>
          </div>
        </div>
        <div className='mt-6 flex flex-col gap-y-2 border-t pt-6'>
          <Button.PrimaryXL
            text={t('spaces.actions.csv_export.modal_submit_action')}
            onClick={formik.handleSubmit}
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}
          />
          <Button.SecondaryXL
            text={t('spaces.actions.csv_export.modal_cancel_action')}
            onClick={close}
          />
        </div>
      </div>
      {/* <div className='mt-3 sm:mt-5 px-4'>
        
        <div className='flex flex-col gap-y-2 mt-8'>
          <Button.PrimaryXL
            text={t('spaces.actions.csv_export.modal_submit_action')}
            onClick={formik.handleSubmit}
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}
          />
          <Button.SecondaryXL
            text={t('spaces.actions.csv_export.modal_cancel_action')}
            onClick={close}
          />
        </div>
        <div className='h-4' />
      </div> */}
    </Modal>
  )
}

CSVExport.propTypes = {
  spaces: PropTypes.array.isRequired,
  careers_map: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired
}

export default CSVExport
