import { flatten, intersection } from 'lodash'
import { useMemo } from 'react'

import { SPIDER_CHART_COLORS } from '../constants/spider-chart-colors'
import { useAivyContext } from '../context'
import { colorByString } from '../helpers'
import { useSetState } from './use-set-state'

const getColor = (item) => {
  return { ...item, ...colorByString(item.id, SPIDER_CHART_COLORS) }
}

export const useChartData = ({ spaces, careers = [] }) => {
  const { system } = useAivyContext()

  const parsed_dimensions = useMemo(
    () => JSON.parse(system.dimensions_pdf_reports.data),
    [system]
  )

  const [state, setState] = useSetState({
    mode: 'LINEAR',
    spaces: spaces.map((s) => ({ ...s, visible: true, ...getColor(s) })),
    dimensions: Object.keys(parsed_dimensions).map((key) => ({
      key,
      ...parsed_dimensions[key],
      visible: true
    })),
    categories: [
      {
        // PERSONALITY
        id: 1,
        visible: true,
        ...system.categories['1'],
        active: true
      },
      {
        // SKILLS
        id: 2,
        visible: true,
        ...system.categories['2'],
        active: false
      },
      {
        // INTERESTS
        id: 3,
        visible: true,
        ...system.categories['3'],
        active: false
      }
    ],
    careers: careers.map((c) => ({ ...c, visible: true, ...getColor(c) }))
  })

  const extractChartData = () => {
    const { spaces, dimensions, careers } = {
      spaces: state.spaces.filter((s) => s.visible),
      dimensions: state.dimensions.filter((d) => d.visible),
      careers: state.careers.filter((c) => c.visible)
    }

    const getDimensionsForAnswerKey = ({ answer_key }) => {
      return Object.values(parsed_dimensions)
        .filter((d) => d.answer_key === answer_key)
        .map((d) => d.key)
    }

    const spaces_dimensions_map = spaces.map((space) =>
      flatten(
        Object.keys(space.answers).map((answer_key) =>
          getDimensionsForAnswerKey({ answer_key })
        )
      )
    )

    const careers_dimensions_map = careers.map((career) =>
      Object.keys(JSON.parse(career.career_analyse).scores)
    )

    const dimensions_object = Object.values(dimensions).reduce(
      (previous, current) => ({ ...previous, [current.key]: current }),
      {}
    )

    // intersection
    // creates an array of unique values
    const dimensions_in_common = intersection(
      ...spaces_dimensions_map,
      ...careers_dimensions_map
    )
      .map((dimension_key) => dimensions_object[dimension_key])
      .filter((item) => !!item)

    // sort dimensions by category
    const sorted_dimensions = [
      ...dimensions_in_common.filter((d) => d.category === 3),
      ...dimensions_in_common.filter((d) => d.category === 1),
      ...dimensions_in_common.filter((d) => d.category === 2)
    ]

    const extractSpaceScore = (space) => {
      const { id, identification, answers, borderColor, backgroundColor } =
        space

      return {
        id,
        label: identification,
        data: sorted_dimensions.map((dimension) => {
          const { key: dimension_key, answer_key, score: score_key } = dimension
          const result =
            answers[answer_key].aivy_output.translatedzScores[score_key]

          if (dimension_key === 'DELEGATION_score_responsibility') {
            return { dimension_key, result: 100 - result }
          }

          return { dimension_key, result }
        }),
        borderWidth: 1,
        borderColor,
        backgroundColor
      }
    }

    const extractCareerScore = (career) => {
      const { id, title, career_analyse, borderColor, backgroundColor } = career
      const { scores } = JSON.parse(career_analyse)

      return {
        id,
        label: title,
        data: sorted_dimensions.map((dimension) => {
          const { key: dimension_key } = dimension
          const { min, max, optimal } = scores[dimension_key]

          return { dimension_key, min, max, optimal }
        }),
        borderWidth: 1,
        borderColor,
        backgroundColor
      }
    }

    return {
      dimensions: sorted_dimensions,
      spaces: spaces.map(extractSpaceScore),
      careers: careers.map(extractCareerScore)
    }
  }

  return {
    data: state,
    setData: setState,
    extractChartData,
    options: {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2,
      scales: {
        r: {
          min: 0,
          max: 100,
          beginAtZero: true,
          angleLines: { display: true },
          grid: { circular: true },
          ticks: { stepSize: 20 }
        }
      },
      plugins: {
        // Disable all animations
        animation: false
      }
    }
  }
}
