const DEFAULT_CAREER_APP_SETTINGS = {
  components: JSON.stringify({
    introSlider: false,
    applyWithID: false,
    newsletter: false,
    drawerCareer: false,
    challengeTinder: false,
    completedScreen: false,
    challengeLevelUp: false,
    buyPDF: false,
    successPerspective: false
  }),
  custom_data_policy: false,
  usedAlgorithm: 'matchMeIfYouCan'
}

export const getAppSettings = (appSettings, challenges) => {
  return {
    ...(appSettings || DEFAULT_CAREER_APP_SETTINGS),
    challenges: JSON.stringify(challenges)
  }
}
