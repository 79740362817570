import { COMMENT } from './comment'
import { PERSON } from './person'

export const SPACE = `{
  id
  owner
  language
  progress
  partner_id
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  career_id
  partner_user_id
  matching_score_to_space_career
  person ${PERSON}
  context
  invitation_reminder_at
  reset_expiredAt_at
  expiresAt
  comment ${COMMENT}
  createdAt
  updatedAt
  mail_stored
  additional_requested_data
  all_challenges_unlocked
  soft_deleted
  last_seen
  customer_dashboard_meta
  net_promoter_scores {
    score
    description
    product
    createdAt
    __typename
  }
  authorization_roles
  favored_by_user_id
  application_status
}`
