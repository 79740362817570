import { format } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { isNumber } from 'lodash'
import constants_work_experience from '../../constants/work-experience'
import { useLanguageContext } from '../../context'
import { mutation } from '../../graphql'
import Panel from '../tailwind/Panel'
import SpaceDetailItem from './space-detail-item'

const SpaceDetailsProfile = ({ space, career, refetchSpace }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  const { firstname, lastname, email, partner_user_id, createdAt } = space

  const { title: career_title, app_settings } = career || {}

  const application_input_additional_fields = JSON.parse(
    app_settings?.application_input_additional_fields || '[]'
  )

  const additional_requested_data = JSON.parse(
    space.additional_requested_data || '{}'
  )

  const { work_experience } = additional_requested_data

  const formik = useFormik({
    initialStatus: {},
    enableReinitialize: true,
    initialValues: {
      firstname:
        space.person?.firstname || space.person?.invitation_firstname || '',
      lastname:
        space.person?.lastname || space.person?.invitation_lastname || ''
    },
    validationSchema: Yup.object({
      firstname: Yup.string(),
      lastname: Yup.string()
    }),
    onSubmit: ({ firstname, lastname }) => {
      Promise.all([
        mutation({
          mutation: 'updatePerson',
          input: { id: space.person.id, firstname, lastname }
        }),
        // update space.updatedAt
        mutation({ mutation: 'updateSpace', input: { id: space.id } })
      ]).then(refetchSpace)
    }
  })

  const profile = {
    basics: [
      {
        available: true,
        term: t('talent.state_invited'),
        details: format(new Date(createdAt), 'd. MMMM yyyy', {
          locale: { de, en }[language]
        })
      },
      {
        available: !!email,
        term: t('pages.talent.space_details_email_label'),
        details: email
      },
      {
        available: !!space.person,
        term: t('pages.talent.space_details_firstname_label'),
        details: firstname,
        input: { id: 'firstname', formik }
      },
      {
        available: !!space.person,
        term: t('pages.talent.space_details_lastname_label'),
        details: lastname,
        input: { id: 'lastname', formik }
      },
      {
        available: isNumber(work_experience),
        term: t(
          `pages.talent.space_details_${
            career_title ? 'career' : 'overall'
          }_work_experience_label`,
          { career_title }
        ),
        details: isNumber(work_experience)
          ? constants_work_experience[work_experience].label[language]
          : ''
      },
      {
        available: !!partner_user_id,
        term: t('pages.talent.space_details_partner_user_id_label'),
        details: partner_user_id
      },
      ...Object.keys(additional_requested_data).map((key) => {
        const field =
          application_input_additional_fields.find(({ id }) => id === key) || {}
        const data = additional_requested_data[key]

        return {
          available: true,
          term:
            field.label ||
            t('pages.talent.space_details_removed_hint', {
              label: data.label
            }),
          details: data.input
        }
      })
    ].filter(({ available }) => available)
  }

  return (
    <Panel>
      <section
        className='md:h-full'
        aria-labelledby='applicant-information-title'
      >
        <div className='md:h-full'>
          <div className='pb-5 sm:flex'>
            <div>
              <h2
                id='applicant-information-title'
                className='text-lg font-medium leading-6 text-gray-900'
              >
                {t('pages.talent.space_details_profile_header')}
              </h2>
              <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                {t('pages.talent.space_details_profile_description')}
              </p>
            </div>
          </div>
          <div className='border-t border-gray-300 pt-5'>
            <dl className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              {profile.basics.map(
                ({ term, details, onClick, input }, index) => (
                  <SpaceDetailItem
                    key={index}
                    {...{ term, details, onClick, input }}
                  />
                )
              )}
            </dl>
          </div>
        </div>
      </section>
    </Panel>
  )
}

SpaceDetailsProfile.propTypes = {
  space: PropTypes.object.isRequired,
  career: PropTypes.object,
  refetchSpace: PropTypes.func.isRequired
}

SpaceDetailsProfile.defaultProps = {
  career: null
}

export default SpaceDetailsProfile
