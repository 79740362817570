import { usePartnerContext } from '../context'
import { useMutationAndUpdateQuery } from './use-mutation-and-update-query'

export const usePartnerIntegrationDelete = () => {
  const { partner } = usePartnerContext()

  return useMutationAndUpdateQuery({
    queryKey: ['partner-integrations', partner.id],
    mutation: 'deleteIntegration',
    updater: ({ prevData, response }) => {
      return [...prevData].filter(({ id }) => id !== response.id)
    }
  })
}
