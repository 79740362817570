import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const SelectMenuWithDescription = ({
  label,
  selected,
  setSelected,
  options
}) => {
  return (
    <>
      <label htmlFor='role' className='block text-sm font-medium text-gray-700'>
        {label}
      </label>
      <div className='mt-2' />
      <Listbox value={selected} onChange={setSelected}>
        <div className='relative'>
          <ListboxButton className='group w-full'>
            <div className='flex divide-x divide-gray-300 rounded-md border border-gray-300 shadow-sm'>
              <div className='inline-flex w-full items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 shadow-sm'>
                <CheckIcon
                  className='-ml-0.5 h-5 w-5 text-gray-700'
                  aria-hidden='true'
                />
                <p className='text-sm text-gray-900'>{selected.title}</p>
              </div>
              <div className='inline-flex items-center rounded-l-none rounded-r-md bg-white p-2 focus:outline-none focus:ring-0 focus:ring-offset-0 group-hover:bg-gray-50'>
                <ChevronDownIcon
                  className='h-5 w-5 text-gray-700'
                  aria-hidden='true'
                />
              </div>
            </div>
          </ListboxButton>
          <ListboxOptions className='absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {options.map((option) => {
              const active = option.id === selected.id

              return (
                <ListboxOption
                  key={option.id}
                  className={classNames(
                    active
                      ? 'bg-indigo-600 text-white'
                      : 'text-gray-900 hover:bg-indigo-600 hover:text-white',
                    'group cursor-pointer select-none p-4 text-sm'
                  )}
                  value={option}
                >
                  <div className='flex flex-col'>
                    <div className='flex justify-between'>
                      <p
                        className={
                          active
                            ? 'font-semibold'
                            : 'font-normal group-hover:font-semibold'
                        }
                      >
                        {option.title}
                      </p>
                      {active ? (
                        <span className='text-white'>
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </div>
                    <p
                      className={classNames(
                        active
                          ? 'text-indigo-200'
                          : 'text-gray-500 group-hover:text-indigo-200',
                        'mt-2'
                      )}
                    >
                      {option.description}
                    </p>
                  </div>
                </ListboxOption>
              )
            })}
          </ListboxOptions>
        </div>
      </Listbox>
    </>
  )
}

SelectMenuWithDescription.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

export default SelectMenuWithDescription
