import { usePartnerContext, useUserContext } from '../context'
import { mutation, query } from '../graphql'

const HISTORY_LOG = ({
  source,
  username,
  partner_id,
  data,
  event_name,
  career_id,
  space_id
}) => ({
  type: 'INFO',
  event_name,
  source,
  career_id,
  space_id,
  fired_by_id: username,
  partner_id,
  data,
  authorized_create: [`__create_${partner_id}`],
  authorized_read: [`__read_low${partner_id}`]
})

export const useCreateLog = () => {
  const { cognitoUser } = useUserContext()
  const { partner } = usePartnerContext()

  const { username } = cognitoUser
  const { id: partner_id } = partner

  const createCareerHistoryLog = ({ career_id, context, input }) => {
    const career_history_log = HISTORY_LOG({
      event_name: 'CAREER_HISTORY_EVENT',
      source: career_id,
      career_id,
      partner_id,
      fired_by_id: username,
      data: JSON.stringify({ context, input })
    })

    return mutation({
      mutation: 'createLog',
      input: career_history_log,
      options: { disableLastUpdatedBy: true }
    })
  }

  const createSpaceHistoryLog = ({
    space_id,
    career_id,
    context,
    status,
    option,
    message
  }) => {
    // fired_by_id gets set via event.identity.username
    return query({
      query: 'helperControl',
      variables: {
        action: 'createSpaceHistoryLog',
        space_id,
        partner_id,
        career_id: career_id || undefined, // could be null
        log_data: JSON.stringify({ context, status, option, message })
      }
    })
  }

  return { createCareerHistoryLog, createSpaceHistoryLog }
}
