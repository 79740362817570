import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

const Modal = ({
  children,
  open,
  setOpen,
  size = 'sm',
  zIndex = 'z-20',
  hideCloseButton = false
}) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as='div' className={clsx('relative', zIndex)} onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div
            className='fixed inset-0 bg-slate-900/25 backdrop-blur-sm transition-opacity'
            aria-hidden='true'
          />
        </TransitionChild>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel
                className={clsx(
                  'relative w-full transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-8',
                  size === 'sm' && 'sm:max-w-sm',
                  size === 'md' && 'sm:max-w-md',
                  size === 'lg' && 'sm:max-w-lg',
                  size === 'xl' && 'sm:max-w-xl',
                  size === '2xl' && 'sm:max-w-2xl',
                  size === '3xl' && 'sm:max-w-3xl',
                  size === '4xl' && 'sm:max-w-4xl',
                  size === '5xl' && 'sm:max-w-5xl',
                  size === '6xl' && 'sm:max-w-6xl',
                  size === '7xl' && 'sm:max-w-7xl',
                  size === 'screen-2xl' && 'sm:max-w-screen-2xl'
                )}
              >
                {hideCloseButton ? null : (
                  <div className='absolute right-8 top-6'>
                    <XMarkIcon
                      onClick={() => setOpen(false)}
                      className='h-6 w-6 cursor-pointer'
                    />
                  </div>
                )}
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  size: PropTypes.oneOf([
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
    'screen-2xl'
  ]),
  zIndex: PropTypes.string,
  hideCloseButton: PropTypes.bool
}

export default Modal
