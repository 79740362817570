import clsx from 'clsx'
import PropTypes from 'prop-types'

const DividerWithText = ({ className = '', text }) => {
  return (
    <div className={clsx('relative', className)}>
      <div aria-hidden='true' className='absolute inset-0 flex items-center'>
        <div className='w-full border-t border-gray-300' />
      </div>
      <div className='relative flex justify-center'>
        <span className='bg-white px-2 text-sm text-gray-500'>{text}</span>
      </div>
    </div>
  )
}

DividerWithText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired
}

export default DividerWithText
