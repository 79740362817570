import { PlusIcon } from '@heroicons/react/24/solid'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { ActivityIndicator } from '..'
import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext,
  useUserContext
} from '../../context'
import { query } from '../../graphql'
import { employeeContextFilterInput } from '../../graphql/filter-inputs'
import { EMAIL_REGEX } from '../../helpers'
import { ROLES } from '../../helpers/authorization-roles'
import { localStorage } from '../../helpers/local-storage'
import { usePaymentRestrictions } from '../../hooks/use-payment-restrictions'
import Input from '../input'
import SEO from '../seo'
import ModalCreateUser from './user-management/modal-create-user'
import Users from './user-management/users'

const UserManagement = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { isAdmin: is_aivy_admin, active_space } = useUserContext()
  const { seat_count } = usePaymentContext()
  const { error } = useNotificationContext()

  const {
    data: users,
    refetch: refetchUsers,
    status
  } = useQuery({
    queryKey: ['user-management', partner.id],
    queryFn: () =>
      query({
        query: 'spacesByPartnerIdByUpdatedAt',
        variables: {
          partner_id: partner.id,
          filter: employeeContextFilterInput
        }
      })
  })

  const [openCreateUser, setOpenCreateUser] = useState(false)
  const [inputMail, setInputMail] = useState('')
  const [inputMailValidationError, setInputMailValidationError] = useState(null)

  const emailErrorMessage = t('settings.user_management.input_email_validation')
  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL_REGEX, emailErrorMessage)
      .required(emailErrorMessage)
  })

  const restricted = usePaymentRestrictions({
    key: 'seat_count',
    value: users?.length || 0
  })

  const has_admin_role = active_space.authorization_role.id === ROLES.ADMIN.id
  const can_invite_teammember = has_admin_role || is_aivy_admin

  if (status === 'pending') return <ActivityIndicator />

  const handleOnClickInviteTeammember = async () => {
    const aivy_admin_is_inside_group_change = !!localStorage.getItem('admin')

    if (is_aivy_admin && aivy_admin_is_inside_group_change) {
      error(t('settings.user_management.add_user_admin_error'))
      return
    }

    if (restricted) {
      error(t('settings.user_management.max_users_reached_error'))
      return
    }

    try {
      await emailSchema.validate({
        email: inputMail.toLowerCase()
      })

      if (
        users.find(
          ({ person }) =>
            person?.email === inputMail.toLowerCase() ||
            person?.invitation_email === inputMail.toLowerCase()
        )
      ) {
        const message = t(
          'settings.user_management.input_email_already_invited_validation'
        )

        throw new Error(message)
      }

      setOpenCreateUser(true)
    } catch (err) {
      setInputMailValidationError(err.message)
    }
  }

  return (
    <>
      <div className='max-w-2xl'>
        <SEO title={t('seo.titles.settings_user')} />
        <h1 className='text-xl font-semibold text-gray-900'>
          {t('settings.user_management.settings_container_title')}
        </h1>
        <p className='mt-1 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
          {t('settings.user_management.settings_container_description')}
        </p>
        {!has_admin_role && (
          <p className='xlg:max-w-2xl mt-4 max-w-lg text-sm italic text-gray-700'>
            {t('settings.user_management.not_admin_hint', {
              role: t(active_space.authorization_role.title)
            })}
          </p>
        )}
        {can_invite_teammember && (
          <div className='mt-4'>
            <div className='flex flex-col gap-y-1 sm:flex-row'>
              <div className='flex-grow'>
                <div className='relative'>
                  <Input
                    id='email'
                    type='email'
                    autoComplete='off'
                    placeholder={t(
                      'settings.user_management.input_email_placeholder'
                    )}
                    onChange={(event) => {
                      if (inputMailValidationError) {
                        setInputMailValidationError(null)
                      }

                      setInputMail(event.target.value.toLowerCase())
                    }}
                    value={inputMail}
                    error={inputMailValidationError}
                    hideErrorIcon
                    touched
                    inlineErrorMessage={false}
                  />
                </div>
              </div>
              <span className='ml-auto mt-2 sm:ml-3 sm:mt-0'>
                <button
                  type='button'
                  onClick={handleOnClickInviteTeammember}
                  className='inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                >
                  <PlusIcon
                    className='-ml-2 mr-1 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  <span>
                    {t('settings.user_management.add_user_action', {
                      users: users.length,
                      seat_count
                    })}
                    <span className='italic'>{` (${users.length}/${seat_count})`}</span>
                  </span>
                </button>
              </span>
            </div>
          </div>
        )}
        <div className='mt-4' />
        <Users users={users} refetchUsers={refetchUsers} />
      </div>
      <ModalCreateUser
        open={openCreateUser}
        setOpen={setOpenCreateUser}
        data={{ email: inputMail }}
        onSuccess={() => {
          refetchUsers()
          setInputMail('')
        }}
      />
    </>
  )
}

export default UserManagement
