import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { ActivityIndicator } from '..'
import { getJobCategory } from '../../constants/job-categories'
import { usePartnerContext, usePaymentContext } from '../../context'
import { query } from '../../graphql'
import NetworkError from '../network-error'
import Panel from '../tailwind/Panel'
import CareersMatching from './careers-matching'

const TRANSLATION_LOCATION = 'pages.talent.'

const CareersMatchingContainer = ({ space, refetchSpace }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { crossComparison } = usePaymentContext()

  const getScore = ({ matching_score, complete_dataset, isScoreVisible }) => {
    let value
    let color // the round div at the beginning of a row

    if (complete_dataset) value = Math.round(matching_score)
    else value = t('talents.crossmode.complete_dataset_false')

    if (!complete_dataset) {
      color = '#e5e7eb'
    } else if (value >= 71 && value <= 100) {
      color = '#29CCC7'
    } else if (value >= 41 && value < 71) {
      color = '#FF9A3B'
    } else {
      color = '#FF8AB0'
    }

    if (complete_dataset && !isScoreVisible) value = '-'

    return { value, color, complete_dataset }
  }

  const queryMatchMeIfYouCanEnabled = !!space.owner && space.progress === 100

  const {
    status,
    data,
    error: queryError
  } = useQuery({
    queryKey: ['match-me-if-you-can', space.owner, partner.id],
    queryFn: () =>
      query({
        query: 'matchMeIfYouCan',
        variables: {
          partner_id: partner.id,
          user_id: space.owner
        }
      }),
    enabled: queryMatchMeIfYouCanEnabled,
    select: (response) => {
      const result = JSON.parse(response)
        .filter((career) => !career.archived)
        .map((career) => ({
          ...career,
          categoryName: getJobCategory(career.category),
          score: getScore({ ...career, isScoreVisible: partner.isScoreVisible })
        }))
        .sort((a, b) => {
          if (!a.complete_dataset || !b.complete_dataset) return -1
          return b.score.value - a.score.value
        })

      if (!crossComparison) {
        return result
          .filter(({ complete_dataset }) => complete_dataset)
          .slice(0, 5)
      }

      return result
    },
    refetchOnWindowFocus: false
  })

  if (queryError) return <NetworkError />

  if (queryMatchMeIfYouCanEnabled && status === 'pending') {
    return <ActivityIndicator className='mx-auto my-16' />
  }

  if (space.progress < 100 && crossComparison) {
    return (
      <Panel>
        <div className='flex w-full items-center justify-center'>
          <p className='max-w-lg text-center text-sm italic text-gray-500'>
            {t(TRANSLATION_LOCATION + 'careers_matching_progress_smaller_100')}
          </p>
        </div>
      </Panel>
    )
  }

  return <CareersMatching {...{ space, refetchSpace, data: data || [] }} />
}

CareersMatchingContainer.propTypes = {
  space: PropTypes.object.isRequired,
  refetchSpace: PropTypes.func.isRequired
}

export default CareersMatchingContainer
