import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ActivityIndicator, Seo } from '../components'
import Data from '../components/aivy-admin/data'
import Logs from '../components/aivy-admin/logs'
import ChooseSpaces from '../components/create-team-analyse/choose-spaces'
import Create from '../components/create-team-analyse/create'
import Research from '../components/create-team-analyse/research'
import LayoutPadding from '../components/layout-padding'
import Button from '../components/tailwind/Button'
import PageHeading from '../components/tailwind/PageHeading'
import Steps from '../components/tailwind/steps'
import { useUserContext } from '../context'
import { mutation, query } from '../graphql'
import { useTalentsQuery } from '../hooks/use-talents-query'

const CreateTeamAnalyse = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { isAdmin } = useUserContext()

  const [displayLogs, setDisplayLogs] = useState(false)
  const [displayData, setDisplayData] = useState(false)

  const {
    data: analyse,
    status: analyse_status,
    refetch: refetch_analyse
  } = useQuery({
    queryKey: ['create-team-analyse', id],
    queryFn: () => query({ query: 'getAnalyse', variables: { id } }),
    enabled: !!id,
    select: (response) => ({ ...response, ...JSON.parse(response.input) })
  })

  const { data: spaces_data, status: spaces_status } = useTalentsQuery()

  // amplify bug! - updateAnalyse: res - { input: null }
  // const analyseMutation = useMutationAndUpdateQuery({
  //   queryKey: ['create-team-analyse', id],
  //   mutation: 'updateAnalyse'
  // })

  const analyseMutation = {
    // implement react-query use mutation functionality with refetch
    mutate: async ({ input }, callbacks = {}) => {
      const { onSuccess, onError } = callbacks

      let response
      // amplify bug! - updateCareer: res - { career_analyse: null }
      try {
        response = await mutation({ mutation: 'updateAnalyse', input })
      } catch (err) {
        onError && onError(response)
        return
      }

      await refetch_analyse()
      onSuccess && onSuccess(response)
    }
  }

  if (!!id && [analyse_status, spaces_status].includes('pending')) {
    return (
      <div className='mx-12'>
        <ActivityIndicator />
      </div>
    )
  }

  const { status } = analyse || {}

  const steps = [
    {
      id: '01',
      current: !status,
      complete: ['WAIT_SPACES', 'WAIT_RESEARCH'].includes(status)
    },
    {
      id: '02',
      current: status === 'WAIT_SPACES',
      complete: ['WAIT_RESEARCH'].includes(status)
    },
    {
      id: '03',
      current: ['WAIT_RESEARCH', 'ACTIVE'].includes(status),
      complete: false
      // complete: ['WAIT_ACTIVE'].includes(status)
    }
    // {
    //   id: '04',
    //   name: t('team_analyse.new.step_4_title'),
    //   current: status === 'WAIT_ACTIVE',
    //   complete: false
    // }
  ]

  return (
    <>
      <Seo title={analyse?.title || t('team_analyse.new.empty_title')} />
      <PageHeading
        title={analyse?.title || t('team_analyse.new.empty_title')}
        withActions={
          isAdmin && analyse ? (
            <div className='ml-auto flex gap-x-1.5'>
              <Button.SecondaryLG
                text='Show Partner'
                onClick={() => setDisplayData(true)}
              />
              <Button.SecondaryLG
                text='Show Logs'
                onClick={() => setDisplayLogs(true)}
              />
            </div>
          ) : undefined
        }
      />
      <LayoutPadding padding='pt-4 pb-12'>
        <div className='mx-auto max-w-5xl'>
          <div className='mt-6'>
            <Steps steps={steps} />
          </div>
          <div className='relative mt-8'>
            {!status && (
              <Create
                analyse={analyse || {}}
                analyseMutation={analyseMutation}
              />
            )}
            {status === 'WAIT_SPACES' && (
              <ChooseSpaces
                analyse={analyse}
                analyseMutation={analyseMutation}
                spaces={spaces_data.spaces.filter((s) => s.progress === 100)}
              />
            )}
            {status === 'WAIT_RESEARCH' && (
              <Research analyse={analyse} analyseMutation={analyseMutation} />
            )}
          </div>
        </div>
      </LayoutPadding>
      {analyse && (
        <>
          <Logs
            title='TEAM-ANALYSE-LOGS'
            open={displayLogs}
            setOpen={setDisplayLogs}
            // TODO
          />
          <Data
            title='TEAM-ANALYSE'
            open={displayData}
            setOpen={setDisplayData}
            data={analyse}
          />
        </>
      )}
    </>
  )
}

export default CreateTeamAnalyse
