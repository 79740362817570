import { Slider } from '@mui/material'
import { Storage } from 'aws-amplify'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import Cropper from 'react-easy-crop'
import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'

import { colors } from '../constants/colors'
import { useNotificationContext, useUserContext } from '../context'
import { notifyBugsnag, resizeFile } from '../helpers'
import getCroppedImg from '../helpers/crop-image'
import { useEventListener } from '../hooks/use-event-listener'
import { useSetState } from '../hooks/use-set-state'
import Button from './tailwind/Button'
import Modal from './tailwind/Modal'

const INITIAL_STATE = {
  isCreatingImage: false,
  isUploading: false,
  storageUrl: null,
  crop: { x: 0, y: 0 },
  croppedAreaPixels: null,
  zoom: 1
}

const ImageCropAssessment = ({ open, close, localImage, onSubmit }) => {
  const { t } = useTranslation()
  const { active_space } = useUserContext()
  const { error } = useNotificationContext()

  const username = useRef(active_space.owner)
  const storageKey = useRef(null)

  const [state, setState] = useSetState(INITIAL_STATE)

  const {
    isCreatingImage,
    isUploading,
    storageUrl,
    crop,
    croppedAreaPixels,
    zoom
  } = state

  const deleteTempFile = () => {
    storageKey.current && Storage.remove(storageKey.current)
  }

  useEventListener({
    event: 'beforeunload',
    handler: deleteTempFile
  })

  useEffect(() => {
    // upload selected file to .temp
    const doAsync = async () => {
      setState({ isUploading: true })

      const fe = localImage.name.split('.').pop()
      const result = await Storage.put(
        `.temp/${username.current}.${fe}`,
        localImage
      )

      storageKey.current = result.key
      const url = (await Storage.get(result.key)).split('?')[0]

      setState({ storageUrl: url, isUploading: false })
    }

    if (localImage) doAsync()
  }, [localImage, setState])

  const handleClose = () => {
    close()

    // await transition ease-out
    setTimeout(() => {
      setState(INITIAL_STATE)
      deleteTempFile()
    }, 0.4 * 1000)
  }

  const handleSubmit = async () => {
    setState({ isCreatingImage: true })

    try {
      let image = await getCroppedImg({
        imageSrc: storageUrl,
        pixelCrop: croppedAreaPixels,
        fileName: 'temp.png' // gets set inside on submit
      })

      image = await resizeFile(image, 500, 500)

      onSubmit({ image })
    } catch (err) {
      notifyBugsnag(err)
      error()
    } finally {
      handleClose()
    }
  }

  return (
    <Modal open={open} setOpen={handleClose} size='lg' hideCloseButton>
      <div className='px-8'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
          {t('image_crop_assessment.title')}
        </h2>

        <div style={{ height: 384 }} className='relative mt-6 rounded'>
          {isUploading ? (
            <div className='flex h-full items-center justify-center pb-32'>
              <BeatLoader
                color={colors.darkGrey}
                speedMultiplier={0.5}
                size={16}
              />
            </div>
          ) : (
            <Cropper
              image={storageUrl}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape='rect'
              onCropChange={(crop) => setState({ crop })}
              onCropComplete={(_, cap) => setState({ croppedAreaPixels: cap })}
              onZoomChange={(zoom) => setState({ zoom })}
              classes={{ containerClassName: 'rounded-xl' }}
            />
          )}
        </div>

        <div className='mx-auto mt-6'>
          <Slider
            value={zoom}
            disabled={isUploading}
            min={1}
            max={3}
            step={0.1}
            onChange={(_, zoom) => setState({ zoom })}
          />
        </div>

        <div className='mt-6 flex justify-end gap-x-2'>
          <Button.SecondaryLG
            text={t('image_crop_assessment.cancel_action')}
            onClick={handleClose}
            disabled={isUploading}
          />
          <Button.PrimaryLG
            text={t('image_crop_assessment.submit_action')}
            onClick={handleSubmit}
            disabled={isUploading}
            isLoading={isCreatingImage}
          />
        </div>
      </div>
    </Modal>
  )
}

ImageCropAssessment.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  localImage: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}

ImageCropAssessment.defaultProps = {
  localImage: null
}

export default ImageCropAssessment
