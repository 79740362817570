import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAivyContext } from '../../context'
import { query } from '../../graphql'
import ActivityRings from '../activity-rings'
import Panel from '../tailwind/Panel'

const SpaceCompletedChallenges = ({ space, career }) => {
  const { t } = useTranslation()

  const { owner } = space
  const { id: career_id, app_settings } = career || {}

  const { system } = useAivyContext()

  const [activityRingData, setActivityRingData] = useState({
    interestsPercent: 0,
    skillsPercent: 0,
    personalityPercent: 0
  })

  const { data: answersByOwner } = useQuery({
    queryKey: ['answers', owner],
    queryFn: () => query({ query: 'answersByOwner', variables: { owner } }),
    enabled: !!owner
  })

  const challenges = useMemo(() => {
    if (!app_settings?.challenges) return null

    const parsed = Object.values(JSON.parse(system.challenges.data))

    if (!career_id) {
      return parsed.filter((c) => c.active === true)
    }

    const careerChallenges = JSON.parse(app_settings.challenges).map(
      (c) => c.exam_id
    )

    return parsed
      .filter((c) => c.active === true)
      .filter((c) => careerChallenges.includes(c.exam_id))
  }, [system, career_id, app_settings])

  useEffect(() => {
    if (!challenges) return

    if (
      answersByOwner === undefined ||
      answersByOwner === null ||
      Array.isArray(answersByOwner) === false
    )
      return

    const interestsCategory = challenges
      .filter((c) => c.category === 'INTERESTS')
      .map((c) => c.exam_id)

    const personalityCategory = challenges
      .filter((c) => c.category === 'PERSONALITY')
      .map((c) => c.exam_id)

    const skillsCategory = challenges
      .filter((c) => c.category === 'SKILLS')
      .map((c) => c.exam_id)

    const mappedAnswers = answersByOwner.map((a) => a.exam_id)

    const completedInterests = countMatchingItems(
      interestsCategory,
      mappedAnswers
    )

    const completedPersonality = countMatchingItems(
      personalityCategory,
      mappedAnswers
    )

    const completedSkills = countMatchingItems(skillsCategory, mappedAnswers)

    const interestsPercent = interestsCategory.length
      ? (100 * completedInterests) / interestsCategory.length
      : 0

    const skillsPercent = skillsCategory.length
      ? (100 * completedSkills) / skillsCategory.length
      : 0

    const personalityPercent = personalityCategory.length
      ? (100 * completedPersonality) / personalityCategory.length
      : 0

    setTimeout(() => {
      setActivityRingData({
        interestsPercent,
        skillsPercent,
        personalityPercent
      })
    }, 500)
  }, [answersByOwner, career_id, challenges])

  function countMatchingItems(arrayA, arrayB) {
    let count = 0
    for (let i = 0; i < arrayB.length; i++) {
      if (arrayA.includes(arrayB[i])) {
        count++
      }
    }
    return count
  }

  if (!challenges) return null

  return (
    <Panel>
      <section className='md:h-full'>
        <div className='pb-6 sm:flex'>
          <div>
            <h2
              id='applicant-information-title'
              className='text-lg font-medium leading-6 text-gray-900'
            >
              {t('pages.talent.completedChallengesTitle')}
            </h2>
            <p className='mt-1 max-w-2xl text-sm text-gray-500'>
              {t('pages.talent.completedChallengesDescription')}
            </p>
          </div>
        </div>
        <div className='flex flex-col items-center'>
          <div className='h-64 w-64'>
            <ActivityRings
              interestsPercent={activityRingData.interestsPercent}
              personalityPercent={activityRingData.personalityPercent}
              skillsPercent={activityRingData.skillsPercent}
            />
          </div>
          <div className='mt-4 flex flex-row gap-x-4'>
            <div className='flex flex-row items-center'>
              <div
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: 6,
                  backgroundColor: '#29CCC7',
                  marginRight: 4
                }}
              />
              <span>{t('global.personality')}</span>
            </div>

            <div className='flex flex-row items-center'>
              <div
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: 6,
                  backgroundColor: '#FF9A3B',
                  marginRight: 4
                }}
              />
              <span>{t('global.professionalInterests')}</span>
            </div>

            <div className='flex flex-row items-center'>
              <div
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: 6,
                  backgroundColor: '#5786FF',
                  marginRight: 4
                }}
              />
              <span>{t('global.skills')}</span>
            </div>
          </div>
        </div>
      </section>
    </Panel>
  )
}

SpaceCompletedChallenges.propTypes = {
  space: PropTypes.object.isRequired,
  career: PropTypes.object
}

SpaceCompletedChallenges.defaultProps = {
  career: null
}

export default SpaceCompletedChallenges
