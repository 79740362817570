/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next'

import { Button } from './Button'
import { Container } from './Container'

import EnbwLogo from '../../assets/images/landingpage/enbw-logo.png'
import FreseniusLogo from '../../assets/images/landingpage/fresenius-logo.png'
import HermesLogo from '../../assets/images/landingpage/hermes-logo.png'
import LufthansaLogo from '../../assets/images/landingpage/lufthansa-logo.png'
import ProvinzialLogo from '../../assets/images/landingpage/provinzial-logo.png'
import RvLogo from '../../assets/images/landingpage/r+v-logo.png'
import ThyssenkruppLogo from '../../assets/images/landingpage/thyssenkrupp-logo.png'
import WuerthLogo from '../../assets/images/landingpage/wuerth-logo.png'
// import LBankLogo from '../../assets/images/landingpage/l-banklogo.png'

import Shape01 from '../../assets/images/landingpage/shape01.svg'

export function Hero({ onPress, onPressVideo }) {
  const { t } = useTranslation()
  return (
    <Container className='relative pb-16 pt-20 text-center lg:pt-32'>
      <img
        src={Shape01}
        className='absolute top-48 hidden h-auto w-56 rotate-180 lg:flex'
      />
      <img
        style={{ rotate: '120deg', opacity: 0.9 }}
        src={Shape01}
        className='absolute bottom-52 right-16 hidden h-auto w-60 lg:flex'
      />
      <h1 className='font-display mx-auto max-w-4xl text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl'>
        {t('landingpage.hero.headline.first')}{' '}
        <span className='relative whitespace-nowrap text-blue-600'>
          <svg
            aria-hidden='true'
            viewBox='0 0 418 42'
            className='absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-300/70'
            preserveAspectRatio='none'
          >
            <path d='M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z' />
          </svg>
          <span className='relative'>
            {t('landingpage.hero.headline.second')}
          </span>
        </span>{' '}
        {t('landingpage.hero.headline.third')}
      </h1>

      <p className='mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700'>
        {t('landingpage.hero.subheadline')}
      </p>

      <div className='mt-10 flex justify-center gap-x-6'>
        <Button onClick={onPress}>
          {t('landingpage.hero.tryout_for_free_cta')}
        </Button>
        <Button variant='outline' onClick={onPressVideo}>
          <svg
            aria-hidden='true'
            className='h-3 w-3 flex-none fill-blue-600 group-active:fill-current'
          >
            <path d='m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z' />
          </svg>
          <span className='ml-3'>
            {t('landingpage.hero.short_desc_aivy_cta')}
          </span>
        </Button>
      </div>
      <div className='mx-auto mt-8 flex flex-wrap justify-center gap-4'>
        <div className='flex flex-row'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='h-6 w-6 text-slate-700'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
            />
          </svg>
          <span className='ml-2 whitespace-nowrap font-bold text-slate-700'>
            {t('landingpage.hero.satisfaction')}
          </span>
        </div>
        <div className='flex flex-row'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='h-6 w-6 text-slate-700'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
          <span className='ml-2 whitespace-nowrap font-bold text-slate-700'>
            {t('landingpage.hero.time_saving')}
          </span>
        </div>
        <div className='flex flex-row'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='h-6 w-6 text-slate-700'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
            />
          </svg>
          <span className='ml-2 whitespace-nowrap font-bold text-slate-700'>
            {t('landingpage.hero.dsgvo')}
          </span>
        </div>
        <div className='flex flex-row'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='h-6 w-6 text-slate-700'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z'
            />
          </svg>
          <span className='ml-2 whitespace-nowrap font-bold text-slate-700'>
            {t('landingpage.hero.no_creditcard')}
          </span>
        </div>
      </div>
      <div className='mt-36 lg:mt-44'>
        <p className='font-display text-base text-slate-900'>
          {t('landingpage.hero.used_by_companies.first')}{' '}
          <strong>{t('landingpage.hero.used_by_companies.second')}</strong>{' '}
          {t('landingpage.hero.used_by_companies.third')}
        </p>
        <ul
          role='list'
          className='mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0'
        >
          {[
            [
              {
                name: 'Fresenius',
                logo: FreseniusLogo,
                className: 'w-32 h-auto object-contain'
              },
              {
                name: 'Würth',
                logo: WuerthLogo,
                className: 'w-32 h-auto object-contain'
              },
              {
                name: 'Lufthansa',
                logo: LufthansaLogo,
                className: 'w-32 h-auto object-contain'
              },
              {
                name: 'Provinzial',
                logo: ProvinzialLogo,
                className: 'w-32 h-auto object-contain'
              }
            ],
            [
              {
                name: 'Thyssenkrupp',
                logo: ThyssenkruppLogo,
                className: 'h-12 w-auto object-contain'
              },
              {
                name: 'Hermès',
                logo: HermesLogo,
                className: 'h-10 w-auto object-contain'
              },
              {
                name: 'R+V',
                logo: RvLogo,
                className: 'h-8 w-auto object-contain'
              },
              {
                name: 'EnBW',
                logo: EnbwLogo,
                className: 'w-32 h-auto object-contain'
              }
            ]
          ].map((group, groupIndex) => (
            <li key={groupIndex}>
              <ul
                role='list'
                className='flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0'
              >
                {group.map((company) => (
                  <li key={company.name} className='flex'>
                    <img
                      src={company.logo}
                      alt={company.name}
                      className={company.className}
                    />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}
