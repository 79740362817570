import { useQuery } from '@tanstack/react-query'

import { useUserContext } from '../context'
import { query } from '../graphql'
import { getSpacesTableData } from '../helpers/spaces-table-data'

const MIN_EXAMS = [
  'BIG_FIVE',
  'HEROS_JOURNEY',
  'EMOTIONS_GAME',
  'TOWER_OF_LONDON',
  'ANALOGICAL_REASONING',
  'FLANKER'
]

export const useTeamAnalyseQuery = ({ id }) => {
  const {
    cognitoUser: { username }
  } = useUserContext()

  const useQueryResult = useQuery({
    queryKey: ['team-analyse', id, username],
    queryFn: async () => {
      const result = {}

      result.analyse = await query({ query: 'getAnalyse', variables: { id } })
      result.analyse = {
        ...result.analyse,
        ...JSON.parse(result.analyse.input)
      }

      result.spaces = await Promise.all(
        result.analyse.spaces.map((id) => {
          return new Promise((resolve) => {
            query({ query: 'getSpace', variables: { id } }).then((space) => {
              if (!space || space.soft_deleted) {
                resolve({ id, deleted: true })
                return
              }

              if (!space.owner) {
                resolve({ ...space, minimum_requirement_fulfilled: false })
                return
              }

              query({
                query: 'helperControl',
                variables: {
                  action: 'listAnswersWithAivyOutput',
                  user_id: space.owner
                }
              }).then((response) => {
                const answers = JSON.parse(
                  response
                ).body.listAnswersWithAivyOutput.res.reduce(
                  (object, item) => ({ ...object, [item.exam_id]: item }),
                  {}
                )

                space.minimum_requirement_fulfilled = true
                const all_played_exams = Object.keys(answers)

                MIN_EXAMS.forEach((exam_id) => {
                  if (!all_played_exams.includes(exam_id)) {
                    space.minimum_requirement_fulfilled = false
                  }
                })

                resolve({ ...space, answers })
              })
            })
          })
        })
      )

      result.spaces = [
        ...getSpacesTableData({
          spaces: result.spaces.filter(({ deleted }) => !deleted),
          user_id: username
        })
        // ...result.spaces.filter(({ deleted }) => deleted)
      ]

      return result
    }
  })

  return useQueryResult
}
