import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

const NativeTooltip = ({ className, text, delay, position, children }) => {
  const [visible, setVisible] = useState(false)
  const timeoutRef = useRef(null)

  const showTooltip = () => {
    timeoutRef.current = setTimeout(() => {
      setVisible(true)
    }, delay)
  }

  const hideTooltip = () => {
    clearTimeout(timeoutRef.current)
    setVisible(false)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <div
      className={className}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      <div
        className={clsx(
          'absolute left-1/2 z-20 -translate-x-1/2 transform rounded bg-gray-900 px-3 py-1.5 transition-opacity duration-200 ease-in-out',
          'whitespace-nowrap text-center text-xs font-medium text-white',
          position === 'bottom' ? 'top-full mt-2' : 'bottom-full mb-2',
          visible ? 'opacity-100' : 'opacity-0'
        )}
      >
        {text}
      </div>
    </div>
  )
}

NativeTooltip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  delay: PropTypes.number,
  position: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node.isRequired
}

NativeTooltip.defaultProps = {
  className: 'relative flex cursor-pointer',
  delay: 1000,
  position: 'top'
}

export default NativeTooltip
