import { PlusCircleIcon, SparklesIcon } from '@heroicons/react/24/solid'
import { useFormik } from 'formik'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from '..'
import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext
} from '../../context'
import { query } from '../../graphql'
import { EMAIL_REGEX, classNames } from '../../helpers'
import { usePartnerIntegrationCreate } from '../../hooks/use-partner-integration-create'
import { usePartnerIntegrationDelete } from '../../hooks/use-partner-integration-delete'
import { usePartnerIntegrations } from '../../hooks/use-partner-integrations'
import Divider from '../divider'
import Input from '../input'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'

const TRANSLATION_LOCATION = 'settings.integrations.'

const Integrations = () => {
  const { t } = useTranslation()
  const { success, alert } = useNotificationContext()
  const { integrations: integrationsAvailable, showPaywall } =
    usePaymentContext()
  const { partner } = usePartnerContext()

  const [displayAddIntegration, setDisplayAddIntegration] = useState(false)
  const [displayActiveIntegration, setDisplayActiveIntegration] =
    useState(false)

  const [selectedIntegration, setSelectedIntegration] = useState(null)

  const { status: integrationsStatus, data: integrations } = useQuery({
    queryKey: ['integrations'],
    queryFn: () =>
      query({
        query: 'integrationControl',
        variables: { action: 'listIntegrations' }
      }),
    select: (response) => {
      const integrationsResult = {}
      JSON.parse(response).listIntegrations.res.forEach(
        (i) =>
          (integrationsResult[i.remote_service_name || i.remote_service] = {
            ...i
          })
      )

      return integrationsResult
    }
  })

  const { status: partnerIntegrationsStatus, data: partnerIntegrations } =
    usePartnerIntegrations({})

  const createIntegration = usePartnerIntegrationCreate()
  const deleteIntegration = usePartnerIntegrationDelete()

  const initialValues = useMemo(() => {
    if (!selectedIntegration || selectedIntegration.isActive) return {}

    const result = {}

    selectedIntegration.integration_settings.forEach(({ field }) => {
      result[field] = ''
    })

    return result
  }, [selectedIntegration])

  const yup = useMemo(() => {
    return {
      email: () =>
        Yup.string().matches(
          EMAIL_REGEX,
          t(TRANSLATION_LOCATION + 'email_validation')
        ),
      assessment_status_reference: () => Yup.string(),
      assessment_test_reference: () => Yup.string(),
      password: () => Yup.string(),
      username: () => Yup.string(),
      url: () => Yup.string().url(t(TRANSLATION_LOCATION + 'url_validation')),
      dvinci_api_user: () => Yup.string(),
      dvinci_api_token: () => Yup.string(),
      token: () => Yup.string()
    }
  }, [t])

  const validationSchema = useMemo(() => {
    if (!selectedIntegration || selectedIntegration.isActive) return null

    const result = {}

    selectedIntegration.integration_settings.forEach(({ field, required }) => {
      result[field] = required
        ? yup[field]().required(t(TRANSLATION_LOCATION + 'required_validation'))
        : yup[field]()
    })

    return Yup.object(result)
  }, [selectedIntegration, yup, t])

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const input = {
        authorized_create: ['__create_' + partner.id],
        authorized_read: ['__read_' + partner.id],
        authorized_update: ['__update_' + partner.id],
        authorized_delete: ['__delete_' + partner.id],
        partner_id: partner.id,
        integration_settings: values,
        remote_service: selectedIntegration.remote_service,
        remote_service_name: selectedIntegration.remote_service_name || null
      }

      createIntegration.mutate(
        { input },
        {
          onSuccess: async () => {
            success(t(TRANSLATION_LOCATION + 'create_success_message'))

            setDisplayAddIntegration(false)
            // await close modal transition
            await new Promise((resolve) => setTimeout(resolve, 1000 * 0.4))
            setSelectedIntegration(null)
            formik.resetForm()
            formik.setSubmitting(false)
          },
          onError: () => formik.setSubmitting(false)
        }
      )
    }
  })

  const deleteIntegrationHandler = ({ id }) => {
    alert(
      t(TRANSLATION_LOCATION + 'modal_remove_integration_title'),
      t(TRANSLATION_LOCATION + 'modal_remove_integration_description'),
      [
        {
          onPress: () => {
            deleteIntegration.mutate(
              { input: { id } },
              {
                onSuccess: () =>
                  success(t(TRANSLATION_LOCATION + 'remove_success_message'))
              }
            )
          },
          text: t(
            TRANSLATION_LOCATION + 'modal_remove_integration_submit_action'
          )
        },
        {
          text: t(
            TRANSLATION_LOCATION + 'modal_remove_integration_cancel_action'
          ),
          style: 'cancel'
        }
      ]
    )
  }

  if (
    integrationsStatus === 'pending' ||
    partnerIntegrationsStatus === 'pending'
  ) {
    return <ActivityIndicator className='flex justify-center py-16' />
  }

  const getIntegrationListItem = ({ integration, index }) => {
    const { logo_landscape, isActive } = integration

    return (
      <div
        key={index}
        onClick={
          integrationsAvailable
            ? () => {
                if (isActive) setDisplayActiveIntegration(true)
                else setDisplayAddIntegration(true)

                setSelectedIntegration(integration)
              }
            : () => showPaywall('general')
        }
        className='group relative h-32 w-64 rounded-xl bg-gray-50 px-16 py-8 hover:cursor-pointer hover:bg-gray-100'
      >
        <div className='h-12'>
          <img
            alt=''
            src={logo_landscape}
            className='h-full w-full object-contain'
          />
        </div>

        {isActive ? (
          <div
            className={classNames(
              'flex items-center justify-center pr-3',
              'absolute left-2 top-2',
              'rounded-full bg-white group-hover:bg-gray-50'
            )}
          >
            <div className='h-6 w-6 rounded-full p-0.5'>
              <div
                style={{ background: '#29CCC7' }} // primary green
                className='h-full w-full rounded-full'
              />
            </div>
            <span className='ml-1 text-xs font-semibold text-gray-600 group-hover:text-gray-700'>
              {t(TRANSLATION_LOCATION + 'create_integration_active_label')}
            </span>
          </div>
        ) : (
          <div
            className={classNames(
              'absolute flex items-center justify-center rounded-full',
              integrationsAvailable
                ? 'bottom-2 right-2 bg-white pl-3 group-hover:bg-gray-50'
                : '-right-1 -top-1 h-6 w-6 bg-amber-400'
            )}
          >
            {integrationsAvailable ? (
              <>
                <span className='mr-1 text-xs font-semibold text-gray-600 group-hover:text-gray-700'>
                  {t(
                    TRANSLATION_LOCATION + 'create_integration_activate_action'
                  )}
                </span>
                <PlusCircleIcon className='h-6 w-6 text-gray-600 group-hover:text-gray-700' />{' '}
              </>
            ) : (
              <SparklesIcon
                className='mx-auto my-auto h-4 w-4 text-white'
                aria-hidden='true'
              />
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div>
        <h1 className='text-xl font-semibold text-gray-900'>
          {t(TRANSLATION_LOCATION + 'settings_container_title')}
        </h1>
        <p className='mt-1 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
          {t(TRANSLATION_LOCATION + 'settings_container_description')}
        </p>
      </div>
      <div className='mt-8 flex max-w-6xl flex-wrap gap-4'>
        {integrationsAvailable &&
          partnerIntegrations.map((integration, index) => {
            const { remote_service_name, remote_service } = integration
            const { logo, logo_landscape } =
              integrations[remote_service_name || remote_service]

            return getIntegrationListItem({
              integration: {
                ...integration,
                logo,
                logo_landscape,
                isActive: true
              },
              index
            })
          })}
        {integrationsAvailable && !!partnerIntegrations.length && (
          <div className='w-full px-8'>
            <Divider />
          </div>
        )}

        {Object.keys(integrations).map((key, index) =>
          getIntegrationListItem({
            integration: { id: key, ...integrations[key], isActive: false },
            index
          })
        )}
      </div>
      <Modal
        open={displayActiveIntegration}
        setOpen={() => setDisplayActiveIntegration(false)}
        size='3xl'
      >
        <div className='mt-3 text-center sm:mt-5'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            {t(TRANSLATION_LOCATION + 'active_integration_popup_title')}
          </h2>
        </div>
        <div className='mx-auto mb-8 mt-4 max-w-xl'>
          {selectedIntegration && selectedIntegration.isActive && (
            <>
              <div className='flex items-center justify-center'>
                <img
                  className='block h-8 w-8 object-contain'
                  src={selectedIntegration.logo}
                  alt='integration-logo'
                />
                <span className='text-md ml-4 block font-medium text-gray-900'>
                  {t(
                    TRANSLATION_LOCATION +
                      `${
                        selectedIntegration.remote_service_name ||
                        selectedIntegration.remote_service
                      }_title`
                  )}
                </span>
              </div>
              <div className='mt-4'>
                <div className='px-2 py-4'>
                  {Object.keys(selectedIntegration.integration_settings || {})
                    .filter(
                      (key) => selectedIntegration.integration_settings[key]
                    )
                    .map((key, index) => {
                      const setting = {
                        key,
                        value: selectedIntegration.integration_settings[key]
                      }

                      return (
                        <div key={index} className='mt-4 flex'>
                          <span className='block text-sm text-gray-700'>
                            {t(`${TRANSLATION_LOCATION}${key}_label`)}:
                          </span>
                          <span className='ml-2 block text-sm font-medium text-gray-900'>
                            {setting.value}
                          </span>
                        </div>
                      )
                    })}
                </div>
                <div className='mb-4 mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                  <Button.SecondaryLG
                    text={t(
                      TRANSLATION_LOCATION + 'active_integration_remove_action'
                    )}
                    onClick={() => {
                      setDisplayActiveIntegration(false)
                      deleteIntegrationHandler({ id: selectedIntegration.id })
                    }}
                  />
                  <Button.PrimaryLG
                    text={t(
                      TRANSLATION_LOCATION + 'active_integration_cancel_action'
                    )}
                    onClick={() => setDisplayActiveIntegration(false)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
      <Modal
        open={displayAddIntegration}
        setOpen={() => setDisplayAddIntegration(false)}
        size='3xl'
      >
        <div className='mt-3 text-center sm:mt-5'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            {t(TRANSLATION_LOCATION + 'create_integration_modal_title')}
          </h2>
        </div>
        <div className='mx-auto mb-8 mt-4 max-w-xl'>
          {selectedIntegration && !selectedIntegration.isActive && (
            <>
              <div className='flex items-center justify-center'>
                <img
                  className='block h-8 w-8 object-contain'
                  src={selectedIntegration.logo}
                  alt='integration-logo'
                />
                <span className='text-md ml-4 block font-medium text-gray-900'>
                  {t(
                    TRANSLATION_LOCATION +
                      `${
                        selectedIntegration.remote_service_name ||
                        selectedIntegration.remote_service
                      }_title`
                  )}
                </span>
              </div>
              <div className='mt-8'>
                {Object.keys(formik.initialValues).map((key, index) => {
                  return (
                    <div key={index} className='mt-4'>
                      <Input
                        id={key}
                        type='text'
                        label={t(`${TRANSLATION_LOCATION}${key}_label`)}
                        placeholder={t(
                          `${TRANSLATION_LOCATION}${key}_placeholder`
                        )}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        touched={formik.touched[key]}
                        value={formik.values[key] || ''}
                        error={formik.errors[key]}
                        autoComplete='off'
                      />
                    </div>
                  )
                })}
              </div>
              <div className='mb-4 mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                <Button.SecondaryLG
                  text={t(
                    TRANSLATION_LOCATION + 'create_integration_cancel_action'
                  )}
                  onClick={() => setDisplayAddIntegration(false)}
                />
                <Button.PrimaryLG
                  text={t(
                    TRANSLATION_LOCATION + 'create_integration_create_action'
                  )}
                  onClick={formik.handleSubmit}
                  isLoading={formik.isSubmitting}
                  disabled={!formik.isValid}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Integrations
