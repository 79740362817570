import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import clsx from 'clsx'
import ProfilePicture from '../assets/images/profilepicture.png'
import trackEvents from '../constants/track-events'
import { useUserContext } from '../context'
import { classNames } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import Badge from './tailwind/Badge'

const TRANSLATION_LOCATION = 'components.layout.'

const ProfileDropdown = ({ isSidebarCollapsed }) => {
  const { t } = useTranslation()
  const { cognitoUser, active_space } = useUserContext()

  const location = useLocation()

  function signOut() {
    trackEvent(trackEvents.LOGOUT)
    Auth.signOut()
  }

  const { firstname, lastname, invitation_firstname, invitation_lastname } =
    active_space.person

  // Profile Menü überschneidet sich leicht mit dem Pfeil Icon. Menü könnte von der width etwas kleiner

  return (
    <div className='flex flex-shrink-0 items-center justify-center bg-gray-900'>
      <Menu className='relative inline-block w-full text-left' as='div'>
        <MenuButton
          className={clsx(
            'group w-full py-3 text-left text-sm font-medium text-gray-700 transition-all duration-300 hover:bg-gray-800',
            isSidebarCollapsed ? 'flex justify-center' : 'px-6'
          )}
        >
          {isSidebarCollapsed && (
            <img
              className='h-10 w-10 rounded-full bg-gray-300'
              src={ProfilePicture}
              alt='profile picture'
            />
          )}
          {!isSidebarCollapsed && (
            <span className='flex w-full items-center justify-between'>
              <span className='flex min-w-0 items-center justify-between space-x-4'>
                <img
                  className='h-10 w-10 flex-shrink-0 rounded-full bg-gray-300'
                  src={ProfilePicture}
                  alt='profile picture'
                />
                <span className='flex min-w-0 flex-1 flex-col'>
                  <span className='truncate font-medium text-white'>
                    {`${firstname || invitation_firstname} ${
                      lastname || invitation_lastname
                    }`}
                  </span>
                  <span className='truncate text-xs text-gray-400'>
                    {cognitoUser.attributes.email}
                  </span>
                </span>
              </span>
              <ChevronUpDownIcon
                className='h-5 w-5 flex-shrink-0 text-gray-300 group-hover:text-gray-400'
                aria-hidden='true'
              />
            </span>
          )}
        </MenuButton>
        <Transition
          enter='transition ease-out duration-75'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <MenuItems
            className={classNames(
              'absolute bottom-16 z-50 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
              isSidebarCollapsed
                ? 'left-4 origin-top-left'
                : 'left-6 origin-top'
            )}
          >
            <div className='py-1'>
              <MenuItem>
                {() => (
                  <Link
                    to='/settings#personal'
                    onClick={() => trackEvent(trackEvents.NAVIGATE_TO_PROFILE)}
                    className={classNames(
                      location.pathname.includes('settings')
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700',
                      'block px-4 py-2 text-sm hover:bg-gray-100'
                    )}
                  >
                    {t(TRANSLATION_LOCATION + 'navigation_view_profile')}
                  </Link>
                )}
              </MenuItem>
            </div>
            <div className='py-1'>
              <MenuItem className='flex flex-row items-center justify-between'>
                {() => (
                  <Link
                    to='/test-library'
                    onClick={() =>
                      trackEvent(trackEvents.NAVIGATE_TO_TEST_LIBRARY)
                    }
                    className={classNames(
                      location.pathname.includes('test-library')
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700',
                      'block px-4 py-2 text-sm hover:bg-gray-100'
                    )}
                  >
                    {t(TRANSLATION_LOCATION + 'navigation_test_library')}
                    <Badge
                      text={t('global.new')}
                      color={'pink'}
                      type={'basic'}
                    />
                  </Link>
                )}
              </MenuItem>
              <MenuItem>
                {() => (
                  <Link
                    to='/release-notes'
                    onClick={() =>
                      trackEvent(trackEvents.NAVIGATE_TO_RELEASE_NOTES)
                    }
                    className={classNames(
                      location.pathname.includes('release-notes')
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700',
                      'block px-4 py-2 text-sm hover:bg-gray-100'
                    )}
                  >
                    {t(TRANSLATION_LOCATION + 'navigation_release_notes')}
                  </Link>
                )}
              </MenuItem>
            </div>
            <div className='py-1'>
              <MenuItem>
                {() => (
                  <button
                    onClick={signOut}
                    className='block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100'
                  >
                    {t('components.profile_dropdown.sign_out')}
                  </button>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  )
}

ProfileDropdown.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired
}

export default ProfileDropdown
