import PropTypes from 'prop-types'

const ActivityRings = ({
  personalityPercent,
  skillsPercent,
  interestsPercent
}) => (
  <svg className='h-full w-full' viewBox='0 0 37 37'>
    <g
      style={{
        transformOrigin: '50%',
        transform: 'scale(1) rotate(-90deg)'
      }}
    >
      <circle
        strokeWidth='3'
        r='15.915'
        cx='50%'
        cy='50%'
        className='fill-none'
        style={{ stroke: '#5786FF', strokeOpacity: 0.3 }}
      />
      <circle
        strokeWidth='3'
        r='15.915'
        cx='50%'
        cy='50%'
        className='animate-ringprogress fill-none'
        strokeDasharray={`${skillsPercent}, 100`}
        style={{
          strokeLinecap: 'round',
          stroke: '#5786FF',
          strokeOpacity: 1
        }}
      />
    </g>

    <g
      className='ring2'
      style={{
        transform: 'scale(0.75) rotate(-90deg)',
        transformOrigin: '50%'
      }}
    >
      <circle
        strokeWidth='4'
        r='15.915'
        cx='50%'
        cy='50%'
        style={{ stroke: '#FF9A3B', strokeOpacity: 0.3 }}
        className='fill-none'
      />
      <circle
        strokeWidth='4'
        r='15.915'
        cx='50%'
        cy='50%'
        className='animate-ringprogress fill-none'
        strokeDasharray={`${interestsPercent}, 100`}
        style={{
          strokeLinecap: 'round',
          stroke: '#FF9A3B',
          strokeOpacity: 1
        }}
      />
    </g>

    <g
      style={{
        transform: 'scale(0.5) rotate(-90deg)',
        transformOrigin: '50%'
      }}
    >
      <circle
        strokeWidth='6'
        r='15.915'
        cx='50%'
        cy='50%'
        className='fill-none'
        style={{ stroke: '#29CCC7', strokeOpacity: 0.3 }}
      />
      <circle
        strokeWidth='6'
        r='15.915'
        cx='50%'
        cy='50%'
        className='animate-ringprogress fill-none'
        strokeDasharray={`${personalityPercent}, 100`}
        style={{
          strokeLinecap: 'round',
          stroke: '#29CCC7',
          strokeOpacity: 1
        }}
      />
    </g>
  </svg>
)

ActivityRings.propTypes = {
  personalityPercent: PropTypes.number.isRequired,
  skillsPercent: PropTypes.number.isRequired,
  interestsPercent: PropTypes.number.isRequired
}

export default ActivityRings
