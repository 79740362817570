import PropTypes from 'prop-types'

const Checkbox = ({ id, label, description, onChange, checked, disabled }) => {
  return (
    <>
      <div className='flex h-5 cursor-pointer items-center'>
        <input
          id={id}
          name={id}
          type='checkbox'
          onChange={onChange}
          checked={checked}
          className='h-4 w-4 cursor-pointer rounded border-gray-300 text-blue-600 focus:ring-blue-500'
          disabled={disabled}
        />
      </div>
      <div className='ml-3 text-sm'>
        <label
          htmlFor={id}
          className='cursor-pointer font-medium text-gray-700'
        >
          {label}
        </label>
        {description && (
          <p className='mt-px text-sm text-gray-500'>{description}</p>
        )}
      </div>
    </>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool
}

Checkbox.defaultProps = {
  onChange: () => {},
  disabled: false,
  description: undefined
}

export default Checkbox
