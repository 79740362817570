import { useCallback, useState } from 'react'
import { localStorage } from '../helpers/local-storage'

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const useRestartDashboard = ({ queryClient }) => {
  const [state, setState] = useState('NOT_SET')

  const restartDashboard = useCallback(async () => {
    setState('RESTART')

    // await set state / PersistQueryClientProvider is in dom
    await sleep(1 * 1000)

    // remove queries
    queryClient.removeQueries()

    // remove url filters
    localStorage.removeItem('/careers')
    localStorage.removeItem('/talents')

    await sleep(1 * 1000)

    window.location.assign(window.location.origin)
  }, [queryClient])

  return { restartDashboard, state }
}
