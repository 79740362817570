import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { useUserContext } from '../../context'
import { mutation } from '../../graphql'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'

const StorePartnerLogoModal = ({ open, setOpen }) => {
  const { t } = useTranslation()

  const { active_space, refetch_active_space } = useUserContext()

  const navigate = useNavigate()

  const updateSpace = {
    mutate: async ({ input }, callbacks = {}) => {
      const { onSuccess } = callbacks

      const response = await mutation({ mutation: 'updateSpace', input })
      await refetch_active_space()

      onSuccess && onSuccess(response)
    }
  }

  const handleSeen = () => {
    updateSpace.mutate({
      input: {
        id: active_space.id,
        customer_dashboard_meta: JSON.stringify([
          ...active_space.customer_dashboard_meta,
          { event: 'STORE_PARTNER_LOGO', status: 'SEEN' }
        ])
      }
    })
  }

  return (
    <Modal {...{ open, setOpen, size: '2xl' }}>
      <div className='flex flex-col items-center'>
        <h2 className='mt-8 text-3xl font-extrabold tracking-tight text-gray-900'>
          {t('talents.store_partner_logo_modal.title')}
        </h2>
        <p className='mt-4 max-w-md text-center text-gray-700'>
          {t('talents.store_partner_logo_modal.message')}
        </p>
        <p className='mt-4 max-w-md text-center text-sm text-gray-700'>
          {t('talents.store_partner_logo_modal.hint')}
        </p>
        <div className='mt-8 flex gap-x-4'>
          <Button.SecondaryXL
            onClick={() => {
              handleSeen()
              setOpen(false)
            }}
            text={t('talents.store_partner_logo_modal.cancel_action')}
          />
          <Button.PrimaryXL
            onClick={() => {
              handleSeen()
              navigate('/settings#company')
            }}
            text={t('talents.store_partner_logo_modal.start_action')}
          />
        </div>
      </div>
    </Modal>
  )
}

StorePartnerLogoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default StorePartnerLogoModal
