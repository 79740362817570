import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'

import AivyIcon from '../assets/images/aivyicon.svg'
import { Seo } from '../components'
import InputTypeform from '../components/input-typeform'
import SelectMenuTypeform from '../components/select-menu-typeform'
import Button from '../components/tailwind/Button'
import Steps from '../components/tailwind/steps'
import { getPlannedNewHiresPerYear } from '../constants/planned-new-hires-per-year'
import trackEvents from '../constants/track-events'
import { usePartnerContext, useUserContext } from '../context'
import { mutation } from '../graphql'
import { S3_AIVY_LOGO } from '../helpers'
import { trackEvent } from '../helpers/analytics'

const CompleteSignup = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    active_space: { person },
    refetch_active_space
  } = useUserContext()
  const { partner, refetchPartner } = usePartnerContext()

  const inputRef = useRef()

  const [state, setState] = useState('intro')

  useEffect(() => {
    inputRef.current?.focus()
  }, [state])

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      // person
      firstname: '',
      lastname: '',
      // partner
      name: '',
      display_name: '',
      planned_new_hires_per_year: -1
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(t('complete_signup.required')),
      lastname: Yup.string().required(t('complete_signup.required')),
      name: Yup.string().required(t('complete_signup.required')),
      display_name: Yup.string().required(t('complete_signup.required')),
      planned_new_hires_per_year: Yup.number()
        // default === -1
        .min(0, t('complete_signup.required'))
        .required(t('complete_signup.required'))
    })
  })

  const steps = [
    {
      id: '01',
      name: t('complete_signup.step_1.title'),
      current: state === '01',
      complete: ['02', '03', '04', '05'].includes(state),
      input: {
        id: 'firstname',
        label: t('complete_signup.step_1.label')
      },
      disabled: !formik.values.firstname,
      next: () => {
        trackEvent(trackEvents.SET_ONBOARDING_FIRSTNAME)
        setState('02')
      }
    },
    {
      id: '02',
      name: t('complete_signup.step_2.title'),
      current: state === '02',
      complete: ['03', '04', '05'].includes(state),
      input: {
        id: 'lastname',
        label: t('complete_signup.step_2.label')
      },
      disabled: !formik.values.lastname,
      next: () => {
        trackEvent(trackEvents.SET_ONBOARDING_LASTNAME)
        setState('03')
      },
      back: () => setState('01')
    },
    {
      id: '03',
      name: t('complete_signup.step_3.title'),
      current: state === '03',
      complete: ['04', '05'].includes(state),
      input: {
        id: 'name',
        label: t('complete_signup.step_3.label'),
        description: t('complete_signup.step_3.description')
      },
      disabled: !formik.values.name,
      next: () => {
        trackEvent(trackEvents.SET_ONBOARDING_COMPANY_NAME)
        setState('04')
      },
      back: () => setState('02')
    },
    {
      id: '04',
      name: t('complete_signup.step_4.title'),
      current: state === '04',
      complete: ['05'].includes(state),
      input: {
        id: 'display_name',
        label: t('complete_signup.step_4.label'),
        description: t('complete_signup.step_4.description')
      },
      disabled: !formik.values.display_name,
      next: () => {
        trackEvent(trackEvents.SET_ONBOARDING_COMPANY_DISPLAY_NAME)
        setState('05')
      },
      back: () => setState('03')
    },
    {
      id: '05',
      name: t('complete_signup.step_5.title'),
      current: state === '05',
      complete: false,
      select_menu: {
        id: 'planned_new_hires_per_year',
        label: t('complete_signup.step_5.label'),
        options: getPlannedNewHiresPerYear().map(([key, value]) => [
          key,
          t(value)
        ]),
        onChange: (selected) => {
          formik.setFieldValue('planned_new_hires_per_year', parseInt(selected))
        },
        defaultValue: formik.values.planned_new_hires_per_year
      },
      disabled: formik.values.planned_new_hires_per_year === -1,
      submit: async () => {
        trackEvent(trackEvents.SET_ONBOARDING_PLANNED_HIRES)
        formik.setSubmitting(true)

        const {
          firstname,
          lastname,
          name,
          display_name,
          planned_new_hires_per_year
        } = formik.values

        await new Promise((resolve) => setTimeout(resolve, 800))

        await mutation({
          mutation: 'updatePerson',
          input: { id: person.id, firstname, lastname }
        })

        await mutation({
          mutation: 'updatePartner',
          input: {
            id: partner.id,
            logo: S3_AIVY_LOGO,
            name,
            display_name,
            planned_new_hires_per_year
          }
        })

        await refetchPartner()
        await refetch_active_space()

        formik.setSubmitting(false)
        trackEvent(trackEvents.FINISH_ONBOARDING)
        navigate('/', { replace: true })
      },
      back: () => setState('04')
    }
  ]

  if (state === 'intro') {
    return (
      <>
        <div className='flex h-full w-full justify-center px-8 py-16'>
          <div className='flex w-full max-w-2xl flex-col items-center justify-center sm:w-3/4'>
            <h1 className='text-5xl font-extrabold tracking-tight text-gray-900'>
              {t('complete_signup.intro_title')}
            </h1>
            <p className='mt-6 max-w-lg text-center text-xl text-gray-900'>
              {t('complete_signup.intro_description')}
            </p>
            <div className='mt-8 flex w-full justify-center'>
              <Button.PrimaryLG
                text={t('complete_signup.start_action')}
                onClick={() => {
                  trackEvent(trackEvents.START_ONBOARDING)
                  setState('01')
                }}
              />
            </div>
            <div className='mt-48' />
          </div>
        </div>
        <img
          src={AivyIcon}
          alt='aivy-icon'
          className='fixed bottom-24 left-24 hidden h-auto w-24 sm:block'
        />
      </>
    )
  }

  const current_step = steps.find(({ current }) => current)

  return (
    <>
      <Seo title={current_step.name} />
      <div className='flex h-full w-full justify-center px-8 py-16'>
        <div className='flex w-full max-w-2xl flex-col items-center justify-center sm:w-3/4'>
          <Steps steps={steps} />
          <div className='mt-32' />

          <form
            className='w-full'
            onSubmit={(event) => {
              event.preventDefault()
              current_step.next()
            }}
          >
            {current_step.input && (
              <InputTypeform
                ref={inputRef}
                {...{ ...current_step.input, formik }}
              />
            )}
            {current_step.select_menu && (
              <SelectMenuTypeform
                {...{ ...current_step.select_menu, formik }}
              />
            )}
          </form>
          <div className='mt-12' />
          <div className='flex w-full items-center justify-end gap-x-3'>
            {current_step.back && (
              <Button.Text
                text={t('complete_signup.back_action')}
                onClick={current_step.back}
              />
            )}
            <Button.PrimaryLG
              text={
                current_step.submit
                  ? t('complete_signup.finish_action')
                  : t('complete_signup.next_action')
              }
              className={
                current_step.submit
                  ? 'bg-green-600 hover:bg-green-700'
                  : undefined
              }
              onClick={current_step.submit || current_step.next}
              disabled={current_step.disabled}
              isLoading={formik.isSubmitting}
            />
          </div>
          <div className='mt-48' />
        </div>
      </div>
      <img
        src={AivyIcon}
        alt='aivy-icon'
        className='fixed bottom-24 left-24 hidden h-auto w-24 sm:block'
      />
    </>
  )
}

export default CompleteSignup

/*
  "add_logo_title": "",
  "add_logo_description": "",
  "input_partner_logo_label": "Unternehmenslogo auswählen",
  "input_partner_logo_hint": "Für eine optimale Darstellung des Logos empfehlen wir eine Mindestgröße von 500x500px.",
  "input_partner_logo_description": "",
  "update_partner_logo_success": "Logo erfolgreich aktualisiert.",
  "update_partner_logo_error": "Bildupload fehlgeschlagen.",
  "input_company_name_label": "Unternehmensname",
  "input_company_name_placeholder": "Muster GmbH",
  "select_menu_planned_new_hires_per_year_label": "",
  "planned_new_hires_per_year_description": "Hier bitte das gebuchte Kontingent an Anforderungsprofilen angeben.",
  "personal_informations_title": "Persönliche Informationen",
  "personal_informations_description": "Hier bitte persönliche Informationen angeben.",
  "input_firstname_label": "Vorname",
  "input_firstname_placeholder": "Max",
  "input_lastname_label": "Nachname",
  "input_lastname_placeholder": "Mustermann",
  "submit_action": "Speichern & zum Dashboard"
*/
