import { ChevronRightIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { classNames } from '../../helpers'
import Badge from './Badge'

const Actions = ({ title, description, actions, titleStyle }) => {
  return (
    <div>
      <h2 className={clsx(titleStyle)}>{title}</h2>
      <p className='mt-1 w-9/12 text-sm text-gray-700'>{description}</p>
      <ul
        role='list'
        className='mt-6 divide-y divide-gray-200 border-b border-t border-gray-200'
      >
        {actions.map((action, index) => (
          <li
            key={index}
            onClick={action.onClick}
            className='group relative flex cursor-pointer items-center py-4'
          >
            <div
              className={classNames(
                action.iconColor,
                'flex h-10 w-10 shrink-0 items-center justify-center rounded-full'
              )}
            >
              <action.icon className='h-6 w-6 text-white' aria-hidden='true' />
            </div>
            <div className='ml-3 flex flex-col justify-center'>
              <span className='text-sm font-medium text-gray-900'>
                {action.name}
                {action.withRecommended === true && (
                  <div className='ml-1 inline-block'>
                    <Badge text='Empfohlen' color={'green'} />
                  </div>
                )}
              </span>
              <p className='mt-1 max-w-2xl text-sm text-gray-500 group-hover:text-gray-700'>
                {action.description}
              </p>
            </div>
            <div className='ml-auto'>
              <ChevronRightIcon
                className='h-5 w-5 text-gray-300 group-hover:text-gray-900'
                aria-hidden='true'
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

Actions.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
  titleStyle: PropTypes.string
}

export default Actions
