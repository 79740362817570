import { Auth, Hub } from 'aws-amplify'
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import 'react-circular-progressbar/dist/styles.css'
import {
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../context'
import { mutation } from '../graphql'
import { BUILD_ENV, notifyBugsnag } from '../helpers'
import { localStorage } from '../helpers/local-storage'
import { setNotification as setNotificationSingleton } from '../helpers/notification'
import { setPartner as setPartnerSingleton } from '../helpers/partner'
import { setUser as setUserSingleton } from '../helpers/user'
import { useEventListener } from '../hooks/use-event-listener'
import { useHubspotChat } from '../hooks/use-hubspotchat'
import { useMount } from '../hooks/use-mount'
import { useUpdateEndpoint } from '../hooks/use-update-endpoint'

const StartupHandler = () => {
  const { t } = useTranslation()
  const isFirstRun = useRef(true)
  const { partner } = usePartnerContext()
  const { success, error } = useNotificationContext()
  const { active_space, refetch_active_space, cognitoUser, isAdmin } =
    useUserContext()

  const { updateEndpoint } = useUpdateEndpoint()

  const updateSpace = {
    mutate: async ({ input }, callbacks = {}) => {
      const { onSuccess } = callbacks

      const response = await mutation({ mutation: 'updateSpace', input })
      await refetch_active_space()

      onSuccess && onSuccess(response)
    }
  }

  useHubspotChat()

  useEventListener({
    event: 'focus',
    handler: () => {
      Auth.currentSession()
        .then(() =>
          updateSpace.mutate({
            input: {
              id: active_space.id,
              last_seen: new Date().toISOString()
            }
          })
        )
        .catch((err) => {
          if (err === 'No current user') {
            Hub.dispatch('auth', { event: 'signOut' })
            return
          }

          notifyBugsnag(err)
        })
    }
  })

  useEventListener({
    event: 'beforeunload',
    handler: () => {
      // reset filter and computed params
      localStorage.setItem('/careers', '')
      localStorage.setItem('/talents', '')
    }
  })

  /**
   * Handle App Opens localStorage
   */
  useEffect(() => {
    if (isFirstRun.current === false) return

    try {
      const opens_app = localStorage.getItem('opens_app')
      if (opens_app === null) {
        localStorage.setItem('opens_app', 1)
      } else {
        localStorage.setItem('opens_app', parseInt(opens_app) + 1)
      }
      isFirstRun.current = false
    } catch (error) {
      notifyBugsnag(error)
    }
  }, [])

  useMount(() => {
    setPartnerSingleton(partner)

    setNotificationSingleton({
      success,
      error,
      not_authorized_error_message: t('not_authorized_error_message')
    })

    const {
      create: can_create,
      read: can_read,
      update: can_update,
      delete: can_delete
    } = active_space.authorization_role.crud

    setUserSingleton({
      ...active_space,
      email: active_space.person?.email || active_space.person.invitation_email,
      username: cognitoUser.username,
      ...{ can_create, can_read, can_update, can_delete }
    })

    updateSpace.mutate({
      input: {
        id: active_space.id,
        last_seen: new Date().toISOString()
      }
    })

    updateEndpoint()

    if (BUILD_ENV !== 'production') return

    const analytics = getAnalytics()
    setUserId(analytics, partner.id)
    setUserProperties(analytics, { partner: partner.id })
  })

  useMount(() => {
    if (isAdmin) return

    if (
      !partner.display_name &&
      window.location.pathname !== '/logout' &&
      window.location.pathname !== '/complete-signup'
    ) {
      window.location.replace('/complete-signup')
    }
  })

  return null
}

export default StartupHandler
