import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import PropTypes from 'prop-types'

import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useUserContext } from '../../context'
import Modal from '../tailwind/Modal'
import AivyJSONTree from './aivy-json-tree'

const language = 'en'

const Logs = ({
  open,
  setOpen,
  title = 'Logs',
  logs: logs_prop = undefined,
  query = undefined,
  displayKey = undefined
}) => {
  const { isAdmin } = useUserContext()

  const queryEnabled = isAdmin && !!query && open

  const { data, refetch, status } = useQuery({
    queryKey: ['logs'],
    queryFn: query,
    enabled: queryEnabled
  })

  useEffect(() => {
    if (open && !!query && status !== 'pending') refetch()
  }, [open, query, refetch, status])

  if (!isAdmin) return null

  const logs = logs_prop || data || []

  return (
    <Modal open={open} setOpen={setOpen} size='3xl'>
      <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {title}
      </h2>
      <div className='mt-4 flex flex-col gap-y-6'>
        {logs
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map((log) => ({ ...log, data: JSON.parse(log.data) }))
          .map((log, index) => (
            <div key={index}>
              <span className='text-md text-gray-900'>
                {'// ' +
                  format(new Date(log.createdAt), 'dd.MM.yyyy / hh:mm', {
                    locale: { en: enUS }[language]
                  }) +
                  ' / ' +
                  (displayKey
                    ? log.data[displayKey] || log.source
                    : log.event_name)}
              </span>
              <div className='border-l-4 border-amber-300 pl-2'>
                <AivyJSONTree
                  data={log}
                  shouldExpandNodeInitially={(keyPath, data, level) => {
                    // automatically expand the root node
                    // if (level === 0) return true
                    if (keyPath.includes('data')) return true
                  }}
                />
              </div>
            </div>
          ))}
      </div>
      <div className='h-8' />
    </Modal>
  )
}

Logs.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  logs: PropTypes.array,
  query: PropTypes.func,
  displayKey: PropTypes.string
}

export default Logs
