import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { classNames, EMAIL_REGEX, notifyBugsnag } from '../helpers'
import { localStorage } from '../helpers/local-storage'
import Alert from './alert'
import Button from './button'
import InputFormik from './input-formik'

const AuthContainer = ({ context, signupEmail, fixedHeight }) => {
  const { t } = useTranslation()

  const [state, setState] = useState('ENTER_EMAIL')

  const [startTimer, setStartTimer] = useState(false)
  const [error, setError] = useState(null)
  const [cognitoUser, setCognitoUser] = useState(null)

  const forms = useMemo(() => {
    return {
      email: {
        id: 'email',
        type: 'email',
        label: t('authenticator.email_label_' + context.toLowerCase()),
        placeholder: t(
          'authenticator.email_placeholder_' + context.toLowerCase()
        ),
        autoComplete: 'username'
      },
      code: {
        id: 'code',
        // type: 'number',
        label: t('authenticator.code_label_' + context.toLowerCase()),
        description: t(
          'authenticator.code_description_' + context.toLowerCase()
        ),
        placeholder: t(
          'authenticator.code_placeholder_' + context.toLowerCase()
        ),
        autoComplete: 'off'
      }
    }
  }, [context, t])

  const validationSchema = useMemo(() => {
    if (state === 'ENTER_EMAIL') {
      return Yup.object({
        email: Yup.string()
          .matches(
            EMAIL_REGEX,
            t('authenticator.validation_email_' + context.toLowerCase())
          )
          .required(
            t('authenticator.validation_required_' + context.toLowerCase())
          )
      })
    }

    if (state === 'ENTER_CODE') {
      return Yup.object({
        code: Yup.string().required(
          t('authenticator.validation_required_' + context.toLowerCase())
        )
      })
    }
  }, [context, state, t])

  const formik = useFormik({
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: {
      email: localStorage.getItem('signup_email') || signupEmail || '',
      code: ''
    },
    validationSchema
  })

  useEffect(() => {
    if (!startTimer) return

    // 2 minutes, 48 secondes
    const timer = setTimeout(() => setState('CODE_EXPIRED'), 1000 * 60 * 14.8)

    return () => clearTimeout(timer)
  }, [startTimer])

  useEffect(() => {
    if (state !== 'CODE_EXPIRED') return

    setStartTimer(false)
  }, [state])

  useEffect(() => {
    setError(null)
  }, [formik.values])

  useEffect(() => {
    if (context === 'LOGIN') return

    if (localStorage.getItem('signup_email')) {
      setState('ENTER_CODE')
    }
  }, [context])

  const confirmEmail = async () => {
    setError(null)
    const { validateForm, setSubmitting } = formik
    const validation = await validateForm()

    if (!isValid(validation)) return

    const { email } = formik.values

    setSubmitting(true)

    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' })
    Auth.signIn(email.toLowerCase())
      .then(
        (res) =>
          setCognitoUser(res) || setState('ENTER_CODE') || setStartTimer(true)
      )
      .catch((err) => handleError(err))
      .finally(() => setSubmitting(false))
  }

  const confirmCode = async () => {
    const { validateForm, setSubmitting, resetForm } = formik
    const validation = await validateForm()

    if (!isValid(validation)) return

    const { code } = formik.values

    setSubmitting(true)

    Auth.sendCustomChallengeAnswer(cognitoUser, code)
      .then(() => {
        Auth.currentSession()
          .then(() => resetForm())
          .catch((err) => handleError(err))
          .finally(() => setSubmitting(false))
      })
      .catch((err) => notifyBugsnag(err))
      .finally(() => setSubmitting(false))
  }

  const isValid = (validation) => {
    const valid = !Object.keys(validation).length

    if (!valid) {
      const fields = {}
      const { setTouched } = formik

      Object.keys(validation).forEach((field) => (fields[field] = true))

      setTouched(fields)
    }

    return valid
  }

  const handleError = (err) => {
    if (err === 'No current user') {
      setError({ text: 'CodeMismatchException'.toLowerCase() })
      return
    }

    if (
      ['UserNotFoundException', 'NotAuthorizedException'].includes(err.code)
    ) {
      setError({ text: err.code.toLowerCase() })
      return
    }

    setError({ text: 'ERROR'.toLowerCase() })
    notifyBugsnag(err)
  }

  return (
    <div style={{ height: fixedHeight ? 512 : 'auto' }}>
      <div>
        <div>
          <InputFormik
            {...forms.email}
            formik={formik}
            disabled={state === 'ENTER_CODE'}
          />

          <div
            className={classNames(
              'mt-4',
              (state === 'ENTER_EMAIL' || state === 'CODE_EXPIRED') && 'hidden'
            )}
          >
            <InputFormik {...forms.code} formik={formik} />
          </div>

          {state === 'CODE_EXPIRED' && (
            <div className='mt-4'>
              <Alert title={t('authenticator.session_expired_login')} />
            </div>
          )}

          {error && (
            <div className='mt-4'>
              <Alert
                title={t(
                  `authenticator.${error.text}_` + context.toLowerCase(),
                  { ...error.params }
                )}
              />
            </div>
          )}

          <div className='mt-6'>
            {state === 'ENTER_EMAIL' && context === 'LOGIN' && (
              <Button
                appearance='w-full-base'
                disabled={formik.isValid === false}
                text={
                  error?.text === 'company_domain_exist'
                    ? t(
                        'authenticator.confirm_email_action_signup_domain_exists'
                      )
                    : t(
                        'authenticator.confirm_email_action_' +
                          context.toLowerCase()
                      )
                }
                onClick={confirmEmail}
                isLoading={formik.isSubmitting}
              />
            )}
            {state === 'ENTER_CODE' && (
              <Button
                appearance='w-full-base'
                text={t(
                  'authenticator.confirm_code_action_' + context.toLowerCase()
                )}
                onClick={confirmCode}
                isLoading={formik.isSubmitting}
              />
            )}
            {state === 'CODE_EXPIRED' && (
              <Button
                appearance='w-full-base'
                text={t('authenticator.session_expired_action_login')}
                onClick={confirmEmail}
                isLoading={formik.isSubmitting}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

AuthContainer.propTypes = {
  context: PropTypes.string.isRequired,
  signupEmail: PropTypes.string,
  signupSearchParams: PropTypes.string,
  withBorder: PropTypes.bool,
  fixedHeight: PropTypes.bool
}

AuthContainer.defaultProps = {
  signupEmail: undefined,
  signupSearchParams: undefined,
  withBorder: false,
  fixedHeight: false
}

export default AuthContainer
