import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import ActualPaymentPlan from '../components/settings/actual-payment-plan'
import ApiKeys from '../components/settings/api-keys'
import CompanyProfile from '../components/settings/company-profile'
import MailTemplates from '../components/settings/mail-templates'
import UserManagement from '../components/settings/user-management'
import UserProfile from '../components/settings/user-profile'
// import AssessmentSettings from '../components/settings/assessment-settings'
import { useState } from 'react'
import { Badge, Container, Seo } from '../components'
import Data from '../components/aivy-admin/data'
import Logs from '../components/aivy-admin/logs'
import SelectMenu from '../components/select-menu'
import Assessment from '../components/settings/assessment'
import Automations from '../components/settings/automations'
import Integrations from '../components/settings/integrations'
import Notifications from '../components/settings/notifications'
import ResultReports from '../components/settings/result-reports'
import Button from '../components/tailwind/Button'
import PageHeading from '../components/tailwind/PageHeading'
import trackEvents from '../constants/track-events'
import {
  useAivyContext,
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../context'
import { query } from '../graphql'
import { classNames } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import { tabsConfig } from '../helpers/settingsTab'

const { version } = require('../../package.json')

const Settings = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { alert } = useNotificationContext()
  const { restartDashboard } = useAivyContext()
  const { isAdmin } = useUserContext()

  const navigate = useNavigate()
  const { hash } = useLocation()

  const [displayLogs, setDisplayLogs] = useState(false)
  const [displayData, setDisplayData] = useState(false)

  const tabs = tabsConfig(t, hash)

  const resetCache = () => {
    alert(
      t('settings.reset_cache.modal_title'),
      t('settings.reset_cache.modal_text'),
      [
        {
          onPress: () => {
            trackEvent(trackEvents.CLICK_SETTINGS_RESET_CACHE)
            restartDashboard()
          },
          text: t('settings.reset_cache.modal_action')
        },
        {
          text: t('settings.reset_cache.modal_cancel'),
          style: 'cancel'
        }
      ]
    )
  }

  return (
    <>
      <Seo title={t('seo.titles.settings')} />
      <PageHeading
        title={t('seo.titles.settings')}
        withActions={
          isAdmin ? (
            <div className='ml-auto flex gap-x-1.5'>
              <Button.SecondaryLG
                text='Show Partner'
                onClick={() => setDisplayData(true)}
              />
              <Button.SecondaryLG
                text='Show Logs'
                onClick={() => setDisplayLogs(true)}
              />
            </div>
          ) : undefined
        }
      />
      <Container withVerticalPadding>
        <SelectMenu
          id='tabs'
          options={tabs.map((tab) => [tab.to, tab.name])}
          translate={false}
          defaultValue={hash || '#personal'}
          onChange={(value) => {
            const { id, to } = tabs.find((tab) => tab.to === value)

            trackEvent(trackEvents.PRESSED_SETTINGS_TAB, {
              tab: id
            })

            navigate(to)
          }}
          className='w-full xl:hidden'
        />
        <div className='flex gap-x-16'>
          <nav className='hidden flex-col gap-y-2 xl:flex' aria-label='Tabs'>
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className='group flex items-center'
                id={tab.id.toLowerCase()}
                onClick={() =>
                  trackEvent(trackEvents.PRESSED_SETTINGS_TAB, {
                    tab: tab.id
                  })
                }
              >
                <div
                  className={classNames(
                    'mr-1 h-8 w-1 rounded-full',
                    tab.current ? 'bg-blue-500' : 'group-hover:bg-gray-500'
                  )}
                />
                <Link
                  to={tab.to}
                  className={classNames(
                    tab.current
                      ? 'bg-gray-100 text-blue-600'
                      : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                    'inline-flex w-64 cursor-pointer items-center rounded-md px-4 py-3 text-sm font-medium'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  <tab.icon
                    className={classNames(
                      tab.current
                        ? 'text-blue-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      '-ml-0.5 mr-2 h-5 w-5'
                    )}
                    aria-hidden='true'
                  />
                  <span className='mr-2 whitespace-nowrap'>{tab.name}</span>
                  {tab.withNewBadge && (
                    <Badge
                      text={t('global.new')}
                      color={'pink'}
                      type={'basic'}
                    />
                  )}
                </Link>
              </div>
            ))}
          </nav>
          <div className='mt-6 w-full xl:mt-1'>
            {hash === '#personal' && <UserProfile />}
            {hash === '#notifications' && <Notifications />}
            {hash === '#integrations' && <Integrations />}
            {hash === '#company' && <CompanyProfile />}
            {hash === '#user' && <UserManagement />}
            {hash === '#resultreport' && <ResultReports />}
            {/* {hash === 'ASSESSMENT' && <AssessmentSettings />} */}
            {hash === '#plan' && <ActualPaymentPlan />}
            {hash === '#ats' && <ApiKeys />}
            {hash === '#assessment' && <Assessment />}
            {hash === '#templates' && <MailTemplates />}
            {hash === '#automations' && <Automations />}
          </div>
        </div>
      </Container>

      <div className='my-12 flex flex-col items-center'>
        <span className='block text-center text-xs italic text-gray-700'>{`v${version}`}</span>
        <span className='block text-center text-xs italic text-gray-700'>
          {t('settings.partner_id', { partner_id: partner.id })}
        </span>
        <Button.Text
          className='mt-2'
          text={t('settings.reset_cache.action')}
          onClick={resetCache}
        />
      </div>

      <Logs
        title='PARTNER-LOGS'
        open={displayLogs}
        setOpen={setDisplayLogs}
        query={() =>
          query({
            query: 'logsByPartner',
            variables: { partner_id: partner.id }
          })
        }
      />
      <Data
        title='PARTNER'
        open={displayData}
        setOpen={setDisplayData}
        data={partner}
      />
    </>
  )
}

export default Settings
