import PropTypes from 'prop-types'
import { useMemo } from 'react'

const Filter = ({ id, label, value, column, handleChange, debounce }) => {
  const checked = useMemo(
    () => (column.getFilterValue() || []).includes(value),
    [column, value]
  )

  return (
    <div className='flex cursor-pointer items-center'>
      <input
        id={id}
        type='checkbox'
        defaultChecked={checked}
        onChange={(event) =>
          setTimeout(
            () =>
              handleChange({ column, value, checked: event.target.checked }),
            debounce
          )
        }
        className='h-4 w-4 cursor-pointer rounded border-gray-300 text-blue-600 focus:ring-1 focus:ring-blue-600'
      />
      <label
        htmlFor={id}
        className='ml-3 cursor-pointer whitespace-nowrap pr-6 text-sm font-normal text-gray-900'
      >
        {label}
      </label>
    </div>
  )
}

Filter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  debounce: PropTypes.number
}

Filter.defaultProps = {
  debounce: 200
}

export default Filter
