import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import EmailBadge from './email-badge'
import Input from './input'

const TRANSLATION_LOCATION = 'components.multi_email_input.'

const MultiEmailInput = ({
  value,
  setValue,
  items,
  setItems,
  error,
  setError,
  callback
}) => {
  const { t } = useTranslation()

  const handleKeyDown = (event) => {
    if (['Enter', 'Tab', ',', ';', ' '].includes(event.key)) {
      event.preventDefault()
      callback()
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value)
    setError(null)
  }

  const handleDelete = (email) => {
    setItems(items.filter((e) => e !== email))
  }

  return (
    <>
      <div className='mt-1'>
        {items.map((item, index) => (
          <div key={index} className='mr-1 mt-1 inline-block'>
            <EmailBadge text={item} onClick={() => handleDelete(item)} />
          </div>
        ))}
      </div>

      <div className='mt-4'>
        <Input
          id='multi-email-input'
          value={value}
          placeholder={t(TRANSLATION_LOCATION + 'input_placeholder')}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          touched
          error={error}
          autoComplete='off'
          description={t(TRANSLATION_LOCATION + 'input_description')}
        />
      </div>
    </>
  )
}

MultiEmailInput.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  setItems: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
}

EmailBadge.defaultProps = {
  error: undefined
}

export default MultiEmailInput
