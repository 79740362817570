export const SMART_PREDICT_SPACE_STATES = {
  OPEN: {
    sortWeight: '11',
    filterValue: 'constants.smart_predict.state_open_label',
    color: 'grey'
  },
  INVITED_NEXT_ROUND: {
    sortWeight: '12',
    filterValue: 'constants.smart_predict.state_invited_next_round_label',
    color: 'orange'
  },
  HIRED: {
    sortWeight: '13',
    filterValue: 'constants.smart_predict.state_hired_label',
    color: 'green'
  },
  TRIAL_PERIOD_SURVIVED: {
    sortWeight: '14',
    filterValue: 'constants.smart_predict.state_trial_period_survived_label',
    color: 'green'
  },
  REJECTED: {
    sortWeight: '15',
    filterValue: 'constants.smart_predict.state_rejected_label',
    color: 'red'
  },
  APPLICANT_CANCELLED: {
    sortWeight: '16',
    filterValue: 'constants.smart_predict.state_applicant_cancelled_label',
    color: 'yellow'
  }
}

export const ASSESSMENT_SPACE_STATES = {
  TALENT_INVITED: {
    label: 'talents.state_invited',
    sortWeight: '2',
    filterValue: 'talents.filter_value_invited',
    color: 'grey'
  },
  ASSESSMENT_STARTED: {
    label: 'talents.state_started',
    sortWeight: '3',
    filterValue: 'talents.filter_value_started',
    color: 'orange'
  },
  ASSESSMENT_COMPLETED: {
    label: 'talents.state_fulfilled',
    sortWeight: '4',
    filterValue: 'talents.filter_value_fulfilled',
    color: 'green'
  },
  REMINDED: {
    label: 'talents.state_reminded',
    sortWeight: '5',
    filterValue: 'talents.filter_value_reminded',
    color: 'grey'
  },
  RESET_EXPIRED: {
    label: 'talents.state_reset',
    sortWeight: '6',
    filterValue: 'talents.filter_value_reset',
    color: 'grey'
  },
  EXPIRED: {
    label: 'talents.state_expired',
    sortWeight: '7',
    filterValue: 'talents.filter_value_expired',
    color: 'grey'
  },
  EMAIL_BOUNCED: {
    label: 'talents.state_email_bounced',
    sortWeight: '8',
    filterValue: 'talents.filter_value_email_bounced',
    color: 'red'
  },
  RECOVERED: {
    label: 'talents.state_recovered',
    sortWeight: '9',
    filterValue: 'talents.filter_value_recovered',
    color: 'orange'
  },
  INTEGRATION_SUCCESS: {
    label: 'talents.state_integration_success',
    sortWeight: '91',
    filterValue: 'talents.filter_value_integration_success',
    color: 'green'
  },
  INTEGRATION_ERROR: {
    label: 'talents.state_integration_error',
    sortWeight: '92',
    filterValue: 'talents.filter_value_integration_error',
    color: 'red'
  }
}
