import { useLanguageContext, usePartnerContext } from '../context'
import { replaceMacros } from '../helpers'
import { useMailTemplateConstants } from './use-mail-template-constants'

export const useMailTemplates = ({ career, isCrossmode }) => {
  const { partner } = usePartnerContext()
  const { language } = useLanguageContext()

  const {
    getDefaultMailTemplate,
    getDefaultReminderMailTemplate,
    getDefaultRejectMailTemplate,
    getMailTemplateMacros,
    getMailTemplateMacrosReminder,
    getMailTemplateMacrosReject,
    getMailTemplateSubjectMacros
  } = useMailTemplateConstants({ career, isCrossmode })

  const mailTemplateSubjectMacros = getMailTemplateSubjectMacros()

  const saveParse = (str) => {
    let template = null

    try {
      template = JSON.parse(JSON.stringify(str))
      return template
    } catch (err) {
      // console.error(err)
      return null
    }
  }

  const getMailTemplate = () => {
    let template = null

    if (career) {
      template = saveParse(career.app_settings?.mail_template)
    } else if (isCrossmode) {
      template = saveParse(getDefaultMailTemplate())
    } else {
      template = saveParse(partner.app_settings?.mail_template)
    }

    template = template || JSON.parse(JSON.stringify(getDefaultMailTemplate()))

    template.imageUrl = career?.logo || partner.logo
    template.language = career?.language || language
    template.useInvitationBlock = true

    template.subject = replaceMacros({
      macros: mailTemplateSubjectMacros,
      text: template.subject
    })

    template.has_firstname_macro = template.data.includes('{{firstname}}')
    template.has_lastname_macro = template.data.includes('{{lastname}}')

    return template
  }

  const getMailTemplateReminder = () => {
    let template = null

    if (career) {
      template = saveParse(career.app_settings?.mail_template_reminder)
    } else {
      template = saveParse(partner.app_settings?.mail_template_reminder)
    }

    template =
      template || JSON.parse(JSON.stringify(getDefaultReminderMailTemplate()))

    template.imageUrl = career?.logo || partner.logo
    template.language = career?.language || language
    template.useInvitationBlock = true

    template.subject = replaceMacros({
      macros: mailTemplateSubjectMacros,
      text: template.subject
    })

    return template
  }

  const getMailTemplateReject = () => {
    let template = null

    if (career) {
      template = saveParse(career.app_settings?.mail_template_reject)
    } else {
      template = saveParse(partner.app_settings?.mail_template_reject)
    }

    template =
      template || JSON.parse(JSON.stringify(getDefaultRejectMailTemplate()))

    template.imageUrl = career?.logo || partner.logo
    template.language = career?.language || language
    template.useInvitationBlock = false

    template.subject = replaceMacros({
      macros: mailTemplateSubjectMacros,
      text: template.subject
    })

    return template
  }

  return {
    mailTemplateMacros: getMailTemplateMacros(),
    mailTemplateMacrosReminder: getMailTemplateMacrosReminder(),
    mailTemplateMacrosReject: getMailTemplateMacrosReject(),
    mailTemplate: getMailTemplate(),
    mailTemplateReminder: getMailTemplateReminder(),
    mailTemplateReject: getMailTemplateReject()
  }
}
