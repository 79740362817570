export const CRON_TTL = `{
  id
  source_table
  source_id
  partner_id
  job
  ttl
  createdAt
  updatedAt
}`
