import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../helpers'

const TRANSLATION_LOCATION = 'mail_template.mail_template_preview_card.'

const MailTemplatePreviewCard = ({ template, actions }) => {
  const { t } = useTranslation()

  const { subject, subtitle, updatedAt, badgeColor } = template

  return (
    <div className='items-center justify-between gap-x-6 py-5 sm:flex'>
      <div className='min-w-0'>
        <div className='flex flex-col items-start gap-x-3'>
          {/* <p className='text-sm text-gray-700'>{subtitle}</p> */}
          {/* <Badge text={subtitle} type={'large'} color={badgeColor} /> */}
          {badgeColor === 'green' ? (
            <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
              {subtitle}
            </span>
          ) : badgeColor === 'yellow' ? (
            <span className='inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'>
              {subtitle}
            </span>
          ) : (
            <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
              {subtitle}
            </span>
          )}
          <p className='text-md mt-2 font-semibold leading-6 text-gray-900'>
            {subject}
          </p>
        </div>
        <div className='mt-0.5 flex items-center gap-x-2 text-xs'>
          <div className={classNames(updatedAt ? '' : 'hidden')}>
            <span className='italic text-gray-500'>
              {t(TRANSLATION_LOCATION + 'updated_at')}
            </span>
            <span className='ml-1 text-gray-700'>
              {new Date(updatedAt).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
          </div>
          {/* <svg viewBox='0 0 2 2' className='h-0.5 w-0.5 fill-current'>
            <circle cx={1} cy={1} r={1} />
          </svg> */}
          {/* <p className='truncate'>Created by {'title'}</p> */}
        </div>
      </div>
      <div className='mr-4 mt-4 flex flex-none items-center gap-x-2 sm:mt-0'>
        {actions}
      </div>
    </div>
  )
}

MailTemplatePreviewCard.propTypes = {
  template: PropTypes.object.isRequired,
  actions: PropTypes.node.isRequired
}

export default MailTemplatePreviewCard
