import { useEffect, useRef, useState } from 'react'

const NULL_RECT = { height: 0, top: 0 } // tbc if needed

export const useRect = () => {
  const ref = useRef()

  const [rect, setRect] = useState(NULL_RECT)

  const set = () =>
    setRect(
      ref && ref.current ? ref.current.getBoundingClientRect() : NULL_RECT
    )

  const useEffectInEvent = ({ event, useCapture }) => {
    useEffect(() => {
      set()
      window.addEventListener(event, set, useCapture)
      return () => window.removeEventListener(event, set, useCapture)
    }, [event, useCapture])
  }

  useEffectInEvent({ event: 'resize' })
  // useEffectInEvent({ event: 'scroll', useCapture: true })

  return { rect, ref }
}
