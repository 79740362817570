import {
  BellIcon as BellIconOutline,
  BookmarkIcon as BookmarkIconOutline,
  PlayCircleIcon as PlayCircleIconOutline,
  TrashIcon as TrashIconOutline
} from '@heroicons/react/24/outline'
import {
  BellIcon as BellIconSolid,
  BookmarkIcon as BookmarkIconSolid,
  PlayCircleIcon as PlayCircleIconSolid,
  TrashIcon as TrashIconSolid
} from '@heroicons/react/24/solid'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ACTIONS = {
  SEND_REMINDER: 'SEND_REMINDER',
  RESET_EXPIRED: 'RESET_EXPIRED',
  SET_SPACE_STATE: 'SET_SPACE_STATE',
  DELETE_SPACE: 'DELETE_SPACE'
}

function combinations(arr) {
  const result = []

  for (let i = 0; i < Math.pow(2, arr.length); i++) {
    const combo = []
    for (let j = 0; j < arr.length; j++) {
      if ((i & (1 << j)) !== 0) {
        combo.push(arr[j])
      }
    }
    result.push(combo)
  }

  return result
}

export const useSpaceActions = ({ spaces }) => {
  const { t } = useTranslation()

  const [displaySendReminder, setDisplaySendReminder] = useState(false)
  const [displaySetSpaceState, setDisplaySetSpaceState] = useState(false)
  const [displayDeleteSpace, setDisplayDeleteSpace] = useState(false)
  const [displayResetExpired, setDisplayResetExpired] = useState(false)

  const uniqueLastInteractionKeys = useMemo(() => {
    // _* = _EMAIL && _LINK

    // SMART_PREDICT FULFILLED STARTED_VIA_*
    const assessment_status_keys = spaces.map(
      ({ assessment_status }) => assessment_status.key
    )

    // REMINDED INVITED_VIA_* EXPIRED_VIA_* RESET_EXPIRED_* EMAIL_BOUNCED
    const smart_predict_status_keys = spaces
      .filter(({ smart_predict_status: sms }) => sms.key !== 'EMPTY_STATE')
      .map(({ smart_predict_status }) => smart_predict_status.key)

    return [
      ...new Set(assessment_status_keys),
      ...new Set(smart_predict_status_keys)
    ].sort()
  }, [spaces])

  const setSpacesStateAvailable = useMemo(() => {
    // all spaces last interaction keys includes only
    const arr = ['ASSESSMENT_COMPLETED', 'SMART_PREDICT']

    return combinations(arr).some(
      (comb) =>
        JSON.stringify(comb.sort()) ===
        // uniqueLastInteractionKeys already sorted!
        JSON.stringify(uniqueLastInteractionKeys)
    )
  }, [uniqueLastInteractionKeys])

  const sendSpacesReminderAvailable = useMemo(() => {
    // all spaces last interaction keys includes only
    const arr = [
      'INVITED_VIA_EMAIL',
      'EXPIRED_VIA_EMAIL',
      'RESET_EXPIRED_VIA_EMAIL',
      'STARTED_VIA_EMAIL',
      'REMINDED'
    ]

    return combinations(arr).some(
      (comb) =>
        JSON.stringify(comb.sort()) ===
        // uniqueLastInteractionKeys already sorted!
        JSON.stringify(uniqueLastInteractionKeys)
    )
  }, [uniqueLastInteractionKeys])

  const resetExpiredAvailable = useMemo(() => {
    // all spaces last interaction keys includes only
    const arr = [
      'INVITED_VIA_EMAIL',
      'INVITED_VIA_LINK',
      'EXPIRED_VIA_EMAIL',
      'EXPIRED_VIA_LINK',
      'RESET_EXPIRED_VIA_EMAIL',
      'RESET_EXPIRED_VIA_LINK',
      'STARTED_VIA_EMAIL',
      'STARTED_VIA_LINK',
      'REMINDED'
    ]

    return combinations(arr).some(
      (comb) =>
        JSON.stringify(comb.sort()) ===
        // uniqueLastInteractionKeys already sorted!
        JSON.stringify(uniqueLastInteractionKeys)
    )
  }, [uniqueLastInteractionKeys])

  const mapAction = ({ action }) => {
    switch (action) {
      case ACTIONS.SET_SPACE_STATE:
        return {
          key: action,
          id: 'action-set-space-state',
          label: t('space_actions.label_' + action),
          icons: { solid: BookmarkIconSolid, outline: BookmarkIconOutline },
          onClick: () => setDisplaySetSpaceState(true),
          available: setSpacesStateAvailable
        }
      case ACTIONS.SEND_REMINDER:
        return {
          key: action,
          id: 'action-remind',
          label: t('space_actions.label_' + action),
          icons: { solid: BellIconSolid, outline: BellIconOutline },
          onClick: () => setDisplaySendReminder(true),
          available: sendSpacesReminderAvailable
        }
      case ACTIONS.DELETE_SPACE:
        return {
          key: action,
          id: 'action-remove',
          label: t('space_actions.label_' + action),
          icons: { solid: TrashIconSolid, outline: TrashIconOutline },
          onClick: () => setDisplayDeleteSpace(true),
          available: true
        }
      case ACTIONS.RESET_EXPIRED:
        return {
          key: action,
          id: 'action-reset-expired',
          label: t('space_actions.label_' + action),
          icons: { solid: PlayCircleIconSolid, outline: PlayCircleIconOutline },
          onClick: () => setDisplayResetExpired(true),
          available: resetExpiredAvailable
        }
      default:
        throw new Error(`action not found - ${action}`)
    }
  }

  return {
    actions: Object.keys(ACTIONS).map((action) => mapAction({ action })),
    displaySendReminder,
    setDisplaySendReminder,
    displaySetSpaceState,
    setDisplaySetSpaceState,
    displayDeleteSpace,
    setDisplayDeleteSpace,
    displayResetExpired,
    setDisplayResetExpired,
    setSpacesStateAvailable
  }
}
