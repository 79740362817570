import { DialogTitle } from '@headlessui/react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UPDATE_ARCHIVED } from '../../constants/career-history'
import { useNotificationContext } from '../../context'
import { mutation } from '../../graphql'
import { useCreateLog } from '../../hooks/use-create-log'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'

const RecoverCareersAction = ({ careers, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()

  const [isLoading, setIsLoading] = useState(false)

  const { createCareerHistoryLog } = useCreateLog()

  const recoverCareers = () => {
    setIsLoading(true)

    const recoverCareer = ({ id: career_id }) => {
      return Promise.all([
        mutation({
          mutation: 'updateCareer',
          input: { id: career_id, archived: false }
        }),
        createCareerHistoryLog({
          career_id,
          context: UPDATE_ARCHIVED,
          input: { archived: false }
        })
      ])
    }

    Promise.all(careers.map((career) => recoverCareer(career)))
      .then(() => {
        updateHandler()

        success(t('recover_careers.recover_career_success'))
        setIsLoading(false)
        setOpen(false)
      })
      .catch(() => setIsLoading(false))
  }

  return (
    <Modal open={open} setOpen={() => setOpen(false)} size='lg'>
      <DialogTitle className='text-center text-2xl font-medium leading-6 text-gray-900'>
        {t('recover_careers.recover_action')}
      </DialogTitle>
      <span className='mt-2 block text-center text-base text-gray-600'>
        {t('recover_careers.recover_description')}
      </span>
      <div className='mt-8 flex flex-row gap-3'>
        <Button.SecondaryXL
          fullWidth
          text={t('recover_careers.recover_cancel_action')}
          onClick={() => setOpen(false)}
        />
        <Button.PrimaryXL
          fullWidth
          text={
            careers.length === 1
              ? t('recover_careers.recover_action_single', {
                  count: careers.length
                })
              : t('recover_careers.recover_action_multiple', {
                  count: careers.length
                })
          }
          onClick={recoverCareers}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}

RecoverCareersAction.propTypes = {
  careers: PropTypes.array.isRequired,
  updateHandler: PropTypes.func,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

RecoverCareersAction.defaultProps = {
  updateHandler: () => undefined
}

export default RecoverCareersAction
