import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePartnerContext } from '../../context'
import { useMailTemplates } from '../../hooks/use-mail-templates'
import { useMutationAndUpdateQuery } from '../../hooks/use-mutation-and-update-query'
import Divider from '../divider'
import EditMailTemplate from '../mail-template/edit-mail-template'
import MailTemplatePreviewCard from '../mail-template/mail-template-preview-card'
import Button from '../tailwind/Button'

const MailTemplates = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()

  const {
    mailTemplateMacros,
    mailTemplateMacrosReminder,
    mailTemplateMacrosReject,
    mailTemplate,
    mailTemplateReminder,
    mailTemplateReject
  } = useMailTemplates({})

  const partnerMutation = useMutationAndUpdateQuery({
    queryKey: ['partner', partner.id],
    mutation: 'updatePartner'
  })

  const [editTemplate, setEditTemplate] = useState(false)
  const [editTemplateReminder, setEditTemplateReminder] = useState(false)
  const [editTemplateReject, setEditTemplateReject] = useState(false)

  return (
    <div className='mb-12'>
      <div className='md:flex md:items-center'>
        <div className='md:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>
            {t('settings.mail_templates.title')}
          </h1>
          <p className='mt-2 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
            {t('settings.mail_templates.description_1')}
            <span className='font-bold'>
              {t('settings.mail_templates.description_2')}
            </span>
          </p>
          <p className='mt-4 max-w-lg text-sm italic text-gray-700 lg:max-w-2xl'>
            {t('settings.mail_templates.hint')}
          </p>
        </div>
      </div>
      <Divider />
      <div className='-mt-2 divide-y divide-gray-100'>
        <MailTemplatePreviewCard
          template={{
            ...mailTemplate,
            badgeColor: 'green',
            subtitle: t('settings.mail_templates.mail_template.title')
          }}
          actions={
            <Button.PrimarySM
              text={t('settings.mail_templates.mail_template.edit_action')}
              onClick={() => setEditTemplate(true)}
            />
          }
        />
        <MailTemplatePreviewCard
          template={{
            ...mailTemplateReminder,
            badgeColor: 'yellow',
            subtitle: t('settings.mail_templates.mail_template_reminder.title')
          }}
          actions={
            <Button.PrimarySM
              text={t(
                'settings.mail_templates.mail_template_reminder.edit_action'
              )}
              onClick={() => setEditTemplateReminder(true)}
            />
          }
        />
        <MailTemplatePreviewCard
          template={{
            ...mailTemplateReject,
            badgeColor: 'red',
            subtitle: t('settings.mail_templates.mail_template_reject.title')
          }}
          actions={
            <Button.PrimarySM
              text={t(
                'settings.mail_templates.mail_template_reject.edit_action'
              )}
              onClick={() => setEditTemplateReject(true)}
            />
          }
        />
      </div>
      <EditMailTemplate
        open={editTemplate}
        setOpen={setEditTemplate}
        mailTemplate={mailTemplate}
        mailTemplateMacros={mailTemplateMacros}
        handleSubmit={({ result, onSuccess, onError }) => {
          const app_settings = partner.app_settings || {}
          app_settings.mail_template = result

          const input = { id: partner.id, app_settings }
          partnerMutation.mutate({ input }, { onSuccess, onError })
        }}
      />
      <EditMailTemplate
        open={editTemplateReminder}
        setOpen={setEditTemplateReminder}
        mailTemplate={mailTemplateReminder}
        mailTemplateMacros={mailTemplateMacrosReminder}
        handleSubmit={({ result, onSuccess, onError }) => {
          const app_settings = partner.app_settings || {}
          app_settings.mail_template_reminder = result

          const input = { id: partner.id, app_settings }
          partnerMutation.mutate({ input }, { onSuccess, onError })
        }}
      />
      <EditMailTemplate
        open={editTemplateReject}
        setOpen={setEditTemplateReject}
        mailTemplate={mailTemplateReject}
        mailTemplateMacros={mailTemplateMacrosReject}
        handleSubmit={({ result, onSuccess, onError }) => {
          const app_settings = partner.app_settings || {}
          app_settings.mail_template_reject = result

          const input = { id: partner.id, app_settings }
          partnerMutation.mutate({ input }, { onSuccess, onError })
        }}
      />
    </div>
  )
}

export default MailTemplates
