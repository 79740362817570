import { LockClosedIcon } from '@heroicons/react/24/solid'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext
} from '../../../context'
import { classNames, toBase64 } from '../../../helpers'
import { localStorage } from '../../../helpers/local-storage'
import { useMutationAndUpdateQuery } from '../../../hooks/use-mutation-and-update-query'
import Alert from '../../alert'
import SelectMenu from '../../select-menu'
import SettingsContainer from '../settings-container'

const i18n_delete_spaces = 'settings.automations.delete_spaces.'

const DeleteSpaces = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { automations, showPaywall } = usePaymentContext()
  const { success } = useNotificationContext()

  const navigate = useNavigate()

  const delete_spaces = useMemo(() => {
    const { delete_spaces: ds } = JSON.parse(
      partner.app_settings?.automations || '{}'
    )

    return ds || 'independent'
  }, [partner])

  const automation_soft_delete_after_months = useMemo(() => {
    const { automation_soft_delete_after_months } = JSON.parse(
      partner.app_settings?.automations || '{}'
    )

    return automation_soft_delete_after_months || 6
  }, [partner])

  const partnerMutation = useMutationAndUpdateQuery({
    queryKey: ['partner', partner.id],
    mutation: 'updatePartner'
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      delete_spaces,
      months: automation_soft_delete_after_months
    },
    validationSchema: Yup.object({
      delete_spaces: Yup.string(),
      months: Yup.number()
    }),
    onSubmit: ({ delete_spaces, months }, { setSubmitting }) => {
      setSubmitting(true)

      const app_settings = partner.app_settings || {}
      const automations = JSON.parse(app_settings.automations || '{}')

      automations.delete_spaces = {
        independent: undefined,
        all: 'all',
        anonym: 'anonym'
      }[delete_spaces]

      automations.automation_soft_delete_after_months = months

      const input = { id: partner.id }
      input.app_settings = {
        ...app_settings,
        automations: JSON.stringify(automations)
      }

      const onSuccess = () => {
        setSubmitting(false)
        success(t('settings.automations.success_message'))
      }

      partnerMutation.mutate(
        { input },
        { onSuccess, onError: () => setSubmitting(false) }
      )
    }
  })

  const delete_space_settings = [
    {
      id: 'independent',
      name: t(i18n_delete_spaces + 'independent_label'),
      description: t(i18n_delete_spaces + 'independent_description'),
      available: true,
      link: {
        onClick: () => {
          const location = {
            pathname: '/talents',
            search: `computed=${toBase64([
              { more: { in: ['BEFORE_6_MONTH'] } }
            ])}`
          }

          localStorage.setItem(location.pathname, location.search)
          navigate(location.pathname)
        },
        text: t(i18n_delete_spaces + 'independent_link_text')
      }
    },
    {
      id: 'all',
      name: t(i18n_delete_spaces + 'all_label'),
      description: t(i18n_delete_spaces + 'all_description'),
      available: automations
    },
    {
      id: 'anonym',
      name: t(i18n_delete_spaces + 'anonym_label'),
      description: t(i18n_delete_spaces + 'anonym_description'),
      available: automations,
      recommended: true
    }
  ]

  const set_months_available =
    automations && ['all', 'anonym'].includes(formik.values.delete_spaces)

  return (
    <SettingsContainer
      title={t(i18n_delete_spaces + 'settings_container_title')}
      description={t(i18n_delete_spaces + 'settings_container_description')}
      onSubmit={formik.handleSubmit}
      dirty={formik.dirty}
      isLoading={formik.isSubmitting}
    >
      <div className='space-y-5'>
        {delete_space_settings.map(
          ({ id, name, description, available, recommended, link }) => (
            <div
              key={id}
              onClick={() => {
                if (available) formik.setFieldValue('delete_spaces', id)
                else showPaywall('general')
              }}
              className='group relative flex items-start'
            >
              <div
                className={classNames(
                  'flex h-6 items-center',
                  available ? '' : 'opacity-60'
                )}
              >
                <input
                  id={id}
                  aria-describedby={`${id}-description`}
                  name='plan'
                  type='radio'
                  checked={formik.values.delete_spaces === id}
                  disabled={!available}
                  readOnly
                  className='h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 group-hover:cursor-pointer'
                />
              </div>
              <div className='ml-3 mt-0.5 text-sm group-hover:cursor-pointer'>
                <label
                  htmlFor={id}
                  className='flex items-center group-hover:cursor-pointer'
                >
                  <span
                    className={classNames(
                      'font-medium text-gray-900',
                      available ? '' : 'opacity-60'
                    )}
                  >
                    {name}
                  </span>
                  {recommended && (
                    <span className='ml-1 inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10'>
                      {t('settings.automations.recommended_badge')}
                    </span>
                  )}
                  {!available && (
                    <LockClosedIcon className='ml-1 h-5 w-5 text-aivyOrange' />
                  )}
                </label>
                <p
                  id={`${id}-description`}
                  className={classNames(
                    'mt-0.5 max-w-lg text-gray-500 group-hover:cursor-pointer',
                    available ? '' : 'opacity-80'
                  )}
                >
                  {description}
                </p>
                {link && (
                  <div
                    onClick={link.onClick}
                    className='mt-1 text-sm font-semibold leading-6 text-blue-600 hover:text-blue-500'
                  >
                    {link.text}
                    <span aria-hidden='true'> &rarr;</span>
                  </div>
                )}
              </div>
            </div>
          )
        )}
        <div className={classNames(set_months_available ? '' : 'opacity-40')}>
          <SelectMenu
            id='month'
            // label={t(i18n_delete_spaces + 'months_title')}
            label={t(i18n_delete_spaces + 'months_description')}
            options={[
              ...Array.from({ length: 12 }, (_, index) => index + 1).map(
                (months) => [
                  months,
                  t(
                    `${i18n_delete_spaces}months_${
                      months === 1 ? 'month' : 'months'
                    }`,
                    { number: months }
                  )
                ]
              )
            ]}
            defaultValue={formik.values.months}
            onChange={(months) => formik.setFieldValue('months', months)}
            className='w-full sm:w-2/3'
            translate={false}
            disabled={!set_months_available}
          />
        </div>
        {formik.dirty && (
          <div className='max-w-lg'>
            <Alert
              type='info'
              title={t('settings.automations.info_title')}
              description={t('settings.automations.info_description')}
            />
          </div>
        )}
      </div>
    </SettingsContainer>
  )
}

export default DeleteSpaces
