import {
  Dialog,
  DialogPanel,
  Disclosure,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
  TransitionChild
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocation, useNavigate } from 'react-router'
import { classNames } from '../../helpers'
import { localStorage } from '../../helpers/local-storage'
import Button from '../tailwind/Button'
import DebouncedInput from './debounced-input'
import Filter from './filter'

const FilterBar = ({
  table,
  filters,
  setFilterUrl,
  globalFilter,
  withOptions
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const activeFilters = useMemo(() => {
    const result = []

    filters.forEach(({ column, options }) => {
      const filterValues = table.getColumn(column).getFilterValue() || []

      filterValues.forEach((value) =>
        result.push({
          value,
          label: options.find((option) => option.value === value).label,
          column: table.getColumn(column)
        })
      )
    })

    return result
  }, [table, filters])

  const handleChange = ({ column, value, checked }) => {
    setFilterUrl({ column, value, checked })
  }

  return (
    <div id='filter-bar' className='bg-white'>
      {/* mobile filter dialog */}
      <Transition show={open} as={Fragment}>
        <Dialog as='div' className='relative z-40 xl:hidden' onClose={setOpen}>
          <TransitionChild
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </TransitionChild>

          <div className='fixed inset-0 z-40 flex'>
            <TransitionChild
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <DialogPanel className='relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl'>
                <div className='flex items-center justify-between px-4'>
                  <h2 className='text-lg font-medium text-gray-900'>
                    {t('react_table.filter_bar.mobile_header')}
                  </h2>
                  <button
                    type='button'
                    className='-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>close menu</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>

                {/* filters */}
                <form className='mt-4'>
                  {filters.map((filter) => (
                    <Disclosure
                      as='div'
                      key={filter.name}
                      className='px-4 py-6'
                    >
                      {({ open }) => (
                        <>
                          <h3 className='-mx-2 -my-3 flow-root'>
                            <Disclosure.Button className='flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400'>
                              <span className='font-medium text-gray-900'>
                                {filter.name}
                              </span>
                              <span className='ml-6 flex items-center'>
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? '-rotate-180' : 'rotate-0',
                                    'h-5 w-5 transform'
                                  )}
                                  aria-hidden='true'
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className='pt-6'>
                            <div className='space-y-6'>
                              {filter.options.map((option, optionIndex) => (
                                <Filter
                                  key={optionIndex}
                                  id={`filter-${filter.id}-${optionIndex}`}
                                  label={option.label}
                                  value={option.value}
                                  column={table.getColumn(filter.column)}
                                  handleChange={handleChange}
                                />
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* filters */}
      <section aria-labelledby='filter-heading'>
        <h2 id='filter-heading' className='sr-only'>
          filters
        </h2>

        <div className='bg-white py-3'>
          <div className='relative mx-auto flex items-center px-4 md:px-6 lg:px-8'>
            <button
              type='button'
              className='inline-block text-sm font-medium text-gray-700 hover:text-gray-900 xl:hidden'
              onClick={() => setOpen(true)}
            >
              {t('react_table.filter_bar.filter_button_title')}
            </button>

            <div className='hidden justify-between py-4 xl:block'>
              <div className='flow-root'>
                <PopoverGroup className='flex items-center gap-x-3'>
                  {filters.map((filter) => (
                    <Fragment key={filter.name}>
                      <Popover className='relative inline-block text-left'>
                        <PopoverButton className='group inline-flex justify-center rounded-md p-2 transition duration-150 ease-out hover:bg-slate-100 hover:ease-in focus:outline-none'>
                          <span
                            id={`filter-bar_${filter.id}`}
                            className='whitespace-nowrap text-sm font-medium text-gray-900'
                          >
                            {filter.name}
                          </span>

                          {table.getColumn(filter.column).getFilterValue() &&
                          table.getColumn(filter.column).getFilterValue()
                            .length ? (
                            <span className='ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-900'>
                              {
                                table.getColumn(filter.column).getFilterValue()
                                  .length
                              }
                            </span>
                          ) : null}

                          <ChevronDownIcon
                            className='-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-700 group-hover:text-gray-900'
                            aria-hidden='true'
                          />
                        </PopoverButton>

                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <PopoverPanel className='absolute left-0 z-30 mt-2 origin-top-right rounded-md border bg-white p-4 shadow-2xl'>
                            <form
                              style={{ maxHeight: 512 }}
                              className='overflow-scroll'
                            >
                              {filter.options.map((option, optionIndex) => (
                                <div key={optionIndex} className='px-1 py-2'>
                                  <Filter
                                    id={`filter-${filter.id}-${optionIndex}`}
                                    label={option.label}
                                    value={option.value}
                                    column={table.getColumn(filter.column)}
                                    handleChange={handleChange}
                                  />
                                </div>
                              ))}
                            </form>
                          </PopoverPanel>
                        </Transition>
                      </Popover>
                      <div
                        aria-hidden='true'
                        className='h-5 w-px bg-gray-300 last:hidden'
                      />
                    </Fragment>
                  ))}
                </PopoverGroup>
              </div>
            </div>
            {table.getState().columnFilters.length ? (
              <div className='mx-6 hidden items-center justify-center gap-x-4 xl:flex'>
                <div aria-hidden='true' className='h-5 w-px bg-gray-300' />
                <Button.Text
                  onClick={() => {
                    localStorage.setItem(pathname, '')
                    navigate({ search: '' })
                  }}
                  text={t('react_table.filter_bar.remove_all_action')}
                />
              </div>
            ) : null}
            <div className='ml-auto flex items-center gap-x-3'>
              {globalFilter && (
                <div id='talent-searchbar' className='w-64'>
                  <DebouncedInput
                    id='global-filter-input'
                    initialValue={globalFilter.value}
                    onChange={(value) => globalFilter.setValue(String(value))}
                    placeholder={t(
                      'react_table.filter_bar.search_input_placeholder'
                    )}
                  />
                </div>
              )}
              {withOptions}
            </div>
          </div>
        </div>

        {/* active filters */}
        {activeFilters.length > 0 && (
          <div
            className={classNames(
              'bg-gray-100',
              !activeFilters.length && 'py-0.5'
            )}
          >
            <div className='px-4 py-3 sm:px-6 lg:px-8 xl:flex xl:items-center'>
              <div className='flex'>
                <h3 className='whitespace-nowrap text-sm font-medium text-gray-700'>
                  {t('react_table.filter_bar.active_filters_title')}
                  <span className='sr-only'>active Filters</span>
                </h3>

                <div
                  aria-hidden='true'
                  className='hidden h-5 w-px bg-gray-300 xl:ml-4 xl:block'
                />
              </div>

              <div className='mt-2 xl:ml-4 xl:mt-0'>
                <div className='-m-1 flex flex-wrap items-center'>
                  {activeFilters.length === 0 && (
                    <span className='text-sm italic text-gray-500'>
                      {t('react_table.filter_bar.empty')}
                    </span>
                  )}

                  {activeFilters.map((activeFilter) => (
                    <span
                      key={activeFilter.value}
                      onClick={() =>
                        handleChange({ ...activeFilter, checked: false })
                      }
                      className='m-1 inline-flex cursor-pointer items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900'
                    >
                      <span>{activeFilter.label}</span>
                      <button
                        type='button'
                        className='ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-700'
                      >
                        <span className='sr-only'>
                          remove filter for {activeFilter.label}
                        </span>
                        <svg
                          className='h-2 w-2'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 8 8'
                        >
                          <path
                            strokeLinecap='round'
                            strokeWidth='1.5'
                            d='M1 1l6 6m0-6L1 7'
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  )
}

FilterBar.propTypes = {
  table: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  setFilterUrl: PropTypes.func.isRequired,
  globalFilter: PropTypes.object,
  withOptions: PropTypes.node
}

FilterBar.defaultProps = {
  globalFilter: undefined,
  withOptions: null
}

export default FilterBar
