import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import {
  ChartPieIcon,
  Cog8ToothIcon,
  CpuChipIcon,
  InformationCircleIcon as InformationCircleIconOutline,
  QuestionMarkCircleIcon,
  RectangleStackIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline'

import AivyIcon from '../assets/images/aivyicon.svg'
import trackEvents from '../constants/track-events'
import { useUserContext } from '../context'
import { trackEvent } from '../helpers/analytics'
import ProfileDropdown from './profile-dropdown'
import SpaceSwitch from './space-switch'
import Badge from './tailwind/Badge'

const Sidebar = ({ isSidebarCollapsed, actions }) => {
  const { t } = useTranslation()
  const { active_space, spacesByOwner, isAdmin } = useUserContext()
  // const { adminInsights, toggleAdminInsights } = useAivyContext()

  const { pathname } = useLocation()

  const { setIsVisibleHelpAndSupport, setVisibilityChooseGroup } = actions

  const {
    create: can_create,
    update: can_update,
    delete: can_delete
  } = active_space.authorization_role.crud

  const navigation = [
    [
      {
        id: 'link-to-talents',
        name: t('components.layout.navigation_talents'),
        onClick: () => trackEvent(trackEvents.PRESSED_TALENTS),
        to: '/talents',
        current: pathname.includes('talent'),
        icon: UserGroupIcon
      },
      {
        id: 'link-to-careers',
        name: t('components.layout.navigation_careers'),
        onClick: () => trackEvent(trackEvents.PRESSED_CAREERS),
        to: '/careers',
        current: pathname.includes('career'),
        icon: RectangleStackIcon
      },
      {
        id: 'link-to-team-analyses',
        name: t('components.layout.navigation_teamanalyses'),
        onClick: () => trackEvent(trackEvents.PRESSED_TEAMANALYSES),
        to: '/team-analyses',
        current: pathname.includes('team-analyse'),
        icon: ChartPieIcon,
        withNewBadge: false
      }
    ],
    [
      // {
      //  name: 'Erhalte Inspiration',
      //  onClick: () => setIsVisibleInspiration(true),
      //  current: false,
      //  icon: LightBulbIcon
      // },
      // eslint-disable-next-line
      can_create && can_update && can_delete && false
        ? {
            name: t('components.layout.navigation_producttour'),
            to: '/careers',
            state: { startProductTour: true },
            replace: pathname === '/careers',
            onClick: () => trackEvent(trackEvents.PRESSED_PRODUCTTOUR),
            current: false,
            icon: InformationCircleIconOutline,
            className: 'hidden xl:flex'
          }
        : null,
      // {
      //   id: 'support',
      //   name: t('components.layout.navigation_support'),
      //   to: '/support',
      //   onClick: () => trackEvent(trackEvents.NAVIGATE_TO_SUPPORT),
      //   current: pathname === '/support',
      //   icon: QuestionMarkCircleIcon
      // },
      {
        id: 'nav_support_hub',
        name: t('components.layout.navigation_support'),
        onClick: () => {
          trackEvent(trackEvents.PRESSED_TALENTS)
          setIsVisibleHelpAndSupport(true)
        },
        current: false,
        icon: QuestionMarkCircleIcon
      },
      {
        id: 'settings',
        name: t('components.layout.navigation_settings'),
        to: '/settings#personal',
        current: pathname === '/settings',
        icon: Cog8ToothIcon
      },
      isAdmin && {
        name: 'Gruppe wechseln',
        onClick: () => setVisibilityChooseGroup(true),
        current: false,
        icon: CpuChipIcon
      }
    ].filter((item) => item)
  ]

  const getNavigationItem = (item, index) => {
    const Element = item.to
      ? Link
      : // eslint-disable-next-line
        (props) => <div {...props}>{props.children}</div>

    return (
      <Fragment key={index}>
        <Element
          id={item.id}
          to={item.to}
          state={item.state}
          replace={item.replace}
          onClick={item.onClick}
          className={clsx(
            item.current
              ? 'bg-gray-800 text-white'
              : 'text-gray-400 hover:bg-gray-800 hover:text-white',
            'group flex cursor-pointer gap-x-3 rounded-md text-sm font-semibold leading-6',
            item.className,
            isSidebarCollapsed ? 'flex items-center justify-center p-3' : 'p-2'
          )}
          data-tooltip-id='my-tooltip'
          data-tooltip-content={item.name}
          data-tooltip-place='right'
        >
          <item.icon className='h-6 w-6 shrink-0' aria-hidden='true' />
          <span
            className={clsx(
              'whitespace-nowrap',
              isSidebarCollapsed ? 'hidden' : 'inline-block'
            )}
          >
            {item.name}
          </span>
          {item.withNewBadge && !isSidebarCollapsed && (
            <Badge text={t('global.new')} color={'pink'} type={'basic'} />
          )}
        </Element>
        <Tooltip
          id='my-tooltip'
          offset={16}
          className='z-30 text-sm font-semibold'
          hidden={!isSidebarCollapsed}
        />
      </Fragment>
    )
  }

  const employees = spacesByOwner.filter(
    ({ context }) => context === 'EMPLOYEE'
  )

  return (
    <div className='flex min-h-0 flex-1 flex-col bg-gray-900'>
      <div className='flex flex-1 flex-col pb-4'>
        <div className='flex h-20 flex-shrink-0 items-center bg-gray-900 px-6'>
          <img className='h-8 w-auto' src={AivyIcon} alt='Aivy GmbH' />

          {/* {!isSidebarCollapsed && is_trial && (
            <button
              onClick={() => navigate('/upgrade')}
              type='button'
              className='inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
            >
              <SparklesIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
              Upgrade
            </button>
          )} */}
          {/* {isAdmin && !isSidebarCollapsed && (
              <Switch
                checked={adminInsights}
                onChange={toggleAdminInsights}
                className={`${
                  adminInsights ? 'bg-blue-600' : 'bg-gray-400'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    adminInsights ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            )} */}
        </div>
        <nav className='mt-5 flex-1 space-y-1 px-4'>
          {navigation[0].map((item, index) => getNavigationItem(item, index))}
          <div className='px-2 py-4'>
            <div className='h-px bg-gray-700' />
          </div>
          {navigation[1].map((item, index) => getNavigationItem(item, index))}
          {employees.length > 1 && (
            <>
              <div className='h-4' />
              {isSidebarCollapsed ? (
                <div className='h-5' />
              ) : (
                <span className='px-2 text-xs font-semibold leading-6 text-gray-400'>
                  {t('components.layout.companys_area_title')}
                </span>
              )}

              <div className='h-px' />
              <div className={clsx(!isSidebarCollapsed && 'px-2')}>
                <SpaceSwitch isSidebarCollapsed={isSidebarCollapsed} />
              </div>
            </>
          )}
        </nav>
      </div>

      <ProfileDropdown isSidebarCollapsed={isSidebarCollapsed} />
    </div>
  )
}

Sidebar.propTypes = {
  isSidebarCollapsed: PropTypes.bool,
  actions: PropTypes.object.isRequired
}

Sidebar.defaultProps = { isSidebarCollapsed: false }

export default Sidebar
