import { MAIL_TEMPLATE } from './mail-template'

export const PARTNER = `{
  id
  name
  display_name
  authorized_create
  authorized_read
  authorized_update
  career_site_url
  last_activity
  planned_new_hires_per_year
  app_settings {
    components
    challenges
    report_settings
    cultural_fit
    custom_apply_mail_template
    mail_template ${MAIL_TEMPLATE}
    mail_template_reminder ${MAIL_TEMPLATE}
    mail_template_reject ${MAIL_TEMPLATE}
    usedAlgorithm
    hello_text
    custom_data_policy
    notifications {
      webhook
      mail
    }
    assessment_expires_after_days
    automations
  }
  logo
  createdAt
  updatedAt
}`
