import { useState } from 'react'
import { useNavigate } from 'react-router'

import { UPDATE_STATUS } from '../constants/career-history'
import { trackEvent } from '../helpers/analytics'
import { useCreateLog } from './use-create-log'

export const useCreateCareerNavigation = ({ career, careerMutation }) => {
  const [isLoadingBack, setIsLoadingBack] = useState(false)
  const [isLoadingNext, setIsLoadingNext] = useState(false)

  const navigate = useNavigate()

  const { createCareerHistoryLog } = useCreateLog()

  const career_states = [
    null,
    'WAIT_CULTURAL_FIT',
    'WAIT_EXPERTS',
    'WAIT_CONFIRM_TESTBATTERY', // || 'WAIT_CONFIG',
    'WAIT_ACTIVE',
    'ACTIVE'
  ]

  const handleBackNavigation = ({ ...props }) => {
    // can stay true because a new component gets rendered
    setIsLoadingBack(true)

    const { id, status } = career
    const backStatus =
      props.backStatus || career_states[career_states.indexOf(status) - 1]

    const event = ['from', status, 'to', backStatus || 'WAIT_BASICS']
    trackEvent(event.join('_').toUpperCase())

    const onSuccess = () => {
      createCareerHistoryLog({
        career_id: id,
        context: UPDATE_STATUS,
        // null for WAIT_BASICS
        input: { status: backStatus || 'WAIT_BASICS' }
      }).then(() => {
        navigate('', { replace: true }) // remove hash
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
    }

    careerMutation.mutate(
      { input: { id, status: backStatus } },
      { onSuccess, onError: () => setIsLoadingNext(false) }
    )
  }

  const handleNextNavigation = ({ input, onSuccess, ...props }) => {
    // can stay true because a new component gets rendered
    setIsLoadingNext(true)

    const { id, status } = career
    const nextStatus =
      props.nextStatus || career_states[career_states.indexOf(status) + 1]

    const event = ['from', status || 'WAIT_BASICS', 'to', nextStatus]
    trackEvent(event.join('_').toUpperCase())

    const onSuccessShadow = (data) => {
      createCareerHistoryLog({
        career_id: career.id,
        context: UPDATE_STATUS,
        input: { status: nextStatus }
      }).then(() => {
        navigate('', { replace: true }) // remove hash
        window.scrollTo({ top: 0, behavior: 'smooth' })
        onSuccess && onSuccess(data)
      })
    }

    careerMutation.mutate(
      { input: { id, status: nextStatus, ...(input || {}) } },
      { onSuccess: onSuccessShadow, onError: () => setIsLoadingNext(false) }
    )
  }

  return {
    isLoadingBack,
    handleBackNavigation,
    isLoadingNext,
    handleNextNavigation
  }
}
