import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/react/24/solid'
import { marked } from 'marked'
import PropTypes from 'prop-types'

import { classNames } from '../helpers'

const types = {
  warning: {
    Icon: () => <ExclamationTriangleIcon className='h-5 w-5 text-yellow-400' />,
    background: 'bg-yellow-50',
    borderColor: 'border-yellow-400',
    titleColor: 'text-yellow-900',
    textColor: 'text-yellow-800'
  },
  error: {
    Icon: () => <XCircleIcon className='h-5 w-5 text-red-400' />,
    background: 'bg-red-50',
    borderColor: 'border-red-400',
    titleColor: 'text-red-900',
    textColor: 'text-red-800'
  },
  success: {
    Icon: () => <CheckCircleIcon className='h-5 w-5 text-green-400' />,
    background: 'bg-green-50',
    borderColor: 'border-green-400',
    titleColor: 'text-green-900',
    textColor: 'text-green-800'
  },
  info: {
    Icon: () => <InformationCircleIcon className='h-5 w-5 text-blue-400' />,
    background: 'bg-blue-50',
    borderColor: 'border-blue-400',
    titleColor: 'text-blue-900',
    textColor: 'text-blue-800'
  }
}

const Alert = ({ type, title, description }) => {
  const { background, titleColor, textColor, borderColor } = types[type]

  return (
    <div
      className={classNames(
        'rounded-r-md border-l-4 p-4 pl-6',
        background,
        borderColor
      )}
    >
      <div className='flex'>
        {/* <div className='flex-shrink-0'>
          <Icon />
        </div> */}
        <div className='xml-2'>
          <h3 className={classNames('text-sm font-medium', titleColor)}>
            {title}
          </h3>
          {description && (
            <div
              className={classNames('mt-1 text-sm', textColor)}
              dangerouslySetInnerHTML={{ __html: marked(description) }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

Alert.defaultProps = {
  type: 'warning',
  description: null
}

export default Alert
