import { PlusSmallIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { classNames } from '../helpers'

const State = ({ color, text, height, action, px }) => {
  const getColor = () => {
    switch (color) {
      case 'green':
        return 'bg-green-100 text-green-800'
      case 'grey':
        return 'bg-gray-100 text-gray-800'
      case 'orange':
      case 'yellow':
        return 'bg-yellow-100 text-yellow-800'
      case 'red':
        return 'bg-red-100 text-red-800'
      case 'indigo':
        return 'bg-indigo-100 text-indigo-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  const getActionIcon = () => {
    switch (action) {
      case 'add':
        return (
          <PlusSmallIcon className='mr-0.5 h-4 w-auto font-bold text-indigo-800' />
        )
      default:
        return <></>
    }
  }

  return (
    <span
      style={{ height }}
      className={classNames(
        'inline-flex items-center rounded-full py-0.5 text-sm font-medium',
        getColor(),
        px
      )}
    >
      {action && getActionIcon()}
      {text}
    </span>
  )
}

State.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  height: PropTypes.number,
  action: PropTypes.string,
  px: PropTypes.string
}

State.defaultProps = {
  height: 24,
  action: undefined,
  px: 'px-3'
}

export default State
