import { Storage } from 'aws-amplify'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { JSONTree } from 'react-json-tree'
import { getPlannedNewHiresPerYear } from '../../constants/planned-new-hires-per-year'
import {
  useAivyContext,
  useNotificationContext,
  usePartnerContext,
  useProductTourContext,
  useUserContext
} from '../../context'
import { mutation } from '../../graphql'
import { notifyBugsnag } from '../../helpers'
import { useMount } from '../../hooks/use-mount'
import ImageCropAssessment from '../image-crop-assessment'
import Input from '../input'
import SelectMenu from '../select-menu'
import SEO from '../seo'
import UploadPicture from '../upload-picture'
import CultureFitCompany from './company/CultureFitCompany'
import SettingsContainer from './settings-container'

const TRANSLATION_LOCATION = 'components.settings.company_profile.'

const CompanyProfile = ({ hideDescription, completeSignUp }) => {
  const { t } = useTranslation()
  const { success, error } = useNotificationContext()
  const { adminInsights } = useAivyContext()
  const { partner, refetchPartner } = usePartnerContext()
  const { refetchSpacesByOwner } = useUserContext()

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const [isLoading, setIsLoading] = useState(false)
  const [partnerLogo, setPartnerLogo] = useState(partner.logo)
  // const [partnerLogoMandatoryHint, setPartnerLogoMandatoryHint] = useState(false)
  const [
    plannedNewHiresPerYearMandatoryHint,
    setPlannedNewHiresPerYearMandatoryHint
  ] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: partner.name || '',
      displayName: partner.display_name || '',
      careerSiteUrl: partner.career_site_url || '',
      plannedNewHiresPerYear: partner.planned_new_hires_per_year || -1
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        t(TRANSLATION_LOCATION + 'input_name_validation')
      ),
      displayName: Yup.string().required(
        t(TRANSLATION_LOCATION + 'input_display_name_validation')
      ),
      careerSiteUrl: Yup.string().url(
        t(TRANSLATION_LOCATION + 'input_career_site_url_validation')
      )
    }),
    onSubmit: async (values, helpers) => {
      // if (!partnerLogo) return
      if (formik.values.plannedNewHiresPerYear < 0) return

      setIsLoading(true)

      try {
        await mutation({
          mutation: 'updatePartner',
          input: {
            id: partner.id,
            name: values.name,
            display_name: values.displayName,
            career_site_url: values.careerSiteUrl,
            planned_new_hires_per_year: values.plannedNewHiresPerYear
          }
        })

        success(t(TRANSLATION_LOCATION + 'update_partner_success'))

        await refetchPartner()
        await refetchSpacesByOwner()
      } catch (err) {
        helpers.resetForm()
      } finally {
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    if (formik.isSubmitting && formik.values.plannedNewHiresPerYear < 0) {
      setPlannedNewHiresPerYearMandatoryHint(
        t(TRANSLATION_LOCATION + 'empty_planned_new_hires_per_year_hint')
      )
    }
  }, [formik.isSubmitting, formik.values.plannedNewHiresPerYear, t])

  const updateURL = ({ values }) => {
    if (values.careerSiteUrl && !values.careerSiteUrl.startsWith('http')) {
      formik.setFieldValue(
        'careerSiteUrl',
        ['https://', values.careerSiteUrl].join('')
      )
    }
  }

  const uploadLogo = async ({ image }) => {
    setIsLoading(true)
    // setPartnerLogoMandatoryHint(false)

    try {
      const extension = image.name.split('.').pop()
      const result = await Storage.put(
        `partner-logos/${partner.id}.${extension}`,
        image
      )
      const updatedPartnerLogo = (await Storage.get(result.key)).split('?')[0]

      await mutation({
        mutation: 'updatePartner',
        input: {
          id: partner.id,
          logo: updatedPartnerLogo
        }
      })

      await refetchPartner()
      await refetchSpacesByOwner()

      setPartnerLogo(updatedPartnerLogo)
      success(t(TRANSLATION_LOCATION + 'update_partner_logo_success'))
    } catch (err) {
      error(t(TRANSLATION_LOCATION + 'update_partner_logo_error'))

      // setPartnerLogoMandatoryHint(
      //  t(TRANSLATION_LOCATION + 'empty_partner_logo_hint')
      // )
      notifyBugsnag(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <SettingsContainer
        title={t(TRANSLATION_LOCATION + 'settings_container_title')}
        description={t(TRANSLATION_LOCATION + 'settings_container_description')}
        onSubmit={formik.handleSubmit}
        dirty={formik.dirty}
        isLoading={isLoading}
        hideDescription={hideDescription}
      >
        <SEO title={t('seo.titles.settings_company')} />
        {adminInsights && <JSONTree data={partner} />}
        <div className='grid grid-cols-6 gap-6'>
          {[
            [
              'name',
              t(TRANSLATION_LOCATION + 'input_name_label'),
              t(TRANSLATION_LOCATION + 'input_name_placeholder')
            ],
            [
              'displayName',
              t(TRANSLATION_LOCATION + 'input_display_name_label'),
              t(TRANSLATION_LOCATION + 'input_display_name_placeholder')
            ],
            !completeSignUp && [
              'careerSiteUrl',
              t(TRANSLATION_LOCATION + 'input_career_site_url_label'),
              'https://www.aivy.app'
            ]
          ]
            .filter((input) => input)
            .map((input, index) => (
              <div key={index} className='col-span-6 sm:col-span-3'>
                <Input
                  id={input[0]}
                  label={input[1]}
                  type='text'
                  placeholder={input[2]}
                  onChange={formik.handleChange}
                  onBlur={(event) =>
                    formik.handleBlur(event) || updateURL(formik)
                  }
                  touched={formik.touched[input[0]]}
                  value={formik.values[input[0]]}
                  error={formik.errors[input[0]]}
                />
              </div>
            ))}
          <div className='col-span-6 sm:col-span-3'>
            <SelectMenu
              id='plannedNewHiresPerYear'
              label={t(
                TRANSLATION_LOCATION + 'planned_new_hires_per_year_label'
              )}
              options={getPlannedNewHiresPerYear()}
              onChange={(value) => {
                setPlannedNewHiresPerYearMandatoryHint(false)
                formik.setFieldValue(
                  'plannedNewHiresPerYear',
                  parseInt(value) || -1
                )
              }}
              defaultValue={formik.values.plannedNewHiresPerYear}
              useOptionValue='relation'
            />
            {plannedNewHiresPerYearMandatoryHint && (
              <p className='mt-2 text-sm text-red-600'>
                {plannedNewHiresPerYearMandatoryHint}
              </p>
            )}
          </div>
        </div>

        {!completeSignUp && (
          <div className='mt-4'>
            <UploadPicture
              title={t(TRANSLATION_LOCATION + 'input_partner_logo_label')}
              subtitle={t(TRANSLATION_LOCATION + 'input_partner_logo_hint')}
              imageSrc={partnerLogo}
              onSubmit={uploadLogo}
              ImageCropComponent={ImageCropAssessment}
            />
            {/* {partnerLogoMandatoryHint && (
          <p className='mt-2 text-sm text-red-600'>
            {partnerLogoMandatoryHint}
          </p>
        )} */}
          </div>
        )}
      </SettingsContainer>
      <SettingsContainer
        title={t('settings.company.cultural_fit.title')}
        description={t('settings.company.cultural_fit.description')}
        containerClassname={'mt-8'}
      >
        <CultureFitCompany />
      </SettingsContainer>
    </React.Fragment>
  )
}

CompanyProfile.propTypes = {
  hideDescription: PropTypes.bool,
  completeSignUp: PropTypes.bool
}

CompanyProfile.defaultProps = {
  hideDescription: false,
  completeSignUp: false
}

export default CompanyProfile
