import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { classNames } from '../../helpers'

import Bold from './icons/bold'
import Braces from './icons/braces'
import Header from './icons/header'
import Italic from './icons/italic'
import ListOrdered from './icons/list-ordered'
import ListUnordered from './icons/list-unordered'

const MenuBarButton = ({ onClick, disabled, active, Icon }) => {
  return (
    <button
      className={classNames(
        active ? 'bg-gray-200 hover:bg-gray-300' : 'hover:bg-gray-100',
        'h-7 w-7 rounded text-gray-900 hover:text-gray-950',
        'flex items-center justify-center'
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon className='h-5 w-5' />
    </button>
  )
}

MenuBarButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  Icon: PropTypes.func.isRequired
}

MenuBarButton.defaultProps = {
  onClick: undefined,
  disabled: false
}

const MenuBar = ({ editor, macros }) => {
  const { t } = useTranslation()

  const [macroMenuVisible, setMacroMenuVisible] = useState(false)

  useEffect(() => {
    if (editor?.isFocused) setMacroMenuVisible(false)
  }, [editor?.isFocused])

  if (!editor) {
    return null
  }

  return (
    <div
      onClick={macroMenuVisible ? () => setMacroMenuVisible(false) : undefined}
      className='flex items-center gap-1 rounded-t-xl bg-gray-50 p-3 pb-2'
    >
      <MenuBarButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        active={editor.isActive('bold')}
        Icon={Bold}
      />
      <MenuBarButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        active={editor.isActive('italic')}
        Icon={Italic}
      />
      <span className='text-xs text-gray-300'>|</span>
      <MenuBarButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        active={editor.isActive('heading', { level: 1 })}
        Icon={Header}
      />
      <span className='text-xs text-gray-300'>|</span>
      <MenuBarButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        active={editor.isActive('bulletList')}
        Icon={ListUnordered}
      />
      <MenuBarButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        active={editor.isActive('orderedList')}
        Icon={ListOrdered}
      />
      {macros && (
        <>
          <span className='text-xs text-gray-300'>|</span>
          <div className='relative flex'>
            <MenuBarButton
              active={macroMenuVisible}
              Icon={Braces}
              onClick={() => {
                if (macroMenuVisible) {
                  // exit the dropdown an focus
                  editor.chain().focus().run()
                }

                setMacroMenuVisible(!macroMenuVisible)
              }}
            />
            <div
              className={classNames(
                'absolute left-0 top-6 z-10 mt-2 divide-y divide-gray-100 rounded bg-white px-4 py-2 shadow',
                macroMenuVisible ? 'block' : 'hidden'
              )}
            >
              {/*
                we need to have a own div for dropdown because
                headlessui reacts to focus / blur of tiptap input
              */}
              {macros.map(({ label, macro }, index) => (
                <button
                  key={index}
                  className={classNames(
                    'group flex w-full whitespace-nowrap px-4 py-2 text-left text-sm text-gray-700',
                    'hover:bg-gray-50 hover:text-gray-900'
                  )}
                  onClick={() => {
                    setMacroMenuVisible(false)
                    editor
                      .chain()
                      .focus()
                      .command(({ tr }) => {
                        // manipulate the transaction
                        tr.insertText(macro)

                        return true
                      })
                      .run()
                  }}
                >
                  {t(label)}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

MenuBar.propTypes = {
  editor: PropTypes.object,
  macros: PropTypes.array
}

MenuBar.defaultProps = {
  editor: null,
  macros: null
}

export default MenuBar
