export default {
  LAUNCH: 'LAUNCH',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SEND_MARKETING_MAIL: 'SEND_MARKETING_MAIL',
  DATA_SUBMITTED_TRYOUT: 'DATA_SUBMITTED_TRYOUT',
  PRESSED_TRY_FOR_FREE: 'PRESSED_TRY_FOR_FREE',
  PRESSED_GO_TO_TALENTS: 'PRESSED_GO_TO_TALENTS',
  PRESSED_CONTACT_SALES: 'PRESSED_CONTACT_SALES',
  PRESSED_PAYWALL: 'PRESSED_PAYWALL',
  PRESSED_BUTTON_PAYWALL_GENERAL: 'PRESSED_BUTTON_PAYWALL_GENERAL',
  PRESSED_BUTTON_PAYWALL_PUBLIC_INVITATION_LINK:
    'PRESSED_BUTTON_PAYWALL_PUBLIC_INVITATION_LINK',
  PRESSED_BUTTON_PAYWALL_TALENTPOOL: 'PRESSED_BUTTON_PAYWALL_TALENTPOOL',
  DISPLAY_RELEASE_NOTES: 'DISPLAY_RELEASE_NOTES',
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
  PRESSED_TAB: 'PRESSED_TAB',
  INVITE_TALENT: 'INVITE_TALENT',
  INVITE_TALENT_POOL: 'INVITE_TALENT_POOL',
  INVITE_TALENT_CAREER: 'INVITE_TALENT_CAREER',
  INVITE_VIA_LINK: 'INVITE_VIA_LINK',
  INVITE_VIA_EMAIL: 'INVITE_VIA_EMAIL',
  DELETE_TALENT: 'DELETE_TALENT',
  DELETE_TALENTS: 'DELETE_TALENTS',
  RECOVER_TALENTS: 'RECOVER_TALENTS',
  REMIND_TALENT: 'REMIND_TALENT',
  REMIND_TALENTS: 'REMIND_TALENTS',
  RESET_EXPIRED_TALENT: 'RESET_EXPIRED_TALENT',
  RESET_EXPIRED_TALENTS: 'RESET_EXPIRED_TALENTS',
  COMMENT_TALENT: 'COMMENT_TALENT',
  OPEN_APPLICANT_STATUS: 'OPEN_APPLICANT_STATUS',
  UPDATE_APPLICANT_STATUS: 'UPDATE_APPLICANT_STATUS',
  ABORT_APPLICANT_STATUS: 'ABORT_APPLICANT_STATUS',
  UPDATE_CAREER_ASSESSMENT_SETTINGS: 'UPDATE_CAREER_ASSESSMENT_SETTINGS',
  UPDATE_CAREER_ASSESSMENT_SETTINGS_PHOTO_URL:
    'UPDATE_CAREER_ASSESSMENT_SETTINGS_PHOTO_URL',
  ARCHIVE_CAREER: 'ARCHIVE_CAREER',
  PREVIEW_REQUIREMENT_PROFILE: 'PREVIEW_REQUIREMENT_PROFILE',
  DOWNLOAD_REQUIREMENT_PROFILE: 'DOWNLOAD_REQUIREMENT_PROFILE',
  ABORT_CREATE_CAREER: 'ABORT_CREATE_CAREER',
  FINISH_CREATE_CAREER: 'FINISH_CREATE_CAREER',
  INVITE_EXPERT_TO_CAREER_ANALYSE: 'INVITE_EXPERT_TO_CAREER_ANALYSE',
  DELETE_CAREER_ANALYSE: 'DELETE_CAREER_ANALYSE',
  FINISH_CAREER_ANALYSE_BY_EXPERT: 'FINISH_CAREER_ANALYSE_BY_EXPERT',
  FINISH_CAREER_ANALYSE_BY_PRESETS: 'FINISH_CAREER_ANALYSE_BY_PRESETS',
  FINISH_CAREER_ANALYSE_BY_CAREER: 'FINISH_CAREER_ANALYSE_BY_CAREER',
  REMIND_EXPERT_CAREER_ANALYSE: 'REMIND_EXPERT_CAREER_ANALYSE',
  ACTIVATE_PUBLIC_LINK_INVITATION: 'ACTIVATE_PUBLIC_LINK_INVITATION',
  PRESSED_PUBLIC_LINK_INVITATION: 'PRESSED_PUBLIC_LINK_INVITATION',
  UPDATE_PUBLIC_LINK_INVITATION: 'UPDATE_PUBLIC_LINK_INVITATION',
  ABORT_PUBLIC_LINK_INVITATION: 'ABORT_PUBLIC_LINK_INVITATION',
  ACTIVATE_PUBLIC_LINK_INVITATION_POOL: 'ACTIVATE_PUBLIC_LINK_INVITATION_POOL',
  UPDATE_PUBLIC_LINK_INVITATION_POOL: 'UPDATE_PUBLIC_LINK_INVITATION_POOL',
  ABORT_PUBLIC_LINK_INVITATION_POOL: 'ABORT_PUBLIC_LINK_INVITATION_POOL',
  DUPLICATE_CAREER: 'DUPLICATE_CAREER',
  DELETE_DEMO_DATA: 'DELETE_DEMO_DATA',
  CREATE_DEMO_DATA: 'CREATE_DEMO_DATA',
  UPDATE_RESULT_REPORTS_SETTINGS: 'UPDATE_RESULT_REPORTS_SETTINGS',
  UPGRADE_PLAN: 'UPGRADE_PLAN',
  UPDATE_CAREER_ATTRACT_SETTINGS: 'UPDATE_CAREER_ATTRACT_SETTINGS',
  SHOW_NET_PROMOTER_SCORE: 'SHOW_NET_PROMOTER_SCORE',
  SUBMIT_NET_PROMOTER_SCORE: 'SUBMIT_NET_PROMOTER_SCORE',
  PRESSED_SETTINGS_TAB: 'PRESSED_SETTINGS_TAB',
  PRESSED_PRODUCTTOUR: 'PRESSED_PRODUCTTOUR',
  PRESSED_TALENTS: 'PRESSED_TALENTS',
  PRESSED_HELP_SUPPORT: 'PRESSED_HELP_SUPPORT',
  PRESSED_CAREERS: 'PRESSED_CAREERS',
  PRESSED_TEAMANALYSES: 'PRESSED_TEAMANALYSES',
  ABORT_PRODUCTTOUR: 'ABORT_PRODUCTTOUR',
  COMPLETE_PRODUCTTOUR: 'COMPLETE_PRODUCTTOUR',
  PRESSED_TRYOUT_CHALLENGE: 'PRESSED_TRYOUT_CHALLENGE',
  DELETE_USER: 'DELETE_USER',
  PRESSED_TAB_INVITE_EXPERTS: 'PRESSED_TAB_INVITE_EXPERTS',
  PRESSED_TAB_SELECT_CAREER: 'PRESSED_TAB_SELECT_CAREER',
  PRESSED_TAB_USE_PRESETS: 'PRESSED_TAB_USE_PRESETS',
  DOWNLOAD_STRENGTH_PROFILE_TALENTDETAIL:
    'DOWNLOAD_STRENGTH_PROFILE_TALENTDETAIL',
  DOWNLOAD_MATCHING_PROFILE_TALENTDETAIL:
    'DOWNLOAD_MATCHING_PROFILE_TALENTDETAIL',
  CLICK_REQUEST_CHALLENGES_CROSSMODE: 'CLICK_REQUEST_CHALLENGES_CROSSMODE',
  SHOW_DIAGNOSTIC_VERSION_1_CROSSMODE: 'SHOW_DIAGNOSTIC_VERSION_1_CROSSMODE',
  SHOW_DIAGNOSTIC_VERSION_2_CROSSMODE: 'SHOW_DIAGNOSTIC_VERSION_2_CROSSMODE',
  CLICK_REQUEST_CHALLENGES_TALENT_DETAILS:
    'CLICK_REQUEST_CHALLENGES_TALENT_DETAILS',
  NAVIGATE_TO_RELEASE_NOTES: 'NAVIGATE_TO_RELEASE_NOTES',
  NAVIGATE_TO_TEST_LIBRARY: 'NAVIGATE_TO_TEST_LIBRARY',
  NAVIGATE_TO_SUPPORT: 'NAVIGATE_TO_SUPPORT',
  NAVIGATE_TO_PROFILE: 'NAVIGATE_TO_PROFILE',
  CREATE_TEAMANALYSIS: 'CREATE_TEAMANALYSIS',
  ABORT_CREATE_TEAMANALYSIS: 'ABORT_CREATE_TEAMANALYSIS',
  COMPLETE_CREATE_TEAMANALYSIS: 'COMPLETE_CREATE_TEAMANALYSIS',
  VIEW_TEAMANALYSIS: 'VIEW_TEAMANALYSIS',
  DELETE_TEAMANALYSIS: 'DELETE_TEAMANALYSIS',
  VIEW_TALENT_TEAMANALYSIS: 'VIEW_TALENT_TEAMANALYSIS',
  CLICK_EXPORT_TO_PNG_TEAMANALYSIS: 'CLICK_EXPORT_TO_PNG_TEAMANALYSIS',
  CLICK_OPEN_PDF_TEAMANALYSIS: 'CLICK_OPEN_PDF_TEAMANALYSIS',
  CLICK_MORE_INFORMATION_TEAMANALYSIS: 'CLICK_MORE_INFORMATION_TEAMANALYSIS',
  CLICK_CONFIGURATION_TEAMANALYSIS: 'CLICK_CONFIGURATION_TEAMANALYSIS',
  CLICK_INVITE_TALENTPOOL_TEAMANALYSIS: 'CLICK_INVITE_TALENTPOOL_TEAMANALYSIS',
  DOWNLOAD_INTERPRETATIONGUIDE_TEAMANALYSIS:
    'DOWNLOAD_INTERPRETATIONGUIDE_TEAMANALYSIS',
  HIDE_SPACE_SET_STATE_HINT: 'HIDE_SPACE_SET_STATE_HINT',
  PRESS_SPACE_SET_STATE_TALENTTABLE: 'PRESS_SPACE_SET_STATE_TALENTTABLE',
  CLICK_NAV_FEATURES_LANDINGPAGE: 'CLICK_NAV_FEATURES_LANDINGPAGE',
  CLICK_NAV_TESTIMONIALS_LANDINGPAGE: 'CLICK_NAV_TESTIMONIALS_LANDINGPAGE',
  CLICK_NAV_PRICING_LANDINGPAGE: 'CLICK_NAV_PRICING_LANDINGPAGE',
  CLICK_NAV_LOGIN_LANDINGPAGE: 'CLICK_NAV_LOGIN_LANDINGPAGE',
  CLICK_NAV_STARTNOW_LANDINGPAGE: 'CLICK_NAV_STARTNOW_LANDINGPAGE',
  CLICK_HERO_TRYOUT_LANDINGPAGE: 'CLICK_HERO_TRYOUT_LANDINGPAGE',
  CLICK_HERO_YOUTUBE_LANDINGPAGE: 'CLICK_HERO_YOUTUBE_LANDINGPAGE',
  CLICK_FEATURES_TRYSELF_LANDINGPAGE: 'CLICK_FEATURES_TRYSELF_LANDINGPAGE',
  CLICK_SIDEBAR_COLLAPSE: 'CLICK_SIDEBAR_COLLAPSE',
  CLICK_PLAY_VIDEO_HELP_SUPPORT: 'CLICK_PLAY_VIDEO_HELP_SUPPORT',
  CLICK_SUPPORT_HELP_SUPPORT: 'CLICK_SUPPORT_HELP_SUPPORT',
  COMPARE_PLAN_DETAILS_LANDINGPAGE: 'COMPARE_PLAN_DETAILS_LANDINGPAGE',
  CLICK_COMPARE_RADARCHART_TALENT: 'CLICK_COMPARE_RADARCHART_TALENT',
  CLICK_VIEW_RADARCHART_TALENT: 'CLICK_VIEW_RADARCHART_TALENT',
  OPEN_CSV_EXPORT: 'OPEN_CSV_EXPORT',
  ABORT_CSV_EXPORT: 'ABORT_CSV_EXPORT',
  COMPLETE_CSV_EXPORT: 'COMPLETE_CSV_EXPORT',
  SEND_TEST_EMAIL: 'SEND_TEST_EMAIL',
  CLICK_CHANGE_LANGUAGE_LANDINGPAGE: 'CLICK_CHANGE_LANGUAGE_LANDINGPAGE',
  SHOW_HELP_SUPPORT_HINT: 'SHOW_HELP_SUPPORT_HINT',
  SHOW_FAVORED_SPACE_HINT: 'SHOW_FAVORED_SPACE_HINT',
  CLICK_DIMENSION_TOOLTIP: 'CLICK_DIMENSION_TOOLTIP',
  CLICK_FUNNEL_DISCOVER_STRENGTH_WEBAPP:
    'CLICK_FUNNEL_DISCOVER_STRENGTH_WEBAPP',
  CANCEL_INVITE_USER: 'CANCEL_INVITE_USER',
  INVITE_USER: 'INVITE_USER',
  EDIT_SPACE_PERSON: 'EDIT_SPACE_PERSON',
  START_ONBOARDING: 'START_ONBOARDING',
  SET_ONBOARDING_FIRSTNAME: 'SET_ONBOARDING_FIRSTNAME',
  SET_ONBOARDING_LASTNAME: 'SET_ONBOARDING_LASTNAME',
  SET_ONBOARDING_COMPANY_NAME: 'SET_ONBOARDING_COMPANY_NAME',
  SET_ONBOARDING_COMPANY_DISPLAY_NAME: 'SET_ONBOARDING_COMPANY_DISPLAY_NAME',
  SET_ONBOARDING_PLANNED_HIRES: 'SET_ONBOARDING_PLANNED_HIRES',
  FINISH_ONBOARDING: 'FINISH_ONBOARDING',
  CLICK_MORE_INFO_DIMENSION: 'CLICK_MORE_INFO_DIMENSION',
  REFETCH_NOW: 'REFETCH_NOW',
  CLICK_SETTINGS_RESET_CACHE: 'CLICK_SETTINGS_RESET_CACHE',
  CLICK_CHANGE_LOCATION: 'CLICK_CHANGE_LOCATION',
  SET_ACTIVE_SPACE_AUTOMATICALLY: 'SET_ACTIVE_SPACE_AUTOMATICALLY',
  CLICK_HINT_GO_TO_AUTOMATIONS: 'CLICK_HINT_GO_TO_AUTOMATIONS',
  CAREERS_MATCHING_SHOW_DIAGNOSTIC_VERSION_1:
    'CAREERS_MATCHING_SHOW_DIAGNOSTIC_VERSION_1',
  CAREERS_MATCHING_SHOW_DIAGNOSTIC_VERSION_2:
    'CAREERS_MATCHING_SHOW_DIAGNOSTIC_VERSION_2'
}
