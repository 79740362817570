import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { rankItem } from '@tanstack/match-sorter-utils'
import { format, formatDistance, subSeconds } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Seo } from '../components'
import ActivityIndicator from '../components/activity-indicator'
import NetworkError from '../components/network-error'
import IndeterminateCheckbox from '../components/react-table/indeterminate-checkbox'
import Table from '../components/react-table/table'
import PageHeading from '../components/tailwind/PageHeading'

import { JSONTree } from 'react-json-tree'
import RecoverCareersAction from '../components/recover/recover-careers-action'
import trackEvents from '../constants/track-events'
import {
  useAivyContext,
  useLanguageContext,
  usePaymentContext
} from '../context'
import { classNames, uuidSmall } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import { useCareersQuery } from '../hooks/use-careers-query'

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const locale = { de, en: enUS }

const RecoverCareers = () => {
  const { t } = useTranslation()

  const { language } = useLanguageContext()
  const { is_trial } = usePaymentContext()
  const { adminInsights } = useAivyContext()

  const [time, setTime] = useState(null)
  const [resetTableKey, setResetTableKey] = useState(uuidSmall())

  const [selectionState, setSelectionState] = useState({ careers: [] })
  const [displayRecoverCareersActions, setDisplayRecoverCareersActions] =
    useState(false)

  const {
    status,
    data: careers,
    dataUpdatedAt,
    fetchStatus,
    refetch
  } = useCareersQuery({
    select: (items) => {
      return items
        .filter(({ id }) => !id.includes('PRODUCT_TOUR'))
        .filter(({ archived }) => archived)
    }
  })

  const fetchingSubtitle = useMemo(() => {
    if (status === 'error') return null

    // data updated at defaults to 0 for an empty cache
    if (!dataUpdatedAt) {
      return t('recover_careers.fetching_subtitle.is_loading')
    }

    if (fetchStatus === 'fetching') {
      return t('recover_careers.fetching_subtitle.is_updating')
    }

    return t('recover_careers.fetching_subtitle.last_updated_at', {
      time, // only used here for the use memo dependencies
      distance_sub_seconds: formatDistance(
        subSeconds(new Date(dataUpdatedAt), 3),
        new Date(),
        {
          locale: locale[language]
        }
      )
    })
  }, [status, dataUpdatedAt, fetchStatus, time, language, t])

  useEffect(() => {
    // update fetching subtitle every minute
    const interval = setInterval(() => setTime(Date.now()), 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const columns = useMemo(
    () =>
      [
        adminInsights && {
          id: 'admin-insights',
          accessorKey: 'id',
          enableSorting: false,
          disableOnClick: true,
          header: (
            <span className='text-sm font-semibold text-gray-900'>ID</span>
          ),
          cell: ({ getValue, row }) => (
            <>
              <span className='block text-sm font-medium text-gray-900'>
                {getValue()}
              </span>
              <JSONTree
                data={row.original}
                shouldExpandNodeInitially={() => false}
              />
            </>
          )
        },
        {
          id: 'select',
          disableOnClick: true,
          header: ({ table }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                // indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler()
              }}
            />
          ),
          cell: ({ row }) => (
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              // disabled={!row.getCanSelect()}
              // indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          )
        },
        {
          accessorKey: 'title',
          disableOnClick: true,
          sortingFn: 'alphanumeric',
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t('recover_careers.table_header_career_name').toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm font-medium text-gray-900'>
              {getValue()}
            </span>
          )
        },
        !!careers?.find(({ external_custom_id }) => !!external_custom_id) && {
          accessorKey: 'external_custom_id',
          disableOnClick: true,
          sortDescFirst: false,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                'recover_careers.table_header_career_external_id'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm text-gray-500'>{getValue() || 'n/a'}</span>
          )
        },
        {
          accessorKey: 'number_of_spaces',
          sortDescFirst: true,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                'recover_careers.table_header_career_number_of_spaces'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm text-gray-500'>{getValue()}</span>
          )
        },
        {
          accessorKey: 'updatedAt',
          disableOnClick: true,
          sortDescFirst: true,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                'recover_careers.table_header_career_updated_at'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm text-gray-500'>
              {t('recover_careers.datetime', {
                datetime: format(new Date(getValue()), 'dd.MM.yyyy | HH:mm')
              })}
            </span>
          )
        },
        {
          accessorKey: 'createdAt',
          disableOnClick: true,
          sortDescFirst: true,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                'recover_careers.table_header_career_created_at'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm text-gray-500'>
              {format(new Date(getValue()), 'dd.MM.yyyy')}
            </span>
          )
        },
        {
          accessorKey: 'id',
          header: null,
          cell: () => <div />,
          invisible: true
        }
      ].filter((item) => item),
    [careers, adminInsights, t]
  )

  const globalFilterFn = useCallback((row, columnId, value, addMeta) => {
    const columnValue = row.getValue(columnId)
    const itemRank = rankItem(columnValue, value)

    addMeta({ itemRank })

    return itemRank.passed
  }, [])

  const selectionCallback = useCallback((table) => {
    setSelectionState((previous) => ({
      ...previous,
      careers: table
        .getSelectedRowModel()
        .rows.map(({ original, toggleSelected }) => ({
          ...original,
          removeSelected: () => toggleSelected(false)
        }))
    }))
  }, [])

  if (status === 'pending') {
    return <ActivityIndicator />
  }

  if (status === 'error') {
    return (
      <div className='w-full'>
        <Seo title={t('seo.titles.recover_careers')} />
        <NetworkError />
      </div>
    )
  }

  const pageHeadingParams = {
    title: t('seo.titles.recover_careers'),
    subtitle: fetchingSubtitle
  }

  if (!careers.length) {
    return (
      <>
        <Seo title={t('seo.titles.recover_careers')} />
        <PageHeading {...pageHeadingParams} />
        <div className='px-4 md:px-6 lg:px-8'>
          <p className='mt-8 max-w-xl text-sm text-gray-900'>
            {t('recover_careers.description')}
          </p>
          <div className='mt-32 flex justify-center'>
            <p className='text-center text-sm italic text-gray-700'>
              {t('recover_careers.empty_state')}
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Seo title={t('seo.titles.recover_careers')} />
      <PageHeading {...pageHeadingParams} />

      <div className='mt-8 px-4 md:px-6 lg:px-8'>
        <p className='max-w-xl text-sm text-gray-900'>
          {t('recover_careers.description')}
        </p>
      </div>

      <Table
        key={resetTableKey}
        data={careers}
        columns={columns}
        globalFilterFn={globalFilterFn}
        initialState={{ sorting: [{ id: 'updatedAt', desc: true }] }}
        withActions={
          <motion.div
            variants={container}
            initial='hidden'
            animate='visible'
            className='mr-8 flex items-center gap-x-2'
          >
            {[
              {
                id: 'action-recover-careers',
                onClick: () => setDisplayRecoverCareersActions(true),
                icons: { outline: ArrowPathIcon },
                label: t('recover_careers.recover_action')
              }
            ].map(({ id, onClick, icons, label }, index) => {
              const Icon = icons.outline

              return (
                <div key={index}>
                  <motion.button
                    id={id}
                    data-tooltip-id={id}
                    className={classNames(
                      'group flex h-8 w-8 items-center justify-center rounded-full',
                      'cursor-pointer hover:bg-blue-700'
                    )}
                    onClick={onClick}
                    variants={item}
                  >
                    <Icon className={classNames('h-5 w-5', 'text-white')} />
                  </motion.button>
                  <ReactTooltip
                    id={id}
                    className='rounded-full font-medium'
                    content={label}
                    style={{ backgroundColor: '#182033' }}
                    place='top'
                    variant='info'
                  />
                </div>
              )
            })}
          </motion.div>
        }
        selectionCallback={selectionCallback}
        disableVirtual={is_trial}
        displayNumberOfEntries
        disableHover
      />
      <RecoverCareersAction
        careers={[...selectionState.careers]}
        updateHandler={() => {
          refetch().then(() => {
            trackEvent(trackEvents.RECOVER_TALENTS)
            setResetTableKey(uuidSmall())
          })
        }}
        open={displayRecoverCareersActions}
        setOpen={setDisplayRecoverCareersActions}
      />
    </>
  )
}

export default RecoverCareers
