import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { ActivityIndicator, Seo } from '../components'
import CareerAnalyse from '../components/create-career/career-analyse'
import CreateNewCareer from '../components/create-career/create-new-career'
import LayoutPadding from '../components/layout-padding'
import Button from '../components/tailwind/Button'
import PageHeading from '../components/tailwind/PageHeading'
import Steps from '../components/tailwind/steps'
import trackEvents from '../constants/track-events'
import { mutation, query } from '../graphql'
import { classNames } from '../helpers'
import { trackEvent } from '../helpers/analytics'
// import { useMutationAndUpdateQuery } from '../hooks/use-mutation-and-update-query'
import Data from '../components/aivy-admin/data'
import Logs from '../components/aivy-admin/logs'
import Adjustment from '../components/create-career/adjustment/Adjustment'
import ConfirmTestbattery from '../components/create-career/confirm-testbattery'
import CulturalFit from '../components/create-career/cultural-fit'
import Overview from '../components/create-career/overview'
import {
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../context'
import { useSampleData } from '../hooks/use-sample-data'
import Error404 from './error-404'

const CreateCareer = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { success, alert } = useNotificationContext()
  const { isAdmin } = useUserContext()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [careerMutationStatus, setCareerMutationStatus] = useState('idle')
  const [isDeleting, setIsDeleting] = useState(false)

  const [displayLogs, setDisplayLogs] = useState(false)
  const [displayData, setDisplayData] = useState(false)

  const {
    data: career,
    status: careerStatus,
    refetch: refetchCareer
  } = useQuery({
    queryKey: ['create-career', id],
    queryFn: () => {
      const promise = new Promise((resolve) => {
        query({ query: 'getCareer', variables: { id } }).then((c) => {
          if (!c) return resolve(null)

          const { career_preset_id } = JSON.parse(c.career_analyse || '{}')

          if (career_preset_id) {
            const queryKey = ['career-preset', career_preset_id]

            const presetCache = queryClient.getQueryCache().find({ queryKey })
            if (presetCache?.state?.status === 'success') return resolve(c)

            queryClient
              .prefetchQuery({
                queryKey,
                queryFn: () =>
                  query({
                    query: 'publicSqlQuery',
                    variables: {
                      action: 'query-career-preset',
                      input: JSON.stringify({ career_preset_id })
                    }
                  })
              })
              .then(() => resolve(c))
          } else resolve(c)
        })
      })

      return promise
    },
    // /career/new || /career/new/:id
    enabled: !!id
  })

  const { data: careers, status: careersStatus } = useQuery({
    queryKey: ['careers', partner.id],
    queryFn: () =>
      query({
        query: 'careerByPartnerByDate',
        variables: { partner_id: partner.id }
      })
  })

  const { deleteSampleData } = useSampleData()

  // amplify bug! - updateCareer: res - { career_analyse: null }
  // const careerMutation = useMutationAndUpdateQuery({
  //  queryKey: ['create-career', id],
  //  mutation: 'updateCareer'
  // })

  const careerMutation = {
    // implement react-query use mutation functionality with refetch
    mutate: async ({ input }, callbacks = {}) => {
      setCareerMutationStatus('pending')

      const { onSuccess, onError } = callbacks

      let response
      // amplify bug! - updateCareer: res - { career_analyse: null }
      try {
        response = await mutation({ mutation: 'updateCareer', input })
      } catch (err) {
        setCareerMutationStatus('error')
        onError && onError()
        return
      }

      await refetchCareer()

      setCareerMutationStatus('success')
      onSuccess && onSuccess(response)
    },
    status: careerMutationStatus
  }

  const deleteCareer = () => {
    alert(
      t('create_career.delete_career_title'),
      [
        t('create_career.delete_career_description_1'),
        t('create_career.delete_career_description_2')
      ],
      [
        {
          onPress: () => {
            setIsDeleting(true)

            const onPromiseResolve = () => {
              setIsDeleting(false)
              success(t('create_career.delete_career_success_message'))
              navigate('/careers')
            }

            if (career.id.includes('sample')) {
              trackEvent(trackEvents.DELETE_DEMO_DATA)

              deleteSampleData({
                onSuccess: () => setTimeout(onPromiseResolve, 0.5 * 1000)
              })

              return
            }

            query({
              query: 'careerControl',
              variables: {
                action: 'deleteCareerAndAnalyses',
                career_id: career.id
              }
            })
              .then(() => setTimeout(onPromiseResolve, 0.5 * 1000))
              .catch(() => setIsDeleting(false))
          },
          text: t('create_career.delete_career_confirm_action'),
          style: 'destructive'
        },
        {
          text: t('create_career.delete_career_cancel_action'),
          style: 'cancel'
        }
      ]
    )
  }

  // /career/new || /career/new/:id
  if ((!!id && careerStatus === 'pending') || careersStatus === 'pending') {
    return (
      <div className='mx-12'>
        <ActivityIndicator />
      </div>
    )
  }

  if (!!id && !career) {
    return <Error404 />
  }

  // career is duplicated if copy_from_career_id is set
  const isDuplicate = !!career?.copy_from_career_id

  const { status: career_status } = career || {}
  const is_aivymode = career_status === 'WAIT_CONFIRM_TESTBATTERY'
  const is_expertmode = career_status === 'WAIT_CONFIG'

  const steps = [
    {
      id: '01',
      name: t('create_career.step_1_title'),
      current: !career_status,
      complete: [
        'WAIT_CULTURAL_FIT',
        'WAIT_EXPERTS',
        'WAIT_CONFIRM_TESTBATTERY',
        'WAIT_CONFIG',
        'WAIT_ACTIVE'
      ].includes(career_status)
    },
    {
      id: '02',
      name: t('create_career.step_2_title'),
      current: career_status === 'WAIT_CULTURAL_FIT',
      complete: [
        'WAIT_EXPERTS',
        'WAIT_CONFIRM_TESTBATTERY',
        'WAIT_CONFIG',
        'WAIT_ACTIVE'
      ].includes(career_status)
    },
    {
      id: '03',
      name: t('create_career.step_3_title'),
      current: career_status === 'WAIT_EXPERTS',
      complete: [
        'WAIT_CONFIRM_TESTBATTERY',
        'WAIT_CONFIG',
        'WAIT_ACTIVE'
      ].includes(career_status)
    },
    is_aivymode && {
      id: '041',
      name: t('create_career.step_41_title'),
      current: career_status === 'WAIT_CONFIRM_TESTBATTERY',
      complete: ['WAIT_ACTIVE'].includes(career_status)
    },
    is_expertmode && {
      id: '042',
      name: t('create_career.step_42_title'),
      current: career_status === 'WAIT_CONFIG',
      complete: ['WAIT_ACTIVE'].includes(career_status)
    },
    {
      id: '05',
      name: t('create_career.step_5_title'),
      current: career_status === 'WAIT_ACTIVE' || career_status === 'ACTIVE',
      complete: false
    }
  ].filter((item) => !!item)

  return (
    <>
      <Seo title={career?.title || t('create_career.title')} />
      <PageHeading
        title={career?.title || t('create_career.title')}
        withActions={
          <div
            className={classNames(
              'ml-auto',
              career?.id ? 'flex items-center gap-x-3' : 'hidden'
            )}
          >
            {isAdmin && (
              <div className='flex gap-x-1.5'>
                <Button.SecondaryLG
                  text='Show Career'
                  onClick={() => setDisplayData(true)}
                />
                <Button.SecondaryLG
                  text='Show Logs'
                  onClick={() => setDisplayLogs(true)}
                />
              </div>
            )}
            <Button.SecondaryXL
              text={t('create_career.delete_career_action')}
              onClick={deleteCareer}
              isLoading={isDeleting}
            />
          </div>
        }
      />
      <LayoutPadding padding='pt-4 pb-32'>
        <div className='mx-auto max-w-5xl'>
          {isDuplicate ? null : (
            <div className='mt-6'>
              <Steps steps={steps} />
            </div>
          )}
          <div className='relative mt-8'>
            <div>
              {!career_status && (
                <CreateNewCareer
                  career={career || { partner_id: partner.id }}
                  careers={careers}
                  careerMutation={careerMutation}
                  isDuplicate={isDuplicate}
                />
              )}
              {career_status === 'WAIT_CULTURAL_FIT' && (
                <CulturalFit career={career} careerMutation={careerMutation} />
              )}
              {career_status === 'WAIT_EXPERTS' && (
                <CareerAnalyse
                  career={career}
                  careerMutation={careerMutation}
                />
              )}
              {career_status === 'WAIT_CONFIG' && (
                <Adjustment
                  career={career}
                  careerMutation={careerMutation}
                  refetchCareer={refetchCareer}
                />
              )}

              {career_status === 'WAIT_CONFIRM_TESTBATTERY' && (
                <ConfirmTestbattery
                  career={career}
                  careerMutation={careerMutation}
                />
              )}

              {career_status === 'WAIT_ACTIVE' && (
                <Overview
                  career={career}
                  careerMutation={careerMutation}
                  careers={careers}
                />
              )}
            </div>
          </div>
        </div>
      </LayoutPadding>
      {career && (
        <>
          <Logs
            title='CREATE-CAREER-LOGS'
            open={displayLogs}
            setOpen={setDisplayLogs}
            query={() =>
              query({
                query: 'logsByCareer',
                variables: {
                  career_id: career.id,
                  filter: { event_name: { eq: 'CAREER_HISTORY_EVENT' } }
                }
              })
            }
            displayKey='context'
          />
          <Data
            title='CREATE-CAREER'
            open={displayData}
            setOpen={setDisplayData}
            data={career}
          />
        </>
      )}
    </>
  )
}

export default CreateCareer
