import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { classNames } from '../helpers'

const DEFAULT_CLASSES =
  'shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300'
const ERROR_CLASSES =
  'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'

const Textarea = ({ id, formik, placeholder, border, rows }) => {
  return (
    <div className='relative'>
      <textarea
        id={id}
        name={id}
        defaultValue={formik.values[id]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        rows={rows}
        placeholder={placeholder}
        // className='block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md'
        className={classNames(
          'block w-full rounded-md text-sm placeholder:italic',
          !border && 'overflow-auto border-none border-transparent',
          formik.touched[id] && formik.errors[id]
            ? ERROR_CLASSES
            : DEFAULT_CLASSES
        )}
      />
      {formik.touched[id] && formik.errors[id] && (
        <p className='mt-2 text-sm text-red-600'>{formik.errors[id]}</p>
      )}
      {formik.touched[id] && formik.errors[id] && (
        <div className='absolute right-3 top-3'>
          <ExclamationCircleIcon
            className='h-5 w-5 text-red-500'
            aria-hidden='true'
          />
        </div>
      )}
    </div>
  )
}

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  border: PropTypes.bool,
  rows: PropTypes.number
}

Textarea.defaultProps = {
  border: false,
  rows: 4
}

export default Textarea
