import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'

import { useAivyContext, usePartnerContext } from '../context'
import { query } from '../graphql'
import { classNames } from '../helpers'
import ActivityIndicator from './activity-indicator'
import Input from './input'

const ChooseGroupSlideOver = ({ open, setOpen }) => {
  const { status, data: partners } = useQuery({
    queryKey: ['partners'],
    queryFn: () => query({ query: 'listPartners' })
  })

  const { partner } = usePartnerContext()
  const { restartDashboard } = useAivyContext()

  const [partnerSearchKeyword, setPartnerSearchKeyword] = useState('')
  const filteredPartner = filterPartner(partners, partnerSearchKeyword)

  async function onPressPartner({ id }) {
    localStorage.setItem('admin', id)

    restartDashboard()
  }

  function handleChange(event) {
    setPartnerSearchKeyword(event.target.value)
  }

  function filterPartner(partners, query) {
    if (partners === null || partners === undefined) {
      return []
    }
    if (!query) {
      return partners
    }

    return partners.filter((p) => {
      const partnerDisplayName = p?.display_name?.toLowerCase()
      const partnerName = p?.name?.toLowerCase()
      const id = p?.id

      return (
        partnerName?.includes(query.toLowerCase()) ||
        partnerDisplayName?.includes(query.toLowerCase()) ||
        id?.includes(query.toLowerCase())
      )
    })
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-hidden'
        onClose={setOpen}
      >
        <div className='absolute inset-0 overflow-hidden'>
          <TransitionChild
            as={Fragment}
            enter='ease-in-out duration-500'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-500'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div
              className='fixed inset-0 bg-slate-900/25 backdrop-blur-sm transition-opacity'
              aria-hidden='true'
            />
          </TransitionChild>

          <div className='fixed inset-y-0 right-0 flex max-w-full pl-10'>
            <TransitionChild
              as={Fragment}
              enter='transform transition ease-in-out duration-500 sm:duration-700'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500 sm:duration-700'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <div className='w-screen max-w-md'>
                <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                  <div className='px-4 sm:px-6'>
                    <div className='flex items-start justify-between'>
                      <DialogTitle className='text-2xl font-medium text-gray-900'>
                        Gruppe wechseln
                      </DialogTitle>
                      <div className='ml-3 flex h-7 items-center'>
                        <button
                          type='button'
                          className='rounded-md bg-white text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                          onClick={() => setOpen(false)}
                        >
                          <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='relative mt-4 flex-1 px-4 sm:px-6'>
                    <div className='px-2 pb-4 pt-2'>
                      <Input
                        type='text'
                        label='Suche'
                        name='partnerSearchKeyword'
                        id='partnerSearchKeyword'
                        placeholder='Aivy GmbH'
                        onChange={handleChange}
                        value={partnerSearchKeyword}
                      />
                    </div>
                    {status === 'pending' ? (
                      <ActivityIndicator />
                    ) : (
                      <ul className='divide-y divide-gray-200'>
                        {partnerSearchKeyword.length > 0 &&
                          filteredPartner.map(
                            ({ id, logo, display_name, name }) => (
                              <li
                                onClick={() => onPressPartner({ id })}
                                key={id}
                                className={classNames(
                                  'flex cursor-pointer flex-row items-center justify-between px-6 py-4 hover:bg-gray-50',
                                  partner.id === id &&
                                    'bg-green-200 hover:bg-green-200',
                                  'group'
                                )}
                              >
                                <div className='flex flex-row items-center'>
                                  {logo ? (
                                    <img
                                      alt='partner-logo'
                                      src={logo}
                                      className='mr-4 h-20 w-20 rounded object-contain'
                                    />
                                  ) : (
                                    <div className='mr-4 flex h-20 w-20 items-center justify-center rounded bg-gray-50 group-hover:bg-gray-100' />
                                  )}

                                  <div>
                                    <span className='block font-medium'>
                                      {display_name || name}
                                    </span>
                                    <span className='block text-sm'>{id}</span>
                                  </div>
                                </div>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='h-5 w-5'
                                  viewBox='0 0 20 20'
                                  fill='currentColor'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z'
                                    clipRule='evenodd'
                                  />
                                </svg>
                              </li>
                            )
                          )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

ChooseGroupSlideOver.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default ChooseGroupSlideOver
