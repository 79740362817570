import { HIGH_FIT_RANGE, MIDDLE_FIT_RANGE } from '.'

export const filter = (row, columnId, value) => {
  return value && value.length ? value.includes(row.getValue(columnId)) : true
}

export const filterStatus = (row, columnId, value) => {
  const { filterValue } = row.getValue(columnId).status
  return value && value.length ? value.includes(filterValue) : true
}

export const filterMore = (row, columnId, value) => {
  const filterValue = row.getValue(columnId)

  let rowCheckPassed = false

  value.forEach((filter) => {
    if (filterValue.includes(filter)) rowCheckPassed = true
  })

  return rowCheckPassed
}

export const filterScore = (row, columnId, value) => {
  let score = row.getValue(columnId)

  if (score === '-') score = '-'
  else if (HIGH_FIT_RANGE(score)) score = 'HIGH_FIT'
  else if (MIDDLE_FIT_RANGE(score)) score = 'MIDDLE_FIT'
  else score = 'LOW_FIT'

  return value && value.length ? value.includes(score) : true
}

export const decodeFilter = (item) => {
  const field = Object.keys(item)[0]
  const operator = Object.keys(item[field])[0]
  let value = Object.values(item[field])[0]

  if (operator === 'between') {
    value = `${value.min} - ${value.max}`
  }

  return { field, operator, value }
}

export const encodeFilter = (item) => {
  const field = item.field
  const operator = item.operator
  const value = item.value

  const result = {}
  result[field] = {}
  result[field][operator] = value

  return result
}
