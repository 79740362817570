export default function Example() {
  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
      <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
        <div className='max-w-2xl text-center'>
          <p className='text-base font-semibold text-indigo-600'>Hinweis</p>
          <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
            Aivy entlang des gesamten Employee-Lifecycles 🚀
          </h1>
          <p className='mt-6 text-base leading-7 text-gray-600'>
            Vielen Dank für die Nutzung der Preview Version. Unser neues
            Dashboard ist ab heute wie gewohnt unter der folgenden URL{' '}
            <a
              className='font-medium text-indigo-600'
              href='https://www.dashboard.aivy.app'
            >
              https://www.dashboard.aivy.app
            </a>{' '}
            erreichbar.
          </p>
          <p className='mt-6 text-base leading-7 text-gray-600'>
            Wir danken allen User:innen für euer wertvolles Feedback.
          </p>

          <p className='mt-4 text-xs leading-7 text-gray-600'>
            Sollten Sie sich ein Lesezeichen zur Preview-Version des Aivy
            Dashboard gesetzt haben, dann vergessen Sie bitte nicht es zu
            ändern. Klicken Sie dazu auf den blauen Button unten und erstellen
            Sie im Nachgang ein Lesezeichen zum Aivy-Dashboard. Vielen Dank :-)
          </p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <a
              href='https://www.dashboard.aivy.app'
              className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              Weiter mit www.dashboard.aivy.app
            </a>
            <a
              href='mailto:support@aivy.app?subject=PreviewSupport'
              className='text-sm font-semibold text-gray-900'
            >
              Support kontaktieren <span aria-hidden='true'>&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  )
}
