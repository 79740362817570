import { DialogTitle } from '@headlessui/react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext,
  useUserContext
} from '../../context'
import { query } from '../../graphql'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'

const TRANSLATION_LOCATIONS = ['components.career.talents.']

const DeleteSpace = ({ spaces, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()
  const { is_trial } = usePaymentContext()
  const { success, alert } = useNotificationContext()
  const { partner } = usePartnerContext()
  const {
    cognitoUser: { username }
  } = useUserContext()

  const queryClient = useQueryClient()

  const [isLoading, setIsLoading] = useState(false)
  const translation = TRANSLATION_LOCATIONS[0]

  const deleteSpace = () => {
    setIsLoading(true)

    const softDeleteSpace = ({ space_id }) => {
      return query({
        query: 'spaceControl',
        variables: { action: 'softDeleteSpace', space_id, user_id: username }
      })
    }

    if (spaces.some(({ id: space_id }) => space_id.includes('sample_'))) {
      alert(
        t('delete_space.sample_data_found.modal_title'),
        t('delete_space.sample_data_found.modal_text'),
        [
          {
            text: t('delete_space.sample_data_found.modal_close_action'),
            style: 'cancel'
          }
        ]
      )

      setIsLoading(false)
      return
    }

    Promise.all(spaces.map(({ id }) => softDeleteSpace({ space_id: id })))
      .then(() => {
        if (is_trial) {
          queryClient.refetchQueries({
            queryKey: ['trial-data', partner.id],
            type: 'active'
          })
        }

        updateHandler()

        success(t(translation + 'delete_space_success'))
        setIsLoading(false)
        setOpen(false)
      })
      .catch(() => setIsLoading(false))
  }

  return (
    <Modal open={open} setOpen={() => setOpen(false)} size='lg'>
      <DialogTitle className='text-center text-2xl font-medium leading-6 text-gray-900'>
        {t(translation + 'modal_delete_space_title')}
      </DialogTitle>
      <span className='mt-2 block text-center text-base text-gray-600'>
        {t(translation + 'modal_delete_space_description')}
      </span>
      <div className='mt-8 flex flex-row gap-3'>
        <Button.SecondaryXL
          fullWidth
          text={t(translation + 'modal_delete_space_cancel_action')}
          onClick={() => setOpen(false)}
        />
        <Button.PrimaryXL
          fullWidth
          text={
            spaces.length > 1
              ? t(translation + 'modal_delete_space_submit_action_multiple', {
                  count: spaces.length
                })
              : t(translation + 'modal_delete_space_submit_action_single', {
                  count: spaces.length
                })
          }
          onClick={deleteSpace}
          isLoading={isLoading}
          className='bg-red-600 hover:bg-red-500'
        />
      </div>
    </Modal>
  )
}

DeleteSpace.propTypes = {
  spaces: PropTypes.array.isRequired,
  updateHandler: PropTypes.func,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

DeleteSpace.defaultProps = {
  updateHandler: () => undefined
}

export default DeleteSpace
