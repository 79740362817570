import PropTypes from 'prop-types'
import { useRef, useState } from 'react'

import cameraIcon from '../assets/images/camera-icon.png'
import { uuidSmall } from '../helpers'

const UploadPicture = ({
  title,
  subtitle,
  description,
  imageSrc,
  onSubmit,
  ImageCropComponent
}) => {
  const inputFile = useRef(null)

  const [uuidInput, setUuidInput] = useState(uuidSmall())

  const [displayImageCrop, setDisplayImageCrop] = useState(false)
  const [localImage, setLocalImage] = useState(null)

  const openFileDialog = () => {
    inputFile.current.click()
  }

  const handleSubmit = (event) => {
    // reset file input
    setUuidInput(uuidSmall())

    if (ImageCropComponent) {
      setDisplayImageCrop(true)
      setLocalImage(event.target.files[0])
    } else {
      onSubmit(event)
    }
  }

  return (
    <>
      <div>
        <label className='block text-sm font-medium text-gray-700'>
          {title}
        </label>
        <span className='mt-2 text-sm text-gray-500'>{subtitle}</span>
        <div className='relative mt-2 flex items-center'>
          {imageSrc ? (
            <img
              className='relative mr-4 h-32 w-32 rounded-full border-2 border-dotted border-gray-300 object-contain'
              src={`${imageSrc}?random_number=${new Date().getTime()}`}
              alt=''
            />
          ) : (
            <div className='relative mr-4 h-32 w-32 rounded-full border-2 border-dotted border-gray-300' />
          )}
          <input
            key={uuidInput}
            type='file'
            accept='image/*'
            id='file'
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleSubmit}
          />
          <div
            className='absolute bottom-0 left-24 h-12 w-12'
            onClick={openFileDialog}
          >
            <img src={cameraIcon} className='cursor-pointer' alt='' />
          </div>
        </div>
        {description && (
          <div className='mt-4 w-8/12 text-sm italic text-gray-700'>
            <p>{description}</p>
          </div>
        )}
      </div>
      {ImageCropComponent && (
        <ImageCropComponent
          open={displayImageCrop}
          close={() => {
            setLocalImage(null)
            setDisplayImageCrop(false)
          }}
          localImage={localImage}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}

UploadPicture.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  imageSrc: PropTypes.string,
  onSubmit: PropTypes.func,
  ImageCropComponent: PropTypes.any
}

UploadPicture.defaultProps = {
  title: '',
  subtitle: '',
  description: undefined,
  imageSrc: undefined,
  onSubmit: () => {},
  ImageCropComponent: null
}

export default UploadPicture
