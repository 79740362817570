import { generateHTML } from '@tiptap/html'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { extensions } from '../../constants/tiptap'
import { replaceMacros } from '../../helpers'
import './mail-template-preview.scss'

function extractSubstringBetween(originalString, fromString, toString) {
  const fromIndex = originalString.indexOf(fromString)
  const toIndex = originalString.indexOf(toString)

  if (fromIndex !== -1 && toIndex !== -1) {
    const substring = originalString.substring(
      fromIndex + fromString.length,
      toIndex
    )

    return substring
  } else {
    throw new Error(
      'extractSubstringBetween - from or to string not found in the original string!'
    )
  }
}

const MailTemplatePreview = ({
  data,
  system,
  imageUrl,
  language,
  useInvitationBlock,
  mailTemplateMacros,
  createClientMail
}) => {
  // regarding react.renderToString
  // useAivyContext must be used within a AppProvider
  // const { system } = useAivyContext()

  const __html = useMemo(() => {
    let scaffold = createClientMail
      ? system.scaffold[language]
      : `<div ${extractSubstringBetween(
          system.scaffold[language],
          '<body ',
          '</body>'
        )}</div>`

    if (useInvitationBlock) {
      const invitationBlock = system.invitationBlock[language]
      scaffold = scaffold.replaceAll('{{invitation_block}}', invitationBlock)
      scaffold = replaceMacros({ text: scaffold, macros: mailTemplateMacros })
    } else {
      scaffold = scaffold.replaceAll('{{invitation_block}}', '')
    }

    const content = replaceMacros({
      text: generateHTML(data, extensions),
      macros: mailTemplateMacros
    })

    const withContent = scaffold.replaceAll('{{content}}', content)
    const withImageUrl = withContent.replaceAll('{{image_url}}', imageUrl)
    const withInvitationLink = withImageUrl.replaceAll(
      '{{invitation_link}}',
      'https://example.com'
    )

    return withInvitationLink
  }, [
    data,
    mailTemplateMacros,
    system,
    language,
    imageUrl,
    useInvitationBlock,
    createClientMail
  ])

  return <div className='preview' dangerouslySetInnerHTML={{ __html }}></div>
}

MailTemplatePreview.propTypes = {
  data: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
  mailTemplateMacros: PropTypes.array.isRequired,
  imageUrl: PropTypes.string,
  language: PropTypes.string.isRequired,
  useInvitationBlock: PropTypes.bool.isRequired,
  createClientMail: PropTypes.bool
}

MailTemplatePreview.defaultProps = {
  createClientMail: false,
  imageUrl:
    'https://aivy-user-content-production.s3.eu-central-1.amazonaws.com/public/partner-logos/af2dabfc-f3ff-43a1-80cd-53ebf3da38d1.png'
}

export default MailTemplatePreview
