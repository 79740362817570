import { ChevronDownIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { useState } from 'react'
import trackEvents from '../../constants/track-events'
import { trackEvent } from '../../helpers/analytics'
import DeleteSpace from '../actions/delete-space'
import SendReminder from '../actions/send-reminder'
import SetSpaceState from '../actions/set-space-state'
import CustomerEffortScore from '../customer-effort-score'
import Dropdown from '../tailwind/dropdown'

const SpaceActionsDropdown = ({
  space,
  refetchSpace,
  career,
  spaceActions
}) => {
  const { t } = useTranslation()
  const [isCustomerEffortScoreVisible, setIsCustomerEffortScoreVisible] =
    useState(false)

  const navigate = useNavigate()

  const {
    actions: actionsFromHook,
    displaySendReminder,
    setDisplaySendReminder,
    displaySetSpaceState,
    setDisplaySetSpaceState,
    displayDeleteSpace,
    setDisplayDeleteSpace
  } = spaceActions

  const { SEND_REMINDER, SET_SPACE_STATE, DELETE_SPACE } =
    actionsFromHook.reduce((acc, curr) => {
      acc[curr.key] = curr
      return acc
    }, {})

  const actions = [
    space.via === 'EMAIL' && SEND_REMINDER,
    SET_SPACE_STATE,
    DELETE_SPACE
  ]
    .filter((item) => item)
    .map((action) => ({ ...action, Icon: action.icons.solid }))

  return (
    <>
      <Dropdown
        MenuButtonX={() => (
          <div className='flex rounded-full bg-blue-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'>
            {t('space_actions.button_title')}
            <ChevronDownIcon
              className='ml-1 h-5 w-5 text-white'
              aria-hidden='true'
            />
          </div>
        )}
        actions={actions}
      />
      <SetSpaceState
        spaces={[space]}
        updateHandler={refetchSpace}
        open={displaySetSpaceState}
        setOpen={setDisplaySetSpaceState}
        setIsCustomerEffortScoreVisible={setIsCustomerEffortScoreVisible}
      />
      <CustomerEffortScore
        open={isCustomerEffortScoreVisible}
        setOpen={setIsCustomerEffortScoreVisible}
        context='TALENT-STATE'
      />
      <SendReminder
        space={space}
        refetchSpace={refetchSpace}
        career={career}
        open={displaySendReminder}
        setOpen={setDisplaySendReminder}
      />
      <DeleteSpace
        spaces={[space]}
        open={displayDeleteSpace}
        setOpen={setDisplayDeleteSpace}
        updateHandler={() => {
          trackEvent(trackEvents.DELETE_TALENT)
          navigate('/talents')
        }}
      />
    </>
  )
}

SpaceActionsDropdown.propTypes = {
  space: PropTypes.object.isRequired,
  refetchSpace: PropTypes.func.isRequired,
  career: PropTypes.object,
  spaceActions: PropTypes.object.isRequired
}

SpaceActionsDropdown.defaultProps = {
  career: null
}

export default SpaceActionsDropdown
