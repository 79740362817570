import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { classNames } from '../../helpers'

const Paragraph = ({ className, children }) => {
  const { t } = useTranslation()

  return (
    <p className={classNames('text-sm text-gray-700', className)}>
      {t(children)}
    </p>
  )
}

Paragraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

Paragraph.defaultProps = {
  className: undefined
}

export default Paragraph
