import { CheckIcon } from '@heroicons/react/24/outline'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import PersonHeart from '../assets/images/illustrations/person-heart.png'
import { Seo } from '../components'
import Button from '../components/tailwind/Button'
import PageHeading from '../components/tailwind/PageHeading'
import Textarea from '../components/textarea'
import { usePartnerContext, useUserContext } from '../context'
import { mutation } from '../graphql'
const { version } = require('../../package.json')

const TRANSLATION_LOCATION = 'pages.support.'

const Success = () => {
  const { t } = useTranslation()

  return (
    <div className='xbg-pink-200 xborder-l-4 rounded-2xl bg-green-50 p-6 sm:flex'>
      <div className='hidden h-12 w-12 items-center justify-center rounded-full bg-green-100 sm:flex'>
        <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
      </div>
      <div className='sm:ml-4'>
        <h3 className='text-md font-medium leading-6 text-gray-900'>
          {t(TRANSLATION_LOCATION + 'message_sent_success_title')}
        </h3>
        <p className='text-sm text-gray-700'>
          {t(TRANSLATION_LOCATION + 'message_sent_success_description')}
        </p>
      </div>
    </div>
  )
}

const getName = (person) => {
  const firstname = person.firstname || person.invitation_firstname
  const lastname = person.lastname || person.invitation_lastname

  if (!firstname) return lastname
  if (!lastname) return firstname

  return `${firstname} ${lastname}`
}

const Support = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { active_space } = useUserContext()

  const person_email =
    active_space.person.email || active_space.person.invitation_email

  const [messageSent, setMessageSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: ''
    },
    onSubmit: async ({ message }) => {
      setIsLoading(true)

      try {
        await mutation({
          // no auth rules specified
          mutation: 'createForm',
          input: {
            partner_id: partner.id,
            data: JSON.stringify({
              EMAIL: person_email,
              properties: {
                content: `${message} | META: ${navigator?.userAgent} Dashboard-Version ${version}`,
                hs_pipeline: '0',
                hs_pipeline_stage: '1',
                subject: 'Neues Support-Ticket'
              }
            }),
            form_source: 'HUBSPOT_SUPPORT_TICKET'
          }
        })

        setMessageSent(true)
        formik.resetForm()
      } catch (err) {
        // do nothing
      } finally {
        setIsLoading(false)
      }
    },
    validationSchema: Yup.object({
      message: Yup.string().required(
        t(TRANSLATION_LOCATION + 'validation_required')
      )
    })
  })

  return (
    <>
      <Seo title={t('seo.titles.support')} />
      <PageHeading title={t('seo.titles.support')} />

      <div className='relative mt-16 max-w-5xl px-4 sm:px-16'>
        <h1 className='text-xl font-semibold text-gray-900'>
          {t(TRANSLATION_LOCATION + 'support_title')}
        </h1>
        <p className='mt-2 max-w-lg text-sm text-gray-700'>
          {t(TRANSLATION_LOCATION + 'support_description')}
        </p>
        <div className='w-full max-w-xl'>
          <div className='mt-8 max-w-lg'>
            <label className='block text-sm font-medium text-gray-900'>
              {t(TRANSLATION_LOCATION + 'name_label')}
            </label>
            <span className='text-sm text-gray-700'>
              {getName(active_space.person)}
            </span>
          </div>
          <div className='mt-4 max-w-lg'>
            <label className='block text-sm font-medium text-gray-900'>
              {t(TRANSLATION_LOCATION + 'email_label')}
            </label>
            <span className='text-sm text-gray-700'>{person_email}</span>
          </div>
          <div className='my-8 px-16'>
            <div className='h-px bg-gray-200' />
          </div>
          {messageSent ? (
            <Success />
          ) : (
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                {t(TRANSLATION_LOCATION + 'message_title')}
              </label>
              <div className='mt-2' />
              <Textarea
                id='message'
                formik={formik}
                placeholder={t(TRANSLATION_LOCATION + 'message_placeholder')}
                border
              />
              <div className='mt-8'>
                <Button.PrimaryXL
                  type='submit'
                  onClick={formik.handleSubmit}
                  text={t(TRANSLATION_LOCATION + 'submit_action')}
                  isLoading={isLoading}
                />
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            position: 'absolute',
            top: 192,
            right: 0
          }}
          className='hidden xl:block'
        >
          <img className='w-80' alt='Person Heart' src={PersonHeart} />
        </div>
      </div>
    </>
  )
}

export default Support
