import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { classNames } from '../../helpers'
import Button from '../button'
import LockedIcon from '../icons/locked-icon'
import Badge from '../tailwind/Badge'

const TRANSLATION_LOCATION = 'components.settings.settings_container.'

const CareerSettingsContainer = ({
  title,
  description,
  onSubmit,
  dirty,
  isLoading,
  actions,
  background,
  padding,
  withLockedIcon,
  withNewBadge,
  children
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='xl:grid xl:grid-cols-3 xl:gap-6'>
        <div className='xl:col-span-1'>
          <div className='px-4 sm:px-0'>
            <div className='flex flex-row items-center'>
              <h3 className='mr-2 text-lg font-medium leading-6 text-gray-900'>
                {title}
              </h3>
              {withNewBadge && (
                <Badge text={t('global.new')} color={'pink'} type={'basic'} />
              )}
              {!!withLockedIcon && (
                <div
                  className='cursor-pointer'
                  onClick={withLockedIcon.onClick}
                >
                  <LockedIcon
                    place={'left'}
                    text={t('tooltip.locked.redirect_talent_url')}
                  />
                </div>
              )}
            </div>
            <p className='mt-1 text-sm text-gray-700'>{description}</p>
          </div>
        </div>
        <div className='mt-5 xl:col-span-2 xl:mt-0'>
          <div className={classNames('relative sm:rounded-md', background)}>
            <div
              className={classNames(
                'space-y-6 sm:rounded-t-md',
                onSubmit ? '' : 'sm:rounded-b-md',
                padding
              )}
            >
              {children}
            </div>
            {actions && (
              <div
                className={classNames(
                  'flex justify-end gap-x-2',
                  'bg-gray-50 px-4 py-3 sm:rounded-b-md sm:px-6'
                )}
              >
                {actions}
              </div>
            )}
            {onSubmit && (
              <div className='bg-gray-100 px-4 py-3 text-right sm:rounded-b-md sm:px-6'>
                <Button
                  appearance='save'
                  text={t(TRANSLATION_LOCATION + 'on_submit_action')}
                  onClick={onSubmit}
                  disabled={!dirty}
                  isLoading={isLoading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

CareerSettingsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onSubmit: PropTypes.func,
  actions: PropTypes.node,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  background: PropTypes.string,
  padding: PropTypes.string,
  withLockedIcon: PropTypes.object,
  withNewBadge: PropTypes.bool,
  children: PropTypes.node.isRequired
}

CareerSettingsContainer.defaultProps = {
  description: null,
  onSubmit: null,
  actions: null,
  dirty: false,
  isLoading: false,
  background: 'bg-gray-50',
  padding: 'px-4 py-5 sm:p-6',
  withLockedIcon: null
}

export default CareerSettingsContainer
