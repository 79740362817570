/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

// import { getCurrencyString } from '../../helpers'
import { useState } from 'react'
import { JSONTree } from 'react-json-tree'
import { useAivyContext, usePaymentContext } from '../../context'
import { query } from '../../graphql'
import SEO from '../seo'
import Button from '../tailwind/Button'
import SettingsContainer from './settings-container'

const ActualPaymentPlan = () => {
  const { t } = useTranslation()
  const { adminInsights } = useAivyContext()

  const {
    career_count,
    is_trial,
    // price,
    seat_count,
    product_type,
    ats,
    redirectTalentUrl,
    crossComparison,
    attract,
    integrations,
    automations
  } = usePaymentContext()

  const currentPlan = usePaymentContext()

  const [isLoading, setIsLoading] = useState(false)

  const createBillingSession = async () => {
    setIsLoading(true)

    const stringData = await query({
      query: 'stripeControl',
      variables: {
        action: 'createBillingPortalSession'
      }
    })

    const parsedData = JSON.parse(stringData)
    const redirectUrl = parsedData.body.createBillingPortalSession.res.url
    setIsLoading(false)
    window.location.href = redirectUrl
  }

  return (
    <>
      <SettingsContainer
        title={t('components.settings.payment_plan.title')}
        description={t('components.settings.payment_plan.description')}
      >
        <SEO title={t('seo.titles.settings_planbilling')} />
        {adminInsights && <JSONTree data={currentPlan} />}
        <div className='flex flex-row items-center justify-between'>
          <div className='flex gap-x-1'>
            <legend className='text-md text-gray-900'>
              {`${t('components.settings.payment_plan.your_actual_plan')}:`}
            </legend>
            <span className='text-md font-medium text-gray-900'>
              {is_trial
                ? `${t('components.settings.payment_plan.trial')}`
                : `${t('components.settings.payment_plan.payed')}`}
            </span>
          </div>
          {(product_type === 'MAIN' || product_type === 'TBC') && (
            <Button.Text
              text='Zahlung verwalten'
              onClick={createBillingSession}
              isLoading={isLoading}
            />
          )}
          {/* {price && (
          <p className='text-md font-medium text-primaryBlue'>
            {`${getCurrencyString(price)} / ${
              payment_period === 'y'
                ? t('components.settings.payment_plan.year')
                : payment_period === 'm'
                  ? t('components.settings.payment_plan.month')
                  : ''
            }`}
          </p>
        )} */}
        </div>
        <ul role='list' className=''>
          {[
            t('components.settings.payment_plan.vacancies_per_year', {
              career_count
            }),
            t('components.settings.payment_plan.testing_flatrate', {
              career_count: career_count * 20
            }),
            t('components.settings.payment_plan.teammember_inclusive', {
              seat_count
            }),
            ats && t('components.settings.payment_plan.ats_inclusive'),
            attract && t('components.settings.payment_plan.attract'),
            redirectTalentUrl &&
              t('components.settings.payment_plan.forward_talents'),
            crossComparison &&
              t('components.settings.payment_plan.cross_comparison'),
            integrations && t('components.settings.payment_plan.integrations'),
            automations && t('components.settings.payment_plan.automations')
          ]
            .filter((listItem) => listItem)
            .map((text, index) => (
              <li key={index} className='flex items-center py-1.5'>
                <CheckCircleIcon
                  className='ml-2 h-6 w-6 flex-shrink-0 text-blue-500'
                  aria-hidden='true'
                />
                <span className='ml-2 text-sm text-gray-700'>{text}</span>
              </li>
            ))}
        </ul>
      </SettingsContainer>
      {/* {products && (
        <div className='w-full mt-10'>
          <ul
            role='list'
            className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'
          >
            {products.map((product, index) => (
              <li
                key={index}
                className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow'
              >
                <div className='flex w-full items-center justify-between space-x-6 p-6'>
                  <div className='flex-1 truncate'>
                    <div className='flex items-center space-x-3'>
                      <h3 className='truncate text-sm font-medium text-gray-900'>
                        {product.name}
                      </h3>
                    </div>
                    <p className='mt-1 truncate text-sm text-gray-500'>
                      {product.unit_amount_decimal}
                    </p>
                  </div>
                </div>
                <div>
                  <div className='-mt-px flex divide-x divide-gray-200'>
                    {product.prices.map((price, index) => (
                      <div key={index} className='-ml-px flex w-0 flex-1'>
                        {isCreatingPaymentSession === price.id ? (
                          <div className='relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm text-gray-700'>
                            pending ...
                          </div>
                        ) : (
                          <button
                            onClick={() => {
                              createPaymentSession({
                                product_id: product.id,
                                price_id: price.id
                              })
                            }}
                            className='relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900'
                          >
                            {price.recurring.interval}
                            <span className='ml-1 italic text-sm font-normal'>
                              {price.unit_amount_decimal}
                            </span>
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )} */}
    </>
  )
}

export default ActualPaymentPlan
