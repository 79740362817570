import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { Fragment, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { classNames } from '../helpers'

const Markdown = ({ children }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ children }) => (
          <p className='block text-2xl font-light tracking-tight text-gray-900'>
            {children}
          </p>
        ),
        strong: ({ children }) => (
          <span className='font-medium'>{children}</span>
        )
      }}
    >
      {children}
    </ReactMarkdown>
  )
}

Markdown.propTypes = { children: PropTypes.node.isRequired }

const SelectMenuTypeform = ({
  id,
  label,
  description,
  options,
  onChange,
  defaultValue
}) => {
  const [selectedKey, setSelectedKey] = useState(defaultValue)

  const selectedValue = useMemo(() => {
    const selected = options.find(([key]) => key === selectedKey)

    return {
      value: selected[1],
      subvalue: selected[2]
    }
  }, [selectedKey, options])

  const handleChange = (value) => setSelectedKey(value) || onChange(value)

  return (
    <Listbox value={selectedKey} onChange={(value) => handleChange(value)}>
      <Listbox.Label className='block'>
        <Markdown>{label}</Markdown>
      </Listbox.Label>
      <div className='relative mt-2'>
        <Listbox.Button className='relative flex w-full cursor-pointer items-center justify-between text-sm'>
          <span className='block py-2 text-3xl tracking-tight text-gray-900'>
            {selectedValue.value}
          </span>
          <ChevronUpDownIcon
            className='h-8 w-8 text-gray-900'
            aria-hidden='true'
          />
        </Listbox.Button>
        <div className='h-0.5 w-full bg-gray-900' />
        <Transition
          as={Fragment}
          leave='transition ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options
            id={id}
            className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5'
          >
            {options.map((option, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  classNames(
                    active ? 'bg-blue-600 text-white' : 'text-gray-900',
                    'relative cursor-pointer select-none py-2 pl-3 pr-9'
                  )
                }
                value={option[0]}
              >
                {({ selected, active }) => {
                  let value = option[1]
                  if (selected) value = value.replaceAll('**', '')

                  return (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-bold' : 'font-normal',
                          'flex items-center'
                        )}
                      >
                        <ReactMarkdown>{value}</ReactMarkdown>
                        {option[2] && (
                          <span
                            className={classNames(
                              'ml-1.5 text-sm',
                              active ? 'text-white' : 'text-gray-700'
                            )}
                          >
                            {option[2]}
                          </span>
                        )}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      )}
                    </>
                  )
                }}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
      {description && (
        <p className='mt-2 text-xs italic text-gray-700'>{description}</p>
      )}
    </Listbox>
  )
}

SelectMenuTypeform.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  options: PropTypes.array.isRequired, // [ key, value ]
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

SelectMenuTypeform.defaultProps = { description: null }

export default SelectMenuTypeform
