import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import trackEvents from '../constants/track-events'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../context'
import { query } from '../graphql'
import { trackEvent } from '../helpers/analytics'

export const useSampleData = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { success } = useNotificationContext()
  const { language } = useLanguageContext()
  const {
    cognitoUser: { username }
  } = useUserContext()

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClient()

  const refetchCareers = () => {
    return queryClient.refetchQueries({
      queryKey: ['careers', partner.id],
      type: 'active'
    })
  }

  async function deleteSampleData({ onSuccess }) {
    setIsLoading(true)
    trackEvent(trackEvents.DELETE_DEMO_DATA)

    query({
      query: 'userControl',
      variables: { action: 'deleteSampleData', groupname: partner.id }
    })
      .then(() => {
        const sample_id = 'sample_' + partner.id

        queryClient.removeQueries({
          queryKey: ['talents', partner.id, username]
        })
        queryClient.removeQueries({ queryKey: ['create-career', sample_id] })
        queryClient.removeQueries({ queryKey: ['career-analyses', sample_id] })
        queryClient.removeQueries({ queryKey: ['career', sample_id] })
        refetchCareers().then(() => setIsLoading(false))

        onSuccess && onSuccess()
        success(t('pages.careers.delete_sample_data_success'))
      })
      .catch(() => setIsLoading(false))
  }

  async function createSampleData({ onSuccess }) {
    setIsLoading(true)
    trackEvent(trackEvents.CREATE_DEMO_DATA)

    return query({
      query: 'userControl',
      variables: { action: 'createSampleData', groupname: partner.id, language }
    })
      .then(() => {
        refetchCareers()

        onSuccess && onSuccess()
        success(t('pages.careers.create_sample_data_success'))
      })
      .catch(() => setIsLoading(false))
  }

  return { isLoading, createSampleData, deleteSampleData }
}
