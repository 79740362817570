import { CloudArrowDownIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { QRCodeCanvas } from 'qrcode.react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BUILD_ENV } from '../../helpers'
import SEO from '../seo'
import Button from '../tailwind/Button'
import CreateOrUpdateFlex from './flex/create-or-update-flex'
import FeatureSection from './flex/feature-section'
import InviteLink from './flex/invite-link'

const Header = ({ career }) => {
  const { t } = useTranslation()

  return (
    <div className='md:flex md:items-center'>
      <div className='md:flex-auto'>
        <h1 className='text-xl font-semibold text-gray-900'>
          {t('career.flex.title')}
        </h1>
        <p className='mt-2 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
          {t('career.flex.description', { career_title: career.title })}
        </p>
      </div>
    </div>
  )
}

Header.propTypes = { career: PropTypes.object.isRequired }

const Divider = () => {
  return <div className='my-8 h-px w-full max-w-4xl bg-gray-200' />
}

const Flex = ({ career, updateHandler }) => {
  const { t } = useTranslation()

  const components = JSON.parse(career.app_settings?.components || '{}')

  const [openCreateFlexModal, setOpenCreateFlexModal] = useState(false)
  const [openUpdateFlexModal, setOpenUpdateFlexModal] = useState(false)

  const getPbcLink = () => {
    const DEVELOP_URL = new URL('https://develop.pbc.aivy.app/invitation/')
    const STAGING_URL = new URL('https://staging.pbc.aivy.app/invitation')
    const PRODUCTION_URL = new URL('https://pbc.aivy.app/invitation/')

    const param = {
      name: 'career',
      value: career.id
    }

    DEVELOP_URL.searchParams.set(param.name, param.value)
    STAGING_URL.searchParams.set(param.name, param.value)
    PRODUCTION_URL.searchParams.set(param.name, param.value)

    switch (BUILD_ENV) {
      case 'develop':
        return DEVELOP_URL.toString()
      case 'staging':
        return STAGING_URL.toString()
      default:
        return PRODUCTION_URL.toString()
    }
  }

  const downloadQRCodeCanvas = () => {
    const a = document.createElement('a')
    const qrcode = document.getElementById('qr-code-flex')

    a.download = `${career.id}.png`
    a.href = qrcode.toDataURL()
    a.click()
  }

  return (
    <div className='mb-16'>
      <SEO title={t('seo.titles.career_pbc_settings')} />
      {!components.customLandingPageFields && (
        <>
          <div className='h-6' />
          <Header career={career} />
          <div className='h-6' />
          <FeatureSection
            action={
              <Button.PrimaryXL
                text={t('career.flex.create_flex.create_action')}
                onClick={() => setOpenCreateFlexModal(true)}
              />
            }
          />
          <CreateOrUpdateFlex
            open={openCreateFlexModal}
            setOpen={setOpenCreateFlexModal}
            career={career}
            updateHandler={({ input, onSuccess, onError }) => {
              updateHandler({ input, onSuccess, onError })
            }}
            create
          />
        </>
      )}
      {components.customLandingPageFields && (
        <>
          <div className='h-6' />
          <Header career={career} />
          <div className='mt-4 max-w-3xl'>
            <Button.SecondaryBase
              text={t('career.flex.update_action')}
              onClick={() => setOpenUpdateFlexModal(true)}
            />
          </div>
          <Divider />
          <div>
            <h1 className='text-lg font-semibold text-gray-900'>
              {t('career.flex.feature_section.link_name')}
            </h1>
            <p className='mt-1 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
              {t('career.flex.feature_section.link_description')}
            </p>
            <div className='mt-3 max-w-3xl'>
              <InviteLink career_id={career.id} />
            </div>
          </div>
          <Divider />
          <div className='pb-16'>
            <h1 className='text-lg font-semibold text-gray-900'>
              {t('career.flex.feature_section.qrcode_name')}
            </h1>
            <p className='mt-1 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
              {t('career.flex.feature_section.qrcode_description')}
            </p>
            <div
              className='mt-3 flex rounded bg-gray-50 p-6'
              style={{ height: 'auto', width: 160 }}
            >
              <QRCodeCanvas
                id='qr-code-flex'
                size={1028}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={getPbcLink()}
              />
            </div>
            <div
              style={{ maxWidth: 160 }}
              onClick={downloadQRCodeCanvas}
              className='group mt-2 flex cursor-pointer items-center justify-center rounded-md bg-gray-50 px-3 py-2 hover:bg-blue-600'
            >
              <CloudArrowDownIcon className='h-5 w-5 text-gray-500 group-hover:text-white' />
              <span className='ml-1 text-xs font-semibold text-gray-700 group-hover:text-white'>
                {t('career.flex.qr_code_download_action')}
              </span>
            </div>
          </div>
          <CreateOrUpdateFlex
            open={openUpdateFlexModal}
            setOpen={setOpenUpdateFlexModal}
            career={career}
            updateHandler={({ input, onSuccess, onError }) => {
              updateHandler({ input, onSuccess, onError })
            }}
            update
          />
        </>
      )}
    </div>
  )
}

Flex.propTypes = {
  career: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired
}

export default Flex
