import { format } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { useTranslation } from 'react-i18next'
import { Seo } from '.'
import { release_notes } from '../assets/markdown/release-notes'
import trackEvents from '../constants/track-events'
import { useLanguageContext, usePaymentContext } from '../context'
import { trackEvent } from '../helpers/analytics'
import { localStorage } from '../helpers/local-storage'
import { useWindowDimensions } from '../hooks/use-window-dimensions'
import Container from './tailwind/Container'
import Modal from './tailwind/Modal'
import PageHeading from './tailwind/PageHeading'

const FOURTEEN_DAYS = 24 * 60 * 60 * 1000 * 14
const STORAGE_KEY = 'release-notes-read'
const locale = { de, en: enUS }

const DEBUG = false // SET TO TRUE FOR DISPLAYING RELEASE NOTE

if (DEBUG) {
  localStorage.removeItem(STORAGE_KEY)
}

const ReleaseNotes = ({ startup, asPopup, note, callback }) => {
  const { t } = useTranslation()
  const { is_trial } = usePaymentContext()
  const { language } = useLanguageContext()
  const { height: windowHeight } = useWindowDimensions()

  const [open, setOpen] = useState(false)

  const releaseNotes = useMemo(() => {
    if (startup && is_trial) return []

    if (startup) {
      // get all release notes with
      // not read yet and
      // created at between now and fourteen days
      // return release_notes // For Debug
      const releaseNotesRead =
        JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}') || {}

      return release_notes.filter(
        ({ key, created_at }) =>
          !releaseNotesRead[key] &&
          Date.now() - FOURTEEN_DAYS < Date.parse(created_at)
      )
    }

    if (note) {
      return [release_notes.find(({ key }) => key === note)]
    }

    return release_notes
  }, [startup, is_trial, note])

  useEffect(() => {
    if (startup && releaseNotes.length) {
      setTimeout(() => setOpen(true), 1000 * 0.5)
    }

    if (note) {
      setOpen(true)
    }
  }, [startup, note, releaseNotes])

  useEffect(() => {
    if (open === false) return

    trackEvent(trackEvents.DISPLAY_RELEASE_NOTES)
  }, [open])

  const handleClose = () => {
    if (startup) {
      const releaseNotesRead =
        JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}') || {}

      releaseNotes.forEach(({ key }) => (releaseNotesRead[key] = true))
      localStorage.setItem(STORAGE_KEY, JSON.stringify(releaseNotesRead))
    }

    callback && callback()
    setOpen(false)
  }

  const getReleaseNotesComponent = () => {
    return (
      <Container withVerticalPadding>
        {[...releaseNotes]
          .reverse()
          .map(({ created_at, notes }, index, array) => (
            <div
              key={index}
              className='mb-8 rounded-lg border border-gray-100 bg-gray-50 px-6 py-8'
            >
              <span className='block text-lg font-semibold text-primaryBlue'>
                {format(new Date(created_at), 'dd. MMMM yyyy', {
                  locale: locale[language]
                })}
              </span>
              <div className='mt-2'>
                <ReactMarkdown
                  children={notes} // eslint-disable-line
                  components={{
                    h1: ({ children }) => (
                      <h1 className='block text-3xl font-bold leading-8 text-gray-900 sm:text-4xl'>
                        {children}
                      </h1>
                    ),
                    h2: ({ children }) => (
                      <h2 className='mb-4 mt-7 block text-2xl font-bold leading-8 text-gray-900 sm:text-3xl'>
                        {children}
                      </h2>
                    ),
                    h3: ({ children }) => (
                      <h3 className='text-1xl mb-4 mt-7 block font-bold leading-8 text-gray-900 sm:text-2xl'>
                        {children}
                      </h3>
                    ),
                    p: ({ children }) => (
                      <p className='text-md block text-gray-700'>{children}</p>
                    ),
                    img: ({ src }) => (
                      <img
                        className='mx-auto block py-4 sm:max-w-md'
                        src={src}
                      />
                    ),
                    a: ({ href, children }) => (
                      <a
                        href={href}
                        className='text-md cursor-pointer font-medium text-blue-700 hover:text-blue-600'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {children}
                      </a>
                    )
                  }}
                />
              </div>
            </div>
          ))}
      </Container>
    )
  }

  if (asPopup) {
    return (
      <Modal open={open} setOpen={handleClose} size='4xl'>
        <div
          className='overflow-scroll'
          style={{ maxHeight: (windowHeight * 80) / 100 }}
        >
          {[...releaseNotes]
            .reverse()
            .map(({ created_at, notes }, index, array) => (
              <div key={index}>
                <span className='block text-lg font-semibold text-primaryBlue'>
                  {format(new Date(created_at), 'dd. MMMM yyyy', {
                    locale: locale[language]
                  })}
                </span>
                <div className='mt-2'>
                  <ReactMarkdown
                    children={notes} // eslint-disable-line
                    components={{
                      h1: ({ children }) => (
                        <h1 className='block text-2xl font-bold leading-8 text-gray-900 sm:text-4xl'>
                          {children}
                        </h1>
                      ),
                      h2: ({ children }) => (
                        <h2 className='text-1xl mb-4 mt-7 block font-bold leading-8 text-gray-900 sm:text-3xl'>
                          {children}
                        </h2>
                      ),
                      h3: ({ children }) => (
                        <h3 className='mb-4 mt-7 block text-xl font-bold leading-8 text-gray-900 sm:text-2xl'>
                          {children}
                        </h3>
                      ),
                      p: ({ children }) => (
                        <p className='block text-gray-700'>{children}</p>
                      ),
                      img: ({ src }) => (
                        <img
                          className='mx-auto block py-4 sm:max-w-md'
                          src={src}
                        />
                      ),
                      a: ({ href, children }) => (
                        <a
                          href={href}
                          className='cursor-pointer font-medium text-blue-700 hover:text-blue-600'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {children}
                        </a>
                      )
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </Modal>
    )
  }

  return (
    <>
      <Seo title={t('seo.titles.release_notes')} />
      <PageHeading title={t('seo.titles.release_notes')} />
      <div className='mx-auto max-w-4xl'>{getReleaseNotesComponent()}</div>
    </>
  )
}

ReleaseNotes.propTypes = {
  startup: PropTypes.bool,
  asPopup: PropTypes.bool,
  note: PropTypes.string,
  callback: PropTypes.func
}

ReleaseNotes.defaultProps = {
  startup: false,
  asPopup: false,
  note: undefined,
  callback: undefined
}

export default ReleaseNotes
