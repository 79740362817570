import { Description, Field, Label, Switch } from '@headlessui/react'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const Toggle = ({
  enabled,
  setEnabled,
  label,
  description,
  appearance,
  locked
}) => {
  if (appearance === 'with-right-label') {
    return (
      <Field as='div' className='flex items-center'>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(
            enabled ? 'bg-blue-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
          )}
        >
          <span
            aria-hidden='true'
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
        <Label as='span' className='ml-3'>
          <span className='text-sm font-medium text-gray-900'>{label}</span>
        </Label>
      </Field>
    )
  }

  if (appearance === 'with-left-label') {
    return (
      <Field as='div' className='flex items-center'>
        <Label as='span' className='mr-3'>
          <span className='text-sm font-medium text-gray-900'>{label}</span>
        </Label>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(
            enabled ? 'bg-blue-700' : 'bg-blue-600',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
          )}
        >
          <span
            aria-hidden='true'
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-gray-50 shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      </Field>
    )
  }

  if (appearance === 'with-left-label-and-description') {
    return (
      <Field as='div' className='flex items-center justify-between'>
        <span className='flex flex-grow flex-col'>
          <Label
            as='span'
            className='text-sm font-medium leading-6 text-gray-900'
            passive
          >
            {label}
          </Label>
          <Description as='span' className='text-sm text-gray-500'>
            {description}
          </Description>
        </span>
        <div className='flex cursor-pointer items-center'>
          {locked && (
            <LockClosedIcon className='mr-1 h-5 w-5 text-aivyOrange' />
          )}
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
              enabled ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
            )}
          >
            <span
              aria-hidden='true'
              className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </div>
      </Field>
    )
  }

  return null
}

Toggle.propTypes = {
  enabled: PropTypes.bool.isRequired,
  setEnabled: PropTypes.func.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  appearance: PropTypes.string,
  locked: PropTypes.bool
}

Toggle.defaultProps = {
  label: 'Lorem ipsum',
  description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
  appearance: 'with-right-label',
  locked: false
}

export default Toggle
