import { isNumber } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useAivyContext,
  useLanguageContext,
  usePartnerContext,
  useProductTourContext
} from '../../context'
import { mutation } from '../../graphql'
import { useCreateCareerNavigation } from '../../hooks/use-create-career-navigation'
import { useMount } from '../../hooks/use-mount'
import { useSetState } from '../../hooks/use-set-state'
import Button from '../tailwind/Button'
import Toggle from '../tailwind/toggle'
import SlideOverDimensionDescription from '../team-analyse/SlideOverDimensionDescription'
import CulturalFitQuestions from './cultural-fit/cultural-fit-questions'
import HerosJourneyMatrix from './cultural-fit/heros-journey-matrix'

const HEROS_JOURNEY_score_coopcomp = 'HEROS_JOURNEY_score_coopcomp'
const HEROS_JOURNEY_score_orderchaos = 'HEROS_JOURNEY_score_orderchaos'

const getPointPosition = ({
  HEROS_JOURNEY_score_coopcomp,
  HEROS_JOURNEY_score_orderchaos
}) => {
  if (
    HEROS_JOURNEY_score_coopcomp === 0 &&
    HEROS_JOURNEY_score_orderchaos === 0
  ) {
    return { left: '20%', top: '72%' }
  }

  if (
    HEROS_JOURNEY_score_coopcomp === 0 &&
    HEROS_JOURNEY_score_orderchaos === 100
  ) {
    return { left: '20%', top: '20%' }
  }

  if (
    HEROS_JOURNEY_score_coopcomp === 100 &&
    HEROS_JOURNEY_score_orderchaos === 0
  ) {
    return { left: '72%', top: '72%' }
  }

  if (
    HEROS_JOURNEY_score_coopcomp === 100 &&
    HEROS_JOURNEY_score_orderchaos === 100
  ) {
    return { left: '72%', top: '20%' }
  }
}

const hasScoreKeys = (cultural_fit) => {
  return (
    isNumber(cultural_fit.HEROS_JOURNEY_score_coopcomp) &&
    isNumber(cultural_fit.HEROS_JOURNEY_score_orderchaos)
  )
}

const CulturalFit = ({ career, careerMutation }) => {
  const { t } = useTranslation()
  const { partner, refetchPartner } = usePartnerContext()
  const { language } = useLanguageContext()

  const {
    system: { dimensions_pdf_reports }
  } = useAivyContext()

  const meta = JSON.parse(career.customer_dashboard_meta || '{}')

  const cultural_fit_dimensions = {
    HEROS_JOURNEY_score_coopcomp: {
      key: HEROS_JOURNEY_score_coopcomp,
      ...dimensions_pdf_reports[HEROS_JOURNEY_score_coopcomp],
      leftImg: require('../../assets/images/teamwork.png'),
      rightImg: require('../../assets/images/independence.png'),
      leftImgAlt: require('../../assets/images/teamwork-alt.png'),
      rightImgAlt: require('../../assets/images/independence-alt.png'),
      leftImgBlue: require('../../assets/images/teamwork-blue.png'),
      rightImgBlue: require('../../assets/images/independence-blue.png')
    },
    HEROS_JOURNEY_score_orderchaos: {
      key: HEROS_JOURNEY_score_orderchaos,
      ...dimensions_pdf_reports[HEROS_JOURNEY_score_orderchaos],
      leftImg: require('../../assets/images/stability.png'),
      rightImg: require('../../assets/images/flexibility.png'),
      leftImgAlt: require('../../assets/images/stability-alt.png'),
      rightImgAlt: require('../../assets/images/flexibility-alt.png'),
      leftImgBlue: require('../../assets/images/stability-blue.png'),
      rightImgBlue: require('../../assets/images/flexibility-blue.png')
    }
  }

  const { scaleEndpoints: scaleEndpointsCoopcomp } =
    cultural_fit_dimensions.HEROS_JOURNEY_score_coopcomp

  const { scaleEndpoints: scaleEndpointsOrderchaos } =
    cultural_fit_dimensions.HEROS_JOURNEY_score_orderchaos

  const cultural_fit_partner = JSON.parse(
    partner.app_settings?.cultural_fit || '{}'
  )

  const cultural_fit_career = JSON.parse(
    career.app_settings?.cultural_fit || '{}'
  )

  const partner_has_culture_profile = hasScoreKeys(cultural_fit_partner)

  const [isLoadingNext, setIsLoadingNext] = useState(false)
  const [openDimensions, setOpenDimensions] = useState(false)

  const [usePartnerProfile, setUsePartnerProfile] = useState(
    partner_has_culture_profile && (meta.partner_profile_used ?? true)
  )

  const [cultureFitState, setCultureFitState] = useSetState(
    usePartnerProfile ? cultural_fit_partner : cultural_fit_career
  )

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const { isLoadingBack, handleBackNavigation, handleNextNavigation } =
    useCreateCareerNavigation({ career, careerMutation })

  const next = async () => {
    setIsLoadingNext(true)

    if (!partner_has_culture_profile) {
      // update partner
      await mutation({
        mutation: 'updatePartner',
        input: {
          id: partner.id,
          app_settings: {
            ...(partner.app_settings || {}),
            cultural_fit: JSON.stringify({ ...cultureFitState })
          }
        }
      })

      // partner gets refreshed inside onSuccess
    }

    const onSuccess = () => {
      refetchPartner()
      setIsLoadingNext(false)
    }

    handleNextNavigation({
      input: {
        app_settings: {
          ...(career.app_settings || {}),
          cultural_fit: JSON.stringify({ ...cultureFitState })
        },
        customer_dashboard_meta: JSON.stringify({
          ...meta,
          // should be true if is set for the first time
          partner_profile_used: partner_has_culture_profile
            ? usePartnerProfile
            : true
        })
      },
      onSuccess
    })
  }

  return (
    <div className='mx-auto max-w-4xl'>
      <span className='text-4xl font-extrabold tracking-tight text-blue-600'>
        {t('create_career.cultural_fit.title')}
      </span>
      <p className='mt-4 w-full text-sm text-gray-900 md:w-9/12'>
        {t('create_career.cultural_fit.description')}
      </p>

      <div className='mt-6'>
        {partner_has_culture_profile ? (
          <>
            <Toggle
              enabled={usePartnerProfile}
              setEnabled={setUsePartnerProfile}
              appearance='with-left-label-and-description'
              label={t(
                'create_career.cultural_fit.use_partner_profile_toggle_label'
              )}
              description={t(
                'create_career.cultural_fit.use_partner_profile_toggle_description'
              )}
            />
            {usePartnerProfile ? (
              <div className='mt-6 table rounded-lg border border-slate-100 bg-slate-50 px-8 py-4'>
                <span className='text-sm font-semibold'>
                  {t('create_career.cultural_fit.your_profile.title')}
                </span>
                <HerosJourneyMatrix
                  className='my-12 h-64 w-80'
                  labels={{
                    x: {
                      minus: scaleEndpointsCoopcomp.left.title[language],
                      plus: scaleEndpointsCoopcomp.right.title[language]
                    },
                    y: {
                      minus: scaleEndpointsOrderchaos.left.title[language],
                      plus: scaleEndpointsOrderchaos.right.title[language]
                    }
                  }}
                  pointPosition={getPointPosition(cultural_fit_partner)}
                />
              </div>
            ) : (
              <div className='mt-10'>
                <div className='relative mb-10'>
                  <div
                    aria-hidden='true'
                    className='absolute inset-0 flex items-center'
                  >
                    <div className='w-full border-t border-gray-300' />
                  </div>
                  <div className='relative flex justify-center'>
                    <span className='bg-white px-2 text-sm text-gray-500'>
                      {t('create_career.cultural_fit.cultural_fit_or', {
                        career_title: career.title
                      })}
                    </span>
                  </div>
                </div>

                <CulturalFitQuestions
                  state={cultureFitState}
                  setState={setCultureFitState}
                  culturalFitDimensions={cultural_fit_dimensions}
                  setOpenDimensions={setOpenDimensions}
                />
              </div>
            )}
          </>
        ) : (
          <CulturalFitQuestions
            state={cultureFitState}
            setState={setCultureFitState}
            culturalFitDimensions={cultural_fit_dimensions}
            setOpenDimensions={setOpenDimensions}
          />
        )}
      </div>

      <div className='mb-32 mt-12 flex justify-end gap-x-2'>
        <Button.SecondaryXL
          onClick={() => handleBackNavigation({})}
          isLoading={isLoadingBack}
          text={t('create_career.back_action')}
        />
        <Button.PrimaryXL
          text={t('create_career.next_action')}
          isLoading={isLoadingNext}
          disabled={
            usePartnerProfile === false && !hasScoreKeys(cultureFitState)
          }
          onClick={next}
          className='bg-green-600 hover:bg-green-500'
        />
      </div>
      <SlideOverDimensionDescription
        open={openDimensions}
        setOpen={setOpenDimensions}
      />
    </div>
  )
}

CulturalFit.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired
}

export default CulturalFit
