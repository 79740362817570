import { Analytics } from 'aws-amplify'
import { Buffer } from 'buffer'
import { initializeApp } from 'firebase/app'
import { isError } from 'lodash'
import Resizer from 'react-image-file-resizer'

import { DE } from '../constants/languages'
import { mutation } from '../graphql'

const firebaseConfig = {
  apiKey: 'AIzaSyCZ79Gysnnmzylz6SH06iyCvJbEHm1eMXA',
  authDomain: 'aivy-dashboard.firebaseapp.com',
  projectId: 'aivy-dashboard',
  storageBucket: 'aivy-dashboard.appspot.com',
  messagingSenderId: '435564825954',
  appId: '1:435564825954:web:62ee88a09dc1b413e0f03b',
  measurementId: 'G-CC8BYBGHMK'
}

export const S3_AIVY_LOGO =
  'https://aivy-assets.s3.eu-central-1.amazonaws.com/logo/aivylogo.png'

export const BUILD_ENV = process.env.REACT_APP_USER_BRANCH || 'develop'
export const NODE_ENV = process.env.NODE_ENV

const GRAPHQL_ERROR_FLAG = 'graphql_error'

export const HIGH_FIT_RANGE = (score) => score >= 71 && score <= 100
export const MIDDLE_FIT_RANGE = (score) => score >= 41 && score < 71
const LOW_FIT_RANGE = (score) => score < 41

export const LOCALSTORAGE_ITEMS = {
  CREATE_CAREER_CONFIGURATION_DISABLE_INTRO:
    'CREATE_CAREER_CONFIGURATION_INTRO',
  HIDE_TEAM_ANALYSE_INTRO: 'HIDE_TEAM_ANALYSE_INTRO'
}

export const EMAIL_REGEX =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

export const RESTIFY_DEV_URL =
  'https://ip28t9adxa.execute-api.eu-central-1.amazonaws.com'
export const RESTIFY_STAGING_URL =
  'https://6hsjl256ka.execute-api.eu-central-1.amazonaws.com'
export const RESTIFY_PROD_URL = 'https://restify.aivy.app'

const DEVELOP_OS_URL = 'https://develop.os.aivy.app'
const STAGING_OS_URL = 'https://staging.os.aivy.app'
const PRODUCTION_OS_URL = 'https://os.aivy.app'

export const getAivyOsCustomerUrl = ({ partner_id }) => {
  const path = `/customer/${partner_id}`

  switch (BUILD_ENV) {
    case 'develop':
      return DEVELOP_OS_URL + path
    case 'staging':
      return STAGING_OS_URL + path
    default:
      return PRODUCTION_OS_URL + path
  }
}

export const getCurrencyString = (price, locale = DE, currency = 'EUR') => {
  if (price === undefined || price === null) return '-'
  if (typeof price === 'string' || price instanceof String) {
    price = parseInt(price)
  }

  const res = (price / 100).toLocaleString(locale, {
    style: 'currency',
    currency
  })
  return res
}

export function prepareSource(source) {
  const umlautMap = {
    Ü: 'UE',
    Ä: 'AE',
    Ö: 'OE',
    ü: 'ue',
    ä: 'ae',
    ö: 'oe',
    ß: 'ss'
  }

  const replaceUmlauts = source
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)]
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1)
    })
    .replace(
      new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'),
      (a) => umlautMap[a]
    )

  const base64Source = Buffer.from(replaceUmlauts).toString('base64')
  const finalSource = `=?UTF-8?B?${base64Source}?= <notification@aivy.app>`

  return finalSource
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function hexToRGB(hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const r = parseInt(result[1], 16)
  const g = parseInt(result[2], 16)
  const b = parseInt(result[3], 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity || 1})`
}

// https://stackoverflow.com/a/2117523
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8

    return v.toString(16)
  })
}

export function uuidSmall() {
  return 'xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8

    return v.toString(16)
  })
}

export function isSet(param) {
  return param !== null && param !== undefined
}

const channels = {
  'bugsnag_customer-dashboard':
    'https://hooks.slack.com/services/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',
  engineering:
    'https://hooks.slack.com/services/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',
  vertrieb:
    'https://hooks.slack.com/services/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',
  'kunden-controlling':
    'https://hooks.slack.com/services/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'
}

export async function sendSlackMessage(channel, message, throwError) {
  // because of cors policy
  if (NODE_ENV === 'development') {
    return console.log('send-slack-message:', message) // eslint-disable-line
  }

  try {
    await window.fetch(channels[channel], {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ text: message })
    })
  } catch (err) {
    notifyBugsnag(err)

    if (throwError) {
      throw err
    }
  }
}

export const convertGraphqlError = (error, others) => {
  const { errors } = error
  const { query, mutation, variables, input } = others || {}

  if (
    // graphql error
    Array.isArray(errors)
  ) {
    const { message, path } = errors[0]
    const error_path = path && path[0] !== query && path[0]
    const additionalInfo =
      (variables && `variables: ${JSON.stringify(variables)}`) ||
      (input && `input: ${JSON.stringify(input)}`)

    return new Error(
      [
        query || mutation,
        message,
        error_path,
        additionalInfo,
        GRAPHQL_ERROR_FLAG
      ]
        .filter((item) => item)
        .join(' / ')
    )
  }

  return new Error(`convertGraphqlError:  ${JSON.stringify(error)} (unknown)`)
}

const isGraphqlError = ({ message }) => {
  return message.toLowerCase().includes(GRAPHQL_ERROR_FLAG)
}

export const createErrorLog = async ({ event: bugsnag_event }) => {
  const { partner_id, user_id } = bugsnag_event._metadata.meta

  const message = bugsnag_event.errors[0].errorMessage
  const event_name = isGraphqlError({ message })
    ? 'GRAPHQL_ERROR'
    : 'JAVASCRIPT_ERROR'

  try {
    await mutation({
      mutation: 'createLog',
      input: {
        type: 'ERROR',
        event_name,
        data: JSON.stringify(bugsnag_event),
        source: partner_id,
        fired_by_id: user_id,
        authorized_create: ['__create_' + partner_id],
        authorized_read: ['__read_low_' + partner_id]
      },
      options: { disableLastUpdatedBy: true }
    })
  } catch (err) {}
}

export const isNetworkError = ({ message }) => {
  return (
    message.toLowerCase().includes('failed to fetch') ||
    message.toLowerCase().includes('network error') ||
    message.toLowerCase().includes('networkerror') ||
    message.toLowerCase().includes('no current user') ||
    message.toLowerCase().includes('load failed') ||
    message.toLowerCase().includes('timeout exceeded')
  )
}

/**
 * if we use src/graphql/index.js query / mutation
 * everywhere, we can remove the check in a while
 *
 * src/index.js
 * the console.error function has been overwritten
 * with Bugsnag.notify (maybe we could use instead
 * of notifyBugsnag console.error for catches)
 *
 * */
export const notifyBugsnag = (error) => {
  console.error(isError(error) ? error : convertGraphqlError(error))
}

export const resizeFile = (file, maxWidth = 375, maxHeight = 250) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'png',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export const convertNumberToTime = (number) => {
  // separate the int from the decimal part
  const minutes = Math.floor(number)
  let decpart = number - minutes

  const min = 1 / 60
  // round to nearest minute
  decpart = min * Math.round(decpart / min)

  const seconds = Math.floor(decpart * 60) + ''

  // concate minutess and seconds
  return { minutes, seconds }
}

export const toBase64 = (obj) => {
  return Buffer.from(JSON.stringify(obj)).toString('base64')
}

export const fromBase64 = (base64String) => {
  try {
    return JSON.parse(Buffer.from(base64String, 'base64'))
  } catch (err) {
    notifyBugsnag(new Error(`parse link error (from base 64) - ${err.message}`))
  }
}

export const setUserIdToAmplifyAnalytics = (userId) => {
  if (!userId) {
    throw new Error('Param userId missing in setUserIdToAmplifyAnalytics')
  }

  Analytics._pluggables.forEach((pluggable) => {
    if (pluggable.getProviderName() === 'AWSPinpoint') {
      const endpointId = `email_${userId}`
      pluggable._config.endpointId = endpointId
    }
  })
}

export const checkForUserBranch = () => {
  if (process.env.NODE_ENV === 'development') return

  if (
    !['develop', 'staging', 'production'].includes(
      process.env.REACT_APP_USER_BRANCH
    )
  ) {
    throw new Error('REACT_APP_USER_BRANCH has a wrong value!')
  }
}

export const initializeFirebase = () => {
  if (BUILD_ENV !== 'production') return
  initializeApp(firebaseConfig)
}

/**
 * Helper function for the system useQuery used in App.js
 * @param {Object} system
 */
export function selectSystemData(items) {
  let result = {
    mail_templates: items.find(({ id }) => id === 'MAIL_TEMPLATES'),
    challenges: items.find(({ id }) => id === 'CHALLENGES'),
    categories: items.find(({ id }) => id === 'CATEGORIES'),
    dimensions: items.find(({ id }) => id === 'DIMENSIONS'),
    dimensions_pdf_reports: items.find(
      ({ id }) => id === 'DIMENSIONS_PDF_REPORTS'
    )
  }

  Object.keys(result).forEach((key) => {
    if (!result[key]) throw new Error('system: !result[key]')

    result[key] = { ...result[key], ...JSON.parse(result[key].data) }
  })

  const {
    DEFAULT_CAREER_MAIL_TEMPLATE,
    DEFAULT_CAREER_MAIL_TEMPLATE_REMINDER,
    DEFAULT_PARTNER_MAIL_TEMPLATE,
    DEFAULT_PARTNER_MAIL_TEMPLATE_REMINDER,
    DEFAULT_CROSSMODE_MAIL_TEMPLATE,
    DEFAULT_CAREER_MAIL_TEMPLATE_REJECT,
    DEFAULT_PARTNER_MAIL_TEMPLATE_REJECT,
    SCAFFOLD,
    INVITATION_BLOCK
  } = result.mail_templates

  result = {
    ...result,
    defaultCareerMailTemplate: DEFAULT_CAREER_MAIL_TEMPLATE,
    defaultCareerMailTemplateReminder: DEFAULT_CAREER_MAIL_TEMPLATE_REMINDER,
    defaultPartnerMailTemplate: DEFAULT_PARTNER_MAIL_TEMPLATE,
    defaultPartnerMailTemplateReminder: DEFAULT_PARTNER_MAIL_TEMPLATE_REMINDER,
    defaultCrossmodeMailTemplate: DEFAULT_CROSSMODE_MAIL_TEMPLATE,
    defaultCareerMailTemplateReject: DEFAULT_CAREER_MAIL_TEMPLATE_REJECT,
    defaultPartnerMailTemplateReject: DEFAULT_PARTNER_MAIL_TEMPLATE_REJECT,
    scaffold: SCAFFOLD,
    invitationBlock: INVITATION_BLOCK
  }

  const announcement = JSON.parse(
    items.find(({ id }) => id === 'ANNOUNCEMENT').data
  ).find(({ application }) => application === 'AIVY_CUSTOMER_DASHBOARD')

  const currentChallengeConfigId = items.find(
    (item) => item.id === 'CURRENT_CHALLENGE_CONFIG_ID'
  )

  return {
    ...result,
    announcement,
    currentChallengeConfigId: JSON.parse(currentChallengeConfigId.data).id
  }
}

export const getInviteToAssessmentLink = ({ space_id, language = 'de' }) => {
  const DEVELOP_URL = new URL(
    'http://develop.webapp.aivy.app.s3-website.eu-central-1.amazonaws.com'
  )
  const STAGING_URL = new URL(
    'http://staging.webapp.aivy.app.s3-website.eu-central-1.amazonaws.com'
  )
  const PRODUCTION_URL = new URL('https://webapp.aivy.app')

  DEVELOP_URL.searchParams.set('invitation', space_id)
  DEVELOP_URL.searchParams.set('language', language)

  STAGING_URL.searchParams.set('invitation', space_id)
  STAGING_URL.searchParams.set('language', language)

  PRODUCTION_URL.searchParams.set('invitation', space_id)
  PRODUCTION_URL.searchParams.set('language', language)

  switch (BUILD_ENV) {
    case 'develop':
      return DEVELOP_URL.toString()
    case 'staging':
      return STAGING_URL.toString()
    default:
      return PRODUCTION_URL.toString()
  }
}

export function getSubdomain() {
  const hostname = window.location.hostname
  const parts = hostname.split('.')
  if (parts.length >= 3) {
    return parts[0]
  } else {
    return null
  }
}

export const getBackgroundColorByMatchingScore = (matchingScore) => {
  if (
    matchingScore === '-' ||
    matchingScore === null ||
    matchingScore === undefined
  )
    return 'rgb(156, 163, 175)'
  else if (HIGH_FIT_RANGE(matchingScore)) return '#29CCC7'
  else if (MIDDLE_FIT_RANGE(matchingScore)) return '#FF9A3B'
  else if (LOW_FIT_RANGE(matchingScore)) return '#ff8ab0'
}

export const colorByString = (s, customColorsArray) => {
  if (typeof s !== 'string')
    return { backgroundColor: 'bg-gray-100', text: 'text-gray-600' } // DEFAULT

  let sumChars = 0
  for (let i = 0; i < s.length; i += 1) {
    sumChars += s.charCodeAt(i)
  }

  // inspired by https://github.com/wbinnssmith/react-user-avatar
  // colors from https://flatuicolors.com/
  const colors = [
    { backgroundColor: 'bg-gray-100', text: 'text-gray-600' },
    { backgroundColor: 'bg-red-100', text: 'text-red-700' },
    { backgroundColor: 'bg-yellow-100', text: 'text-yellow-800' },
    { backgroundColor: 'bg-green-100', text: 'text-green-700' },
    { backgroundColor: 'bg-blue-100', text: 'text-blue-700' },
    { backgroundColor: 'bg-indigo-100', text: 'text-indigo-700' },
    { backgroundColor: 'bg-purple-100', text: 'text-purple-700' },
    { backgroundColor: 'bg-pink-100', text: 'text-pink-700' },
    { backgroundColor: 'bg-slate-100', text: 'text-slate-700' },
    { backgroundColor: 'bg-zinc-100', text: 'text-zinc-700' },
    { backgroundColor: 'bg-stone-100', text: 'text-stone-700' },
    { backgroundColor: 'bg-orange-100', text: 'text-orange-700' },
    { backgroundColor: 'bg-orange-100', text: 'text-orange-700' },
    { backgroundColor: 'bg-emerald-100', text: 'text-emerald-700' },
    { backgroundColor: 'bg-fuchsia-100', text: 'text-fuchsia-700' }
  ]

  if (customColorsArray) {
    return customColorsArray[sumChars % colors.length]
  }

  return colors[sumChars % colors.length]
}

export const replaceMacros = ({ text: t, macros }) => {
  let text = t

  macros.forEach(({ macro, value }) => {
    text = text.replaceAll(macro, value)
  })

  return text
}

export const startStrWithCapital = (str) => {
  if (str === null || str === undefined) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isEmptyObj = (obj) => {
  if (obj === null || obj === undefined) return true
  if (!(typeof obj === 'object' && obj !== null)) return true

  return Object.keys(obj).length === 0
}

/**
 * Überprüft, ob ein Wert weder 'null' noch 'undefined' ist.
 *
 * @param {*} value - Der Wert, der geprüft werden soll. Kann ein beliebiger Datentyp sein.
 * @returns {boolean} - Gibt 'true' zurück, wenn der Wert weder 'null' noch 'undefined' ist, ansonsten 'false'. */
export const isDefined = (value) => value != null

export const riasec = [
  { id: 'RIASEC_IMAGES_r_score', title: { de: 'Handwerklich-technisch' } },
  { id: 'RIASEC_IMAGES_i_score', title: { de: 'Untersuchend-forschend' } },
  { id: 'RIASEC_IMAGES_a_score', title: { de: 'Künstlerisch-kreativ' } },
  { id: 'RIASEC_IMAGES_s_score', title: { de: 'Erziehend-pflegend' } },
  { id: 'RIASEC_IMAGES_e_score', title: { de: 'Führend-verkaufend' } },
  { id: 'RIASEC_IMAGES_c_score', title: { de: 'Ordnend-verwaltend' } }
]

export const getRequirementAnalyseLink = ({ analyse_id }) => {
  const DEVELOP_URL = new URL('https://develop.anforderungsanalyse.aivy.app')
  const STAGING_URL = new URL('https://staging.anforderungsanalyse.aivy.app')
  const PRODUCTION_URL = new URL('https://www.anforderungsanalyse.aivy.app')

  DEVELOP_URL.searchParams.set('invitation', analyse_id)
  STAGING_URL.searchParams.set('invitation', analyse_id)
  PRODUCTION_URL.searchParams.set('invitation', analyse_id)

  switch (BUILD_ENV) {
    case 'develop':
      return DEVELOP_URL.toString()
    case 'staging':
      return STAGING_URL.toString()
    default:
      return PRODUCTION_URL.toString()
  }
}
