import { Analytics } from 'aws-amplify'
import { getAnalytics, logEvent } from 'firebase/analytics'

import { BUILD_ENV, notifyBugsnag } from '.'
import { getPartner } from './partner'
import { getUser } from './user'

export const trackEvent = (eventName, eventParams) => {
  const attributes = {
    partner: getPartner().id || '',
    user: getUser().id || '',
    ...(eventParams || {})
  }

  if (BUILD_ENV !== 'production') {
    console.log('trackEvent', { eventName, eventParams }) // eslint-disable-line
    return
  }

  const analytics = getAnalytics()
  logEvent(analytics, eventName, attributes)

  if (!getUser()?.id) return

  Analytics.record({ name: eventName, attributes, immediate: true }).catch(
    (err) => notifyBugsnag(new Error('analytics record error: ' + err.message))
  )
}
