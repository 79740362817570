import PropTypes from 'prop-types'
import ActivityIndicator from 'react-spinners/BeatLoader'

import { colors } from '../constants/colors'

const Loader = ({ className = 'flex justify-center py-48' }) => (
  <div className={className}>
    <ActivityIndicator color={colors.primaryBlue} />
  </div>
)

Loader.propTypes = {
  className: PropTypes.string
}

export default Loader
