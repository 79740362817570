import { useEffect, useState } from 'react'

import { query } from '../graphql'
import {
  BUILD_ENV,
  RESTIFY_DEV_URL,
  RESTIFY_PROD_URL,
  RESTIFY_STAGING_URL,
  notifyBugsnag
} from '../helpers'

const getSystemStatusUrl = () => {
  const path = '/system/status'

  switch (BUILD_ENV) {
    case 'develop':
      return RESTIFY_DEV_URL + '/develop' + path
    case 'staging':
      return RESTIFY_STAGING_URL + '/staging' + path
    default:
      return RESTIFY_PROD_URL + path
  }
}

export const useSystemStatus = () => {
  const [status, setStatus] = useState('INITIAL')

  useEffect(() => {
    const fetchSystemStatus = async () => {
      try {
        const response = await fetch(getSystemStatusUrl())

        // the HTTP 403 Forbidden response status code indicates
        // that the server understands the request but refuses
        // to authorize it
        // the access is tied to the application logic,
        // such as insufficient rights to a resource

        if (response.status === 403) {
          try {
            const getSystem = await query({
              query: 'getSystem',
              authMode: 'AWS_IAM',
              variables: { id: 'SYSTEM_STATUS' }
            })

            setStatus(
              JSON.parse(getSystem.data)['aivy-customer-dashboard'].status
                ? 'ONLINE'
                : 'MAINTENANCE_WORK'
            )
          } catch (err) {
            setStatus('OFFLINE')
          }

          return
        }

        try {
          const responseToJson = await response.json()

          setStatus(
            responseToJson['aivy-customer-dashboard'].status
              ? 'ONLINE'
              : 'MAINTENANCE_WORK'
          )
        } catch (err) {
          notifyBugsnag(err)
          setStatus('OFFLINE')
        }
      } catch (err) {
        notifyBugsnag(err)
        setStatus('OFFLINE')
      }
    }

    fetchSystemStatus()
  }, [])

  return {
    status
  }
}
