import PropTypes from 'prop-types'

import { useUserContext } from '../../context'
import Modal from '../tailwind/Modal'
import AivyJSONTree from './aivy-json-tree'

const Data = ({ open, setOpen, title = 'Data', data }) => {
  const { isAdmin } = useUserContext()

  if (!isAdmin) return null

  return (
    <Modal open={open} setOpen={setOpen} size='3xl'>
      <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {title}
      </h2>
      <div className='mt-4 flex flex-col gap-y-6'>
        <AivyJSONTree data={data} />
      </div>
      <div className='h-8' />
    </Modal>
  )
}

Data.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  data: PropTypes.any.isRequired
}

export default Data
