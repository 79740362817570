import { PlusIcon } from '@heroicons/react/24/outline'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import { useCallback, useRef, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactMarkdown from 'react-markdown'

import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useNotificationContext } from '../../../context'
import { riasec } from '../../../helpers'
import { useMount } from '../../../hooks/use-mount'
import Button from '../../tailwind/Button'
import Modal from '../../tailwind/Modal'
import Steps from '../../tailwind/steps'

const I18N_PREFIX = 'create_career.configuration.riasec_requirement_modal.'
const TYPES = { CARD: 'card' }

// eslint-disable-next-line react/prop-types
const Card = ({ id, text, index, moveCard }) => {
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: TYPES.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: TYPES.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div
      className='mb-2 flex h-16 cursor-move flex-row items-center justify-between overflow-hidden rounded-lg border border-dashed border-gray-300 bg-gray-50 px-6'
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <div className='flex flex-row items-center'>
        <div className='mr-4 flex h-10 w-10 items-center justify-center rounded-full border-2 border-primaryBlue'>
          <span className='text-base font-normal text-darkBlue'>{`0${index + 1}`}</span>
        </div>
        <div className='text-base font-bold text-darkBlack'>{text}</div>
      </div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='size-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9'
        />
      </svg>
    </div>
  )
}

const RiasecRequirementModal = ({ career, careerMutation, back, onFinish }) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()

  const [selectedInterests, setSelectedInterests] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(true)

  const career_analyse = JSON.parse(career.career_analyse)

  useMount(() => {
    const ids = riasec.map((obj) => obj.id)

    const filteredObj = Object.fromEntries(
      Object.entries(career_analyse.scores).filter(([key]) => ids.includes(key))
    )

    const entries = Object.entries(filteredObj)
    entries.sort(([, valueA], [, valueB]) => valueA.optimal - valueB.optimal)

    const existingSelectedInterests = []
    entries.forEach((e) => {
      const key = e[0]
      existingSelectedInterests.push(riasec.filter((r) => r.id === key)[0])
    })

    if (existingSelectedInterests.length) {
      setSelectedInterests(existingSelectedInterests.slice(0, 3))
      setCurrentStep(1)
    }
  })

  const steps = [
    {
      id: '0',
      name: t(I18N_PREFIX + 'step_0_name'),
      current: currentStep === 0,
      complete: currentStep === 1
    },
    {
      id: '1',
      name: t(I18N_PREFIX + 'step_1_name'),
      current: currentStep === 1,
      complete: false
    }
  ]

  const addItem = (item) => {
    setSelectedInterests((prevItems) => [...prevItems, item])
  }

  const removeItem = (item) => {
    setSelectedInterests((prevItems) =>
      prevItems.filter((i) => i.id !== item.id)
    )
  }

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setSelectedInterests((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    )
  }, [])

  const renderCard = useCallback(
    (i, index) => (
      <Card
        key={i.id}
        index={index}
        id={i.id}
        text={i.title.de}
        moveCard={moveCard}
      />
    ),
    [moveCard]
  )

  const submit = () => {
    setIsLoading(true)
    const career_analyse = { ...JSON.parse(career.career_analyse) }

    // Clean scores
    const ids = riasec.map((obj) => obj.id)
    for (const key in career_analyse.scores) {
      // Prüfe, ob irgendein Element aus filterArray im Schlüssel enthalten ist
      if (ids.some((element) => key.includes(element))) {
        delete career_analyse.scores[key]
      }
    }

    const notSelectedInterests = riasec.filter(
      (item1) => !selectedInterests.some((item2) => item1.id === item2.id)
    )

    const arr = [...selectedInterests, ...notSelectedInterests]

    arr.forEach((i, index) => {
      career_analyse.scores[i.id] = { optimal: index + 1 }
    })

    const onSuccess = () => {
      success(t(I18N_PREFIX + 'success_message'))
      setIsLoading(false)
      setOpen(false)
      setTimeout(onFinish, 1000 * 0.4) // await transition
    }

    const input = {
      id: career.id,
      career_analyse: JSON.stringify(career_analyse)
    }

    careerMutation.mutate({ input }, { onSuccess })
  }

  return (
    <Modal open={open} setOpen={() => undefined} size='2xl' hideCloseButton>
      <div className='px-4'>
        <Steps {...{ steps }} />
        <div className='mt-6' />
        {currentStep === 0 && (
          <>
            <h2 className='text-2xl font-bold tracking-tight text-gray-900'>
              {t(I18N_PREFIX + 'step_0_title')}
            </h2>
            <ReactMarkdown className='mt-4 text-sm text-gray-900'>
              {t(I18N_PREFIX + 'step_0_description')}
            </ReactMarkdown>
            <span
              style={{ opacity: selectedInterests.length >= 3 ? 0.3 : 1 }}
              className='mt-6 block text-sm font-semibold leading-6 text-gray-900'
            >
              {t(I18N_PREFIX + 'step_0_select_action')}
            </span>
            <div
              style={{ opacity: selectedInterests.length >= 3 ? 0.3 : 1 }}
              className='mt-2 flex flex-row flex-wrap gap-2'
            >
              {riasec
                .filter(
                  (r) =>
                    !selectedInterests.some(
                      (selectedItem) => selectedItem.id === r.id
                    )
                )
                .map((r) => (
                  <span
                    key={r.id}
                    className='inline-flex items-center gap-x-0.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700'
                  >
                    {r.title.de}
                    <button
                      disabled={selectedInterests.length >= 3}
                      onClick={() => addItem(r)}
                      type='button'
                      className='group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20'
                    >
                      <span className='sr-only'>Add</span>

                      <PlusIcon className='h-3.5 w-3.5 stroke-blue-800/50 group-hover:stroke-blue-800/75' />
                      <span className='absolute -inset-1' />
                    </button>
                  </span>
                ))}
            </div>
            <span className='mt-6 block text-sm font-semibold leading-6 text-gray-900'>
              {t(I18N_PREFIX + 'step_0_selected_title')}
            </span>
            <div className='mt-2 flex flex-row flex-wrap gap-2'>
              {selectedInterests.map((r) => (
                <span
                  key={r.id}
                  className='inline-flex items-center gap-x-0.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700'
                >
                  {r.title.de}
                  <button
                    onClick={() => removeItem(r)}
                    type='button'
                    className='group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-green-600/20'
                  >
                    <span className='sr-only'>Remove</span>
                    <svg
                      viewBox='0 0 14 14'
                      className='h-3.5 w-3.5 stroke-green-800/50 group-hover:stroke-green-800/75'
                    >
                      <path d='M4 4l6 6m0-6l-6 6' />
                    </svg>
                    <span className='absolute -inset-1' />
                  </button>
                </span>
              ))}
            </div>
          </>
        )}
        {currentStep === 1 && (
          <>
            <h2 className='text-2xl font-bold tracking-tight text-gray-900'>
              {t(I18N_PREFIX + 'step_1_title')}
            </h2>
            <ReactMarkdown className='mt-4 text-sm text-gray-900'>
              {t(I18N_PREFIX + 'step_1_description')}
            </ReactMarkdown>
            <div className='mt-6 w-full'>
              <DndProvider backend={HTML5Backend}>
                {selectedInterests.map((card, i) => renderCard(card, i))}
              </DndProvider>
            </div>
          </>
        )}

        <div className='mt-12 flex flex-row justify-end gap-x-2'>
          <Button.SecondaryBase
            text={t(I18N_PREFIX + 'back_action')}
            onClick={currentStep === 0 ? back : () => setCurrentStep(0)}
          />
          <Button.PrimaryBase
            className={clsx(
              currentStep === 1 && 'bg-green-600 hover:bg-green-500',
              currentStep === 0 && selectedInterests.length < 3
                ? 'opacity-30'
                : 'opacity-100'
            )}
            onClick={() => {
              if (currentStep === 0) setCurrentStep(1)
              else submit()
            }}
            isLoading={isLoading}
            disabled={selectedInterests.length < 3}
            text={
              currentStep === 0
                ? t(I18N_PREFIX + 'forward_action')
                : t(I18N_PREFIX + 'save_action')
            }
          />
        </div>

        <div className='pt-4' />
      </div>
    </Modal>
  )
}

RiasecRequirementModal.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  back: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired
}

export default RiasecRequirementModal
