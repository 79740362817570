import { useEffect, useState } from 'react'
import Header from './joyride/header'

import Joyride from 'react-joyride'
import { colors } from '../constants/colors'
import trackEvents from '../constants/track-events'
import { notifyBugsnag } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import Paragraph from './joyride/paragraph'

const HelpSupportHint = () => {
  const [showHint, setShowHint] = useState(false)

  useEffect(() => {
    try {
      const opens_app = localStorage.getItem('opens_app')
      const didShowHintBefore = localStorage.getItem('show_help_support_hint')

      if (opens_app === null && didShowHintBefore !== null) return

      if (parseInt(opens_app) === 10) {
        setShowHint(true)
        localStorage.setItem('show_help_support_hint', 'true')
        trackEvent(trackEvents.SHOW_HELP_SUPPORT_HINT)
      }
    } catch (error) {
      notifyBugsnag(error)
    }
  }, [])

  if (showHint === false) return null

  return (
    <Joyride
      hideCloseButton
      locale={{ close: 'Verstanden' }}
      run
      styles={{
        buttonNext: {
          fontSize: 14,
          backgroundColor: colors.primaryBlue,
          borderRadius: 32,
          padding: '12px 24px',
          fontFamily: 'Fira Sans',
          fontWeight: 600
        }
      }}
      steps={[
        {
          target: '#nav_support_hub',
          disableBeacon: true,
          content: (
            <>
              <Header>{'help_support_hint.title'}</Header>
              <div className='h-4'></div>
              <Paragraph className='mt-2'>
                {'help_support_hint.description'}
              </Paragraph>
            </>
          )
        }
      ]}
    />
  )
}

export default HelpSupportHint
