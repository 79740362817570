import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import trackEvents from '../../constants/track-events'
import { classNames } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import Button from '../tailwind/Button'

const ChooseSpaces = ({ analyse, analyseMutation, spaces }) => {
  const { t } = useTranslation()

  const [query, setQuery] = useState('')
  const [submitting, setSubmitting] = useState(null)
  const [selectedSpaces, setSelectedSpaces] = useState(
    (analyse.spaces || []).map((id) => spaces.find((space) => space.id === id))
  )

  const next = () => {
    setSubmitting('next')

    const input = {
      id: analyse.id,
      input: JSON.stringify({
        ...JSON.parse(analyse.input),
        spaces: selectedSpaces.map(({ id }) => id),
        status: 'WAIT_RESEARCH'
      })
    }

    analyseMutation.mutate(
      { input },
      {
        onSuccess: () => setSubmitting(null),
        onError: () => setSubmitting(null)
      }
    )
  }

  const back = () => {
    setSubmitting('back')

    const input = {
      id: analyse.id,
      input: JSON.stringify({
        ...JSON.parse(analyse.input),
        spaces: selectedSpaces.map(({ id }) => id),
        status: null
      })
    }

    analyseMutation.mutate(
      { input },
      {
        onSuccess: () => setSubmitting(null),
        onError: () => setSubmitting(null)
      }
    )
  }

  const filteredSpaces = query
    ? spaces.filter((space) => {
        const name = space.name || ''
        const identification = space.identification || ''

        return (
          name.toLowerCase().includes(query.toLowerCase()) ||
          identification.toLowerCase().includes(query.toLowerCase())
        )
      })
    : spaces

  const removeSpace = ({ id: space_id }) => {
    setSelectedSpaces(selectedSpaces.filter((space) => space.id !== space_id))
  }

  return (
    <div className='mx-auto max-w-2xl'>
      <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {t('team_analyse.new.choose_spaces.title')}
      </span>
      <p className='mt-4 max-w-lg text-sm text-gray-900'>
        {t('team_analyse.new.choose_spaces.description')}
      </p>

      <Combobox
        as='div'
        value={selectedSpaces}
        onChange={setSelectedSpaces}
        multiple
        className={'mt-6'}
      >
        <Label className='block text-sm font-medium text-gray-700'>
          {t('team_analyse.new.choose_spaces.combobox_title')}
        </Label>
        <div className='relative mt-2'>
          <ComboboxInput
            className='w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-sm placeholder:italic focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            onChange={(event) => setQuery(event.target.value)}
            placeholder={t(
              'team_analyse.new.choose_spaces.combobox_placeholder'
            )}
          />
          <ComboboxButton className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
            <ChevronUpDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </ComboboxButton>
          <ComboboxOptions className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredSpaces.map((space) => (
              <ComboboxOption
                key={space.id}
                value={space}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-blue-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className='flex flex-col'>
                      <span
                        className={classNames(
                          'truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {space.name}
                      </span>
                      <span className='truncate'>{space.identification}</span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-blue-600'
                        )}
                      >
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </div>
      </Combobox>
      <p className='mt-2 text-sm text-gray-500' id='email-description'>
        {t('team_analyse.new.choose_spaces.talentpool_hint')}
        <Link
          to={'/talents'}
          // target='_blank'
          // rel='noopener noreferrer'
          state={{ inviteTalentpool: true }}
          onClick={() =>
            trackEvent(trackEvents.CLICK_INVITE_TALENTPOOL_TEAMANALYSIS)
          }
          className='group ml-2 text-sm font-semibold leading-6 text-primaryBlue'
        >
          {t('team_analyse.new.choose_spaces.talentpool_cta')}{' '}
          <span className='inline-block group-hover:ml-1' aria-hidden='true'>
            →
          </span>
        </Link>
      </p>
      {selectedSpaces.length > 0 && (
        <div className='mt-4'>
          <span className='block text-sm font-normal leading-6 text-gray-900'>
            {t('team_analyse.new.choose_spaces.selected_spaces_title')}
          </span>
          <div className='mt-1 flex flex-row flex-wrap gap-2'>
            {selectedSpaces.map((space) => (
              <span
                key={space.id}
                className='inline-flex items-center gap-x-0.5 rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10'
              >
                {space.name || space.identification}
                <button
                  type='button'
                  className='group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-purple-600/20'
                  onClick={() => removeSpace(space)}
                >
                  <span className='sr-only'>remove</span>
                  <svg
                    viewBox='0 0 14 14'
                    className='h-3.5 w-3.5 stroke-violet-600/50 group-hover:stroke-violet-600/75'
                  >
                    <path d='M4 4l6 6m0-6l-6 6' />
                  </svg>
                  <span className='absolute -inset-1' />
                </button>
              </span>
            ))}
          </div>
        </div>
      )}
      <div className='mt-8 flex justify-end gap-x-2 sm:mt-12'>
        <Button.SecondaryLG
          onClick={back}
          text={t('team_analyse.new.back_action')}
          isLoading={submitting === 'back'}
        />
        <Button.PrimaryLG
          text={t('team_analyse.new.next_action')}
          onClick={next}
          disabled={selectedSpaces.length < 2}
          isLoading={submitting === 'next'}
        />
      </div>
    </div>
  )
}

ChooseSpaces.propTypes = {
  analyse: PropTypes.object.isRequired,
  analyseMutation: PropTypes.object.isRequired,
  spaces: PropTypes.array.isRequired
}

export default ChooseSpaces
