import PropTypes from 'prop-types'
import { createContext, useContext, useMemo } from 'react'
import { mutation } from '../graphql'
import { getAuthorizationRole } from '../helpers/authorization-roles'

const UserContext = createContext()

UserContext.displayName = 'UserContext'

export function UserContextProvider({
  cognitoUser,
  active_space,
  refetch_active_space,
  isAdmin,
  emailEndpoint,
  refetchEmailEndpoint,
  spacesByOwner,
  refetchSpacesByOwner,
  user,
  children
}) {
  const value = useMemo(() => {
    const customer_dashboard_meta = JSON.parse(
      active_space.customer_dashboard_meta || '[]'
    )

    const has_seen_product_tour = !!customer_dashboard_meta.find(
      ({ event, status }) => event === 'PRODUCT_TOUR' && status === 'FINISHED'
    )

    const has_seen_favored_space_hint = !!customer_dashboard_meta.find(
      ({ event, status }) => event === 'FAVORED_SPACE_HINT' && status === 'SEEN'
    )

    const has_seen_store_partner_logo_hint = !!customer_dashboard_meta.find(
      ({ event, status }) => event === 'STORE_PARTNER_LOGO' && status === 'SEEN'
    )

    const ui_settings_general = customer_dashboard_meta.find(
      ({ id }) => id === 'UI_GENERAL'
    ) || { id: 'UI_GENERAL' }

    const ui_space_state_hint = customer_dashboard_meta.find(
      ({ id }) => id === 'UI_SPACE_STATE_HINT'
    ) || { id: 'UI_SPACE_STATE_HINT' }

    const updateSpace = {
      mutate: async ({ input }, callbacks = {}) => {
        const { onSuccess } = callbacks

        const response = await mutation({ mutation: 'updateSpace', input })
        await refetch_active_space()

        onSuccess && onSuccess(response)
      }
    }

    const updateUiSettingsGeneral = ({ key, value }) => {
      ui_settings_general[key] = value

      const cdm = JSON.stringify([
        ...customer_dashboard_meta.filter(({ id }) => id !== 'UI_GENERAL'),
        ui_settings_general
      ])

      updateSpace.mutate({
        input: {
          id: active_space.id,
          customer_dashboard_meta: cdm
        }
      })
    }

    const updateUiSettingsSpaceStateHint = ({ key, value }) => {
      ui_space_state_hint[key] = value

      const cdm = JSON.stringify([
        ...customer_dashboard_meta.filter(
          ({ id }) => id !== 'UI_SPACE_STATE_HINT'
        ),
        ui_space_state_hint
      ])

      updateSpace.mutate({
        input: {
          id: active_space.id,
          customer_dashboard_meta: cdm
        }
      })
    }

    return {
      cognitoUser,
      active_space: {
        ...active_space,
        customer_dashboard_meta,
        has_seen_product_tour,
        has_seen_favored_space_hint,
        has_seen_store_partner_logo_hint,
        ui_settings_general,
        ui_space_state_hint,
        updateUiSettingsGeneral,
        updateUiSettingsSpaceStateHint,
        authorization_role: getAuthorizationRole({ user: cognitoUser })
      },
      refetch_active_space,
      isAdmin, // used for aivy employees
      emailEndpoint,
      refetchEmailEndpoint,
      spacesByOwner,
      refetchSpacesByOwner,
      user
    }
  }, [
    cognitoUser,
    active_space,
    refetch_active_space,
    isAdmin,
    emailEndpoint,
    refetchEmailEndpoint,
    spacesByOwner,
    refetchSpacesByOwner,
    user
  ])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

UserContextProvider.propTypes = {
  cognitoUser: PropTypes.object.isRequired,
  active_space: PropTypes.object.isRequired,
  refetch_active_space: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  emailEndpoint: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  refetchEmailEndpoint: PropTypes.func.isRequired,
  spacesByOwner: PropTypes.array.isRequired,
  refetchSpacesByOwner: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export function useUserContext() {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUserContext must be used within a AppProvider')
  }

  return context
}
