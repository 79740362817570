import { useTranslation } from 'react-i18next'

import Shape02 from '../../assets/images/landingpage/shape02.svg'
import avatarImage4 from '../../assets/images/testimonials/anna-miels.png'
import avatarImage1 from '../../assets/images/testimonials/juergen-muthig.png'
import avatarImage3 from '../../assets/images/testimonials/melanie-vinci.png'
import avatarImage2 from '../../assets/images/testimonials/wolfgang-boehm.png'
import { Container } from './Container'

function QuoteIcon(props) {
  return (
    <svg aria-hidden='true' width={105} height={78} {...props}>
      <path d='M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z' />
    </svg>
  )
}

export function Testimonials() {
  const { t } = useTranslation()

  const testimonials = [
    [
      {
        content: () => (
          <p className='text-lg tracking-tight text-slate-900'>
            {t('landingpage.testimonial_section.testimonials.01.first')}{' '}
            <strong className='text-blue-600'>
              {t('landingpage.testimonial_section.testimonials.01.second')}
            </strong>
          </p>
        ),
        author: {
          name: 'Carl-Christoph Fellinger',
          role: 'Head of Early Career Programs - Beiersdorf',
          image:
            'https://uploads-ssl.webflow.com/5d3c10823dfa66198f3d1166/5fac1cf2e7e1cc573d979572_Testimonial-CarlChristoph_Fellinger.jpg'
        }
      },
      {
        content: () => (
          <p className='text-lg tracking-tight text-slate-900'>
            {t('landingpage.testimonial_section.testimonials.02.first')}{' '}
            <strong className='text-blue-600'>
              {t('landingpage.testimonial_section.testimonials.02.second')}
            </strong>{' '}
            {t('landingpage.testimonial_section.testimonials.02.third')}
          </p>
        ),

        author: {
          name: 'Marie-Jo Goldmann',
          role: 'Head of HR & Senior Recruiterin - nucao',
          image:
            'https://uploads-ssl.webflow.com/5d3c10823dfa66198f3d1166/61b7123c403cc39927c7f438_Marie-Jo.png'
        }
      }
    ],
    [
      {
        content: () => (
          <p className='text-lg tracking-tight text-slate-900'>
            {t('landingpage.testimonial_section.testimonials.03.first')}{' '}
            <strong className='text-blue-600'>
              {t('landingpage.testimonial_section.testimonials.03.second')}
            </strong>
            {t('landingpage.testimonial_section.testimonials.03.third')}
          </p>
        ),
        author: {
          name: 'Melanie Vinci',
          role: 'Präsidentin des Verwaltungsrates - persona service',
          image: avatarImage3
        }
      },
      {
        content: () => (
          <p className='text-lg tracking-tight text-slate-900'>
            {t('landingpage.testimonial_section.testimonials.04.first')}{' '}
            <strong className='text-blue-600'>
              {t('landingpage.testimonial_section.testimonials.04.second')}
            </strong>
            {t('landingpage.testimonial_section.testimonials.04.third')}
          </p>
        ),
        author: {
          name: 'Wolfgang Böhm',
          role: 'Ausbildungsleiter - Diehl',
          image: avatarImage2
        }
      }
    ],
    [
      {
        content: () => (
          <p className='text-lg tracking-tight text-slate-900'>
            <strong className='text-blue-600'>
              {t('landingpage.testimonial_section.testimonials.05.first')}
            </strong>
            {t('landingpage.testimonial_section.testimonials.05.second')}
          </p>
        ),
        author: {
          name: 'Jürgen Muthig',
          role: 'Leiter Berufsausbildung - Fresenius',
          image: avatarImage1
        }
      },
      {
        content: () => (
          <p className='text-lg tracking-tight text-slate-900'>
            {t('landingpage.testimonial_section.testimonials.06.first')}{' '}
            <strong className='text-blue-600'>
              {t('landingpage.testimonial_section.testimonials.06.second')}
            </strong>{' '}
            {t('landingpage.testimonial_section.testimonials.06.third')}
          </p>
        ),

        author: {
          name: 'Anna Miels',
          role: 'Manager Learning & Development - apoproject',
          image: avatarImage4
        }
      }
    ]
  ]

  return (
    <section
      id='testimonials'
      aria-label='What our customers are saying'
      className='bg-slate-50 py-20 sm:py-32'
    >
      <Container className={'relative'}>
        <img
          src={Shape02}
          className='absolute bottom-0 left-20 hidden h-auto w-48 rotate-45 lg:flex'
        />
        <div className='mx-auto max-w-2xl md:text-center'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
            {t('landingpage.testimonial_section.title')}
          </h2>
          {/* <p className='mt-4 text-lg tracking-tight text-slate-700'>
            Our software is so simple that people can’t help but fall in love
            with it. Simplicity is easy when you just skip tons of
            mission-critical features.
          </p> */}
        </div>
        <ul
          role='list'
          className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3'
        >
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role='list' className='flex flex-col gap-y-6 sm:gap-y-8'>
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className='relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10'>
                      <QuoteIcon className='absolute left-6 top-6 fill-slate-100' />
                      <blockquote className='relative'>
                        {testimonial.content()}
                      </blockquote>
                      <figcaption className='relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6'>
                        <div className='w-9/12'>
                          <div className='font-display text-base text-slate-900'>
                            {testimonial.author.name}
                          </div>
                          <div className='mt-1 text-sm text-slate-500'>
                            {testimonial.author.role}
                          </div>
                        </div>
                        <div className='overflow-hidden rounded-full bg-slate-50'>
                          <img
                            className='h-14 w-14 object-cover'
                            src={testimonial.author.image}
                            alt=''
                            width={56}
                            height={56}
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
