import {
  CheckIcon,
  ClipboardDocumentListIcon,
  LinkIcon
} from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon, PaperClipIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import ActivityIndicator from 'react-spinners/BeatLoader'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ActivityIndicator as ActivityIndicatorAivy } from '../'
import trackEvents from '../../constants/track-events'
import {
  useAivyContext,
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../../context'
import { query } from '../../graphql'
import {
  BUILD_ENV,
  RESTIFY_DEV_URL,
  RESTIFY_PROD_URL,
  RESTIFY_STAGING_URL,
  classNames,
  convertNumberToTime,
  toBase64
} from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { usePartnerIntegrations } from '../../hooks/use-partner-integrations'
import ChallengeMaterialCard from '../challenge-material-card'
import Feedback from '../feedback'
import Panel from '../tailwind/Panel'
import SimpleStats from '../tailwind/simple-stats'

const TRANSLATION_LOCATION = 'pages.career.'

const getDvinciUrl = ({ career_id }) => {
  const path = `/ats/dvinci/invite?testId=${career_id}`

  switch (BUILD_ENV) {
    case 'develop':
      return RESTIFY_DEV_URL + path
    case 'staging':
      return RESTIFY_STAGING_URL + path
    default:
      return RESTIFY_PROD_URL + path
  }
}

const Overview = ({ career }) => {
  const { t } = useTranslation()

  const { partner } = usePartnerContext()
  const { system } = useAivyContext()
  const { language } = useLanguageContext()
  const { success } = useNotificationContext()
  const {
    cognitoUser: { username }
  } = useUserContext()

  const navigate = useNavigate()

  const [dvinciUrlCopied, setDvinciUrlCopied] = useState(false)

  const { status: partnerIntegrationsStatus, data: dvinciIntegration } =
    usePartnerIntegrations({
      select: (response) => {
        return response.find(
          ({ remote_service }) => remote_service === 'DVINCI'
        )
      }
    })

  const { id: career_id, diagnostic_version } = career
  const { career_preset_id } = JSON.parse(career.career_analyse)

  const { data: preset, status: presetStatus } = useQuery({
    queryKey: ['career-preset', career_preset_id],
    queryFn: () =>
      query({
        query: 'publicSqlQuery',
        variables: {
          action: 'query-career-preset',
          input: JSON.stringify({ career_preset_id })
        }
      }),
    select: (response) => {
      const result = JSON.parse(response.Items)[0]
      result.name = JSON.parse(result.name)

      return result
    },
    enabled: !!career_preset_id
  })

  const { data: requirementProfile, isFetching: isFetchingRequirementProfile } =
    useQuery({
      queryKey: [
        'requirement-profile',
        career_id,
        language,
        diagnostic_version
      ],
      queryFn: () =>
        query({
          query: 'generatePdf',
          variables: {
            career_id,
            language,
            type: diagnostic_version === 2 ? 'REQUIREMENTPROFILE' : undefined
          }
        }),
      select: (response) => JSON.parse(response),
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 8 // 8 minutes
    })

  const previewRequirementProfile = () => {
    trackEvent(trackEvents.PREVIEW_REQUIREMENT_PROFILE)

    const pdfDocument = window.open('', '_blank')
    pdfDocument.location.href = requirementProfile.headers.Location
  }

  const downloadRequirementProfile = () => {
    trackEvent(trackEvents.DOWNLOAD_REQUIREMENT_PROFILE)

    // https://stackoverflow.com/a/63965930
    fetch(requirementProfile.headers.Location, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute(
          'download',
          `${career.title.toLowerCase().replaceAll(' ', '-')}.pdf`
        )

        // append to html link element page
        document.body.appendChild(link)

        // start download
        link.click()

        // clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }

  const queryClient = useQueryClient()

  const spaces_to_careers = useMemo(() => {
    const { state } =
      queryClient
        .getQueryCache()
        .find({ queryKey: ['talents', partner.id, username] }) || {}

    if (state?.status !== 'success') return null

    const result = new Map()

    // queryKey: ['talents', partner.id, username]
    // queryFn: () => Promise.all([getAllSpaces(), getCareers()])
    // select: [0] = spaces / [1] = careers
    state.data[0].forEach((space) =>
      result.set(
        space.career_id,
        (result.get(space.career_id) || []).concat([{ ...space }])
      )
    )

    // make sure the selected career has an entry
    if (!result.get(career.id)) {
      result.set(career.id, [])
    }

    return result
  }, [queryClient, career.id, partner.id, username])

  const challenges = JSON.parse(career.app_settings?.challenges || '[]').map(
    ({ exam_id }) => system.challenges[exam_id]
  )

  const timeToComplete = challenges
    .map(({ timeToComplete }) => timeToComplete)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const navigateToTalents = () => {
    const location = {
      pathname: '/talents',
      search: `computed=${toBase64([{ assessment_id: { in: [career_id] } }])}`
    }

    localStorage.setItem(location.pathname, location.search)
    navigate(location.pathname)
  }

  const number_of_invited_talents = spaces_to_careers?.get(career_id).length

  const number_of_completed_talents = spaces_to_careers
    ?.get(career_id)
    .filter(({ progress }) => progress === 100).length

  const is_loading =
    (career_preset_id && presetStatus === 'pending') ||
    partnerIntegrationsStatus === 'pending'

  if (is_loading) {
    return (
      <div className='mx-8 my-8'>
        <ActivityIndicatorAivy />
      </div>
    )
  }

  const dvinciUrl = dvinciIntegration && getDvinciUrl({ career_id })

  const copyDvinciUrlToClipboard = () => {
    navigator.clipboard.writeText(dvinciUrl)
    setDvinciUrlCopied(true)
    success(t('career.overview.dvinci_copy_success'))
    setTimeout(() => setDvinciUrlCopied(false), 1000 * 3)
  }

  return (
    <div className='mb-12 mt-4'>
      <div className='my-6 md:flex md:items-center'>
        <div className='md:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>
            {t('career.overview.title')}
          </h1>
          <p className='mt-2 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
            {t('career.overview.description', {
              career_title: career.title
            })}
          </p>
        </div>
      </div>
      <Panel className='mt-4'>
        <SimpleStats
          stats={[
            {
              name: t('career.overview.stats_name_number_of_invited_talents'),
              value: number_of_invited_talents ?? 'n/a',
              onClick: number_of_invited_talents ? navigateToTalents : undefined
            },
            {
              name: t('career.overview.stats_name_number_of_completed_talents'),
              value: number_of_completed_talents ?? 'n/a'
            },
            {
              name: t('career.overview.stats_name_number_of_challenges'),
              value: challenges.length || 'n/a'
            },
            {
              name: t('career.overview.stats_name_time_to_complete'),
              value:
                convertNumberToTime(timeToComplete / 60000).minutes || 'n/a',
              unit: t('career.overview.stats_unit_time_to_complete')
            }
          ]}
          background='bg-gray-50'
        />
      </Panel>
      <Panel className='mt-4'>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>
          {t('career.overview.requirement_profile_section_title')}
        </h3>
        <p className='mt-1 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
          {career_preset_id
            ? t(
                'career.overview.requirement_profile_section_description_preset',
                { preset_name: preset.name[language] }
              )
            : t('career.overview.requirement_profile_section_description')}
        </p>
        <div className='mt-8 max-w-2xl'>
          <ul
            role='list'
            className='divide-y divide-gray-100 rounded-md border border-gray-200 bg-white'
          >
            <li
              onClick={
                isFetchingRequirementProfile
                  ? undefined
                  : previewRequirementProfile
              }
              className={classNames(
                'flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6',
                !isFetchingRequirementProfile &&
                  'group cursor-pointer hover:bg-gray-50/50'
              )}
            >
              <div className='flex w-0 flex-1 items-center'>
                <PaperClipIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <div className='ml-4 flex min-w-0 flex-1 gap-2'>
                  <span className='truncate font-medium'>
                    {t(TRANSLATION_LOCATION + 'requirement_profile')}
                  </span>
                  {/* <span className='flex-shrink-0 text-gray-400'>2.4mb</span> */}
                </div>
              </div>
              <div className='ml-4 flex-shrink-0'>
                {isFetchingRequirementProfile ? (
                  <div className='flex pt-1'>
                    <ActivityIndicator color='#111827' size={12} />
                  </div>
                ) : (
                  <span className='font-medium text-blue-600 group-hover:text-blue-500'>
                    {t(
                      TRANSLATION_LOCATION + 'requirement_profile_show_action'
                    )}
                  </span>
                )}
              </div>
            </li>
          </ul>
          <div className='flex items-center justify-end border-r border-gray-200 py-4 pl-4 pr-5'>
            <div
              onClick={
                isFetchingRequirementProfile
                  ? undefined
                  : downloadRequirementProfile
              }
              className={classNames(
                'flex w-36 items-center justify-end text-sm leading-6',
                !isFetchingRequirementProfile && 'group hover:cursor-pointer'
              )}
            >
              <ArrowDownTrayIcon
                className={classNames(
                  'mr-2 h-5 w-5 flex-shrink-0 text-gray-400',
                  'group-hover:text-gray-600'
                )}
                aria-hidden='true'
              />
              <span
                className={classNames(
                  'font-medium text-gray-900',
                  'group-hover:text-gray-800'
                )}
              >
                {t(
                  TRANSLATION_LOCATION + 'requirement_profile_download_action'
                )}
              </span>
            </div>
          </div>
        </div>
      </Panel>
      {dvinciIntegration && (
        <Panel className='mt-4'>
          <div>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              {t('career.overview.dvinci_section_title')}
            </h3>
            <p className='mt-1 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
              {t('career.overview.dvinci_section_description')}
            </p>
          </div>
          <div className='mt-4 max-w-2xl rounded-md bg-white px-4 py-3'>
            <div className='flex items-center'>
              <LinkIcon className='h-6 w-6 text-gray-700' />
              <code className='ml-3 mr-4 truncate text-xs text-gray-900'>
                <a
                  className='text-blue-700 underline visited:text-blue-800 hover:text-blue-500'
                  href={dvinciUrl}
                  target='_blank'
                  rel='noreferrer'
                >
                  {dvinciUrl}
                </a>
              </code>
              <div
                className='ml-auto flex h-9 w-9 shrink-0 cursor-pointer items-center justify-center rounded-full bg-primaryBlue hover:bg-gray-200'
                onClick={copyDvinciUrlToClipboard}
              >
                {dvinciUrlCopied ? (
                  <CheckIcon className='h-5 w-5 text-white' />
                ) : (
                  <ClipboardDocumentListIcon className='h-5 w-5 text-white' />
                )}
              </div>
            </div>
          </div>
        </Panel>
      )}
      <Panel className='mt-4'>
        <div>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            {t('career.overview.challenges_section_title')}
          </h3>
          <p className='mt-1 max-w-lg text-sm text-gray-700 lg:max-w-2xl'>
            {t('career.overview.challenges_section_description')}
          </p>
        </div>
        <div className='mt-8 max-w-4xl'>
          <div className='flex flex-wrap gap-4'>
            {challenges.map((challenge, index) => (
              <ChallengeMaterialCard
                key={index}
                challenge={challenge}
                className='border border-gray-200 bg-white'
              />
            ))}
          </div>
        </div>
      </Panel>
      <Feedback context='CAREER' />
    </div>
  )
}

Overview.propTypes = {
  career: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default Overview
