import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition
} from '@headlessui/react'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { GlobeAltIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router'
import { DE, EN, getAllLanguages } from '../../constants/languages'
import trackEvents from '../../constants/track-events'
import { useLanguageContext } from '../../context'
import { trackEvent } from '../../helpers/analytics'
import { Container } from './Container'

export function HeaderHint() {
  const { language } = useLanguageContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const languages = useMemo(
    () => [
      {
        id: 0,
        title: t('components.switch_lang.title_de'),
        locale: DE,
        active: language === DE
      },
      {
        id: 1,
        title: t('components.switch_lang.title_en'),
        locale: EN,
        active: language === EN
      }
    ],
    [language, t]
  )

  const currentLanguage = languages.find(({ locale }) => locale === language)
  return (
    <div className='z-50 w-full bg-gray-200 py-2'>
      <Container className={'flex flex-row items-center justify-between'}>
        {/* <Popover className='relative'>

          <PopoverPanel anchor='bottom start' className='absolute mt-1'>
            
          </PopoverPanel>
        </Popover> */}

        <Popover className={'z-50'}>
          <PopoverButton className={'flex flex-row items-center'}>
            <GlobeAltIcon className='h-5 w-5 text-slate-700 hover:text-slate-900' />
            <span className='ml-1 block text-sm font-medium text-slate-700 hover:text-slate-900'>
              {currentLanguage.locale.toUpperCase()}
            </span>
          </PopoverButton>
          <Transition
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <PopoverPanel
              className={'z-50'}
              anchor={{ to: 'bottom start', gap: '4px' }}
            >
              {({ close }) => (
                <div className='flex w-32 flex-col overflow-hidden rounded-md border border-slate-200 bg-slate-50'>
                  {getAllLanguages().map((l) => (
                    <button
                      key={l[0]}
                      onClick={() => {
                        trackEvent(
                          trackEvents.CLICK_CHANGE_LANGUAGE_LANDINGPAGE
                        )

                        navigate(l[0] === 'de' ? '/de/ausprobieren' : '/en/try')
                        close()
                      }}
                      className={clsx(
                        'block w-full py-2 text-base text-slate-700 hover:opacity-70',
                        currentLanguage.locale === l[0] &&
                          'bg-blue-500 font-medium text-white'
                      )}
                    >
                      {l[0].toUpperCase()}
                    </button>
                  ))}
                </div>
              )}
            </PopoverPanel>
          </Transition>
        </Popover>
        <div>
          <span className='text-sm font-medium text-gray-900'>
            {t('landingpage.header_hint.talent')}
          </span>{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              trackEvent(trackEvents.CLICK_FUNNEL_DISCOVER_STRENGTH_WEBAPP)
            }}
            href='https://webapp.aivy.app'
            className='group inline-block'
          >
            <span className='cursor-pointer text-sm font-semibold text-blue-700 underline group-hover:text-blue-600'>
              {t('landingpage.header_hint.here_you_go')}
              <ArrowRightIcon className='ml-1 inline-block h-4 w-4 text-blue-700 group-hover:text-blue-600' />
            </span>
          </a>
        </div>
      </Container>
    </div>
  )
}
