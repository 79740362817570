import { DialogTitle } from '@headlessui/react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { useNotificationContext, useUserContext } from '../context'
import { mutation, query } from '../graphql'
import Button from './tailwind/Button'
import Modal from './tailwind/Modal'

const { version } = require('../../package.json')

const STEPS = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
  THIRD: 'THIRD'
}

const CustomerEffortScore = ({ open, setOpen, context }) => {
  const { active_space } = useUserContext()
  const FEEDBACK_ID = `${active_space.id}-CUSTOMER-EFFORT-SCORE`
  const [step, setStep] = useState(STEPS.FIRST)
  const [score, setScore] = useState(null)
  const [text, setText] = useState('')
  const { success } = useNotificationContext()
  const { t } = useTranslation()

  const isPositive = score + 1 >= 5

  const submit = async () => {
    // no auth rules specified
    await mutation({
      mutation: 'createFeedback',
      input: {
        id: FEEDBACK_ID,
        rating: score + 1,
        ref_link: window?.location?.href || 'NOT_SET',
        rating_text: '',
        context,
        version,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      },
      options: { disableLastUpdatedBy: true }
    })

    setStep(STEPS.SECOND)
  }

  const update = async () => {
    const feedback = await query({
      query: 'getFeedback',
      variables: { id: FEEDBACK_ID }
    })

    // no auth rules specified
    await mutation({
      mutation: 'updateFeedback',
      input: {
        id: FEEDBACK_ID,
        rating_text: feedback.rating_text + `- ${step}: ${text} `
      },
      options: { disableLastUpdatedBy: true }
    })

    if (step === STEPS.THIRD) {
      success('Vielen Dank für dein Feedback! :)')
      setOpen(false)
      return
    }

    setStep(STEPS.THIRD)
    setText('')
  }

  return (
    <Modal open={open} setOpen={setOpen} size={'lg'}>
      {step === STEPS.FIRST && (
        <div>
          <DialogTitle className='mt-6 whitespace-pre-line text-center text-2xl font-medium leading-6 text-gray-900'>
            {t('customer_effort_score.first_screen.title')}
          </DialogTitle>
          <div className='mt-6 flex flex-row justify-between'>
            {[...Array(7).keys()].map((i) => (
              <div
                onClick={() => {
                  setScore(i)
                }}
                key={i}
                style={{
                  height: 40,
                  width: 40,
                  borderRadius: 4
                }}
                className={`flex cursor-pointer items-center justify-center border border-slate-300 ${
                  i === score
                    ? 'bg-blue-600 text-white'
                    : 'bg-slate-100 hover:bg-slate-200'
                }`}
              >
                <span className='text-lg font-normal'>{i + 1}</span>
              </div>
            ))}
          </div>

          <div className='mt-1 flex flex-row justify-between'>
            <span className='text-sm text-gray-900'>
              {t('customer_effort_score.first_screen.left_skala_title')}
            </span>
            <span className='text-sm text-gray-900'>
              {t('customer_effort_score.first_screen.right_skala_title')}
            </span>
          </div>
          <div className='my-6 border-b border-gray-200' />
          <div className='flex w-full flex-row justify-end'>
            <Button.PrimaryBase
              text={t('customer_effort_score.send_button_title')}
              disabled={score === null}
              onClick={submit}
            />
          </div>
          <div className='absolute left-8 top-6'>
            <svg
              data-tooltip-id='ces-info-text'
              data-tooltip-content={t('customer_effort_score.information_hint')}
              data-tooltip-place='top'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='h-6 w-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z'
              />
            </svg>
            <Tooltip
              id='ces-info-text'
              className='max-w-xs text-center text-sm'
            />
          </div>
        </div>
      )}
      {step === STEPS.SECOND && (
        <div>
          <DialogTitle className='mt-6 whitespace-pre-line text-center text-2xl font-medium leading-6 text-gray-900'>
            {isPositive
              ? t('customer_effort_score.second_screen.positive_title')
              : t('customer_effort_score.second_screen.negative_title')}
          </DialogTitle>
          <textarea
            rows={4}
            name='description'
            placeholder={t('customer_effort_score.textarea_placeholder')}
            id='description'
            className='mt-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            value={text}
            maxLength={500}
            onChange={(e) => setText(e.target.value)}
          />
          <div className='my-6 border-b border-gray-200' />
          <div className='flex w-full flex-row justify-end'>
            <Button.PrimaryBase
              text={t('customer_effort_score.send_button_title')}
              disabled={text === ''}
              onClick={update}
            />
          </div>
        </div>
      )}
      {step === STEPS.THIRD && (
        <div>
          <DialogTitle className='mt-6 whitespace-pre-line text-center text-2xl font-medium leading-6 text-gray-900'>
            {t('customer_effort_score.third_screen.title')}
          </DialogTitle>
          <textarea
            rows={4}
            name='description'
            placeholder={t('customer_effort_score.textarea_placeholder')}
            id='description'
            className='mt-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            value={text}
            maxLength={500}
            onChange={(e) => setText(e.target.value)}
          />
          <div className='my-6 border-b border-gray-200' />
          <div className='flex w-full flex-row justify-end'>
            <Button.PrimaryBase
              text={t('customer_effort_score.complete_button_title')}
              disabled={text === ''}
              onClick={update}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

CustomerEffortScore.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired
}

export default CustomerEffortScore
