import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createAnalyseInput } from '../../../constants/career-analyse'
import {
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../../../context'
import { mutation } from '../../../graphql'
import { sendSlackMessage } from '../../../helpers'
import Input from '../../input'
import Button from '../../tailwind/Button'
import Modal from '../../tailwind/Modal'
import SelectPreset from './select-preset'

const i18n = 'career.requirement_profile.presets.'

const ModalSelectPreset = ({
  open,
  closeModal,
  career,
  refetchCareerAnalyses
}) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { success } = useNotificationContext()
  const {
    active_space,
    cognitoUser: { username }
  } = useUserContext()

  const [isRequestingPreset, setIsRequestingPreset] = useState(false)
  const [showRequestPresetModal, setShowRequestPresetModal] = useState(false)
  const [requestPresetInput, setRequestPresetInput] = useState('')

  const [selectedPreset, setSelectedPreset] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClose = () => {
    setSelectedPreset(null)
    closeModal()
  }

  const createAnalyse = async () => {
    setIsSubmitting(true)

    const input = createAnalyseInput({
      career_id: career.id,
      status: 'COMPLETED',
      partner_id: partner.id,
      username
    })

    input.person_id = active_space.person.id
    input.verified = true
    input.input = selectedPreset.career_analyse // AWSJSON

    const preset = { ...selectedPreset }
    delete preset.career_analyse
    delete preset.displayValue
    delete preset.synonym
    delete preset.syns
    input.preset = JSON.stringify(preset)

    await mutation({ mutation: 'createCareerAnalyse', input })
    await refetchCareerAnalyses()

    setIsSubmitting(false)
    success(t(i18n + 'add_preset_success'))
    handleClose()
  }

  const requestPreset = async () => {
    setIsRequestingPreset(true)

    await sendSlackMessage(
      'bugsnag_customer-dashboard',
      `Profilanfrage: *${requestPresetInput}* (partner_id: ${partner.id} - active_space_id: ${active_space.id})`
    )

    setTimeout(() => {
      setIsRequestingPreset(false)
      success(t(i18n + 'request_preset_success'))
    }, 1000 * 0.8)
  }

  return (
    <Modal open={open} setOpen={handleClose} size='3xl'>
      <div className='p-4'>
        <div className='xmt-2'>
          <span
            id='use-presets'
            className='text-3xl font-extrabold tracking-tight text-blue-600'
          >
            {t(i18n + 'title')}
          </span>
        </div>
        <div className='mt-4 w-full md:w-9/12'>
          <p className='text-sm text-gray-900'>{t(i18n + 'description_1')}</p>
        </div>
        <div className='mt-8 max-w-2xl'>
          <SelectPreset callback={(item) => setSelectedPreset(item)} />
        </div>
        <div className='mt-4 flex items-center gap-x-1.5'>
          <span className='block text-sm text-gray-900'>
            {t(i18n + 'request_preset_title')}
          </span>
          <Button.Text
            className='inline-block'
            text={t(i18n + 'request_preset_action')}
            onClick={() => setShowRequestPresetModal(true)}
          />
        </div>
        <div className='mt-12 flex justify-end gap-x-2'>
          <Button.SecondaryXL
            text={t(
              'components.career.invite_career_analyse_dialog.dialog_cancel_action'
            )}
            onClick={handleClose}
          />
          <Button.PrimaryXL
            text={t(i18n + 'add_preset_action')}
            onClick={createAnalyse}
            isLoading={isSubmitting}
            disabled={!selectedPreset}
          />
        </div>
        <div className='pb-4' />
        <Modal
          open={showRequestPresetModal}
          setOpen={setShowRequestPresetModal}
          size='lg'
        >
          <span className='text-md block font-bold text-gray-900'>
            {t(i18n + 'request_preset_title')}
          </span>
          <span className='mt-2 block text-sm text-gray-900'>
            {t(i18n + 'request_preset_description')}
          </span>
          <div className='mt-4' />
          <Input
            id='request_preset'
            type='text'
            label={t(i18n + 'request_preset_input_title')}
            placeholder={t(i18n + 'request_preset_input_placeholder')}
            onChange={(event) => setRequestPresetInput(event.target.value)}
            value={requestPresetInput}
          />
          <div className='mt-8 flex justify-end'>
            <Button.PrimaryBase
              text={t(i18n + 'request_preset_submit_action')}
              onClick={requestPreset}
              isLoading={isRequestingPreset}
            />
          </div>
        </Modal>
      </div>
    </Modal>
  )
}

ModalSelectPreset.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  career: PropTypes.object.isRequired,
  refetchCareerAnalyses: PropTypes.func.isRequired
}

export default ModalSelectPreset
