import {
  BellAlertIcon as BellAlertIconOutline,
  LinkIcon as LinkIconOutline,
  TrashIcon as TrashIconOutline
} from '@heroicons/react/24/outline'
import {
  AtSymbolIcon as AtSymbolIconSolid,
  BeakerIcon as BeakerIconSolid,
  BellAlertIcon as BellAlertIconSolid,
  CircleStackIcon as CircleStackIconSolid,
  TrashIcon as TrashIconSolid
} from '@heroicons/react/24/solid'
import { formatDistanceToNow } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { colors } from '../../../constants/colors'
import trackEvents from '../../../constants/track-events'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext
} from '../../../context'
import { mutation, query } from '../../../graphql'
import { getRequirementAnalyseLink } from '../../../helpers'
import { trackEvent } from '../../../helpers/analytics'
import State from '../../state'
import MenuButton from '../../tailwind/MenuButton'

const I18N = 'components.career.expert_analysis.'

const Analyses = ({
  career,
  careers,
  careerAnalyses,
  refetchCareerAnalyses,
  setOpenModal
}) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { language } = useLanguageContext()
  const { success, alert } = useNotificationContext()

  const [remindExpert, setRemindExpert] = useState(null)

  async function deleteCareerAnalyse({ id: analyse_id, person, preset }) {
    const text_key = preset
      ? 'modal_delete_analyse_text_for_preset'
      : 'modal_delete_analyse_text_for_email'

    alert(
      t(I18N + 'modal_delete_analyse_title'),
      t(I18N + text_key, {
        email: person?.email || person?.invitation_email,
        preset_name: preset?.name[language]
      }),
      [
        {
          onPress: async () => {
            try {
              await mutation({
                mutation: 'deleteCareerAnalyse',
                input: { id: analyse_id }
              })
            } catch (err) {
              return
            }

            refetchCareerAnalyses()

            trackEvent(trackEvents.DELETE_CAREER_ANALYSE)
            success(t(I18N + 'delete_analyse_success'))
          },
          text: t(I18N + 'modal_delete_analyse_action')
        },
        {
          text: t(I18N + 'modal_delete_analyse_cancel'),
          style: 'cancel'
        }
      ],
      <TrashIconSolid className='h-8 w-8 text-gray-800' />
    )
  }

  const copyInvitationLinkToClipboard = ({ id }) => {
    const invitationLink = getRequirementAnalyseLink({ analyse_id: id })
    navigator.clipboard.writeText(invitationLink)

    success(t(I18N + 'copy_success_message'))
  }

  const remindExpertHandler = ({ id: analyse_id, person }) => {
    const { email } = person

    alert(
      t(I18N + 'modal_remind_expert_title'),
      t(I18N + 'modal_remind_expert_text', { email }),
      [
        {
          onPress: () => {
            setRemindExpert(analyse_id)

            const input = {
              to_addresses: [email],
              template_id: { de: 'INVITE_EXPERT_DE', en: 'INVITE_EXPERT_EN' }[
                language
              ],
              input: JSON.stringify({
                partner_name: partner.display_name || partner.name,
                career_name: career.title,
                invitation_link: getRequirementAnalyseLink({ analyse_id })
              })
            }

            query({ query: 'sendTemplateMail', variables: input }).then(() => {
              success(t(I18N + 'remind_expert_success', { email }))

              trackEvent(trackEvents.REMIND_EXPERT_CAREER_ANALYSE)
              setRemindExpert(null)
            })
          },
          text: t(I18N + 'modal_remind_expert_action')
        },
        {
          text: t(I18N + 'modal_remind_expert_cancel'),
          style: 'cancel'
        }
      ],
      <BellAlertIconSolid className='h-8 w-8 text-gray-800' />
    )
  }

  return (
    <>
      <div className='mt-8 flex flex-row items-end justify-between'>
        <span className='mr-2 text-2xl/6 font-bold text-gray-900'>
          {t('career.requirement_profile.experts.invited_experts_title')}
        </span>
        <MenuButton
          cta={t(I18N + 'action_cta')}
          menuItems={[
            {
              text: t(I18N + 'action_invite_experts_name'),
              onClick: () => {
                setOpenModal('invite-experts')
                trackEvent(trackEvents.PRESSED_TAB_INVITE_EXPERTS)
              },
              Icon: AtSymbolIconSolid
            },
            {
              text: t(I18N + 'action_use_presets_name'),
              onClick: () => {
                setOpenModal('select-preset')
                trackEvent(trackEvents.PRESSED_TAB_USE_PRESETS)
              },
              Icon: BeakerIconSolid
            },
            {
              text: t(I18N + 'action_select_career_name'),
              onClick: () => {
                setOpenModal('select-career')
                trackEvent(trackEvents.PRESSED_TAB_SELECT_CAREER)
              },
              Icon: CircleStackIconSolid
            }
          ]}
        />
      </div>
      {isEmpty(careerAnalyses) && (
        <span className='mt-2 block text-sm italic text-gray-900'>
          {t('career.requirement_profile.experts.nobody_invited')}
        </span>
      )}
      <ul className='mt-4 divide-y divide-gray-200'>
        {careerAnalyses
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .map((analyse, index) => {
            const isPreset = !!analyse.preset
            const isCompleted = analyse.status === 'COMPLETED'
            const hasStarted = !!analyse.input
            const isCopy = !!analyse.copy_from_careeranalyse_id

            const distanceToNow = formatDistanceToNow(
              new Date(isCompleted ? analyse.updatedAt : analyse.createdAt),
              { addSuffix: true, locale: { de, en }[language] }
            )

            const status =
              isPreset || !hasStarted || isCopy
                ? t(I18N + 'expert_analysis_added', { distanceToNow })
                : isCompleted
                  ? t(I18N + 'expert_analysis_fulfilled', { distanceToNow })
                  : t(I18N + 'expert_analysis_last_edited', { distanceToNow })

            const stateProps = isPreset
              ? {
                  color: 'indigo',
                  text: t(I18N + 'expert_analysis_state_preset')
                }
              : isCopy
                ? {
                    color: 'indigo',
                    text: t(I18N + 'expert_analysis_state_copy', {
                      career_title: careers.find(
                        ({ id }) =>
                          id === analyse.copy_from_careeranalyse_career_id
                      ).title
                    })
                  }
                : isCompleted
                  ? {
                      color: 'green',
                      text: t(I18N + 'expert_analysis_state_fulfilled')
                    }
                  : hasStarted
                    ? {
                        color: 'yellow',
                        text: t(I18N + 'expert_analysis_state_started')
                      }
                    : {
                        color: 'grey',
                        text: t(I18N + 'expert_analysis_state_invited')
                      }

            const displayValue = isPreset
              ? analyse.preset.name[language]
              : analyse.person?.email || analyse.person?.invitation_email

            return (
              <li
                key={index}
                className='flex items-center justify-between px-3 py-5'
              >
                <div className='flex flex-col'>
                  <span className='text-sm font-medium text-blue-600'>
                    {displayValue}
                  </span>
                  <span className='mt-0.5 text-xs italic text-gray-700'>
                    {status}
                  </span>
                </div>
                <div className='flex flex-row items-center'>
                  <div className='mr-4 hidden flex-shrink-0 gap-x-2 sm:flex'>
                    {analyse.id.includes('sample') && (
                      <State color='orange' text='Demo' />
                    )}
                    <State {...stateProps} />
                  </div>
                  <div className='flex items-center justify-center'>
                    {!isCompleted && (
                      <div
                        data-tooltip-id='remind'
                        className='flex h-8 w-8 cursor-pointer items-center justify-center rounded-full'
                      >
                        {remindExpert === analyse.id ? (
                          <BeatLoader
                            color={colors.darkGrey}
                            speedMultiplier={0.5}
                            size={7}
                          />
                        ) : (
                          <>
                            <BellAlertIconOutline
                              className='h-5 w-5 text-gray-800'
                              onClick={() => remindExpertHandler(analyse)}
                            />
                            <ReactTooltip
                              id='remind'
                              content={t(I18N + 'expert_actions.remind')}
                              style={{ backgroundColor: '#182033' }}
                              className='rounded-full font-medium'
                              place='top'
                              variant='info'
                            />
                          </>
                        )}
                      </div>
                    )}
                    {!isCompleted && !isPreset && (
                      <div
                        data-tooltip-id='copy_link'
                        className='flex h-8 w-8 cursor-pointer items-center justify-center rounded-full'
                        onClick={() => copyInvitationLinkToClipboard(analyse)}
                      >
                        <LinkIconOutline className='h-5 w-5 text-gray-800' />
                        <ReactTooltip
                          id='copy_link'
                          content={t(I18N + 'expert_actions.copy_link')}
                          className='rounded-full font-medium'
                          style={{ backgroundColor: '#182033' }}
                          place='top'
                          variant='info'
                        />
                      </div>
                    )}
                    <div
                      data-tooltip-id='delete'
                      className='flex h-8 w-8 cursor-pointer items-center justify-center rounded-full'
                    >
                      <TrashIconOutline
                        className='h-5 w-5 text-gray-800'
                        onClick={() => deleteCareerAnalyse(analyse)}
                      />
                      <ReactTooltip
                        id='delete'
                        content={t(I18N + 'expert_actions.delete')}
                        style={{ backgroundColor: '#182033' }}
                        className='rounded-full font-medium'
                        place='top'
                        variant='info'
                      />
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
    </>
  )
}

Analyses.propTypes = {
  career: PropTypes.object.isRequired,
  careers: PropTypes.array.isRequired,
  careerAnalyses: PropTypes.array.isRequired,
  refetchCareerAnalyses: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired
}

export default Analyses
