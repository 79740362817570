import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import trackEvents from '../../constants/track-events'
import { classNames } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import Button from './Button'

/**
 * TailwindUI Application UI / Headings / Page Headings
 * Supporting with Actions
 * https://tailwindui.com/components/application-ui/elements/badges
 */
const PageHeading = ({
  withActions,
  title,
  subtitle,
  sticky,
  children,
  withRefetch
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={classNames(
        'bg-gray-50 px-4 py-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8',
        sticky === true && 'sticky top-0 z-10',
        children ? '' : 'md:h-20 md:py-0'
      )}
    >
      {title && (
        <div className='min-w-0 flex-1'>
          <h1 className='text-2xl font-bold leading-7 text-gray-900 md:truncate md:text-3xl md:tracking-tight'>
            {title}
          </h1>
          {subtitle && (
            <div className='flex flex-row items-center'>
              <span className='block text-xs text-gray-700'>{subtitle}</span>
              {withRefetch && (
                <Button.Text
                  onClick={() => {
                    trackEvent(trackEvents.REFETCH_NOW)
                    withRefetch()
                  }}
                  text={t('global.refetch_now')}
                  className='ml-2'
                />
              )}
            </div>
          )}
        </div>
      )}
      {children && children}
      {withActions && (
        <div className='mt-4 flex md:ml-4 md:mt-0'>{withActions}</div>
      )}
    </div>
  )
}

PageHeading.propTypes = {
  withActions: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sticky: PropTypes.bool,
  children: PropTypes.node,
  withRefetch: PropTypes.func
}

PageHeading.defaultProps = {
  withActions: undefined,
  title: undefined,
  subtitle: undefined,
  sticky: false,
  children: undefined,
  withRefetch: undefined
}

export default PageHeading
