/* eslint-disable react/prop-types */
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  Transition,
  TransitionChild
} from '@headlessui/react'
import clsx from 'clsx'
import { Fragment } from 'react'

import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import AivyLogoRGB from '../../assets/images/aivy_logo_rgb.png'
import trackEvents from '../../constants/track-events'
import { trackEvent } from '../../helpers/analytics'
import { Button } from './Button'
import { Container } from './Container'
import { NavLink } from './NavLink'

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden='true'
      className='h-3.5 w-3.5 overflow-visible stroke-slate-700'
      fill='none'
      strokeWidth={2}
      strokeLinecap='round'
    >
      <path
        d='M0 1H14M0 7H14M0 13H14'
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0'
        )}
      />
      <path
        d='M2 2L12 12M12 2L2 12'
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0'
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <PopoverButton
        className='ui-not-focus-visible:outline-none relative z-10 flex h-8 w-8 items-center justify-center'
        aria-label='Toggle Navigation'
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <Transition>
        <TransitionChild
          as={Fragment}
          enter='duration-150 ease-out'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='duration-150 ease-in'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <PopoverOverlay className='fixed inset-0 bg-slate-300/50' />
        </TransitionChild>
        <TransitionChild
          as={Fragment}
          enter='duration-150 ease-out'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='duration-100 ease-in'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <PopoverPanel
            as='div'
            className='absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5'
          >
            <NavLink
              onClick={() =>
                trackEvent(trackEvents.CLICK_NAV_FEATURES_LANDINGPAGE)
              }
              to='#features'
            >
              {t('landingpage.header.nav.features_title')}
            </NavLink>
            <NavLink
              onClick={() =>
                trackEvent(trackEvents.CLICK_NAV_TESTIMONIALS_LANDINGPAGE)
              }
              to='#testimonials'
            >
              {t('landingpage.header.nav.testimonials_title')}
            </NavLink>
            <NavLink
              onClick={() =>
                trackEvent(trackEvents.CLICK_NAV_PRICING_LANDINGPAGE)
              }
              to='#pricing'
            >
              {t('landingpage.header.nav.pricing_title')}
            </NavLink>
            <hr className='m-2 border-slate-300/40' />
            <NavLink
              onClick={() => {
                trackEvent(trackEvents.CLICK_NAV_LOGIN_LANDINGPAGE)
              }}
              to='/'
            >
              {t('landingpage.header.login')}
            </NavLink>
            {/* <MobileNavLink href='/login'>Unternehmens-Login</MobileNavLink> */}
          </PopoverPanel>
        </TransitionChild>
      </Transition>
    </Popover>
  )
}

export function Header({ onPress, shrink }) {
  const { t } = useTranslation()

  return (
    <header className={shrink === true ? 'py-5' : 'py-10'}>
      <Container>
        <nav className='relative z-50 flex justify-between'>
          <div className='flex items-center md:gap-x-12'>
            <a href='https://www.aivy.app'>
              <img src={AivyLogoRGB} className='h-10 w-auto' />
            </a>
            <div className='hidden md:flex md:gap-x-6'>
              <NavLink
                onClick={() =>
                  trackEvent(trackEvents.CLICK_NAV_FEATURES_LANDINGPAGE)
                }
                to='#features'
              >
                {t('landingpage.header.nav.features_title')}
              </NavLink>
              <NavLink
                onClick={() =>
                  trackEvent(trackEvents.CLICK_NAV_TESTIMONIALS_LANDINGPAGE)
                }
                to='#testimonials'
              >
                {t('landingpage.header.nav.testimonials_title')}
              </NavLink>
              <NavLink
                onClick={() =>
                  trackEvent(trackEvents.CLICK_NAV_PRICING_LANDINGPAGE)
                }
                to='#pricing'
              >
                {t('landingpage.header.nav.pricing_title')}
              </NavLink>
            </div>
          </div>
          <div className='flex items-center gap-x-5 md:gap-x-8'>
            <div className='hidden md:block'>
              <NavLink
                onClick={() => {
                  trackEvent(trackEvents.CLICK_NAV_LOGIN_LANDINGPAGE)
                }}
                to='/'
              >
                {t('landingpage.header.login')}
              </NavLink>
            </div>
            <Button onClick={onPress} color='blue'>
              <span>{t('landingpage.header.start_now')}</span>
            </Button>
            <div className='-mr-1 md:hidden'>
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  )
}
