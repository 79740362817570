import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useAivyContext, useNotificationContext } from '../../../context'
import {
  mapChallengesToCoveredDimensions,
  testBatteryConfig
} from '../../../helpers/diagnostic'
import Button from '../../tailwind/Button'
import ChallengeCard from '../pieces/challenge-card'
import DividerWithText from './divider-with-text'

const I18N_PREFIX = 'create_career.adjust_test_battery.'

const CustomizationTestBattery = ({
  career,
  refetchCareer,
  careerMutation
}) => {
  const { t } = useTranslation()
  const { system } = useAivyContext()
  const { success, error } = useNotificationContext()

  const system_dimensions_data = JSON.parse(system.dimensions_pdf_reports.data)

  const allChallenges = Object.values(JSON.parse(system.challenges.data))
    .map((c) => c.exam_id)
    .filter((exam_id) => exam_id !== 'PATH_FINDER')

  const parsedCareerAnalyse = useMemo(
    () => JSON.parse(career.career_analyse),
    [career]
  )

  const initialChallenges = useMemo(() => {
    const res = {}

    const selectedChallenges =
      parsedCareerAnalyse.override_defaults.selected_challenges.map(
        ({ exam_id }) => exam_id
      )

    allChallenges.forEach((exam_id) => {
      if (selectedChallenges.includes(exam_id)) res[exam_id] = true
      else res[exam_id] = false
    })

    return res
  }, [parsedCareerAnalyse, allChallenges])

  const configuration = testBatteryConfig(parsedCareerAnalyse.scores)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { challenges: initialChallenges },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const filteredChallenges = Object.fromEntries(
          Object.entries(values.challenges).filter(
            ([key, value]) => value === true
          )
        )

        const challenges = Object.keys(filteredChallenges).map((exam_id) => ({
          exam_id
        }))

        const dimensions = mapChallengesToCoveredDimensions({
          challenges,
          dimensions: Object.values(system_dimensions_data)
        })

        const updatedOverrideDefaults = {
          dimensions,
          selected_challenges: challenges
        }

        const beforeOverrideDefaults =
          parsedCareerAnalyse.override_defaults || {}

        const mergedOverrideDefaults = {
          ...beforeOverrideDefaults,
          ...updatedOverrideDefaults
        }

        await careerMutation.mutate({
          input: {
            id: career.id,
            career_analyse: JSON.stringify({
              ...parsedCareerAnalyse,
              override_defaults: mergedOverrideDefaults
            })
          }
        })

        await refetchCareer()
        success(t(I18N_PREFIX + 'success'))
      } catch (err) {
        error(t(I18N_PREFIX + 'error'))
      } finally {
        setSubmitting(false)
      }
    }
  })

  const isSubmitButtonDisabled = !formik.dirty || formik.isSubmitting

  const handleToggle = (challenge) => {
    const { exam_id, standard } = challenge

    if (standard) return

    formik.setFieldValue(
      `challenges.${exam_id}`,
      !formik.values.challenges[exam_id]
    )
  }

  const selectedChallenges = Object.keys(formik.values.challenges).filter(
    (key) => formik.values.challenges[key] // true || false
  )

  const standardChallenges = allChallenges
    .map((exam_id) => ({
      exam_id,
      ...system.challenges[exam_id],
      ...configuration[exam_id]
    }))
    .filter(({ standard }) => standard)

  const moreChallenges = allChallenges
    .map((exam_id) => ({
      exam_id,
      ...system.challenges[exam_id],
      ...configuration[exam_id]
    }))
    .filter(({ standard }) => !standard)

  return (
    <>
      <div className='items-end justify-between md:flex'>
        <div>
          <h2 className='text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
            {t(I18N_PREFIX + 'title')}
          </h2>
          <ReactMarkdown className='mt-2 max-w-2xl text-sm/6 text-gray-900'>
            {t(I18N_PREFIX + 'description')}
          </ReactMarkdown>
        </div>
        <span className='text-sm/6 font-semibold'>
          {t(I18N_PREFIX + 'number_challenges_selected', {
            number: selectedChallenges.length,
            total: allChallenges.length
          })}
        </span>
      </div>

      <DividerWithText
        className='mt-6'
        text={t(I18N_PREFIX + 'standard_divider_title')}
      />
      <div className='mt-6 grid grid-cols-1 gap-4 xl:grid-cols-2'>
        {standardChallenges.map((challenge, index) => (
          <ChallengeCard
            key={index}
            challenge={challenge}
            selected={formik.values.challenges[challenge.exam_id]}
          />
        ))}
      </div>
      <DividerWithText
        className='mt-6'
        text={t(I18N_PREFIX + 'more_divider_title')}
      />
      <div className='mt-6 grid grid-cols-1 gap-4 xl:grid-cols-2'>
        {moreChallenges.map((challenge, index) => (
          <ChallengeCard
            key={index}
            challenge={challenge}
            onClick={() => handleToggle(challenge)}
            selected={formik.values.challenges[challenge.exam_id]}
          />
        ))}
      </div>
      <div className='mt-12 flex justify-end'>
        <Button.PrimaryXL
          text={t('create_career.hardness_of_selection.cta_save')}
          onClick={formik.handleSubmit}
          disabled={isSubmitButtonDisabled}
          className={`${isSubmitButtonDisabled ? 'opacity-50' : ''}`}
          isLoading={formik.isSubmitting}
        />
      </div>
    </>
  )
}

CustomizationTestBattery.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  refetchCareer: PropTypes.func.isRequired
}

export default CustomizationTestBattery
