import { LockClosedIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const LockedIcon = ({ id, text }) => {
  return (
    <div>
      <div
        data-tooltip-id={id}
        className='ml-2 flex h-7 w-7 items-center justify-center rounded-full border-2 border-aivyOrange'
      >
        <LockClosedIcon className='h-4 w-5 text-aivyOrange' />
      </div>
      <ReactTooltip
        id={id}
        content={text}
        style={{ backgroundColor: '#45586B' }}
        place='right'
        variant='info'
        className='z-20 max-w-xs rounded-full font-medium sm:max-w-sm'
      />
    </div>
  )
}

LockedIcon.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string
}

LockedIcon.defaultProps = {
  text: 'Hier Tipp / Info übergeben'
}

export default LockedIcon
