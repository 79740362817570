import { BellIcon, TrashIcon } from '@heroicons/react/24/solid'
import { differenceInDays } from 'date-fns'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { classNames } from '../../helpers'
import Panel from '../tailwind/Panel'

const AUTOMATION_UI_ITEMS = {
  AUTOMATION_DELETE_SPACE_CONNECTION_TO_PARTNER: {
    Icon: TrashIcon,
    bgColor: 'bg-blue-500',
    textColor: 'text-white',
    translation_key: 'space.ttl_timeline.auto_delete_text'
  },
  AUTOMATION_ASSESSMENT_REMINDER: {
    Icon: BellIcon,
    bgColor: 'bg-yellow-400',
    textColor: 'text-white',
    translation_key: 'space.ttl_timeline.auto_remind_text'
  }
}

const AUTOMATION_UI_ITEMS_KEYS = Object.keys(AUTOMATION_UI_ITEMS)

const SpaceTtlTimeline = ({ space }) => {
  const { t } = useTranslation()

  const cron_ttls = space.cron_ttls
    .sort((a, b) => a.ttl - b.ttl)
    .filter((item) => AUTOMATION_UI_ITEMS_KEYS.includes(item.job))
    .filter((item) => {
      if (
        space.progress === 100 &&
        item.job === 'AUTOMATION_ASSESSMENT_REMINDER'
      ) {
        return false
      }

      return true
    })
    .map((item) => ({
      ...item,
      ...AUTOMATION_UI_ITEMS[item.job],
      differenceInDays: differenceInDays(new Date(item.ttl * 1000), new Date())
    }))

  return (
    <Panel>
      <h2 className='text-lg font-medium leading-6 text-gray-900'>
        {t('space.ttl_timeline.title')}
      </h2>
      <p className='mt-1 max-w-2xl text-sm text-gray-500'>
        {t('space.ttl_timeline.description')}
      </p>
      <div className='mt-5 border-t border-gray-300' />
      <div className='mt-7 flow-root'>
        <ul role='list'>
          {cron_ttls.map(
            (
              { Icon, bgColor, textColor, translation_key, differenceInDays },
              index
            ) => (
              <li key={index}>
                <div className='relative pb-8'>
                  {index !== cron_ttls.length - 1 ? (
                    <span
                      className='absolute left-4 top-4 -ml-0.5 h-full w-px bg-gray-300'
                      aria-hidden='true'
                    />
                  ) : null}
                  <div className='relative flex space-x-3'>
                    <div>
                      <span
                        className={classNames(
                          bgColor,
                          'flex h-7 w-7 items-center justify-center rounded-full ring-2 ring-white'
                        )}
                      >
                        <Icon
                          className={classNames(
                            'h-5 w-5 text-white',
                            textColor
                          )}
                          aria-hidden='true'
                        />
                      </span>
                    </div>
                    <p className='pt-1 text-sm text-gray-900'>
                      {t(translation_key, {
                        convertedDayInput:
                          differenceInDays === 0 || differenceInDays === 1
                            ? t('space.ttl_timeline.automation_day')
                            : t('space.ttl_timeline.automation_days', {
                                days: differenceInDays
                              })
                      })}
                    </p>
                  </div>
                </div>
              </li>
            )
          )}
        </ul>
      </div>
      <Link
        to='/settings#automations'
        className='text-sm text-gray-700 hover:text-blue-600'
      >
        {t('space.ttl_timeline.settings_action')}
        <span aria-hidden='true' className='font-medium'>
          {' '}
          &rarr;
        </span>
      </Link>
    </Panel>
  )
}

SpaceTtlTimeline.propTypes = { space: PropTypes.object.isRequired }

export default SpaceTtlTimeline
