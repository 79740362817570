import {
  Menu,
  MenuButton as MenuButtonHeadless,
  MenuItem,
  MenuItems,
  Transition
} from '@headlessui/react'
import { EnvelopeIcon, PlusIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

const MenuButton = ({
  IconCta = PlusIcon,
  cta = 'call to action',
  menuItems = [
    { text: 'Lorem Ipsum', onClick: () => undefined, Icon: EnvelopeIcon }
  ]
}) => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <MenuButtonHeadless
        id='btn-invite-talent'
        type='button'
        className='inline-flex items-center justify-center gap-x-1 whitespace-nowrap rounded-full bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
      >
        <IconCta className='-ml-0.5 h-5 w-5' aria-hidden='true' />
        {cta}
      </MenuButtonHeadless>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <MenuItems className='absolute right-0 z-10 mt-2 min-w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            {menuItems.map(({ text, onClick, Icon }, index) => (
              <MenuItem key={index}>
                {({ focus }) => (
                  <button
                    onClick={onClick}
                    className={clsx(
                      focus
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700 group-hover:text-gray-900',
                      'group flex w-full items-center whitespace-nowrap px-4 py-3 text-sm'
                    )}
                  >
                    <Icon
                      className='mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-700'
                      aria-hidden='true'
                    />
                    {text}
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

MenuButton.propTypes = {
  IconCta: PropTypes.node,
  cta: PropTypes.string,
  menuItems: PropTypes.array
}

export default MenuButton
