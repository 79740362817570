import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { classNames } from '../helpers'

const DEFAULT_CLASSES =
  'shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300'
const ERROR_CLASSES =
  'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'

const Input = forwardRef(
  (
    {
      id,
      label,
      type,
      onChange,
      onKeyDown,
      onBlur,
      value,
      placeholder,
      touched,
      error,
      disabled,
      autoComplete,
      description,
      inlineErrorMessage,
      optional
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <label
            htmlFor={id}
            className='block text-sm font-medium text-gray-700'
          >
            {label}
            {optional && (
              <label className='inline text-sm font-normal text-gray-400'>
                {' (optional)'}
              </label>
            )}
          </label>
        )}
        <div className={classNames('relative', label && 'mt-2')}>
          <input
            ref={ref}
            id={id}
            type={type}
            name={id}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            className={`block w-full rounded-md text-sm placeholder:italic ${
              touched && error ? ERROR_CLASSES : DEFAULT_CLASSES
            }`}
            style={disabled ? { background: '#eeeeee', cursor: 'auto' } : {}}
            readOnly={disabled}
            disabled={disabled}
            autoComplete={autoComplete}
          />
          {touched && error && (
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              {inlineErrorMessage && (
                <p className='mr-1 pl-2 text-sm text-red-600'>{error}</p>
              )}
              <ExclamationCircleIcon
                className='h-5 w-5 text-red-500'
                aria-hidden='true'
              />
            </div>
          )}
        </div>
        {touched && !inlineErrorMessage && error && (
          <p className='mt-2 text-sm text-red-600'>{error}</p>
        )}
        {description && (
          <p className='mt-2 text-xs italic text-gray-500'>{description}</p>
        )}
      </>
    )
  }
)

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  description: PropTypes.string,
  inlineErrorMessage: PropTypes.bool,
  optional: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  label: undefined,
  onChange: undefined,
  onKeyDown: undefined,
  onBlur: undefined,
  value: undefined,
  placeholder: '',
  touched: undefined,
  error: undefined,
  disabled: false,
  autoComplete: undefined,
  description: undefined,
  inlineErrorMessage: true,
  optional: false
}

export default Input
