import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useMount } from '../hooks/use-mount'

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

const SelectMenu = ({
  id,
  label,
  description,
  options,
  className,
  onChange,
  defaultValue,
  disabled,
  translate,
  position
}) => {
  const { t } = useTranslation()

  const buttonRef = useRef(null)

  const [optionsPosition, setOptionsPosition] = useState(position)
  const [selectedKey, setSelectedKey] = useState(defaultValue)

  useMount(() => {
    if (position !== 'auto') return

    const updateOptionsPosition = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect()
        const spaceBelow = window.innerHeight - rect.bottom
        const spaceAbove = rect.top

        // set listbox options position based on available space
        if (spaceBelow < 320 && spaceAbove > spaceBelow) {
          setOptionsPosition('top')
        } else setOptionsPosition('bottom')
      }
    }

    updateOptionsPosition()

    window.addEventListener('resize', updateOptionsPosition)
    return () => window.removeEventListener('resize', updateOptionsPosition)
  })

  const selectedValue = useMemo(() => {
    const selected = options.find(([key]) => key === selectedKey)

    return {
      value: translate ? t(selected[1]) : selected[1],
      subvalue: selected[2]
    }
  }, [selectedKey, options, translate, t])

  const handleChange = (value) => setSelectedKey(value) || onChange(value)

  return (
    <div className={className}>
      <Listbox
        disabled={disabled}
        value={selectedKey}
        onChange={(value) => handleChange(value)}
      >
        {label && (
          <Label className='block text-sm font-medium text-gray-700'>
            {label}
          </Label>
        )}
        <div className={classNames('relative', label && 'mt-2')}>
          <ListboxButton
            ref={buttonRef}
            className={classNames(
              'relative w-full border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm',
              disabled
                ? 'border-l'
                : 'cursor-pointer rounded-md border shadow-sm'
            )}
          >
            <span className='flex items-center'>
              <ReactMarkdown>{selectedValue.value}</ReactMarkdown>
              {selectedValue.subvalue && (
                <span className='ml-1.5 text-sm text-gray-700'>
                  {selectedValue.subvalue}
                </span>
              )}
            </span>
            <span
              className={classNames(
                'pointer-events-none absolute inset-y-0 right-0 pr-2',
                disabled ? 'hidden' : 'flex items-center'
              )}
            >
              <ChevronUpDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </ListboxButton>
          <ListboxOptions
            id={id}
            className={classNames(
              'absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5',
              optionsPosition === 'bottom' && 'mt-1',
              optionsPosition === 'top' && 'bottom-full mb-1',
              'data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in'
            )}
          >
            {options.map((option, index) => (
              <ListboxOption
                key={index}
                className={({ active }) =>
                  classNames(
                    active ? 'bg-blue-600 text-white' : 'text-gray-900',
                    'relative cursor-pointer select-none py-2 pl-3 pr-9'
                  )
                }
                value={option[0]}
              >
                {({ selected, active }) => {
                  let value = translate ? t(option[1]) : option[1]
                  if (selected) value = value.replaceAll('**', '')

                  return (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-bold' : 'font-normal',
                          'flex items-center'
                        )}
                      >
                        <ReactMarkdown>{value}</ReactMarkdown>
                        {option[2] && (
                          <span
                            className={classNames(
                              'ml-1.5 text-sm',
                              active ? 'text-white' : 'text-gray-700'
                            )}
                          >
                            {option[2]}
                          </span>
                        )}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      )}
                    </>
                  )
                }}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
        {description && (
          <p className='mt-2 text-xs italic text-gray-700'>{description}</p>
        )}
      </Listbox>
    </div>
  )
}

SelectMenu.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.array, // [ key, value ]
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  translate: PropTypes.bool,
  position: PropTypes.string
}

SelectMenu.defaultProps = {
  id: 'location',
  label: undefined,
  description: undefined,
  options: [],
  classNames: '',
  defaultValue: undefined,
  disabled: false,
  translate: true,
  position: 'bottom'
}

export default SelectMenu
