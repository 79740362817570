import {
  ClockIcon,
  GlobeAltIcon,
  Square3Stack3DIcon
} from '@heroicons/react/24/outline'
import 'html5-device-mockups'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import trackEvents from '../constants/track-events'
import { useAivyContext, useLanguageContext } from '../context'
import { convertNumberToTime } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import Badge from './tailwind/Badge'
import Button from './tailwind/Button'
import Modal from './tailwind/Modal'

const ChallengeInfoModal = ({ open, setOpen, challenge }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()
  const { system } = useAivyContext()

  if (!challenge) return null

  const { minutes: challenge_duration } = convertNumberToTime(
    challenge.timeToComplete / 60000
  )

  const category = system.categories[challenge.category_id]
  const dimensions = Object.values(
    JSON.parse(system.dimensions_pdf_reports.data)
  ).filter((dimension) => dimension.measured_by.includes(challenge.exam_id))

  return (
    <Modal open={open} setOpen={setOpen} size='5xl'>
      <div
        style={{ maxHeight: 'calc(100vh - 14rem)' }}
        className='mt-8 flex flex-row justify-between overflow-y-scroll pb-8'
      >
        <div className='w-full lg:w-6/12'>
          <div className='flex flex-row items-center'>
            <img
              className='h-16 w-16 rounded-full bg-gray-200 object-contain p-1'
              src={challenge.card.image}
              style={{ backgroundColor: challenge.backgroundColor }}
              alt='challenge image'
            />
            <div className='ml-3 flex flex-col'>
              <h3 className='text-xl font-bold text-gray-900'>
                {challenge.title[language]}
              </h3>
              <div className='mt-1 flex space-x-2'>
                <Badge
                  color='pink'
                  text={challenge.dimensionSynonym[language]}
                />
                {challenge.dimensionSynonym[language] !==
                  category.name[language] && (
                  <Badge color='pink' text={category.name[language]} />
                )}
              </div>
            </div>
          </div>
          <p className='mt-6 max-w-xl text-sm text-gray-700'>
            {challenge.introScreen.intro[language]}
          </p>
          <p className='mt-4 max-w-xl text-sm text-gray-700'>
            {challenge.description[language]}
          </p>
          <div className='mt-4 flex flex-col gap-y-3'>
            <div className='flex items-center'>
              <ClockIcon className='h-6 w-6 text-gray-900' />
              <span className='ml-1.5 text-sm font-medium text-gray-900'>
                {t('challenge_material_card.minutes_title')}
              </span>
              <span className='ml-1 text-sm text-gray-900'>
                {t('challenge_material_card.minutes', {
                  minutes: challenge_duration
                })}
              </span>
            </div>
            <div className='flex items-center'>
              <GlobeAltIcon className='h-6 w-6 text-gray-900' />
              <span className='ml-1.5 text-sm font-medium text-gray-900'>
                {t('challenge_material_card.languages_title')}
              </span>
              <span className='ml-1 text-sm text-gray-900'>
                {challenge.available_languages
                  .map((lang) => t(`challenge_material_card.${lang}`))
                  .join(', ')}
              </span>
            </div>
            <div className='flex items-center'>
              <Square3Stack3DIcon className='h-6 w-6 text-gray-900' />
              <span className='ml-1.5 text-sm font-medium text-gray-900'>
                {t('challenge_material_card.platforms_title')}
              </span>
              <span className='ml-1 text-sm text-gray-900'>
                {challenge.available_platforms
                  .map((platform) => t(`challenge_material_card.${platform}`))
                  .join(', ')}
              </span>
            </div>
          </div>

          <h3 className='text-md mt-6 font-semibold text-gray-900'>
            {t('challenge_info_modal.measuring_title')}
          </h3>
          <p className='mt-1 max-w-xl text-sm text-gray-700'>
            {challenge.measuring[language]}
          </p>

          <h3 className='text-md mt-6 font-semibold text-gray-900'>
            {t('challenge_info_modal.choosing_title')}
          </h3>
          <p className='mt-1 max-w-xl text-sm text-gray-700'>
            {challenge.choosing[language]}
          </p>

          <h3 className='text-md mt-6 font-semibold text-gray-900'>
            {t('create_career.configuration.dimensions_title')}
          </h3>
          <p className='mt-1 max-w-xl text-sm text-gray-700'>
            {t('create_career.configuration.dimensions_description')}
          </p>
          <ul className='ml-6 mt-2 list-disc pb-8'>
            {dimensions.map(({ property, intro }, index) => (
              <li key={index} className='mb-2 max-w-lg text-sm text-gray-700'>
                <p className='font-medium text-gray-900'>
                  {property[language]}
                </p>
                <p className='mt-0.5'>{intro[language]}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className='hidden w-5/12 justify-center lg:flex'>
          <div className='fixed w-52'>
            <div className='device-wrapper'>
              <div
                className='device'
                data-device='Pixel'
                data-orientation='portrait'
                data-color='white'
              >
                <div className='screen flex justify-center bg-white'>
                  <img
                    className='w-full object-contain'
                    src={challenge.instructionScreen.image}
                    alt='GIF'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-end gap-x-4 border-t border-gray-300 pt-8'>
        <Button.PrimaryXL
          text={t('create_career.configuration.tryout')}
          onClick={() => {
            trackEvent(trackEvents.PRESSED_TRYOUT_CHALLENGE)
            window.open(
              `http://webapp.aivy.app/?challenge=${challenge.exam_id}`
            )
          }}
        />
      </div>
    </Modal>
  )
}

ChallengeInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  challenge: PropTypes.object
}

export default ChallengeInfoModal
