import PropTypes from 'prop-types'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import trackEvents from '../constants/track-events'
import { trackEvent } from '../helpers/analytics'
import Modal from './tailwind/Modal'

const Paywall = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const paywalls = {
    general: {
      title: t('paywall.general.title'),
      paragraphs: [t('paywall.general.paragraphs.first')],
      buttonText: t('paywall.general.button_text'),
      analyticsEvent: trackEvents.PRESSED_BUTTON_PAYWALL_GENERAL
    }
    // ... TBC
  }
  const [open, setOpen] = useState(false)
  const [paywall, setPaywall] = useState(props.paywall)
  const [type, setType] = useState(props.type)

  useEffect(() => {
    open && trackEvent(trackEvents.PRESSED_PAYWALL, { context: paywall })
  }, [open, paywall])

  useImperativeHandle(ref, () => ({
    openPaywall({ paywall, type }) {
      setPaywall(paywall)
      setType(type)
      setOpen(true)
    }
  }))

  const close = () => {
    setOpen(false)
    setPaywall(null)
  }

  if (!paywall) return null

  const paywallChildren = () => (
    <>
      <div className='flex flex-col items-center'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
          {paywalls[paywall].title}
        </h2>
        {paywalls[paywall].paragraphs.map((paragraph, index) => (
          <p
            key={index}
            className='mt-4 max-w-lg text-center text-sm text-gray-700'
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        ))}
      </div>
      <div className='mt-8 flex justify-center'>
        <div className='inline-flex'>
          <a
            href='https://aivy.app/gespraech-vereinbaren/'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => trackEvent(paywalls[paywall].analyticsEvent)}
            className='rounded-full bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none'
          >
            {paywalls[paywall].buttonText}
          </a>
        </div>
      </div>
    </>
  )

  if (type === 'static') return paywallChildren()

  return (
    <Modal open={open} setOpen={close} size='3xl'>
      {paywallChildren()}
    </Modal>
  )
})

Paywall.propTypes = {
  paywall: PropTypes.string,
  type: PropTypes.string
}

Paywall.defaultProps = {
  paywall: null,
  type: null
}

export default Paywall
