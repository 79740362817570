import { Label, Radio, RadioGroup as RadioGroupReact } from '@headlessui/react'
import PropTypes from 'prop-types'

import { classNames } from '../helpers'

const RadioGroup = ({
  group,
  selected,
  setSelected,
  disabled,
  description
}) => {
  return (
    <RadioGroupReact
      value={selected}
      onChange={disabled ? () => {} : setSelected}
    >
      <div className='my-4 grid grid-cols-2 gap-3'>
        {group.map((option, index) => (
          <Radio
            key={index}
            value={option.id}
            className={({ checked }) =>
              classNames(
                !disabled && 'cursor-pointer focus:outline-none',
                disabled && 'opacity-25',
                checked &&
                  'border-transparent bg-blue-600 text-white hover:bg-blue-700',
                !checked &&
                  'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-full border px-2 py-2 text-sm font-semibold sm:flex-1'
              )
            }
          >
            <Label className={classNames(!disabled && 'cursor-pointer')}>
              {option.label}
            </Label>
          </Radio>
        ))}
      </div>
      {description && (
        <Label className='text-left text-sm italic text-gray-700'>
          {description}
        </Label>
      )}
    </RadioGroupReact>
  )
}

RadioGroup.propTypes = {
  group: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  description: PropTypes.string
}

RadioGroup.defaultProps = {
  description: null,
  disabled: false
}

export default RadioGroup
