import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import trackEvents from '../../../constants/track-events'
import { useLanguageContext } from '../../../context'
import { classNames, isEmptyObj } from '../../../helpers'
import { trackEvent } from '../../../helpers/analytics'

const CulturalFitQuestions = ({
  state,
  setState,
  culturalFitDimensions,
  setOpenDimensions
}) => {
  const { t } = useTranslation()
  const [hover, setHover] = useState({})

  const { language } = useLanguageContext()

  return (
    <div className='flex flex-col space-y-4'>
      {Object.values(culturalFitDimensions).map(
        (
          {
            key,
            property,
            intro,
            scaleEndpoints: { left, right },
            leftImg,
            rightImg,
            leftImgAlt,
            rightImgAlt,
            rightImgBlue,
            leftImgBlue
          },
          index
        ) => {
          const displayLeftOnHover = !isEmptyObj(hover) && hover[key] === 0
          const displayRightOnHover = !isEmptyObj(hover) && hover[key] === 100

          const displayLeft =
            !displayLeftOnHover && !displayRightOnHover && state[key] === 0
          const displayRight =
            !displayLeftOnHover && !displayRightOnHover && state[key] === 100

          return (
            <div
              key={index}
              className='relative flex flex-row justify-between rounded-lg bg-gray-50 p-6'
            >
              <div>
                <h2
                  className='cursor-pointer text-lg font-semibold text-gray-900 hover:opacity-70'
                  onClick={() => {
                    trackEvent(trackEvents.CLICK_MORE_INFO_DIMENSION, {
                      property: key
                    })
                    setOpenDimensions(culturalFitDimensions[key])
                  }}
                >
                  {property[language]}
                  <InformationCircleIcon className='ml-1 inline-block h-6 w-6 text-gray-900' />
                </h2>
                <p className='mt-2 max-w-xl text-sm text-gray-900'>
                  {intro[language]}
                </p>
                <h3 className='mt-4 text-sm font-semibold text-gray-900'>
                  {t('cultural_fit.dimension_question')}
                </h3>
                <div className='mt-2 items-center md:flex'>
                  <span className='isolate inline-flex rounded-md shadow-sm'>
                    <button
                      type='button'
                      onClick={() => setState({ [key]: 0 })}
                      onMouseOver={() => setHover({ [key]: 0 })}
                      onMouseOut={() => setHover({})}
                      className={classNames(
                        'relative inline-flex items-center rounded-l-md px-4 py-2 text-sm font-semibold ring-1 ring-inset',
                        state[key] === 0
                          ? 'bg-blue-600 text-white ring-blue-600'
                          : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50'
                      )}
                    >
                      <img
                        src={state[key] === 0 ? leftImg : leftImgAlt}
                        className='mr-2 hidden h-8 w-auto md:block'
                      />
                      {left.title[language]}
                    </button>
                    <button
                      onMouseOver={() => setHover({ [key]: 100 })}
                      onMouseOut={() => setHover({})}
                      type='button'
                      onClick={() => setState({ [key]: 100 })}
                      className={classNames(
                        '-ml-px',
                        'relative inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset',
                        state[key] === 100
                          ? 'bg-blue-600 text-white ring-blue-600'
                          : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50'
                      )}
                    >
                      <img
                        src={state[key] === 100 ? rightImg : rightImgAlt}
                        className='mr-2 hidden h-8 w-auto md:block'
                      />
                      {right.title[language]}
                    </button>
                  </span>
                  {(displayLeftOnHover || displayLeft) && (
                    <span className='mt-2 block w-72 text-xs text-gray-700 md:ml-3 md:mt-0'>
                      ... {left.description[language]}.
                    </span>
                  )}
                  {(displayRightOnHover || displayRight) && (
                    <span className='mt-2 block w-72 text-xs text-gray-700 md:ml-3 md:mt-0'>
                      ... {right.description[language]}.
                    </span>
                  )}
                </div>
              </div>
              {state[key] === 0 && (
                <img
                  src={leftImgBlue}
                  className='mr-4 hidden h-auto w-28 object-contain xl:block'
                />
              )}
              {state[key] === 100 && (
                <img
                  src={rightImgBlue}
                  className='mr-4 hidden h-auto w-28 object-contain xl:block'
                />
              )}
            </div>
          )
        }
      )}
    </div>
  )
}

CulturalFitQuestions.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  culturalFitDimensions: PropTypes.object.isRequired,
  setOpenDimensions: PropTypes.func.isRequired
}

export default CulturalFitQuestions
