import { useQueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Joyride from 'react-joyride'

import Header from '../components/joyride/header'
import Paragraph from '../components/joyride/paragraph'
import trackEvents from '../constants/track-events'
import { trackEvent } from '../helpers/analytics'

import Button from '../components/tailwind/Button'
import { usePartnerContext, useUserContext } from '../context'
import { mutation, query } from '../graphql'
import { useWindowDimensions } from './use-window-dimensions'

export const useUpdateFavoredByUserId = () => {
  const { t } = useTranslation()

  const {
    active_space,
    refetch_active_space,
    cognitoUser: { username }
  } = useUserContext()
  const {
    partner: { id: partner_id }
  } = usePartnerContext()

  const updateSpace = {
    mutate: async ({ input }, callbacks = {}) => {
      const { onSuccess } = callbacks

      const response = await mutation({ mutation: 'updateSpace', input })
      await refetch_active_space()

      onSuccess && onSuccess(response)
    }
  }

  // developed only for screen width >= 1280px (xl)
  // (filter is not visible on screens < xl)
  const { width } = useWindowDimensions()

  const [showFavoredSpaceHint, setShowFavoredSpaceHint] = useState(false)

  const queryClient = useQueryClient()

  const updateFavoredByUserId = useCallback(
    async (space) => {
      const queryKeyTalents = ['talents', partner_id, username]
      const queryKeyTalent = ['space', space.id]

      const get_new_favored_by_user_id = (s) => {
        const favored_by_user_id = s.favored_by_user_id || []

        return favored_by_user_id.includes(username)
          ? favored_by_user_id.filter((user_id) => user_id !== username)
          : favored_by_user_id.concat([username])
      }

      const updater = ({ queryData, favored_by_user_id }) => {
        const newdata = produce(queryData, (draft) => {
          // [talents, careers][0]
          const index = draft[0].findIndex((s) => space.id === s.id)

          if (index !== -1) {
            draft[0][index].favored_by_user_id = favored_by_user_id
          }
        })

        return newdata
      }

      queryClient.setQueryData(queryKeyTalents, (queryData) => {
        if (!queryData) return queryData

        return updater({
          queryData,
          favored_by_user_id: get_new_favored_by_user_id(space)
        })
      })

      queryClient.setQueryData(queryKeyTalent, (queryData) => {
        if (!queryData) return queryData

        queryData.favored_by_user_id = get_new_favored_by_user_id(space)

        return queryData
      })

      if (!active_space.has_seen_favored_space_hint) {
        setShowFavoredSpaceHint(true)
        trackEvent(trackEvents.SHOW_FAVORED_SPACE_HINT)
      }

      const space_response_1 = await query({
        query: 'getSpace',
        variables: { id: space.id }
      })

      await mutation({
        mutation: 'updateSpace',
        input: {
          id: space.id,
          favored_by_user_id: get_new_favored_by_user_id(space_response_1),
          updatedAt: space_response_1.updatedAt
        }
      })
    },
    [username, partner_id, queryClient, active_space]
  )

  const setFavoredSpaceHintSeen = () => {
    setShowFavoredSpaceHint(false)
    updateSpace.mutate({
      input: {
        id: active_space.id,
        customer_dashboard_meta: JSON.stringify([
          ...active_space.customer_dashboard_meta,
          { event: 'FAVORED_SPACE_HINT', status: 'SEEN' }
        ])
      }
    })
  }

  return {
    updateFavoredByUserId,
    FavoredSpaceHint: () =>
      width >= 1280 && !active_space.has_seen_favored_space_hint ? (
        <Joyride
          run={showFavoredSpaceHint}
          hideCloseButton
          hideBackButton
          styles={{ buttonNext: { display: 'none' } }}
          steps={[
            {
              target: '#filter-bar_more',
              disableBeacon: true,
              content: (
                <>
                  <Header>favored_space.hint_title</Header>
                  <div className='h-4'></div>
                  <Paragraph className='mt-2'>
                    favored_space.hint_description
                  </Paragraph>
                  <div className='mt-8 flex justify-end'>
                    <Button.PrimaryLG
                      text={t('favored_space.hint_close_action')}
                      onClick={setFavoredSpaceHintSeen}
                    />
                  </div>
                </>
              )
            }
          ]}
        />
      ) : null
  }
}
