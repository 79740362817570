import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Seo } from '../components'
import ReleaseNotes from '../components/release-notes'

const ReleaseNotesPage = () => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Seo title={t('seo.titles.release_notes')} />
      <ReleaseNotes />
    </Fragment>
  )
}

export default ReleaseNotesPage
