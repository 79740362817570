import {
  AcademicCapIcon,
  ArrowPathIcon,
  AtSymbolIcon,
  BellIcon,
  CheckIcon,
  EllipsisVerticalIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  PlayCircleIcon,
  TrashIcon,
  UserIcon,
  XMarkIcon
} from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLanguageContext } from '../../context'
import { classNames } from '../../helpers'
import { useWindowDimensions } from '../../hooks/use-window-dimensions'
import Panel from '../tailwind/Panel'

const HISTORY_UI_ITEMS = {
  TALENT_INVITED: {
    icon: UserIcon,
    bgColor: 'bg-gray-400',
    textColor: 'text-white'
  },
  ASSESSMENT_LAUNCHED: {
    icon: HandThumbUpIcon,
    bgColor: 'bg-blue-500',
    textColor: 'text-white'
  },
  ASSESSMENT_COMPLETED: {
    icon: CheckIcon,
    bgColor: 'bg-green-100',
    textColor: 'text-green-800'
  },
  EMAIL_BOUNCED: {
    icon: XMarkIcon,
    bgColor: 'bg-red-400',
    textColor: 'text-white'
  },
  HIRED: {
    icon: CheckIcon,
    bgColor: 'bg-green-100',
    textColor: 'text-green-800'
  },
  REJECTED: {
    icon: HandThumbDownIcon,
    bgColor: 'bg-red-400',
    textColor: 'text-white'
  },
  RESET_EXPIRED: {
    icon: PlayCircleIcon,
    bgColor: 'bg-orange-400',
    textColor: 'text-white'
  },
  REMINDED: {
    icon: BellIcon,
    bgColor: 'bg-orange-400',
    textColor: 'text-white'
  },
  INVITED_NEXT_ROUND: {
    icon: AcademicCapIcon,
    bgColor: 'bg-orange-400',
    textColor: 'text-white'
  },
  APPLICANT_CANCELLED: {
    icon: HandThumbDownIcon,
    bgColor: 'bg-yellow-400',
    textColor: 'text-white'
  },
  SOFT_DELETED: {
    icon: TrashIcon,
    bgColor: 'bg-red-400',
    textColor: 'text-white'
  },
  RECOVERED: {
    icon: ArrowPathIcon,
    bgColor: 'bg-orange-400',
    textColor: 'text-white'
  },
  INTEGRATION_SUCCESS: {
    icon: AtSymbolIcon,
    bgColor: 'bg-green-100',
    textColor: 'text-green-800'
  },
  INTEGRATION_ERROR: {
    icon: XMarkIcon,
    bgColor: 'bg-red-400',
    textColor: 'text-white'
  }
}

const SpaceHistoryItem = ({ item, index, logs }) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        'relative block',
        index !== logs.length - 1 && 'pb-8'
      )}
    >
      {index !== logs.length - 1 ? (
        <span
          className='absolute left-4 top-4 -ml-0.5 h-full w-px bg-gray-300'
          aria-hidden='true'
        />
      ) : null}
      <div className='relative flex space-x-3'>
        <div>
          <span
            className={classNames(
              item.bgColor,
              'flex h-7 w-7 items-center justify-center rounded-full ring-2 ring-white'
            )}
          >
            <item.icon
              className={classNames('h-5 w-5', item.textColor)}
              aria-hidden='true'
            />
          </span>
        </div>
        <div className='min-w-0 flex-1 pt-1.5'>
          <div>
            <p className='text-sm text-gray-900'>
              {t(item.content.translationKey, item.content)}{' '}
              <a href='#' className='font-medium text-gray-900'>
                {item.target}
              </a>
            </p>
          </div>
          <div className='mt-1 whitespace-nowrap text-right text-xs italic text-gray-500'>
            <p>{item.date}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

SpaceHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  logs: PropTypes.array.isRequired
}

const SpaceHistory = ({ space }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  const { width } = useWindowDimensions()

  const [extended, setExtended] = useState(false)

  const logs = space.logs
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map(({ data, createdAt }, index) => {
      const { context, status, option, message } = JSON.parse(data)
      const ui_item = HISTORY_UI_ITEMS[status]

      if (!ui_item) return null

      let translationKey = 'pages.talent.' + status

      if (option === 'VIA_EMAIL' || option === 'VIA_LINK') {
        translationKey += `_${option}`
      }

      const createdAtDate = new Date(createdAt)
      const date = format(createdAtDate, 'd. MMMM yyyy', {
        locale: { de, en }[language]
      })
      const time = format(createdAtDate, 'HH:mm', {
        locale: { de, en }[language]
      })

      const result = {
        id: index,
        ...ui_item,
        date: t('pages.talent.date', { date, time }),
        target: null,
        content: { translationKey, reason: message }
      }

      if (['INTEGRATION_SUCCESS', 'INTEGRATION_ERROR'].includes(status)) {
        result.content.remote_service = t(
          `settings.integrations.${context}_title`
        )
      }

      return result
    })
    .filter((item) => item)

  if (!logs.length) return null

  const first_item = logs[0]
  const last_item = logs[logs.length - 1]

  const could_be_extended = logs.length > 2 && width >= 1440

  return (
    <div className='relative'>
      <Panel>
        <section className='md:h-full' aria-labelledby='timeline-title'>
          <div className='px-4 sm:px-6 md:h-full'>
            <div className='flow-root lg:mt-2'>
              <ul
                role='list'
                className={classNames(
                  could_be_extended ? (extended ? 'block' : 'hidden') : 'block'
                )}
              >
                {logs.map((item, index) => (
                  <li key={index}>
                    <SpaceHistoryItem {...{ item, index, logs }} />
                  </li>
                ))}
              </ul>
              <ul
                role='list'
                className={classNames(
                  could_be_extended ? (extended ? 'hidden' : 'block') : 'hidden'
                )}
              >
                <li>
                  <SpaceHistoryItem {...{ item: first_item, index: 0, logs }} />
                </li>
                <li className='mb-4 mt-8 flex'>
                  <EllipsisVerticalIcon
                    style={{ marginLeft: 1 }}
                    className={classNames('h-8 w-8 text-gray-500')}
                    aria-hidden='true'
                  />
                  <div
                    style={{ marginLeft: 7 }}
                    className='mt-px flex w-full items-center'
                  >
                    <span
                      onClick={() => setExtended(true)}
                      className='cursor-pointer p-2 text-xs text-gray-700 hover:text-blue-600'
                    >
                      {t('pages.talent.show_more')}
                    </span>
                  </div>
                </li>
                <SpaceHistoryItem
                  {...{ item: last_item, index: logs.length - 1, logs }}
                />
              </ul>
              {extended && (
                <div className='mt-8 flex w-full justify-center'>
                  <span
                    onClick={() => setExtended(false)}
                    className='cursor-pointer p-2 text-xs text-gray-700 hover:text-blue-600'
                  >
                    {t('pages.talent.show_less')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </section>
      </Panel>
    </div>
  )
}

SpaceHistory.propTypes = {
  space: PropTypes.object.isRequired
}

export default SpaceHistory
