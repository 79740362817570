import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateFavoredByUserId } from '../../hooks/use-update-favored-by-user-id'
import NativeTooltip from '../native-tooltip'

const SpaceFavored = ({ space }) => {
  const { t } = useTranslation()
  const { updateFavoredByUserId } = useUpdateFavoredByUserId()

  const [isFavored, setIsFavored] = useState(space.favored_by.isFavored)

  const handleOnClick = () => {
    setIsFavored(!isFavored)
    updateFavoredByUserId(space)
  }

  const tooltip_content = t('favored_space.tooltip')

  return (
    <motion.div
      className='inline-flex'
      whileHover={{ scale: 1.02 }}
      whileTap={isFavored ? undefined : { scale: 1.2, rotate: 45 }}
    >
      {isFavored ? (
        <button className='flex' onClick={handleOnClick}>
          <StarIconSolid className='h-7 w-7 cursor-pointer text-amber-400' />
        </button>
      ) : (
        <NativeTooltip
          text={tooltip_content}
          delay={1000 * 3}
          position='bottom'
        >
          <button onClick={handleOnClick}>
            <StarIconOutline className='h-7 w-7 cursor-pointer text-gray-500 hover:text-gray-600' />
          </button>
        </NativeTooltip>
      )}
    </motion.div>
  )
}

SpaceFavored.propTypes = { space: PropTypes.object.isRequired }

export default SpaceFavored
