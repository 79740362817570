import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
  // Switch
} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import trackEvents from '../constants/track-events'
import {
  useAivyContext,
  usePartnerContext,
  usePaymentContext,
  useUserContext
} from '../context'
import { trackEvent } from '../helpers/analytics'
import { localStorage } from '../helpers/local-storage'
import AccessExpired from './access-expired'
import Announcement from './announcement'
import ChooseGroupSlideOver from './choose-group-slide-over'
import Footer from './footer'
import HelpSupportSlideover from './help-support-slideover'
import Sidebar from './sidebar'

const Layout = ({ children }) => {
  const { t } = useTranslation()

  const { accessExpired } = usePaymentContext()
  const { system, restartDashboard } = useAivyContext()
  const { isAdmin, active_space } = useUserContext()
  const { partner } = usePartnerContext()

  const { pathname } = useLocation()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isVisibleChooseGroup, setVisibilityChooseGroup] = useState(false)
  const [isVisibleAnnouncement, setIsVisibleAnnouncement] = useState(null)
  const [isVisibleHelpAndSupport, setIsVisibleHelpAndSupport] = useState(false)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    !!active_space.ui_settings_general.is_sidebar_collapsed
  )

  useEffect(() => {
    const { announcement } = system
    if (!announcement) return

    const toDate = new Date(announcement.to_date)
    const fromDate = new Date(announcement.from_date)

    if (announcement.active && new Date() >= fromDate && new Date() <= toDate) {
      setIsVisibleAnnouncement(announcement)
    }
  }, [system])

  const handleRemoveAdmin = async () => {
    localStorage.removeItem('admin')
    restartDashboard()
  }

  return (
    <>
      <div>
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-40 lg:hidden'
            onClose={setSidebarOpen}
          >
            <TransitionChild
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </TransitionChild>

            <div className='fixed inset-0 z-40 flex'>
              <TransitionChild
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <DialogPanel className='relative flex w-full max-w-xs flex-1 flex-col bg-gray-800'>
                  {/* <TransitionChild
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  > */}
                  <div className='absolute right-0 top-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex h-10 w-10 items-center justify-center rounded-full bg-gray-100'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon
                        className='h-6 w-6 text-gray-900'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                  {/* </TransitionChild> */}
                  <Sidebar
                    actions={{
                      setIsVisibleHelpAndSupport,
                      setVisibilityChooseGroup
                    }}
                  />
                </DialogPanel>
              </TransitionChild>
              <div className='w-14 flex-shrink-0'>
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* Static sidebar for desktop */}
        <div
          className={clsx(
            'relative z-20 hidden transition-[width] duration-300 lg:fixed lg:inset-y-0 lg:flex lg:flex-col',
            isSidebarCollapsed ? 'lg:w-20' : 'lg:w-72'
          )}
        >
          <div
            onClick={() => {
              trackEvent(trackEvents.CLICK_SIDEBAR_COLLAPSE)
              active_space.updateUiSettingsGeneral({
                key: 'is_sidebar_collapsed',
                value: !isSidebarCollapsed
              })
              setIsSidebarCollapsed(!isSidebarCollapsed)
            }}
            className='absolute -right-3 bottom-28 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-blue-600 hover:bg-blue-500'
          >
            {isSidebarCollapsed ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 16 16'
                fill='#fff'
                className='h-4 w-4'
              >
                <path
                  fillRule='evenodd'
                  d='M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z'
                  clipRule='evenodd'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 16 16'
                fill='#fff'
                className='h-4 w-4'
              >
                <path
                  fillRule='evenodd'
                  d='M9.78 4.22a.75.75 0 0 1 0 1.06L7.06 8l2.72 2.72a.75.75 0 1 1-1.06 1.06L5.47 8.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0Z'
                  clipRule='evenodd'
                />
              </svg>
            )}
          </div>
          <Sidebar
            actions={{
              setIsVisibleHelpAndSupport,
              setVisibilityChooseGroup
            }}
            isSidebarCollapsed={isSidebarCollapsed}
          />
        </div>
        <div
          className={clsx(
            'flex min-h-screen flex-1 flex-col transition-[width] duration-300',
            isSidebarCollapsed ? 'lg:pl-20' : 'lg:pl-72'
          )}
        >
          <div className='sticky top-0 z-10 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden'>
            <button
              type='button'
              className='-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white text-gray-900'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <main className='flex-grow'>
            {isVisibleAnnouncement && (
              <Announcement
                // onClose={() => setIsVisibleAnnouncement(null)}
                type={isVisibleAnnouncement.type}
                text={isVisibleAnnouncement.message}
                action={isVisibleAnnouncement.action}
              />
            )}
            {!!localStorage.getItem('admin') && (
              <div className='sticky top-0 z-50'>
                <Announcement
                  onClose={handleRemoveAdmin}
                  type='ERROR'
                  text={t('components.layout.admin_hint', {
                    partner: (
                      partner.display_name || partner.name
                    ).toUpperCase()
                  })}
                />
              </div>
            )}
            <div>
              {accessExpired && pathname !== '/support' ? (
                <AccessExpired />
              ) : (
                children
              )}
            </div>
          </main>

          {pathname === '/settings' && <Footer />}
        </div>
      </div>

      {isAdmin && (
        <ChooseGroupSlideOver
          open={isVisibleChooseGroup}
          setOpen={setVisibilityChooseGroup}
        />
      )}
      <HelpSupportSlideover
        isVisibleHelpAndSupport={isVisibleHelpAndSupport}
        setIsVisibleHelpAndSupport={setIsVisibleHelpAndSupport}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
