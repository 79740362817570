import {
  useAivyContext,
  useLanguageContext,
  usePartnerContext
} from '../context'

const calculateAssessmentDuration = ({ career, partner, language }) => {
  const { app_settings } = career || partner

  const minutes =
    JSON.parse(app_settings?.challenges || '[]').length * 2.5 || 25

  return {
    de: `${minutes} Minuten`,
    en: `${minutes} minutes`
  }[language]
}

const MACROS = {
  PARTER_DISPLAY_NAME: (partner) => ({
    macro: '{{partner.display_name}}',
    label: 'mail_template.edit_mail_template.macro.companyname',
    value: `${partner.display_name || partner.name}`
  }),
  CAREER_TITLE: (career) => ({
    macro: '{{career.title}}',
    label: 'mail_template.edit_mail_template.macro.career_title',
    value: career.title
  }),
  ASSESSMENT_DURATION: ({ career, partner, language }) => ({
    macro: '{{assessment_duration}}',
    label: 'mail_template.edit_mail_template.macro.assessment_duration',
    value: calculateAssessmentDuration({ career, partner, language })
  }),
  FIRSTNAME: {
    macro: '{{firstname}}',
    label: 'mail_template.edit_mail_template.macro.firstname',
    value: 'John'
  },
  LASTNAME: {
    macro: '{{lastname}}',
    label: 'mail_template.edit_mail_template.macro.lastname',
    value: 'Doe'
  }
}

export const useMailTemplateConstants = ({ career, isCrossmode }) => {
  const { system } = useAivyContext()
  const { partner } = usePartnerContext()
  const { language: contextLanguage } = useLanguageContext()

  const {
    PARTER_DISPLAY_NAME,
    CAREER_TITLE,
    ASSESSMENT_DURATION,
    FIRSTNAME,
    LASTNAME
  } = MACROS

  const language = career?.language || contextLanguage

  const getMailTemplateMacros = () => {
    return [
      PARTER_DISPLAY_NAME(partner),
      career && CAREER_TITLE(career),
      ASSESSMENT_DURATION({ career, partner, language }),
      FIRSTNAME,
      LASTNAME
    ].filter((macro) => macro)
  }

  const getMailTemplateMacrosReminder = () => {
    return [
      PARTER_DISPLAY_NAME(partner),
      career && CAREER_TITLE(career),
      ASSESSMENT_DURATION({ career, partner, language })
    ].filter((macro) => macro)
  }

  const getMailTemplateMacrosReject = () => {
    return [
      PARTER_DISPLAY_NAME(partner),
      career && CAREER_TITLE(career)
    ].filter((macro) => macro)
  }

  // only used for replacements inside old subjects
  const getMailTemplateSubjectMacros = () => {
    return [
      PARTER_DISPLAY_NAME(partner),
      career && CAREER_TITLE(career)
    ].filter((macro) => macro)
  }

  const getDefaultMailTemplate = () => {
    const {
      defaultCareerMailTemplate,
      defaultPartnerMailTemplate,
      defaultCrossmodeMailTemplate
    } = system

    const result = {}

    if (career) {
      result.subject = defaultCareerMailTemplate.subject[language]
      result.data = defaultCareerMailTemplate.data[language]
    } else if (isCrossmode) {
      result.subject = defaultCrossmodeMailTemplate.subject[language]
      result.data = defaultCrossmodeMailTemplate.data[language]
    } else {
      result.subject = defaultPartnerMailTemplate.subject[language]
      result.data = defaultPartnerMailTemplate.data[language]
    }

    return { ...result, data: JSON.stringify(result.data) }
  }

  const getDefaultReminderMailTemplate = () => {
    const {
      defaultCareerMailTemplateReminder,
      defaultPartnerMailTemplateReminder
    } = system

    const result = {}

    if (career) {
      result.subject = defaultCareerMailTemplateReminder.subject[language]
      result.data = defaultCareerMailTemplateReminder.data[language]
    } else {
      result.subject = defaultPartnerMailTemplateReminder.subject[language]
      result.data = defaultPartnerMailTemplateReminder.data[language]
    }

    return { ...result, data: JSON.stringify(result.data) }
  }

  const getDefaultRejectMailTemplate = () => {
    const {
      defaultCareerMailTemplateReject,
      defaultPartnerMailTemplateReject
    } = system

    const result = {}

    if (career) {
      result.subject = defaultCareerMailTemplateReject.subject[language]
      result.data = defaultCareerMailTemplateReject.data[language]
    } else {
      result.subject = defaultPartnerMailTemplateReject.subject[language]
      result.data = defaultPartnerMailTemplateReject.data[language]
    }

    return { ...result, data: JSON.stringify(result.data) }
  }

  return {
    getDefaultMailTemplate,
    getDefaultReminderMailTemplate,
    getDefaultRejectMailTemplate,
    getMailTemplateMacros,
    getMailTemplateMacrosReminder,
    getMailTemplateMacrosReject,
    getMailTemplateSubjectMacros
  }
}
