import { useQuery } from '@tanstack/react-query'

import { usePartnerContext, useUserContext } from '../context'
import { query } from '../graphql'
import {
  careerActiveFilterInput,
  talentContextFilterInput
} from '../graphql/filter-inputs'
import { getSpacesTableData } from '../helpers/spaces-table-data'

const DELETE_SPACE_CONNECTION_TO_PARTNER = 'DELETE_SPACE_CONNECTION_TO_PARTNER'

export const useRecoverTalentsQuery = () => {
  const { partner } = usePartnerContext()
  const {
    cognitoUser: { username }
  } = useUserContext()

  const talentsQuery = useQuery({
    queryKey: ['recover/talents', partner.id],
    queryFn: async () => {
      const [spaces, careers] = await Promise.all([
        new Promise((resolve, reject) => {
          query({
            query: 'spacesByPartnerIdByUpdatedAt',
            variables: {
              partner_id: partner.id,
              filter: talentContextFilterInput
            }
          })
            .then((response) => {
              resolve(response.filter(({ soft_deleted }) => !!soft_deleted))
            })
            .catch((err) => reject(err))
        }),
        query({
          query: 'careerByPartnerByDate',
          variables: {
            partner_id: partner.id,
            filter: careerActiveFilterInput
          }
        })
      ])

      // DELETE_SPACE_CONNECTION_TO_PARTNER
      const cronJobs = (
        await Promise.all(
          spaces.map(({ id: space_id }) =>
            query({
              query: 'listCronTTLBySourceID',
              variables: { source_id: space_id }
            })
          )
        )
      )
        // listCronTTLBySourceID - [[], [], ...]
        .flat()
        .filter(({ job }) => job === DELETE_SPACE_CONNECTION_TO_PARTNER)
        // sort the oldest job to the top via unix timestamp
        // the first cron task is always prioritized
        .sort((a, b) => a.ttl - b.ttl)

      return [
        spaces.map((space) => ({
          ...space,
          // find the oldest cron task for the source_id
          // the tasks are already sorted via ttl unix timestamp
          cron_ttl: cronJobs.find(({ source_id }) => source_id === space.id)
        })),
        careers
      ]
    },
    select: (response) => {
      return {
        spaces: getSpacesTableData({
          spaces: response[0].filter(({ context, mail_stored }) =>
            context === 'ATTRACT' ? mail_stored : true
          ),
          user_id: username
        }),
        careers: response[1].filter(({ id }) => !id.includes('PRODUCT_TOUR'))
      }
    }
  })

  return talentsQuery
}
