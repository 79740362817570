import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition
} from '@headlessui/react'
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import trackEvents from '../constants/track-events'
import { useAivyContext, useUserContext } from '../context'
import { mutation } from '../graphql'
import { classNames } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import { useWindowDimensions } from '../hooks/use-window-dimensions'

const SpaceSwitch = ({ isSidebarCollapsed }) => {
  const { t } = useTranslation()

  const {
    user,
    spacesByOwner,
    active_space: { authorization_role }
  } = useUserContext()

  const { restartDashboard } = useAivyContext()

  const { height } = useWindowDimensions()

  const employees = spacesByOwner.filter(
    ({ context }) => context === 'EMPLOYEE'
  )

  const selected = employees.find(
    ({ id: space_id }) => user.active_space_id === space_id
  )

  const handleOnClick = ({ space_id }) => {
    trackEvent(trackEvents.CLICK_CHANGE_LOCATION)

    mutation({
      mutation: 'updateUser',
      input: { id: user.id, active_space_id: space_id }
    }).then(restartDashboard)
  }

  if (!selected) return null

  return (
    <Popover className='relative w-full'>
      <PopoverButton
        className={classNames(
          'xrelative inline-flex w-full items-center gap-x-1 focus:outline-none',
          isSidebarCollapsed && 'justify-center'
        )}
      >
        <div
          className={classNames(
            'relative flex cursor-pointer items-center rounded-md bg-gray-100',
            isSidebarCollapsed ? 'justify-center p-0.5' : 'w-full p-2'
          )}
        >
          <img
            className={classNames(
              'flex-none rounded bg-white object-contain',
              isSidebarCollapsed ? 'h-9 w-9' : 'h-10 w-10'
            )}
            src={selected.partner.logo}
            alt=''
          />
          {!isSidebarCollapsed && (
            <>
              <div className='ml-2 flex flex-col items-start'>
                <p className='w-32 truncate text-left text-xs font-medium text-gray-900'>
                  {selected.partner.name}
                </p>
                <span
                  style={{ fontSize: 10 }}
                  className='-mt-0.5 text-left text-gray-700'
                >
                  {t(authorization_role.title)}
                </span>
              </div>
              <ArrowsRightLeftIcon className='ml-auto mr-2 hidden h-5 w-5 flex-shrink-0 text-gray-900 sm:block' />
            </>
          )}
          {isSidebarCollapsed ? (
            <div className='absolute -bottom-1 left-0 w-full px-1'>
              <div className='h-1 rounded-b-md bg-gray-600' />
            </div>
          ) : (
            <div className='absolute -bottom-2 left-0 w-full px-2'>
              <div className='h-2 rounded-b-md bg-gray-600' />
            </div>
          )}
        </div>
      </PopoverButton>

      <Transition
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
      >
        <PopoverPanel
          className={classNames(
            'absolute z-20 mt-1 flex max-h-96 max-w-72 overflow-y-scroll sm:w-screen sm:max-w-md',
            'rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5',
            height < 800 ? 'bottom-16' : 'left-0'
          )}
        >
          <div className='flex-auto divide-y divide-gray-100 py-1'>
            {employees
              .sort((space) => (selected.id === space.id ? -1 : 1))
              .map((space, index) => {
                const { id: space_id, partner } = space
                const current = index === 0

                return (
                  <div
                    key={space_id}
                    onClick={
                      current ? undefined : () => handleOnClick({ space_id })
                    }
                    className={classNames(
                      'relative flex items-center px-3 py-2 sm:gap-x-3',
                      !current && 'group cursor-pointer hover:bg-gray-50'
                    )}
                  >
                    <img
                      className={classNames(
                        'hidden h-12 w-12 flex-none rounded-lg border-2 bg-gray-50 object-contain sm:block',
                        current
                          ? 'border-blue-500'
                          : 'border-gray-50 group-hover:border-gray-100 group-hover:bg-gray-100'
                      )}
                      src={partner.logo}
                      alt=''
                    />
                    <div className='flex w-56 flex-col justify-center lg:w-72'>
                      <p className='truncate text-left text-sm font-medium text-gray-900'>
                        {partner.name}
                      </p>
                      <span className='truncate text-left text-xs text-gray-700'>
                        {partner.display_name}
                      </span>
                    </div>
                    <div className='ml-auto' />
                    {current ? (
                      <CheckIcon
                        className='h-5 w-5 flex-none pr-1 text-gray-700 sm:mr-2'
                        aria-hidden='true'
                      />
                    ) : (
                      <ChevronRightIcon
                        className='h-5 w-5 flex-none text-gray-700 group-hover:text-gray-900 sm:mr-2'
                        aria-hidden='true'
                      />
                    )}
                  </div>
                )
              })}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  )
}

SpaceSwitch.propTypes = { isSidebarCollapsed: PropTypes.bool.isRequired }

export default SpaceSwitch
