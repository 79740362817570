import PropTypes from 'prop-types'

const RadioInputGroup = ({ group, selected, setSelected }) => {
  return (
    <fieldset>
      <div className='space-y-3'>
        {group.map(({ id, label, description }, index) => (
          <div
            key={index}
            className='relative flex cursor-pointer items-start'
            onClick={() => setSelected(id)}
          >
            <div className='flex h-6 items-center'>
              <input
                id={id}
                aria-describedby={`${id}-description`}
                name='group'
                type='radio'
                checked={id === selected}
                className='h-4 w-4 cursor-pointer border-gray-300 text-blue-600 focus:ring-1 focus:ring-blue-400'
                readOnly
              />
            </div>
            <div className='ml-3 cursor-pointer text-sm leading-6'>
              <label
                htmlFor={id}
                className='cursor-pointer font-medium text-gray-900'
              >
                {label}
              </label>{' '}
              <span id={`${id}-description`} className='text-gray-500'>
                {description}
              </span>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

RadioInputGroup.propTypes = {
  group: PropTypes.array.isRequired,
  selected: PropTypes.string,
  setSelected: PropTypes.func.isRequired
}

RadioInputGroup.defaultProps = { selected: null }

export default RadioInputGroup
