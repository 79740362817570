import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import Button from '../../tailwind/Button'

const Description = ({
  career,
  updateHandler,
  backHandler,
  create,
  update,
  isLoading
}) => {
  const { t } = useTranslation()

  const components = useMemo(() => {
    return JSON.parse(career.app_settings?.components || '{}')
  }, [career])

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      landing_page_text: Yup.string()
    }),
    initialValues: {
      landing_page_text: components.customLandingPageText || ''
    },
    onSubmit: ({ landing_page_text: customLandingPageText }) => {
      updateHandler({ customLandingPageText })
    }
  })

  return (
    <>
      <div className='flex flex-col rounded bg-gray-50 px-6 py-6'>
        <div className='h-2' />
        <textarea
          rows={8}
          name='landing_page_text'
          id='landing_page_text'
          className='block w-full rounded-md border-gray-300 leading-relaxed shadow-sm placeholder:italic placeholder:leading-relaxed focus:border-primaryBlue focus:ring-primaryBlue sm:text-sm'
          defaultValue={formik.values.landing_page_text}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={t('career.flex.textarea_placeholder')}
        />
      </div>
      <div className='mt-8 flex justify-end gap-x-2'>
        {create && (
          <Button.PrimaryLG
            text={t('career.flex.create_flex.create_action')}
            onClick={formik.handleSubmit}
            isLoading={isLoading}
          />
        )}
        {update && (
          <>
            <Button.SecondaryLG
              text={t('career.flex.create_flex.back_action')}
              onClick={backHandler}
            />
            <Button.PrimaryLG
              text={t('career.flex.create_flex.submit_action')}
              onClick={formik.handleSubmit}
              disabled={!formik.dirty}
              isLoading={isLoading}
            />
          </>
        )}
      </div>
    </>
  )
}

Description.propTypes = {
  career: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  backHandler: PropTypes.func.isRequired,
  create: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
}

Description.defaultProps = {
  isLoading: false
}

export default Description
