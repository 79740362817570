import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'

import { useNotificationContext } from '../../../context'
import { notifyBugsnag } from '../../../helpers'
import { getDifficultyOptions } from '../../../helpers/diagnostic'
import SelectMenu from '../../select-menu'
import Button from '../../tailwind/Button'

const HardnessOfSelection = ({ career, refetchCareer, careerMutation }) => {
  const { t } = useTranslation()
  const { success, error } = useNotificationContext()

  const parsedCareerAnalyse = useMemo(() => {
    try {
      return JSON.parse(career.career_analyse)
    } catch {
      return {}
    }
  }, [career.career_analyse])

  const options = useMemo(() => getDifficultyOptions(), [])

  const initialValue = useMemo(() => {
    return (
      options.find(
        (option) =>
          option.difficulty ===
          parsedCareerAnalyse.override_defaults.selection_difficulty
      )?.value || 0
    )
  }, [options, parsedCareerAnalyse])

  // Formik Validation Schema
  const validationSchema = Yup.object().shape({
    selectionDifficulty: Yup.number()
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const selection_difficulty = options.find(
        (opt) => opt.value === values.selectionDifficulty
      )?.difficulty

      const updatedOverrideDefaults = { selection_difficulty }
      const beforeOverrideDefaults = parsedCareerAnalyse.override_defaults || {}
      const mergedOverrideDefaults = {
        ...beforeOverrideDefaults,
        ...updatedOverrideDefaults
      }

      parsedCareerAnalyse.override_defaults = mergedOverrideDefaults

      await careerMutation.mutate({
        input: {
          id: career.id,
          career_analyse: JSON.stringify(parsedCareerAnalyse)
        }
      })

      await refetchCareer()
      success(t('create_career.hardness_of_selection.options.success_msg'))
    } catch (err) {
      error(t('create_career.hardness_of_selection.options.error_msg'))
      notifyBugsnag(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <h2 className='text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
        {t('create_career.hardness_of_selection.title')}
      </h2>
      <ReactMarkdown className='mt-2 max-w-2xl text-sm/6 text-gray-900'>
        {t('create_career.hardness_of_selection.description')}
      </ReactMarkdown>
      <div className='mt-6' />
      <Formik
        enableReinitialize
        initialValues={{ selectionDifficulty: initialValue }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, isSubmitting, setFieldValue, submitForm }) => (
          <Form>
            <SelectMenu
              id='selectionDifficulty'
              options={options.map(({ value, label }) => [value, label])}
              position='bottom'
              defaultValue={values.selectionDifficulty}
              onChange={(newValue) => {
                setFieldValue('selectionDifficulty', newValue)
              }}
            />
            <div className='mt-12 flex justify-end'>
              <Button.PrimaryXL
                text={t('create_career.hardness_of_selection.cta_save')}
                onClick={() => submitForm()}
                disabled={!dirty || isSubmitting}
                className={`${!dirty || isSubmitting ? 'opacity-50' : ''}`}
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

HardnessOfSelection.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  refetchCareer: PropTypes.func.isRequired
}

export default HardnessOfSelection
