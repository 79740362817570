import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { localStorage } from '../helpers/local-storage'

const LanguageContext = createContext()

LanguageContext.displayName = 'LanguageContext'

export function LanguageContextProvider(props) {
  const { i18n } = useTranslation()

  const [currentLang, setCurrentLang] = useState(props.language)

  const value = useMemo(
    () => ({
      changeLanguage: (payload) => {
        setCurrentLang(payload)
        localStorage.setItem('language', payload)
        i18n.changeLanguage(payload.toLowerCase())
      },
      getLanguage: () => {
        return currentLang
      },
      language: currentLang
    }),
    [currentLang, i18n]
  )

  return <LanguageContext.Provider value={value} {...props} />
}

LanguageContextProvider.propTypes = {
  language: PropTypes.string.isRequired
}

export function useLanguageContext() {
  const context = useContext(LanguageContext)

  if (!context) {
    throw new Error('useLanguageContext must be used within a AppProvider')
  }

  return context
}
