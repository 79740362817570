import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { createAnalyseInput } from '../../../constants/career-analyse'
import trackEvents from '../../../constants/track-events'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../../../context'
import { mutation, query } from '../../../graphql'
import { employeeContextFilterInput } from '../../../graphql/filter-inputs'
import { EMAIL_REGEX, getRequirementAnalyseLink } from '../../../helpers'
import { trackEvent } from '../../../helpers/analytics'
import MultiEmailInput from '../../multi-email-input'
import Button from '../../tailwind/Button'
import Modal from '../../tailwind/Modal'

const I18N = 'components.career.invite_career_analyse_dialog.'

const ModalInviteExperts = ({
  open,
  closeModal,
  career,
  careerAnalyses,
  refetchCareerAnalyses
}) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { success } = useNotificationContext()
  const { language } = useLanguageContext()
  const {
    cognitoUser: { username }
  } = useUserContext()

  const [items, setItems] = useState([])
  const [value, setValue] = useState('')
  const [error, setError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const emailErrorMessage = t(I18N + 'input_email_validation')
  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL_REGEX, emailErrorMessage)
      .required(emailErrorMessage)
  })

  const closeModalHandler = () => {
    closeModal()
    setItems([])
    setValue('')
    setError(null)
  }

  const addEmailToItems = async (callback) => {
    const email = value.toLowerCase()

    emailSchema
      .validate({
        email
      })
      .then(() => {
        if (items.includes(email)) {
          throw new Error(t(I18N + 'input_email_already_specified', { email }))
        }

        if (
          careerAnalyses
            .map(({ person }) => person && person.email)
            .includes(email)
        ) {
          throw new Error(t(I18N + 'input_email_already_invited', { email }))
        }

        setItems([...items, email])
        setValue('')
        callback && callback(email)
      })
      .catch((err) => setError(err.message))
  }

  async function onInviteCareerAnalyse(email) {
    if (!email) return

    const employeeByPartnerItems = await query({
      query: 'spacesByPartnerIdByUpdatedAt',
      variables: {
        partner_id: partner.id,
        filter: employeeContextFilterInput
      }
    })

    const employee = employeeByPartnerItems.find(
      (item) => item.person && item.person.email === email
    )

    try {
      const input = createAnalyseInput({
        career_id: career.id,
        status: 'INVITED',
        partner_id: partner.id,
        username
      })

      if (employee) {
        input.person_id = employee.person.id
      } else {
        const { id: person_id } = await mutation({
          mutation: 'createPerson',
          input: {
            owner: partner.id,
            email,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            authorized_create: ['__create_' + partner.id],
            authorized_read: ['__read_low_' + partner.id],
            authorized_update: ['__update_' + partner.id],
            authorized_delete: ['__delete_' + partner.id]
          }
        })

        input.person_id = person_id
      }

      const { id: analyse_id } = await mutation({
        mutation: 'createCareerAnalyse',
        input
      })

      const email_input = {
        to_addresses: [email],
        template_id: {
          de: 'INVITE_EXPERT_DE',
          en: 'INVITE_EXPERT_EN'
        }[language],
        input: JSON.stringify({
          partner_name: partner.display_name || partner.name,
          career_name: career.title,
          invitation_link: getRequirementAnalyseLink({ analyse_id })
        })
      }

      await query({ query: 'sendTemplateMail', variables: email_input })
      refetchCareerAnalyses()

      trackEvent(trackEvents.INVITE_EXPERT_TO_CAREER_ANALYSE)
    } catch (err) {
      console.error(err)
    }
  }

  const submitItems = async (experts) => {
    setIsSubmitting(true)

    try {
      for (const email of experts) {
        await onInviteCareerAnalyse(email)
      }

      success(t(I18N + 'invitation_success'))
    } catch (err) {
      error(t(I18N + 'invitation_error'))
    } finally {
      setIsSubmitting(false)
      closeModalHandler()
    }
  }

  const handleSubmit = () => {
    const experts = [...items]

    const inviteExperts = (email) => {
      email && experts.push(email)
      submitItems(experts)
    }

    !value && inviteExperts()
    value && addEmailToItems(inviteExperts)
  }

  return (
    <Modal open={open} setOpen={closeModalHandler} size='2xl'>
      <div className='p-4'>
        <span
          id='invite-experts'
          className='text-3xl font-extrabold tracking-tight text-blue-600'
        >
          {t('career.requirement_profile.experts.title')}
        </span>
        <ul className='ml-6 mt-4 max-w-lg list-disc'>
          {[
            'career.requirement_profile.experts.list_entry_0',
            'career.requirement_profile.experts.list_entry_1'
            // 'career.requirement_profile.experts.list_entry_2'
          ].map((entry, index) => (
            <li key={index} className='mb-1 text-sm text-gray-900'>
              {t(entry)}
            </li>
          ))}
        </ul>
        <div className='mt-6'>
          <label className='block text-sm font-medium text-gray-700'>
            {t(I18N + 'input_email_label')}
          </label>
          <div className='mt-1'>
            <MultiEmailInput
              value={value}
              setValue={setValue}
              items={items}
              setItems={setItems}
              error={error}
              setError={setError}
              callback={addEmailToItems}
            />
          </div>
        </div>
        <div className='mt-12 flex justify-end gap-x-2'>
          <Button.SecondaryXL
            text={t(I18N + 'dialog_cancel_action')}
            onClick={closeModalHandler}
          />
          <Button.PrimaryXL
            text={t(I18N + 'dialog_submit_action')}
            disabled={!value && !items.length}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </Modal>
  )
}

ModalInviteExperts.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  career: PropTypes.object.isRequired,
  careerAnalyses: PropTypes.array.isRequired,
  refetchCareerAnalyses: PropTypes.func.isRequired
}

export default ModalInviteExperts
