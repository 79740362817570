export const createAnalyseInput = ({
  career_id,
  partner_id,
  status,
  username
}) => ({
  career_id,
  status,
  partner_id,
  diagnostic_version: 2,
  authorized_create: ['__create_' + partner_id],
  authorized_read: ['__read_low_' + partner_id],
  authorized_update: ['__update_' + partner_id],
  authorized_delete: ['__delete_' + partner_id],
  invited_from_user_id: username,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString()
})
