import { useEffect, useState } from 'react'
import ReactJoyride, { EVENTS, STATUS } from 'react-joyride'
import { useLocation, useNavigate } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import trackEvents from '../../constants/track-events'
import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext,
  useProductTourContext,
  useUserContext
} from '../../context'
import { mutation, query } from '../../graphql'
import { NODE_ENV, notifyBugsnag } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { localStorage } from '../../helpers/local-storage'
import { getSpacesTableData } from '../../helpers/spaces-table-data'
import { useEventListener } from '../../hooks/use-event-listener'
import { useMount } from '../../hooks/use-mount'
import { useWindowDimensions } from '../../hooks/use-window-dimensions'
import Button from '../tailwind/Button'
import Header from './header'
import Introduction from './introduction'
import { app_settings, career_analyse, tech_1, tech_2 } from './mock-data'
import Paragraph from './paragraph'

const ProductTour = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { error } = useNotificationContext()
  const { active_space, refetch_active_space, cognitoUser } = useUserContext()
  const { is_trial } = usePaymentContext()

  const { username } = cognitoUser

  const {
    create: can_create,
    update: can_update,
    delete: can_delete
  } = active_space.authorization_role.crud

  const navigate = useNavigate()
  const location = useLocation()

  const { width } = useWindowDimensions()

  const [isLoading, setIsLoading] = useState(false)
  const [exampleCareer, setExampleCareer] = useState({})
  const [displayIntroduction, setDisplayIntroduction] = useState(false)

  useEventListener({
    event: 'beforeunload',
    handler: () => {
      // reset location state
      navigate(location.pathname, { replace: true })
    }
  })

  // amplify bug! - updateCareer: res - { career_analyse: null }
  // const careerMutation = useMutationAndUpdateQuery({
  //  queryKey: ['create-career', exampleCareer.id],
  //  mutation: 'updateCareer'
  // })

  const queryClient = useQueryClient()

  const refetch = ({ career_id }) => {
    return queryClient.refetchQueries({
      queryKey: ['create-career', career_id],
      type: 'active'
    })
  }

  const prefetchAnalyses = ({ career_id }) => {
    return queryClient.fetchQuery({
      queryKey: ['career-analyses', career_id],
      queryFn: () =>
        query({ query: 'careerAnalyseByCareer', variables: { career_id } })
    })
  }

  const careerMutation = {
    // implement react-query use mutation functionality with refetch
    mutate: async ({ input }, callbacks = {}) => {
      const { onSuccess } = callbacks

      // amplify bug! - updateCareer: res - { career_analyse: null }
      const response = await mutation({ mutation: 'updateCareer', input })
      await refetch({ career_id: input.id })

      onSuccess && onSuccess(response)
    }
  }

  const updateSpace = {
    mutate: async ({ input }, callbacks = {}) => {
      const { onSuccess } = callbacks

      const response = await mutation({ mutation: 'updateSpace', input })
      await refetch_active_space()

      onSuccess && onSuccess(response)
    }
  }

  const {
    setProductTourState,
    productTourState: { run, stepIndex, steps, tourActive }
  } = useProductTourContext()

  const nextMutationHandler = ({ input, onSuccess }) => {
    setIsLoading(true)

    careerMutation.mutate(
      { input },
      {
        onSuccess: (data) => {
          onSuccess(data)
          setIsLoading(false)
        }
      }
    )
  }

  useMount(() => {
    setProductTourState({
      steps: [
        {
          header: 'product_tour.step_1.title',
          paragraphs: [
            'product_tour.step_1.paragraph_1',
            'product_tour.step_1.paragraph_2',
            'product_tour.step_1.paragraph_3'
          ],
          placement: 'bottom',
          target: '#btn-new-career',
          next: ({ currentStepIndex }) => {
            // reset filter and computed params
            localStorage.setItem('/careers', '')
            localStorage.setItem('/talents', '')

            mutation({
              mutation: 'createCareer',
              input: {
                id: `PRODUCT_TOUR-${active_space.id}`,
                title: 'Performance Marketing Manager',
                category: '92',
                language: 'de',
                status: null,
                authorized_create: ['__create_' + partner.id],
                authorized_read: ['__read_low_' + partner.id],
                authorized_update: ['__update_' + partner.id],
                authorized_delete: ['__delete_' + partner.id],
                partner_id: partner.id,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
              }
            }).then((response) => {
              prefetchAnalyses({ career_id: response.id })
              setExampleCareer(response)
              setProductTourState({
                run: false,
                stepIndex: currentStepIndex + 1
              })
              navigate(`/career/new/${response.id}`)
            })
          }
        },
        {
          header: 'product_tour.step_2.title',
          paragraphs: ['product_tour.step_2.paragraph_1'],
          placement: 'top',
          target: '#title'
        },
        {
          header: 'product_tour.step_3.title',
          paragraphs: [
            'product_tour.step_3.paragraph_1',
            'product_tour.step_3.paragraph_2'
          ],
          placement: 'top',
          target: '#external_id'
        },
        {
          header: 'product_tour.step_4.title',
          paragraphs: ['product_tour.step_4.paragraph_1'],
          placement: 'top',
          target: '#product-tour-language',
          next: ({ exampleCareer, currentStepIndex }) => {
            nextMutationHandler({
              input: {
                id: exampleCareer.id,
                status: 'WAIT_EXPERTS',
                career_analyse
              },
              onSuccess: () => {
                setProductTourState({
                  run: false,
                  stepIndex: currentStepIndex + 1
                })
              }
            })
          }
        },
        {
          header: 'product_tour.step_6.title',
          paragraphs: ['product_tour.step_6.paragraph_1'],
          placement: 'bottom',
          target: '#use-presets',
          next: ({ exampleCareer, currentStepIndex }) => {
            navigate(`/career/new/${exampleCareer.id}#invite-experts`)
            setProductTourState({ stepIndex: currentStepIndex + 1 })
          }
        },
        {
          header: 'product_tour.step_5.title',
          paragraphs: [
            'product_tour.step_5.paragraph_1',
            'product_tour.step_5.paragraph_2'
          ],
          placement: 'bottom',
          target: '#invite-experts',
          next: ({ exampleCareer, currentStepIndex }) => {
            nextMutationHandler({
              input: { id: exampleCareer.id, status: 'WAIT_CONFIG' },
              onSuccess: () => {
                setProductTourState({
                  run: false,
                  stepIndex: currentStepIndex + 1
                })
              }
            })
          }
        },
        {
          header: 'product_tour.step_7.title',
          paragraphs: [
            'product_tour.step_7.paragraph_1',
            'product_tour.step_7.paragraph_2'
          ],
          placement: 'center',
          target: 'body',
          next: ({ exampleCareer, currentStepIndex }) => {
            nextMutationHandler({
              input: {
                id: exampleCareer.id,
                status: 'WAIT_ACTIVE',
                app_settings
              },
              onSuccess: () => {
                setProductTourState({
                  run: false,
                  stepIndex: currentStepIndex + 1
                })
              }
            })
          }
        },
        {
          header: 'product_tour.step_8.title',
          paragraphs: ['product_tour.step_8.paragraph_1'],
          placement: 'center',
          target: 'body',
          next: ({ exampleCareer, currentStepIndex }) => {
            nextMutationHandler({
              input: { id: exampleCareer.id, status: 'ACTIVE' },
              onSuccess: () => {
                navigate(`/career/${exampleCareer.id}`)
                setProductTourState({
                  run: false,
                  stepIndex: currentStepIndex + 1
                })
              }
            })
          }
        },
        {
          header: 'product_tour.step_9.title',
          paragraphs: ['product_tour.step_9.paragraph_1'],
          placement: 'center',
          target: 'body',
          next: ({ exampleCareer, currentStepIndex }) => {
            navigate(`/talents`, {
              state: {
                productTourData: {
                  spaces: getSpacesTableData({
                    spaces: [tech_1, tech_2].map((space) => ({
                      ...space,
                      career_id: exampleCareer.id
                    })),
                    user_id: username
                  }),
                  careers: [exampleCareer]
                }
              }
            })
            setProductTourState({ run: false, stepIndex: currentStepIndex + 1 })
          }
        },
        {
          header: 'product_tour.step_10.title',
          paragraphs: ['product_tour.step_10.paragraph_1'],
          placement: 'bottom',
          target: '#btn-invite-talent'
        },
        {
          header: 'product_tour.step_11.title',
          paragraphs: ['product_tour.step_11.paragraph_1'],
          placement: 'bottom',
          target: '#react-table'
        },
        {
          header: 'product_tour.step_12.title',
          paragraphs: ['product_tour.step_12.paragraph_1'],
          placement: 'bottom',
          target: '#filter-bar'
        },
        {
          header: 'product_tour.step_13.title',
          paragraphs: ['product_tour.step_13.paragraph_1'],
          placement: 'bottom',
          target: '#react-table-select-all',
          next: ({ currentStepIndex }) => {
            document.getElementById('react-table-select-all').click()
            setProductTourState({ run: false, stepIndex: currentStepIndex + 1 })
            // await framer-motion
            setTimeout(() => setProductTourState({ run: true }), 1000 * 0.4)
          }
        },
        {
          header: 'product_tour.step_14.title',
          paragraphs: ['product_tour.step_14.paragraph_1'],
          placement: 'bottom',
          target: '#action-crossmode'
        },
        {
          header: 'product_tour.step_15.title',
          paragraphs: ['product_tour.step_15.paragraph_1'],
          placement: 'bottom',
          target: '#action-remind'
        },
        {
          header: 'product_tour.step_16.title',
          paragraphs: ['product_tour.step_16.paragraph_1'],
          placement: 'bottom',
          target: '#action-reset-expired'
        },
        {
          header: 'product_tour.step_17.title',
          paragraphs: ['product_tour.step_17.paragraph_1'],
          placement: 'bottom',
          target: '#action-set-space-state'
        },
        {
          header: 'product_tour.step_18.title',
          paragraphs: ['product_tour.step_18.paragraph_1'],
          placement: 'bottom',
          target: '#action-remove'
        },
        {
          header: 'product_tour.step_19.title',
          paragraphs: ['product_tour.step_19.paragraph_1'],
          placement: 'right',
          target: '#settings',
          next: ({ currentStepIndex }) => {
            navigate(`/settings#personal`)
            setProductTourState({ run: false, stepIndex: currentStepIndex + 1 })
          }
        },
        {
          header: 'product_tour.step_20.title',
          paragraphs: ['product_tour.step_20.paragraph_1'],
          placement: 'bottom',
          target: '#personal',
          next: ({ currentStepIndex }) => {
            navigate(`/settings#notifications`)
            setProductTourState({ run: false, stepIndex: currentStepIndex + 1 })
          }
        },
        {
          header: 'product_tour.step_21.title',
          paragraphs: ['product_tour.step_21.paragraph_1'],
          placement: 'bottom',
          target: '#notifications',
          next: ({ currentStepIndex }) => {
            navigate(`/settings#company`)
            setProductTourState({ run: false, stepIndex: currentStepIndex + 1 })
          }
        },
        {
          header: 'product_tour.step_22.title',
          paragraphs: ['product_tour.step_22.paragraph_1'],
          placement: 'bottom',
          target: '#company'
        },
        {
          header: 'product_tour.step_23.title',
          paragraphs: [
            'product_tour.step_23.paragraph_1',
            'product_tour.step_23.paragraph_2'
          ],
          placement: 'center',
          target: 'body'
        }
      ]
    })
  })

  const setProductTourSeen = () => {
    if (active_space.has_seen_product_tour) return

    updateSpace.mutate({
      input: {
        id: active_space.id,
        customer_dashboard_meta: JSON.stringify([
          ...active_space.customer_dashboard_meta,
          { event: 'PRODUCT_TOUR', status: 'FINISHED' }
        ])
      }
    })
  }

  useMount(() => {
    const { pathname } = location

    if (is_trial) return
    if (active_space.has_seen_product_tour) return
    if (!can_create || !can_update || !can_delete) return

    // developed only for screen width >= 1280px (xl)
    if (width < 1280) return

    setProductTourSeen()
    navigate('/careers', {
      state: { startProductTour: true },
      replace: pathname === '/careers'
    })
  })

  useEffect(() => {
    const { pathname, state } = location

    if (state && state.startProductTour) {
      setDisplayIntroduction(true)

      // remove state.startProductTour
      navigate(pathname, { replace: true })
    }
  }, [location, navigate])

  const startProductTour = () => {
    setDisplayIntroduction(false)
    setTimeout(
      () => setProductTourState({ run: true, tourActive: true }),
      1000 * 0.4
    )
  }

  const handleResetTour = () => {
    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      setExampleCareer({})
      setProductTourState({ run: false, stepIndex: 0, tourActive: false })
      trackEvent(trackEvents.ABORT_PRODUCTTOUR, { index: stepIndex })
      navigate('/careers')
    }

    if (!exampleCareer.id) return onSuccess()

    mutation({
      mutation: 'deleteCareer',
      input: { id: exampleCareer.id }
    }).then(onSuccess)
  }

  // https://docs.react-joyride.com/callback
  const handleJoyrideCallback = (data) => {
    const {
      status,
      type,
      step: { target }
    } = data

    if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {
      error()
      notifyBugsnag(
        new Error(`TARGET_NOT_FOUND: ${JSON.stringify({ type, target })}`)
      )
      handleResetTour()
    } else if ([STATUS.FINISHED].includes(status)) {
      trackEvent(trackEvents.COMPLETE_PRODUCTTOUR)
      setProductTourSeen()
      handleResetTour()
    }
  }

  if (!can_create || !can_update || !can_delete) return null

  return (
    <>
      <Introduction
        open={displayIntroduction}
        next={startProductTour}
        cancel={() => setDisplayIntroduction(false)}
      />
      {tourActive && (
        <ReactJoyride
          steps={steps.map((step, index, items) => {
            const next = step.next
              ? () => step.next({ exampleCareer, currentStepIndex: stepIndex })
              : () => setProductTourState({ stepIndex: stepIndex + 1 })

            const isLastStep = index + 1 === items.length

            return {
              ...step,
              disableBeacon: true,
              content: (
                <>
                  <Header>{step.header}</Header>
                  <div className='h-4'></div>
                  {step.paragraphs.map((paragraph, index) => (
                    <Paragraph key={index} className='mt-2'>
                      {paragraph}
                    </Paragraph>
                  ))}
                  <div className='-mb-8 mt-8 flex items-center justify-between'>
                    <span className='block text-xs text-gray-700'>
                      {t('product_tour.step_index', {
                        step_index: stepIndex + 1,
                        steps_length: steps.length
                      })}
                    </span>
                    <div className='flex gap-x-2'>
                      <Button.SecondaryLG
                        text={t('product_tour.cancel_action')}
                        onClick={handleResetTour}
                      />
                      <Button.PrimaryLG
                        text={
                          isLastStep
                            ? t('product_tour.last_action')
                            : t('product_tour.next_action')
                        }
                        onClick={next}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </>
              )
            }
          })}
          stepIndex={stepIndex}
          run={run}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              zIndex: 10000,
              width: '100vw'
            },
            tooltip: {
              maxWidth: 512,
              padding: 32
            },
            buttonSkip: { display: 'none' },
            buttonBack: { display: 'none' },
            buttonNext: { display: 'none' }
          }}
          hideBackButton
          hideCloseButton
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          disableScrollParentFix
          debug={NODE_ENV !== 'production'}
        />
      )}
    </>
  )
}

export default ProductTour
