import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { rankItem } from '@tanstack/match-sorter-utils'
import { formatDistance, subSeconds } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Seo } from '../components'
import ActivityIndicator from '../components/activity-indicator'
import NetworkError from '../components/network-error'
import Table from '../components/react-table/table'
import PageHeading from '../components/tailwind/PageHeading'

import RecoverSpacesAction from '../components/recover/recover-spaces-action'
import trackEvents from '../constants/track-events'
import {
  useAivyContext,
  useLanguageContext,
  usePaymentContext
} from '../context'
import { classNames, uuidSmall } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import { createRecoverTalentsTableColumns } from '../helpers/spaces-table-columns'
import { useRecoverTalentsQuery } from '../hooks/use-recover-talents-query'

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const locale = { de, en: enUS }

const RecoverTalents = () => {
  const { t } = useTranslation()

  const { language } = useLanguageContext()
  const { is_trial } = usePaymentContext()
  const { adminInsights } = useAivyContext()

  const [time, setTime] = useState(null)
  const [resetTableKey, setResetTableKey] = useState(uuidSmall())

  const [selectionState, setSelectionState] = useState({ spaces: [] })
  const [displayRecoverSpacesActions, setDisplayRecoverSpacesActions] =
    useState(false)

  const { status, data, dataUpdatedAt, fetchStatus, refetch } =
    useRecoverTalentsQuery()

  const fetchingSubtitle = useMemo(() => {
    if (status === 'error') return null

    // data updated at defaults to 0 for an empty cache
    if (!dataUpdatedAt) {
      return t('talents.fetching_subtitle.is_loading')
    }

    if (fetchStatus === 'fetching') {
      return t('talents.fetching_subtitle.is_updating')
    }

    return t('talents.fetching_subtitle.last_updated_at', {
      time, // only used here for the use memo dependencies
      distance_sub_seconds: formatDistance(
        subSeconds(new Date(dataUpdatedAt), 3),
        new Date(),
        {
          locale: locale[language]
        }
      )
    })
  }, [status, dataUpdatedAt, fetchStatus, time, language, t])

  useEffect(() => {
    // update fetching subtitle every minute
    const interval = setInterval(() => setTime(Date.now()), 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const careers_map = useMemo(() => {
    return new Map([
      ...(data ? data.careers : []).map((career) => [career.id, career]),
      ['TALENTPOOL', { title: t('talents.talentpool_title') }],
      ['ATTRACT', { title: 'Attract' }]
    ])
  }, [data, t])

  const columns = useMemo(
    () =>
      createRecoverTalentsTableColumns({
        adminInsights,
        t,
        careers_map,
        data,
        language,
        locale
      }),
    [data, careers_map, adminInsights, t, language]
  )

  const globalFilterFn = useCallback(
    (row, columnId, value, addMeta) => {
      let columnValue = row.getValue(columnId)

      if (columnId === 'assessment_id') {
        columnValue = (careers_map.get(columnValue) || {}).title
      }

      const itemRank = rankItem(columnValue, value)

      addMeta({ itemRank })

      return itemRank.passed
    },
    [careers_map]
  )

  const selectionCallback = useCallback((table) => {
    setSelectionState((previous) => ({
      ...previous,
      spaces: table
        .getSelectedRowModel()
        .rows.map(({ original, toggleSelected }) => ({
          ...original,
          removeSelected: () => toggleSelected(false)
        }))
    }))
  }, [])

  if (status === 'pending') {
    return <ActivityIndicator />
  }

  if (status === 'error') {
    return (
      <div className='w-full'>
        <Seo title={t('seo.titles.recover_talents')} />
        <NetworkError />
      </div>
    )
  }

  const pageHeadingParams = {
    title: t('seo.titles.recover_talents'),
    subtitle: fetchingSubtitle
  }

  if (!data.spaces.length) {
    return (
      <>
        <Seo title={t('seo.titles.recover_talents')} />
        <PageHeading {...pageHeadingParams} />
        <div className='px-4 md:px-6 lg:px-8'>
          <p className='mt-8 max-w-xl text-sm text-gray-900'>
            {t('recover_talents.description')}
          </p>
          <div className='mt-32 flex justify-center'>
            <p className='text-center text-sm italic text-gray-700'>
              {t('recover_talents.empty_state')}
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Seo title={t('seo.titles.recover_talents')} />
      <PageHeading {...pageHeadingParams} />

      <div className='mt-8 px-4 md:px-6 lg:px-8'>
        <p className='max-w-xl text-sm text-gray-900'>
          {t('recover_talents.description')}
        </p>
      </div>

      <Table
        key={resetTableKey}
        data={data.spaces}
        columns={columns}
        globalFilterFn={globalFilterFn}
        initialState={{ sorting: [{ id: 'soft_deleted', desc: true }] }}
        withActions={
          <motion.div
            variants={container}
            initial='hidden'
            animate='visible'
            className='mr-8 flex items-center gap-x-2'
          >
            {[
              {
                id: 'action-recover-talents',
                onClick: () => setDisplayRecoverSpacesActions(true),
                icons: { outline: ArrowPathIcon },
                label: t('recover_talents.recover_action')
              }
            ].map(({ id, onClick, icons, label }, index) => {
              const Icon = icons.outline

              return (
                <div key={index}>
                  <motion.button
                    id={id}
                    data-tooltip-id={id}
                    className={classNames(
                      'group flex h-8 w-8 items-center justify-center rounded-full',
                      'cursor-pointer hover:bg-blue-700'
                    )}
                    onClick={onClick}
                    variants={item}
                  >
                    <Icon className={classNames('h-5 w-5', 'text-white')} />
                  </motion.button>
                  <ReactTooltip
                    id={id}
                    className='rounded-full font-medium'
                    content={label}
                    style={{ backgroundColor: '#182033' }}
                    place='top'
                    variant='info'
                  />
                </div>
              )
            })}
          </motion.div>
        }
        selectionCallback={selectionCallback}
        disableVirtual={is_trial}
        displayNumberOfEntries
        disableHover
      />
      <RecoverSpacesAction
        spaces={[...selectionState.spaces]}
        updateHandler={() => {
          refetch().then(() => {
            trackEvent(trackEvents.RECOVER_TALENTS)
            setResetTableKey(uuidSmall())
          })
        }}
        open={displayRecoverSpacesActions}
        setOpen={setDisplayRecoverSpacesActions}
      />
    </>
  )
}

export default RecoverTalents
