import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { JSONTree } from 'react-json-tree'
import { Seo } from '..'
import { DE, EN, getAllLanguages } from '../../constants/languages'
import trackEvents from '../../constants/track-events'
import {
  useAivyContext,
  useLanguageContext,
  useNotificationContext,
  useProductTourContext,
  useUserContext
} from '../../context'
import { mutation } from '../../graphql'
import { NODE_ENV } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { useMount } from '../../hooks/use-mount'
import Input from '../input'
import SelectMenu from '../select-menu'
import SettingsContainer from './settings-container'

const TRANSLATION_LOCATION = 'components.settings.user_profile.'

const UserProfile = ({ hideDescription }) => {
  const { t } = useTranslation()
  const { success, error } = useNotificationContext()
  const { changeLanguage, language } = useLanguageContext()
  const { active_space, refetch_active_space } = useUserContext()
  const { adminInsights } = useAivyContext()

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const [isLoading, setIsLoading] = useState(false)

  const languages = useMemo(
    () => [
      {
        id: 0,
        title: t('components.switch_lang.title_de'),
        locale: DE,
        active: language === DE
      },
      {
        id: 1,
        title: t('components.switch_lang.title_en'),
        locale: EN,
        active: language === EN
      }
    ],
    [language, t]
  )

  const current = languages.find(({ locale }) => locale === language)

  const handleChangeLanguage = (language) => {
    trackEvent(trackEvents.SWITCH_LANGUAGE, { locale: language })
    changeLanguage(language)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: active_space?.person?.firstname || '',
      lastname: active_space?.person?.lastname || '',
      language: current.locale
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(
        t(TRANSLATION_LOCATION + 'input_firstname_validation')
      ),
      lastname: Yup.string().required(
        t(TRANSLATION_LOCATION + 'input_lastname_validation')
      )
    }),
    onSubmit: async (values) => {
      setIsLoading(true)

      try {
        await mutation({
          mutation: 'updatePerson',
          input: {
            id: active_space.person.id,
            firstname: values.firstname,
            lastname: values.lastname
          }
        })

        success(t(TRANSLATION_LOCATION + 'update_person_success'))
        await refetch_active_space()
      } catch (err) {
        error(t(TRANSLATION_LOCATION + 'update_person_error'))
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <>
      <Seo title={t('seo.titles.settings_userprofile')} />
      <SettingsContainer
        title={t(TRANSLATION_LOCATION + 'settings_container_title')}
        description={t(TRANSLATION_LOCATION + 'settings_container_description')}
        onSubmit={formik.handleSubmit}
        dirty={formik.dirty}
        isLoading={isLoading}
        hideDescription={hideDescription}
      >
        {adminInsights && (
          <JSONTree
            data={active_space}
            shouldExpandNodeInitially={() => false}
          />
        )}
        <div className='grid grid-cols-6 gap-6'>
          <div className='col-span-6 sm:col-span-3'>
            <Input
              id='firstname'
              label={t(TRANSLATION_LOCATION + 'input_firstname_label')}
              type='text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.firstname}
              value={formik.values.firstname}
              placeholder={t(
                TRANSLATION_LOCATION + 'input_firstname_placeholder'
              )}
              error={formik.errors.firstname}
            />
          </div>

          <div className='col-span-6 sm:col-span-3'>
            <Input
              id='lastname'
              label={t(TRANSLATION_LOCATION + 'input_lastname_label')}
              type='text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.lastname}
              value={formik.values.lastname}
              placeholder={t(
                TRANSLATION_LOCATION + 'input_lastname_placeholder'
              )}
              error={formik.errors.lastname}
            />
          </div>

          <div className='col-span-6 sm:col-span-3'>
            <Input
              id='email'
              type='email'
              label={t(TRANSLATION_LOCATION + 'input_email_label')}
              value={
                active_space.person.email ||
                active_space.person.invitation_email
              }
              disabled
            />
          </div>
        </div>
        <div className='mt-4'>
          <SelectMenu
            id='language'
            label={t('create_career.overview.language_title')}
            options={getAllLanguages()}
            defaultValue={formik.values.language}
            onChange={(language) => {
              handleChangeLanguage(language)
              formik.setFieldValue('language', language)
            }}
          />
        </div>
      </SettingsContainer>
      {NODE_ENV === 'development' && (
        <div className='my-12 text-center'>
          <span className='block text-center text-xs italic text-gray-700'>
            {`active_space_id - ${active_space.id}`}
          </span>
        </div>
      )}
    </>
  )
}

UserProfile.propTypes = {
  hideDescription: PropTypes.bool
}

UserProfile.defaultProps = {
  hideDescription: false
}

export default UserProfile
