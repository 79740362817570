import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import Alex from '../../assets/images/landingpage/alex.png'
import { useUserContext } from '../../context/user-context'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'

const Introduction = ({ open, next, trial_days }) => {
  const { t } = useTranslation()
  const { active_space } = useUserContext()

  return (
    <Modal open={open} setOpen={next} size='2xl'>
      <div className='flex flex-col items-center'>
        <div className='flex items-center gap-x-4'>
          <img src={Alex} className='h-20 w-20 rounded-full' />
          <div className='flex flex-col pb-4'>
            <span className='block text-xl font-bold tracking-tight text-gray-900'>
              {t('trial.introduction.contact_person_name')}
            </span>
            <span className='block text-sm text-gray-700'>
              {t('trial.introduction.contact_person_position')}
            </span>
          </div>
        </div>
        <h2 className='mt-8 text-3xl font-extrabold tracking-tight text-gray-900'>
          {t('trial.introduction.welcome_title', {
            firstname: active_space?.person?.firstname
          })}
        </h2>
        <iframe
          className='my-4 aspect-video h-36 w-full rounded-xl md:h-80'
          src='https://www.youtube.com/embed/h0cvtbwQAIk?si=0-gpMan22r7RnVB5&amp;controls=0'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        />
        <p className='mt-4 max-w-md text-center text-gray-700'>
          {t('trial.introduction.welcome_message_1')}
        </p>
        <p className='mt-2 max-w-md text-center text-gray-700'>
          {t('trial.introduction.welcome_message_2', { trial_days })}
        </p>
        <Button.PrimaryXL
          onClick={next}
          text={t('trial.introduction.lets_go_action')}
          className='mt-8'
        />
      </div>
    </Modal>
  )
}

Introduction.propTypes = {
  open: PropTypes.bool.isRequired,
  next: PropTypes.func.isRequired,
  trial_days: PropTypes.number.isRequired
}

export default Introduction
