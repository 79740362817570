export const LOG = `{
  id
  event_name
  source
  type
  fired_by_id
  career_analyse_id
  team_analyse_id
  answer_id
  space_id
  form_id
  person_id
  career_id
  user_id
  cron_ttl_id
  reach_campaign_id
  reach_request_id
  payment_id
  request_id
  data
  createdAt
  updatedAt
  authorized_create
  authorized_read
}`
