import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'

import { useState } from 'react'
import { ActivityIndicator } from '../components'
import Data from '../components/aivy-admin/data'
import Logs from '../components/aivy-admin/logs'
import Feedback from '../components/feedback'
import SEO from '../components/seo'
import Button from '../components/tailwind/Button'
import PageHeading from '../components/tailwind/PageHeading'
import CareersMatchingContainer from '../components/talent/careers-matching-container'
import SpaceActionsDropdown from '../components/talent/space-actions-dropdown'
import SpaceComment from '../components/talent/space-comment'
import SpaceCompletedChallenges from '../components/talent/space-completed-challenges'
import SpaceDetailsAssessment from '../components/talent/space-details-assessment'
import SpaceDetailsProfile from '../components/talent/space-details-profile'
import SpaceFavored from '../components/talent/space-favored'
import SpaceHeader from '../components/talent/space-header'
import SpaceHistory from '../components/talent/space-history'
import SpaceSetStateHint from '../components/talent/space-set-state-hint'
import SpaceTtlTimeline from '../components/talent/space-ttl-timeline'
import { useNotificationContext, useUserContext } from '../context'
import { query } from '../graphql'
import { notifyBugsnag } from '../helpers'
import { getSpacesTableData } from '../helpers/spaces-table-data'
import { useSpaceActions } from '../hooks/use-space-actions'
import Error404 from './error-404'

const Talent = () => {
  const { id } = useParams() // CHECK SECURITY
  const { alert, error } = useNotificationContext()

  const {
    isAdmin,
    cognitoUser: { username }
  } = useUserContext()

  const [displayLogs, setDisplayLogs] = useState(false)
  const [displayData, setDisplayData] = useState(false)
  const [isResetting, setIsResetting] = useState(false)

  const {
    status: spaceQueryStatus,
    data: space,
    refetch: refetchSpace
  } = useQuery({
    queryKey: ['space', id],
    queryFn: async () => {
      const space = await query({ query: 'getSpace', variables: { id } })

      const cron_ttls = await query({
        query: 'listCronTTLBySourceID',
        variables: { source_id: space.id }
      })

      const logs = await query({
        query: 'logsBySpace',
        variables: { space_id: space.id }
      })

      return { ...space, cron_ttls, logs }
    },
    select: (space) => {
      if (space.soft_deleted) return null

      const result = getSpacesTableData({ spaces: [space], user_id: username })

      return { ...result[0], original: space }
    }
  })

  // amplify bug! - updateSpace: res - { comment: null }
  // const spaceMutation = useMutationAndUpdateQuery({
  //   queryKey: ['space', id],
  //   mutation: 'updateSpace'
  // })

  const { career_id, assessment_id } = space || {}

  const {
    status: careerQueryStatus,
    fetchStatus: careerFetchStatus,
    data: career
  } = useQuery({
    queryKey: ['career', career_id],
    queryFn: () => query({ query: 'getCareer', variables: { id: career_id } }),
    enabled:
      !!assessment_id &&
      assessment_id !== 'TALENTPOOL' &&
      assessment_id !== 'ATTRACT'
  })

  const spaceActions = useSpaceActions({ spaces: space ? [space] : [] })

  const loading = [
    spaceQueryStatus,
    careerFetchStatus !== 'idle' && careerQueryStatus
  ].includes('pending')

  if (loading) {
    return (
      <div className='mt-32 flex items-center justify-center'>
        <ActivityIndicator />
      </div>
    )
  }

  if (!space) return <Error404 />

  const resetMatchingAndIntegration = () => {
    setIsResetting(true)

    query({
      query: 'spaceControl',
      variables: { action: 'resetMatchingForSpace', user_id: space.owner }
    })
      .then(refetchSpace)
      .catch((err) => {
        error()
        notifyBugsnag(err)
      })
      .finally(() => setIsResetting(false))
  }

  const resetMatchingAndIntegrationAlert = () => {
    alert(
      'Achtung',
      [
        'Das Matching wird für alle Talente von diesem Owner neu berechnet und mögliche Integrationen werden geupdatet.',
        'Möchtest du fortfahren?'
      ],
      [
        { onPress: resetMatchingAndIntegration, text: 'Ja' },
        { text: 'Abbrechen', style: 'cancel' }
      ]
    )
  }

  return (
    <>
      <SEO title={`Talent ${space.name || space.identification}`} />
      <PageHeading
        withActions={
          <div className='ml-auto flex items-center justify-center gap-x-4'>
            <SpaceFavored space={space} />
            <SpaceActionsDropdown
              space={space}
              refetchSpace={refetchSpace}
              career={career}
              spaceActions={spaceActions}
            />
          </div>
        }
        sticky
      >
        <SpaceHeader space={space} />
      </PageHeading>
      {isAdmin && (
        <div className='flex flex-wrap items-center gap-2 bg-gray-100 px-4 py-4 sm:justify-end sm:px-6 lg:px-8'>
          {space.progress === 100 && (
            <Button.SecondaryLG
              text='Reset'
              onClick={resetMatchingAndIntegrationAlert}
              isLoading={isResetting}
            />
          )}
          <Button.SecondaryLG
            text='Show Space'
            onClick={() => setDisplayData(true)}
          />
          <Button.SecondaryLG
            text='Show Logs'
            className='mr-2'
            onClick={() => setDisplayLogs(true)}
          />
        </div>
      )}
      <div className='mb-8 mt-8 min-h-full px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto mb-4 grid grid-cols-1 gap-4 xl:grid-flow-col-dense xl:grid-cols-3'>
          <div className='space-y-4 xl:col-span-2 xl:col-start-1'>
            {space.progress === 100 && (
              <SpaceSetStateHint space={space} spaceActions={spaceActions} />
            )}
            <SpaceDetailsProfile
              space={space}
              career={career}
              refetchSpace={refetchSpace}
            />
            <SpaceDetailsAssessment
              space={space}
              refetchSpace={refetchSpace}
              career={career}
              spaceActions={spaceActions}
            />
          </div>
          <div className='xl:col-span-1 xl:col-start-3'>
            <SpaceHistory space={space} />
            {space.progress === 100 && (
              <>
                {!!space.logs.length && <div className='h-4' />}
                {/* <SpaceRadarChart space={space} career={career} /> */}
              </>
            )}
          </div>
        </div>
        <div className='mx-auto'>
          <SpaceCompletedChallenges space={space} career={career} />
        </div>
        <div className='mx-auto mt-4 md:flex md:items-center md:justify-between'>
          <CareersMatchingContainer space={space} refetchSpace={refetchSpace} />
        </div>
        {!!space.cron_ttls.length && (
          <div className='mx-auto mt-4 grid grid-cols-1 gap-4 xl:grid-flow-col-dense xl:grid-cols-3'>
            <div className='space-y-4 xl:col-span-2 xl:col-start-1'>
              <SpaceTtlTimeline space={space} />
            </div>
          </div>
        )}

        <div className='mt-4'>
          <SpaceComment
            data={{ space, refetchSpace }}
            // amplify bug! - updateSpace: res - { comment: null }
            // updateHandler={({ input, onSuccess }) =>
            //   spaceMutation.mutate({ input }, { onSuccess })
            // }
          />
        </div>

        <Logs
          title='SPACE-LOGS'
          open={displayLogs}
          setOpen={setDisplayLogs}
          logs={space.original.logs}
          displayKey='status'
        />
        <Data
          title='SPACE'
          open={displayData}
          setOpen={setDisplayData}
          data={space.original}
        />
      </div>
      <Feedback context='TALENT' />
    </>
  )
}

export default Talent
