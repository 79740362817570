import { useQuery, useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { getJobCategory } from '../../constants/job-categories'
import { getLanguage } from '../../constants/languages'
import trackEvents from '../../constants/track-events'
import { convertNumberToTime, sendSlackMessage } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
// import { usePaymentRestrictions } from '../../hooks/use-payment-restrictions'
import { ActivityIndicator } from '..'
import { UPDATE_STATUS } from '../../constants/career-history'
import {
  useAivyContext,
  useLanguageContext,
  usePartnerContext,
  usePaymentContext,
  useProductTourContext
} from '../../context'
import { mutation, query } from '../../graphql'
import { getDifficultyOptions } from '../../helpers/diagnostic'
import { useCreateCareerNavigation } from '../../hooks/use-create-career-navigation'
import { useCreateLog } from '../../hooks/use-create-log'
import { useMount } from '../../hooks/use-mount'
import Button from '../tailwind/Button'
import RiasecRequirementModal from './adjustment/riasec-requirement-modal'
import ExternalIdAlert from './pieces/ExternalIdAlert'

const Overview = ({ career, careerMutation, careers }) => {
  const { t } = useTranslation()

  const { language } = useLanguageContext()
  const { is_trial } = usePaymentContext()
  const { system } = useAivyContext()
  const { partner } = usePartnerContext()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const { createCareerHistoryLog } = useCreateLog()

  const { isLoadingBack, handleBackNavigation } = useCreateCareerNavigation({
    career,
    careerMutation
  })

  const [isLoadingNext, setIsLoadingNext] = useState(false)
  const [displayRiasecRequirement, setDisplayRiasecRequirement] = useState(true)

  const career_analyse = JSON.parse(career.career_analyse)
  const { career_preset_id } = career_analyse

  const { data: preset, status: presetStatus } = useQuery({
    queryKey: ['career-preset', career_preset_id],
    queryFn: () =>
      query({
        query: 'publicSqlQuery',
        variables: {
          action: 'query-career-preset',
          input: JSON.stringify({ career_preset_id })
        }
      }),
    select: (response) => JSON.parse(response.Items)[0],
    enabled: !!career_preset_id
  })

  const { data: careerAnalyses, status: careerAnalysesStatus } = useQuery({
    queryKey: ['career-analyses', career.id],
    queryFn: () =>
      query({
        query: 'careerAnalyseByCareer',
        variables: { career_id: career.id }
      }),
    enabled: !career_preset_id
  })

  const is_loading = [
    !!career_preset_id && presetStatus === 'pending',
    !career_preset_id && careerAnalysesStatus === 'pending'
  ]

  if (is_loading.includes(true)) {
    return <ActivityIndicator />
  }

  const foundCareerWithExternalCustomID = careers.find(
    ({ status, external_custom_id }) => {
      return (
        status === 'ACTIVE' && external_custom_id === career.external_custom_id
      )
    }
  )

  const overview = [
    {
      title: t('create_career.overview.career_title_title'),
      value: career.title
    },
    career.external_custom_id && {
      title: t('create_career.overview.external_custom_id_title'),
      value: career.external_custom_id,
      Info: foundCareerWithExternalCustomID
        ? (props) => (
            <ExternalIdAlert
              {...props}
              old_career_title={foundCareerWithExternalCustomID.title}
              new_career_title={career.title}
              external_custom_id={career.external_custom_id}
            />
          )
        : undefined
    },
    career.language && {
      title: t('create_career.overview.language_title'),
      value: t(getLanguage(career.language))
    },
    {
      title: t('create_career.overview.category_title'),
      value: t(getJobCategory(career.category))
    }
  ]

  if (career_preset_id) {
    overview.push({
      title: t('create_career.overview.preset_title'),
      value: JSON.parse(preset.name)[language]
    })
  } else {
    overview.push({
      title: t('create_career.overview.invited_experts_title'),
      value: careerAnalyses.map(({ person }) => person.email).join(', ')
    })
  }

  const challenges = JSON.parse(career.app_settings.challenges)
  const { minutes, seconds } = convertNumberToTime(
    challenges
      .map(({ exam_id }) => system.challenges[exam_id].timeToComplete)
      .reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      ) / 60000
  )

  const getSelectionDifficulty = () => {
    const selection_difficulty = career_analyse.selection_difficulty ?? 'NORMAL'

    const option = getDifficultyOptions().find(
      ({ difficulty }) => difficulty === selection_difficulty
    )

    return t(option.label)
  }

  const mandatoryCriterions = Object.entries(career_analyse.scores)
    .filter(([_, value]) => value.mandatoryCriterion === true)
    .map(([key]) => key)

  const getMandatoryCriterions = () => {
    const { dimensions_pdf_reports: dimensions } = system

    return mandatoryCriterions
      .map((dimension_key) => dimensions[dimension_key].property[language])
      .join(', ')
  }

  overview.push(
    {
      title: t('create_career.overview.challenges_title'),
      value: challenges
        .map(({ exam_id }) => system.challenges[exam_id].title[language])
        .join(', ')
    },
    {
      title: t('create_career.overview.duration_title'),
      value: t('create_career.overview.duration_value', {
        minutes,
        seconds
      })
    },
    {
      title: t('create_career.overview.selection_difficulty_title'),
      value: getSelectionDifficulty()
    },
    !!mandatoryCriterions.length && {
      title: t('create_career.overview.must_criteria_title'),
      value: getMandatoryCriterions()
    }
  )

  const onFinishOverview = async () => {
    setIsLoadingNext(true)

    // rm override_defaults
    await mutation({
      mutation: 'updateCareer',
      input: {
        id: career.id,
        career_analyse: JSON.stringify({
          ...JSON.parse(career.career_analyse),
          override_defaults: undefined
        })
      }
    })

    await createCareerHistoryLog({
      career_id: career.id,
      context: UPDATE_STATUS,
      input: { status: 'ACTIVE' }
    })

    const response = await query({
      query: 'careerControl',
      variables: { action: 'activateCareer', career_id: career.id }
    })

    const { name: partner_name } = partner
    const { id: activated_career_id, title: career_title } =
      JSON.parse(response).activateCareer.res

    if (!activated_career_id.includes('sample')) {
      trackEvent(trackEvents.FINISH_CREATE_CAREER)

      if (is_trial) {
        queryClient.refetchQueries({
          queryKey: ['trial-data', partner.id],
          type: 'active'
        })
      }

      sendSlackMessage(
        'kunden-controlling',
        `Die Stelle *${career_title}* (${activated_career_id}) von _${partner_name}_ ${
          is_trial ? '(TRIAL)' : ''
        } ist nun aktiv.`
      )
    }

    // invalidate cache because career_id has changed
    if (activated_career_id !== career.id) queryClient.removeQueries()

    setIsLoadingNext(false)
    navigate(`/career/${activated_career_id}`, { replace: true })
  }

  if (
    displayRiasecRequirement &&
    challenges.some(({ exam_id }) => exam_id === 'RIASEC_IMAGES')
  ) {
    return (
      <RiasecRequirementModal
        career={career}
        careerMutation={careerMutation}
        back={() => handleBackNavigation({})}
        onFinish={() => setDisplayRiasecRequirement(false)}
      />
    )
  }

  return (
    <>
      <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {t('create_career.overview.title')}
      </span>
      <p className='mt-4 max-w-lg text-sm text-gray-900'>
        {t('create_career.overview.description')}
      </p>
      <div className='mt-8 border-t border-gray-200'>
        <dl className='divide-y divide-gray-200'>
          {overview
            .filter((item) => item)
            .map(({ title, value, Info }, index) => (
              <div
                key={index}
                className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5'
              >
                <dt className='my-auto text-sm text-gray-700'>{title}</dt>
                <dd className='mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  <span className='flex-grow'>{value}</span>
                </dd>
                {!!Info && <Info className='sm:col-span-3' />}
              </div>
            ))}
        </dl>
      </div>
      <div className='mt-8 flex justify-end gap-x-2 sm:mt-12'>
        <Button.SecondaryLG
          onClick={() => handleBackNavigation({})}
          isLoading={isLoadingBack}
          text={t('create_career.back_action')}
        />
        <Button.PrimaryLG
          text={t('create_career.overview.submit_action')}
          isLoading={isLoadingNext}
          onClick={onFinishOverview}
        />
      </div>
    </>
  )
}

Overview.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  careers: PropTypes.array.isRequired
}

export default Overview
