import { XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HashLoader } from 'react-spinners'

import { colors } from '../../constants/colors'
import trackEvents from '../../constants/track-events'
import { usePartnerContext, usePaymentContext } from '../../context'
import { trackEvent } from '../../helpers/analytics'
import { sortCrossComparison } from '../../helpers/sortings'
import Paywall from '../paywall'
import Table from '../react-table/table'
import Button from '../tailwind/Button'
import PageHeading from '../tailwind/PageHeading'
import Slideover from '../tailwind/slideover'
import Tooltip from '../tooltip'
import VersionSwitch from '../VersionSwitch'

const {
  SHOW_DIAGNOSTIC_VERSION_1_CROSSMODE,
  SHOW_DIAGNOSTIC_VERSION_2_CROSSMODE,
  CLICK_REQUEST_CHALLENGES_CROSSMODE
} = trackEvents

const CrossmodeSlideover = ({
  open,
  handleClose,
  data,
  setSelectionState,
  crossmodeLoading,
  selectionState,
  matchBatchIfYouCan,
  setShowReinviteSpaceModal,
  careers_map
}) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { crossComparison } = usePaymentContext()

  const activeCareers = data.careers.filter(
    ({ status, archived }) => status === 'ACTIVE' && !archived
  )

  const hasV1Careers = activeCareers.some(
    ({ diagnostic_version }) => diagnostic_version === 1
  )

  const hasV2Careers = activeCareers.some(
    ({ diagnostic_version }) => diagnostic_version === 2
  )

  const [activeDiagnosticVersion, setActiveDiagnosticVersion] = useState(2)

  const versionedCareers =
    hasV1Careers && hasV2Careers
      ? activeCareers.filter(({ diagnostic_version }) => {
          return diagnostic_version === activeDiagnosticVersion
        })
      : activeCareers

  return (
    <Slideover open={open} setOpen={handleClose}>
      <PageHeading
        title={t('talents.crossmode.title')}
        withActions={
          <div className='flex items-center gap-x-4'>
            {hasV1Careers && hasV2Careers && (
              <div className='bg-white-50 flex justify-end'>
                <VersionSwitch
                  versions={[
                    {
                      version: 1,
                      active: activeDiagnosticVersion === 1,
                      handleChange: () => {
                        trackEvent(SHOW_DIAGNOSTIC_VERSION_1_CROSSMODE)
                        setActiveDiagnosticVersion(1)
                        setSelectionState({ career_ids: [] })
                      }
                    },
                    {
                      version: 2,
                      active: activeDiagnosticVersion === 2,
                      handleChange: () => {
                        trackEvent(SHOW_DIAGNOSTIC_VERSION_2_CROSSMODE)
                        setActiveDiagnosticVersion(2)
                        setSelectionState({ career_ids: [] })
                      }
                    }
                  ]}
                />
              </div>
            )}
            <button
              type='button'
              className={clsx(
                'flex items-center justify-center',
                'h-8 w-8 rounded-full bg-gray-200 hover:bg-gray-300',
                'group focus:outline-none'
              )}
              onClick={handleClose}
            >
              <span className='sr-only'>Close panel</span>
              <XMarkIcon
                className='h-6 w-6 text-gray-700 group-hover:text-gray-900'
                aria-hidden='true'
              />
            </button>
          </div>
        }
        sticky
      />
      {crossComparison ? null : (
        <div className='mb-4 py-4'>
          <Paywall type='static' paywall='general' />
        </div>
      )}
      <div className='bg-gray-100'>
        <div className='px-4 py-3 sm:px-6 md:flex md:items-center lg:px-8'>
          <div className='flex flex-wrap items-center gap-1'>
            {versionedCareers.map(({ id, title }) => {
              const { career_ids } = selectionState
              const active = career_ids.includes(id)

              const handleOnClick = () => {
                if (active) {
                  setSelectionState({
                    career_ids: career_ids.filter((activeID) => activeID !== id)
                  })
                } else {
                  if (career_ids.length === 3) return

                  setSelectionState({ career_ids: [...career_ids, id] })
                }
              }

              return (
                <span
                  key={id}
                  onClick={handleOnClick}
                  className={clsx(
                    'inline-flex cursor-pointer items-center rounded-full border px-3 py-1.5 text-sm font-medium',
                    active
                      ? 'border-gray-900 bg-gray-700 text-white'
                      : 'border-gray-200 bg-white text-gray-900'
                  )}
                >
                  <span>{title}</span>
                </span>
              )
            })}
          </div>
        </div>
      </div>
      {crossmodeLoading.current.length ? (
        <div className='mt-32 flex justify-center'>
          <HashLoader
            size={128}
            color={colors.carnationPink}
            speedMultiplier={0.8}
          />
        </div>
      ) : (
        <Table
          data={selectionState.spaces.map((space) => {
            const matching = {}
            const { isScoreVisible } = partner

            const hasCompleteDataset = { current: true }

            Object.keys(matchBatchIfYouCan[space.owner] || {}).forEach(
              (career_id) => {
                const { matching_score, complete_dataset } =
                  matchBatchIfYouCan[space.owner][career_id][0]

                // if there is only one with !complete_dataset,
                // we should display invite again
                if (!complete_dataset) hasCompleteDataset.current = false

                if (complete_dataset && isScoreVisible) {
                  matching[career_id] = {
                    value: crossComparison
                      ? Math.round(matching_score)
                      : 'blur',
                    complete_dataset
                  }
                } else if (complete_dataset && !isScoreVisible) {
                  matching[career_id] = {
                    value: crossComparison ? '-' : 'blur',
                    complete_dataset
                  }
                } else {
                  matching[career_id] = {
                    value: t('talents.crossmode.complete_dataset_false'),
                    complete_dataset
                  }
                }
              }
            )

            return {
              ...space,
              ...matching,
              hasCompleteDataset: hasCompleteDataset.current
            }
          })}
          columns={[
            {
              accessorKey: 'identification',
              accessorFn: (row) => row.identification || row.email,
              enableSorting: false,
              header: (
                <span className='text-sm font-semibold text-gray-900'>
                  {t('talents.column_header_identification')}
                </span>
              ),
              cell: ({ getValue, cell }) => {
                const { id, hasCompleteDataset, all_challenges_unlocked } =
                  cell.row.original

                const alreadyReinvited =
                  all_challenges_unlocked && !hasCompleteDataset

                const showReinviteOption =
                  !all_challenges_unlocked && !hasCompleteDataset

                return (
                  <div>
                    <span className='block text-sm font-medium text-gray-900'>
                      {getValue()}
                    </span>
                    {showReinviteOption && (
                      <Button.Text
                        onClick={() => {
                          trackEvent(CLICK_REQUEST_CHALLENGES_CROSSMODE)

                          handleClose()
                          setShowReinviteSpaceModal(id)
                        }}
                        text={t('talents.open_reinvite_space_modal_action')}
                      />
                    )}
                    {alreadyReinvited && (
                      <span className='text-xs italic text-gray-700'>
                        {t('talents.already_reinvited_hint')}
                      </span>
                    )}
                  </div>
                )
              }
            },
            ...selectionState.career_ids.map((id) => ({
              accessorKey: id,
              sortDescFirst: false,
              sortingFn: sortCrossComparison,
              header: (
                <span className='text-sm font-semibold text-gray-900'>
                  {careers_map.get(id).title}
                </span>
              ),
              cell: ({ getValue }) => {
                const { complete_dataset, value } = getValue() || {}
                const displayTooltip = !complete_dataset && value

                return (
                  <div className='flex items-center'>
                    <span
                      className={clsx(
                        'text-sm text-gray-500',
                        complete_dataset ? null : 'italic',
                        value === 'blur' ? 'blur' : null
                      )}
                    >
                      {value || t('talents.crossmode.no_owner')}
                    </span>
                    {displayTooltip && (
                      <div className='ml-2'>
                        <Tooltip
                          id='complete_dataset_false_tooltip'
                          place='top'
                          tip={t(
                            'talents.crossmode.complete_dataset_false_tooltip'
                          )}
                        />
                      </div>
                    )}
                  </div>
                )
              }
            })),
            {
              accessorKey: 'remove',
              enableSorting: false,
              header: '',
              cell: ({ row }) => (
                <XMarkIcon
                  onClick={row.original.removeSelected}
                  className='ml-auto h-5 w-5 text-gray-900 hover:cursor-pointer'
                />
              )
            }
          ]}
          disableHover
        />
      )}
      <div className='h-16' />
    </Slideover>
  )
}

CrossmodeSlideover.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setSelectionState: PropTypes.func.isRequired,
  crossmodeLoading: PropTypes.object.isRequired,
  selectionState: PropTypes.object.isRequired,
  matchBatchIfYouCan: PropTypes.object.isRequired,
  setShowReinviteSpaceModal: PropTypes.func.isRequired,
  careers_map: PropTypes.object.isRequired
}

export default CrossmodeSlideover
