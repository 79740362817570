import { XMarkIcon } from '@heroicons/react/24/solid'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { BeatLoader } from 'react-spinners'
import * as Yup from 'yup'

import { colors } from '../../constants/colors'
import { mutation, query } from '../../graphql'
import { EMAIL_REGEX } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { isCompanyEmail } from '../../helpers/companyEmailValidator'
import { localStorage } from '../../helpers/local-storage'
import Alert from '../alert'
import Checkbox from '../checkbox'
import Input from '../input'
import SelectMenu from '../select-menu'
import Button from '../tailwind/Button'

const INITIAL_VALUES = {
  EMAIL: '',
  RECRUITMENT_CHALLENGE: [],
  ACTUAL_DIAGNOSTIC_SUITE_0: null,
  ACTUAL_DIAGNOSTIC_SUITE_1: [],
  ACTUAL_DIAGNOSTIC_SUITE_2: null,
  ACTUAL_DIAGNOSTIC_SUITE_3: '',
  YEARLY_HIRES: 0,
  YEARLY_APPLIES: 0,
  RECRUITMENT_CHALLENGE_TIME: 0,
  MONTHLY_BUDGET: 0,
  ROLE: '',
  PHONE: ''
}

const Questionaire = ({ data, showFunnelIntro, handleNavigateBackAction }) => {
  const { id } = useParams()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { state: locationState } = useLocation()

  const [checkDomain, setCheckDomain] = useState(null)
  const [how_did_you_hear, set_how_did_you_hear] = useState(null)

  const checkIfUserDomainExist = async ({ email }) => {
    const response = await query({
      query: 'partnerControl',
      authMode: 'AWS_IAM',
      variables: {
        action: 'checkCompanyDomainExists',
        email: email.toLowerCase()
      }
    })

    const parsedRes = JSON.parse(response)
    const checkCompanyDomainExists = parsedRes.body.checkCompanyDomainExists.res

    if (parsedRes.statusCode !== 200 || checkCompanyDomainExists === false) {
      return { domainExists: false }
    }

    const { email: contact_email } = checkCompanyDomainExists

    return {
      domainExists: true,
      error: {
        title: t('authenticator.company_domain_exist_signup_title'),
        description: t(
          'authenticator.company_domain_exist_signup_description',
          { email: contact_email }
        )
      }
    }
  }

  const formMutation = useMutation({
    mutationFn: ({ input }) => {
      // no auth rules specified
      return mutation({
        mutation: 'updateForm',
        input: { ...input, lastUpdatedBy: 'AWS_IAM' },
        authMode: 'AWS_IAM'
      })
    },
    onSuccess: (response) => {
      queryClient.setQueryData(['lead-qualification-funnel', id], response)
    }
  })

  const [isLoading, setIsLoading] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(
    data && data.current !== -1 ? data.current : 0
  )

  const initialValues = useMemo(() => {
    return data ? { ...data } : { ...INITIAL_VALUES }
  }, [data])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      EMAIL: Yup.string()
        .matches(EMAIL_REGEX, t('leadfunnel.validation_email'))
        .required(t('leadfunnel.validation_required')),
      ROLE: Yup.string().required(t('leadfunnel.validation_required')),
      PHONE: Yup.string()
        .matches(/^[0-9]+$/, t('leadfunnel.validation_phone'))
        .required(t('leadfunnel.validation_required'))
    })
  })

  const {
    EMAIL,
    RECRUITMENT_CHALLENGE,
    ACTUAL_DIAGNOSTIC_SUITE_0,
    ACTUAL_DIAGNOSTIC_SUITE_2,
    YEARLY_HIRES,
    YEARLY_APPLIES,
    RECRUITMENT_CHALLENGE_TIME,
    MONTHLY_BUDGET,
    ROLE,
    PHONE
  } = formik.values

  const questions = [
    {
      key: 'EMAIL',
      title: t('leadfunnel.email.title'),
      input: {
        label: t('leadfunnel.email.input.label'),
        placeholder: t('leadfunnel.email.input.placeholder'),
        type: 'email',
        handleChange: (event) => {
          setCheckDomain(null)
          formik.setFieldValue('EMAIL', event.target.value.toLowerCase())
        }
      },
      getNextKey: () => 'RECRUITMENT_CHALLENGE',
      getBackKey: () => 'INTRO',
      disabled: !EMAIL || !!formik.errors.EMAIL
    },
    {
      key: 'RECRUITMENT_CHALLENGE',
      title: t('leadfunnel.recruitment_challenge.title'),
      description: t('leadfunnel.recruitment_challenge.description'),
      checkbox: {
        options: [
          t('leadfunnel.recruitment_challenge.checkbox.options.0'),
          t('leadfunnel.recruitment_challenge.checkbox.options.1'),
          t('leadfunnel.recruitment_challenge.checkbox.options.2'),
          t('leadfunnel.recruitment_challenge.checkbox.options.3'),
          t('leadfunnel.recruitment_challenge.checkbox.options.4')
        ]
      },
      getNextKey: () => 'ACTUAL_DIAGNOSTIC_SUITE_0',
      getBackKey: () => 'EMAIL',
      disabled: !RECRUITMENT_CHALLENGE.length
    },
    {
      key: 'ACTUAL_DIAGNOSTIC_SUITE_0',
      title: t('leadfunnel.actual_diagnostic_suite_0.title'),
      radio_group: {
        options: [
          t('leadfunnel.actual_diagnostic_suite_0.radio_group.options.0'),
          t('leadfunnel.actual_diagnostic_suite_0.radio_group.options.1')
        ]
      },
      getNextKey: () => {
        if (ACTUAL_DIAGNOSTIC_SUITE_0 === 0) {
          // yes
          return 'ACTUAL_DIAGNOSTIC_SUITE_1'
        } else if (ACTUAL_DIAGNOSTIC_SUITE_0 === 1) {
          // no
          return 'YEARLY_HIRES'
        }
      },
      getBackKey: () => 'RECRUITMENT_CHALLENGE',
      disabled: ACTUAL_DIAGNOSTIC_SUITE_0 === null
    },
    {
      key: 'ACTUAL_DIAGNOSTIC_SUITE_1',
      title: t('leadfunnel.actual_diagnostic_suite_1.title'),
      description: t('leadfunnel.actual_diagnostic_suite_1.description'),
      checkbox: {
        options: [
          t('leadfunnel.actual_diagnostic_suite_1.checkbox.options.0'),
          t('leadfunnel.actual_diagnostic_suite_1.checkbox.options.1'),
          t('leadfunnel.actual_diagnostic_suite_1.checkbox.options.2')
        ]
      },
      getNextKey: () => 'ACTUAL_DIAGNOSTIC_SUITE_2',
      getBackKey: () => 'ACTUAL_DIAGNOSTIC_SUITE_0',
      disabled: false
    },
    {
      key: 'ACTUAL_DIAGNOSTIC_SUITE_2',
      title: t('leadfunnel.actual_diagnostic_suite_2.title'),
      radio_group: {
        options: [
          t('leadfunnel.actual_diagnostic_suite_2.radio_group.options.0'),
          t('leadfunnel.actual_diagnostic_suite_2.radio_group.options.1')
        ]
      },
      getNextKey: () => {
        if (ACTUAL_DIAGNOSTIC_SUITE_2 === 0) {
          // yes
          return 'ACTUAL_DIAGNOSTIC_SUITE_3'
        } else if (ACTUAL_DIAGNOSTIC_SUITE_2 === 1) {
          // no
          return 'YEARLY_HIRES'
        }
      },
      getBackKey: () => 'ACTUAL_DIAGNOSTIC_SUITE_1',
      disabled: ACTUAL_DIAGNOSTIC_SUITE_2 === null
    },
    {
      key: 'ACTUAL_DIAGNOSTIC_SUITE_3',
      title: t('leadfunnel.actual_diagnostic_suite_3.title'),
      description: t('leadfunnel.actual_diagnostic_suite_3.description'),
      input: { label: t('leadfunnel.actual_diagnostic_suite_3.input.label') },
      getNextKey: () => 'YEARLY_HIRES',
      getBackKey: () => 'ACTUAL_DIAGNOSTIC_SUITE_2',
      disabled: false
    },
    {
      key: 'YEARLY_HIRES',
      title: t('leadfunnel.yearly_hires.title'),
      select: {
        defaultValue: t('leadfunnel.yearly_hires.select.options.0'),
        options: [
          t('leadfunnel.yearly_hires.select.options.0'),
          t('leadfunnel.yearly_hires.select.options.1'),
          t('leadfunnel.yearly_hires.select.options.2'),
          t('leadfunnel.yearly_hires.select.options.3'),
          t('leadfunnel.yearly_hires.select.options.4'),
          t('leadfunnel.yearly_hires.select.options.5'),
          t('leadfunnel.yearly_hires.select.options.6')
        ]
      },
      getNextKey: () => 'YEARLY_APPLIES',
      getBackKey: () => {
        if (ACTUAL_DIAGNOSTIC_SUITE_0 === 1) {
          return 'ACTUAL_DIAGNOSTIC_SUITE_0'
        } else if (ACTUAL_DIAGNOSTIC_SUITE_2 === 1) {
          return 'ACTUAL_DIAGNOSTIC_SUITE_2'
        } else if (
          ACTUAL_DIAGNOSTIC_SUITE_0 === 0 &&
          ACTUAL_DIAGNOSTIC_SUITE_2 === 0
        ) {
          return 'ACTUAL_DIAGNOSTIC_SUITE_3'
        }
      },
      disabled: !YEARLY_HIRES
    },
    {
      key: 'YEARLY_APPLIES',
      title: t('leadfunnel.yearly_applies.title'),
      select: {
        defaultValue: t('leadfunnel.yearly_applies.select.options.0'),
        options: [
          t('leadfunnel.yearly_applies.select.options.0'),
          t('leadfunnel.yearly_applies.select.options.1'),
          t('leadfunnel.yearly_applies.select.options.2'),
          t('leadfunnel.yearly_applies.select.options.3'),
          t('leadfunnel.yearly_applies.select.options.4'),
          t('leadfunnel.yearly_applies.select.options.5'),
          t('leadfunnel.yearly_applies.select.options.6'),
          t('leadfunnel.yearly_applies.select.options.7'),
          t('leadfunnel.yearly_applies.select.options.8')
        ]
      },
      getNextKey: () => 'RECRUITMENT_CHALLENGE_TIME',
      getBackKey: () => 'YEARLY_HIRES',
      disabled: !YEARLY_APPLIES
    },
    {
      key: 'RECRUITMENT_CHALLENGE_TIME',
      title: t('leadfunnel.recruitment_challenge_time.title'),
      select: {
        defaultValue: t(
          'leadfunnel.recruitment_challenge_time.select.options.0'
        ),
        options: [
          t('leadfunnel.recruitment_challenge_time.select.options.0'),
          t('leadfunnel.recruitment_challenge_time.select.options.1'),
          t('leadfunnel.recruitment_challenge_time.select.options.2'),
          t('leadfunnel.recruitment_challenge_time.select.options.3'),
          t('leadfunnel.recruitment_challenge_time.select.options.4')
        ]
      },
      getNextKey: () => 'MONTHLY_BUDGET',
      getBackKey: () => 'YEARLY_APPLIES',
      disabled: !RECRUITMENT_CHALLENGE_TIME
    },
    {
      key: 'MONTHLY_BUDGET',
      title: t('leadfunnel.monthly_budget.title'),
      select: {
        defaultValue: t('leadfunnel.monthly_budget.select.options.0'),
        options: [
          t('leadfunnel.monthly_budget.select.options.0'),
          t('leadfunnel.monthly_budget.select.options.1'),
          t('leadfunnel.monthly_budget.select.options.2'),
          t('leadfunnel.monthly_budget.select.options.3'),
          t('leadfunnel.monthly_budget.select.options.4'),
          t('leadfunnel.monthly_budget.select.options.5'),
          t('leadfunnel.monthly_budget.select.options.6')
        ]
      },
      getNextKey: () => 'ROLE',
      getBackKey: () => 'RECRUITMENT_CHALLENGE_TIME',
      disabled: !MONTHLY_BUDGET
    },
    {
      key: 'ROLE',
      title: t('leadfunnel.role.title'),
      input: {
        label: t('leadfunnel.role.input.label'),
        placeholder: t('leadfunnel.role.input.placeholder')
      },
      getNextKey: () => 'PHONE',
      getBackKey: () => 'MONTHLY_BUDGET',
      disabled: !ROLE
    },
    {
      key: 'PHONE',
      title: t('leadfunnel.phone.title'),
      input: {
        label: t('leadfunnel.phone.input.label'),
        placeholder: t('leadfunnel.phone.input.placeholder')
      },
      getNextKey: () => 'HOW_DID_YOU_HEAR',
      getBackKey: () => 'ROLE',
      disabled: !PHONE || !!formik.errors.PHONE
    },
    {
      key: 'HOW_DID_YOU_HEAR',
      title: t('leadfunnel.how_did_you_hear.title'),
      description: t('leadfunnel.how_did_you_hear.description'),
      radio_group_with_input: {
        options: [0, 1, 2, 3, 4, 5, 6].map((index) => ({
          label: t(`leadfunnel.how_did_you_hear.radio_group.options.${index}`),
          value: index,
          checked: index === how_did_you_hear,
          onChange: () => {
            const field_value =
              index === 6
                ? ''
                : t(`leadfunnel.how_did_you_hear.radio_group.options.${index}`)

            set_how_did_you_hear(index)
            formik.setFieldValue('HOW_DID_YOU_HEAR', field_value)
          },
          input:
            index === 6
              ? {
                  id: 'how_did_you_hear_options_6',
                  onChange: (event) => {
                    formik.setFieldValue('HOW_DID_YOU_HEAR', event.target.value)
                  },
                  value: formik.values.HOW_DID_YOU_HEAR
                }
              : undefined
        }))
      },
      getNextKey: () => 'COMPLETED',
      getBackKey: () => 'PHONE',
      disabled: false
    }
  ]

  const {
    key,
    title,
    description,
    select,
    checkbox,
    input,
    radio_group,
    radio_group_with_input,
    getNextKey,
    getBackKey,
    disabled
  } = questions[currentIndex]

  const updateFormData = async ({ nextKey }) => {
    const KEY_IS_EMAIL = key === 'EMAIL'

    if (nextKey === 'INTRO') {
      showFunnelIntro()
      return
    }

    const { values } = formik

    if (KEY_IS_EMAIL && !isCompanyEmail(values.EMAIL)) {
      formik.setFieldError('EMAIL', t('authenticator.no_company_mail_signup'))
      return
    }

    setIsLoading(true)

    // request the check only once
    if (KEY_IS_EMAIL && !checkDomain && id === 'new') {
      const shadow = await checkIfUserDomainExist({ email: values.EMAIL })

      if (shadow.domainExists) {
        setCheckDomain(shadow)
        setIsLoading(false)

        return
      }
    }

    const nextIndex = questions.map(({ key: k }) => k).indexOf(nextKey)
    const completed = nextKey === 'COMPLETED'

    if (completed) {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'submit_lead_form', {})
      // console.log('Push submit_lead_form uet')
      trackEvent('completeLeadQualificationFunnel')
    }

    const result = {
      ...values,
      current: completed ? undefined : nextIndex,
      completed,
      ref_link: data
        ? data.ref_link
        : locationState
          ? locationState.searchParams
          : ''
    }

    if (id === 'new') {
      // no auth rules specified
      mutation({
        mutation: 'createForm',
        authMode: 'AWS_IAM',
        input: {
          data: JSON.stringify(result),
          form_source: 'LEAD_QUALIFICATION_FUNNEL',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          lastUpdatedBy: 'AWS_IAM'
        }
      }).then((response) => {
        // we have to set the query data as seen below because
        // we get the id the first time from the response
        queryClient.setQueryData(
          ['lead-qualification-funnel', response.id],
          response
        )

        setIsLoading(false)
        setCurrentIndex(nextIndex)
        localStorage.setItem('ausprobieren', response.id)

        navigate(`/ausprobieren/${response.id}`, { replace: true })
      })

      return
    }

    formMutation.mutate(
      {
        input: { id, data: JSON.stringify(result) }
      },
      {
        onSuccess: () => {
          setIsLoading(false)

          if (nextKey !== 'COMPLETED') setCurrentIndex(nextIndex)
        }
      }
    )
  }

  const totalLength = questions.length
  const progress = (currentIndex / totalLength) * 100

  return (
    <>
      <div
        className='absolute right-8 top-8 z-10 cursor-pointer'
        onClick={() => handleNavigateBackAction({ question: key })}
      >
        <XMarkIcon className='h-6 w-6 text-gray-900' />
      </div>
      <div className='mt-24'>
        <div className='mx-auto max-w-xl'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            {title}
          </h3>
          {description && (
            <p className='mt-1 max-w-lg text-sm text-gray-700'>{description}</p>
          )}
          <div className='mt-4' />
          {checkbox &&
            checkbox.options.map((value, index) => (
              <div key={index} className='mb-2 flex'>
                <Checkbox
                  id={String(index)}
                  checked={formik.values[key].includes(index)}
                  label={value}
                  onChange={(event) => {
                    const { checked } = event.target
                    let res = formik.values[key]

                    if (checked) res.push(index)
                    else res = res.filter((i) => i !== index)

                    formik.setFieldValue(key, res)
                  }}
                />
              </div>
            ))}
          {select && (
            <SelectMenu
              key={key}
              id={key}
              options={select.options.map((value, index) => [index, value])}
              defaultValue={formik.values[key]}
              onChange={(index) => formik.setFieldValue(key, index)}
              translate={false}
            />
          )}
          {input && (
            <Input
              {...input}
              id={key}
              onChange={
                input.handleChange
                  ? (event) => input.handleChange(event)
                  : formik.handleChange
              }
              value={formik.values[key]}
              error={formik.errors[key]}
              touched={formik.touched[key]}
              onBlur={formik.handleBlur}
            />
          )}
          {radio_group &&
            radio_group.options.map((value, index) => (
              <label
                key={index}
                className='mb-2 flex items-center text-sm font-medium text-gray-700'
              >
                <input
                  type='radio'
                  name={key}
                  value={index}
                  className='mr-3 h-4 w-4 rounded-full border-gray-300 text-blue-600 focus:ring-blue-500'
                  checked={formik.values[key] === index}
                  onChange={() => formik.setFieldValue(key, index)}
                />
                {value}
              </label>
            ))}
          {radio_group_with_input &&
            radio_group_with_input.options.map(
              ({ checked, onChange, input, value, label }, index) => (
                <div key={index}>
                  <label className='mb-2 flex items-center text-sm font-medium text-gray-700'>
                    <input
                      type='radio'
                      name={key}
                      value={value}
                      className='mr-3 h-4 w-4 rounded-full border-gray-300 text-blue-600 focus:ring-blue-500'
                      checked={checked}
                      onChange={onChange}
                    />
                    {label}
                  </label>
                  {checked && input && (
                    <div className='mt-4 max-w-sm'>
                      <Input {...input} />
                    </div>
                  )}
                </div>
              )
            )}
          {checkDomain && key === 'EMAIL' && (
            <div className='mt-4'>
              <Alert
                title={checkDomain.error.title}
                description={checkDomain.error.description}
              />
            </div>
          )}
          <div className='my-12 flex justify-end gap-x-2'>
            {isLoading ? (
              <BeatLoader color={colors.primaryBlue} />
            ) : (
              <>
                <Button.SecondaryLG
                  onClick={() => updateFormData({ nextKey: getBackKey() })}
                  text={t('leadfunnel.go_back')}
                />
                <Button.PrimaryLG
                  text={t('leadfunnel.continue')}
                  onClick={() => updateFormData({ nextKey: getNextKey() })}
                  disabled={disabled}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {!!progress && (
        <div className='relative mx-auto mt-auto flex h-6 w-full max-w-2xl rounded-xl bg-blue-100'>
          <span
            style={{ marginLeft: '48%' }}
            className='absolute self-center text-sm font-medium text-blue-900'
          >
            {`${Math.round(progress)}%`}
          </span>
          <div
            style={{
              width: `${progress}%`
            }}
            className='h-6 rounded-xl bg-blue-300'
          />
        </div>
      )}
    </>
  )
}

Questionaire.propTypes = {
  data: PropTypes.object,
  showFunnelIntro: PropTypes.func.isRequired,
  handleNavigateBackAction: PropTypes.func.isRequired
}

Questionaire.defaultProps = {
  data: null
}

export default Questionaire
