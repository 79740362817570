import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import Checkbox from '../../checkbox'
import Button from '../../tailwind/Button'

const FIELDS = {
  email: {
    key: 'email',
    label: 'career.flex.checkbox_email_fieldlabel'
  },
  firstname: {
    key: 'firstname',
    label: 'career.flex.checkbox_firstname_fieldlabel'
  },
  lastname: {
    key: 'lastname',
    label: 'career.flex.checkbox_lastname_fieldlabel'
  },
  phone: {
    key: 'phone',
    label: 'career.flex.checkbox_phone_fieldlabel'
  },
  postcode: {
    key: 'postcode',
    label: 'career.flex.checkbox_postcode_fieldlabel'
  },
  work_experience: {
    key: 'work_experience',
    label: 'career.flex.checkbox_work_experience_fieldlabel',
    description: 'career.flex.checkbox_work_experience_fielddescription'
  }
}

const GeneralFields = ({
  career,
  updateHandler,
  backHandler,
  create,
  update,
  isLoading
}) => {
  const { t } = useTranslation()

  const components = useMemo(() => {
    return JSON.parse(career.app_settings?.components || '{}')
  }, [career])

  const initialValues = useMemo(() => {
    if (!components.customLandingPageFields) {
      return {
        email: true,
        firstname: true,
        lastname: true,
        phone: true,
        postcode: true,
        work_experience: true
      }
    }

    return {
      email: components.customLandingPageFields.includes(FIELDS.email.key),
      firstname: components.customLandingPageFields.includes(
        FIELDS.firstname.key
      ),
      lastname: components.customLandingPageFields.includes(
        FIELDS.lastname.key
      ),
      phone: components.customLandingPageFields.includes(FIELDS.phone.key),
      postcode: components.customLandingPageFields.includes(
        FIELDS.postcode.key
      ),
      work_experience: components.customLandingPageFields.includes(
        FIELDS.work_experience.key
      )
    }
  }, [components])

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      landing_page_text: Yup.string(),
      email: Yup.bool(),
      firstname: Yup.bool(),
      lastname: Yup.bool(),
      phone: Yup.bool(),
      postcode: Yup.bool(),
      work_experience: Yup.bool()
    }),
    initialValues,
    onSubmit: ({
      email,
      firstname,
      lastname,
      phone,
      postcode,
      work_experience
    }) => {
      updateHandler({
        customLandingPageFields: [
          email && FIELDS.email.key,
          firstname && FIELDS.firstname.key,
          lastname && FIELDS.lastname.key,
          phone && FIELDS.phone.key,
          postcode && FIELDS.postcode.key,
          work_experience && FIELDS.work_experience.key
        ].filter((field) => field)
      })
    }
  })

  const allRecognitionFieldsUnchecked = (formik) => {
    const { email, firstname, lastname, phone } = formik.values

    return !email && !firstname && !lastname && !phone
  }

  return (
    <>
      <div className='rounded bg-gray-50 px-6 py-6'>
        <fieldset className='grid grid-cols-2 gap-2'>
          {Object.keys(FIELDS)
            .map((key) => FIELDS[key])
            .map(({ key, label, description }) => (
              <div className='flex' key={key}>
                <Checkbox
                  id={key}
                  onChange={formik.handleChange}
                  checked={formik.values[key]}
                  label={t(label)}
                  description={t(description)}
                />
              </div>
            ))}
        </fieldset>
        {allRecognitionFieldsUnchecked(formik) && (
          <div className='mt-6 text-center text-xs italic text-gray-500'>
            {t('public_link.all_recognition_fields_unchecked_attention')}
          </div>
        )}
      </div>
      <div className='mt-8 flex justify-end gap-x-2'>
        {create && (
          <Button.PrimaryLG
            text={t('career.flex.create_flex.next_action')}
            onClick={formik.handleSubmit}
          />
        )}
        {update && (
          <>
            <Button.SecondaryLG
              text={t('career.flex.create_flex.back_action')}
              onClick={backHandler}
            />
            <Button.PrimaryLG
              text={t('career.flex.create_flex.submit_action')}
              onClick={formik.handleSubmit}
              disabled={!formik.dirty}
              isLoading={isLoading}
            />
          </>
        )}
      </div>
    </>
  )
}

GeneralFields.propTypes = {
  career: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  backHandler: PropTypes.func.isRequired,
  create: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
}

GeneralFields.defaultProps = {
  isLoading: false
}

export default GeneralFields
