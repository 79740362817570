import {
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import { format, formatDistance, subSeconds } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import TeamAnalysisIllustration from '../assets/images/illustrations/team-analysis-compact-min.png'
import { ActivityIndicator, Seo } from '../components'
import Button from '../components/tailwind/Button'
import Modal from '../components/tailwind/Modal'
import PageHeading from '../components/tailwind/PageHeading'
import trackEvents from '../constants/track-events'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext
} from '../context'
import { mutation, query } from '../graphql'
import { classNames } from '../helpers'
import { trackEvent } from '../helpers/analytics'

const TeamAnalyses = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { language } = useLanguageContext()
  const { alert, success } = useNotificationContext()
  const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useState(false)

  const navigate = useNavigate()

  const { status, data, dataUpdatedAt, fetchStatus, refetch } = useQuery({
    queryKey: ['team-analyses', partner.id],
    queryFn: () =>
      query({
        query: 'listAnalysesByPartner',
        variables: { partner_id: partner.id }
      }),
    select: (response) =>
      response
        .map((item) => ({ ...item, ...JSON.parse(item.input) }))
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  })

  useEffect(() => {
    if (data === null || data === undefined) return

    if (!data.length && fetchStatus === 'idle') {
      setIsWelcomeModalVisible(true)
    }
  }, [data, fetchStatus])

  if (status === 'pending') {
    return (
      <div className='mt-32 flex items-center justify-center'>
        <ActivityIndicator />
      </div>
    )
  }

  const getFetchingSubtitle = () => {
    if (status === 'error') return null

    // data updated at defaults to 0 for an empty cache
    if (!dataUpdatedAt) {
      return t('team_analyses.fetching_subtitle.is_loading')
    }

    if (fetchStatus === 'fetching') {
      return t('team_analyses.fetching_subtitle.is_updating')
    }

    return t('team_analyses.fetching_subtitle.last_updated_at', {
      distance_sub_seconds: formatDistance(
        subSeconds(new Date(dataUpdatedAt), 3),
        new Date(),
        {
          locale: { de, en }[language]
        }
      )
    })
  }

  const deleteTeamAnalyse = ({ analyse_id }) => {
    alert(
      t('team_analyses.delete_modal.title'),
      t('team_analyses.delete_modal.description'),
      [
        {
          onPress: () => {
            mutation({
              mutation: 'deleteAnalyse',
              input: { id: analyse_id }
            })
              .then(() => {
                refetch()
                trackEvent(trackEvents.DELETE_TEAMANALYSIS)
                success(t('team_analyses.delete_success'))
              })
              .catch(() => undefined)
          },
          text: t('team_analyses.delete_modal.submit_action')
        },
        {
          text: t('team_analyses.delete_modal.cancel_action'),
          style: 'cancel'
        }
      ]
    )
  }

  return (
    <>
      <Seo title={t('seo.titles.teamanalyses')} />
      <PageHeading
        title={t('seo.titles.teamanalyses')}
        subtitle={getFetchingSubtitle()}
        withActions={
          <Button.PrimaryXL
            text={t('team_analyses.create_action')}
            onClick={() => {
              trackEvent(trackEvents.CREATE_TEAMANALYSIS)
              navigate('/team-analyse/new')
            }}
          />
        }
      />
      <div className='px-4 sm:px-6 lg:px-8'>
        <ul role='list' className='divide-y divide-gray-100'>
          {data.map(({ id, title, createdAt, status }) => (
            <li
              key={id}
              className='flex items-center justify-between gap-x-6 py-5'
            >
              <div className='min-w-0'>
                <div className='flex items-start gap-x-3'>
                  <p className='truncate text-sm font-semibold leading-6 text-gray-900'>
                    {title}
                  </p>
                  <p
                    className={classNames(
                      status === 'ACTIVE'
                        ? 'bg-green-50 text-green-700 ring-green-600/20'
                        : 'bg-gray-50 text-gray-600 ring-gray-500/10',
                      'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                    )}
                  >
                    {status === 'ACTIVE'
                      ? t('team_analyses.active')
                      : t('team_analyses.in_progress')}
                  </p>
                </div>
                <div className='mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500'>
                  <p className='whitespace-nowrap'>
                    {t('team_analyses.created_at', {
                      created_at: format(new Date(createdAt), 'd. MMMM yyyy', {
                        locale: { de, en }[language]
                      })
                    })}
                  </p>
                </div>
              </div>
              <div className='flex flex-none items-center gap-x-4'>
                <Link
                  onClick={() => trackEvent(trackEvents.VIEW_TEAMANALYSIS)}
                  to={
                    status === 'ACTIVE'
                      ? `/team-analyse/${id}`
                      : `/team-analyse/new/${id}`
                  }
                  className='block rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                >
                  {t('team_analyses.show_analyse_action')}
                  <span className='sr-only'>, {title}</span>
                </Link>
                <Menu as='div' className='relative flex-none'>
                  <MenuButton className='-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900'>
                    <span className='sr-only'>Open options</span>
                    <EllipsisVerticalIcon
                      className='h-5 w-5'
                      aria-hidden='true'
                    />
                  </MenuButton>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <MenuItems className='absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                      <MenuItem
                        onClick={() => deleteTeamAnalyse({ analyse_id: id })}
                        className='cursor-pointer'
                      >
                        {({ active }) => (
                          <span
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block whitespace-nowrap px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            {t('team_analyses.delete_action')}
                            <span className='sr-only'>, {title}</span>
                          </span>
                        )}
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Modal
        open={isWelcomeModalVisible}
        setOpen={() => setIsWelcomeModalVisible(!isWelcomeModalVisible)}
        size='xl'
      >
        <div className='mx-auto flex justify-center'>
          <img src={TeamAnalysisIllustration} className='h-auto w-7/12' />
        </div>
        <div className='mt-3 text-center sm:mt-5'>
          <DialogTitle
            as='h3'
            className='text-2xl font-medium leading-6 text-gray-900'
          >
            {t('team_analyses.title.modal')}
          </DialogTitle>
          <div className='mt-4 flex justify-center'>
            <p className='w-9/12 text-sm text-gray-700'>
              {t('team_analyses.description.modal')}
            </p>
          </div>
        </div>
        <div className='mt-5 flex justify-center sm:mt-6'>
          <Button.PrimaryXL
            text='Erstelle deine erste Teamanalyse'
            onClick={() => navigate('/team-analyse/new')}
          />
        </div>
      </Modal>
    </>
  )
}

export default TeamAnalyses
