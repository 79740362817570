export const PAYMENT = `{
  id
  partner_id
  authorized_read
  products
  expires_at
  is_trial
  start_at
  price
  payment_service
  spaces_count
  career_count
  seat_count
  unused_campaign_credits
  product_type
  hirings_count
  status
  createdAt
  updatedAt
}`
