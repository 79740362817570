import { DialogTitle } from '@headlessui/react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNotificationContext } from '../../context'
import { mutation } from '../../graphql'
import { useCreateLog } from '../../hooks/use-create-log'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'

const RecoverSpacesAction = ({ spaces, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()

  const { createSpaceHistoryLog } = useCreateLog()

  const [isLoading, setIsLoading] = useState(false)

  const recoverSpaces = async () => {
    setIsLoading(true)

    try {
      const event = { context: 'SYSTEM', status: 'RECOVERED' }

      for (const space of spaces) {
        const { id: space_id, career_id } = space

        createSpaceHistoryLog({ space_id, career_id, ...event })
        mutation({
          mutation: 'updateSpace',
          input: { id: space_id, soft_deleted: null }
        })
      }

      updateHandler()

      success(t('recover_talents.recover_space_success'))
      setIsLoading(false)
      setOpen(false)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal open={open} setOpen={() => setOpen(false)} size='lg'>
      <DialogTitle className='text-center text-2xl font-medium leading-6 text-gray-900'>
        {t('recover_talents.recover_action')}
      </DialogTitle>
      <span className='mt-2 block text-center text-base text-gray-600'>
        {t('recover_talents.recover_description')}
      </span>
      <div className='mt-8 flex flex-row gap-3'>
        <Button.SecondaryXL
          fullWidth
          text={t('recover_talents.recover_cancel_action')}
          onClick={() => setOpen(false)}
        />
        <Button.PrimaryXL
          fullWidth
          text={
            spaces.length === 1
              ? t('recover_talents.recover_action_single', {
                  count: spaces.length
                })
              : t('recover_talents.recover_action_multiple', {
                  count: spaces.length
                })
          }
          onClick={recoverSpaces}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}

RecoverSpacesAction.propTypes = {
  spaces: PropTypes.array.isRequired,
  updateHandler: PropTypes.func,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

RecoverSpacesAction.defaultProps = {
  updateHandler: () => undefined
}

export default RecoverSpacesAction
