import { motion } from 'framer-motion'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { JSONTree } from 'react-json-tree'

import {
  ArrowSmallRightIcon,
  StarIcon as StarIconOutline
} from '@heroicons/react/24/outline'
import {
  IdentificationIcon,
  StarIcon as StarIconSolid,
  UserIcon
} from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import { classNames, colorByString, getBackgroundColorByMatchingScore } from '.'
import IndeterminateCheckbox from '../components/react-table/indeterminate-checkbox'
import {
  ASSESSMENT_SPACE_STATES,
  SMART_PREDICT_SPACE_STATES
} from '../constants/space-states'
import { query } from '../graphql'
import { filter, filterMore, filterScore, filterStatus } from './filters'

import NativeTooltip from '../components/native-tooltip'

const getName = ({ firstname, lastname }) => {
  if (!firstname && !lastname) return null
  if (!firstname) return lastname
  if (!lastname) return firstname

  return `${firstname} ${lastname}`
}

const COLUMNS = ({ t, careers_map, data, language, locale, actions }) => ({
  SELECT: {
    id: 'select',
    disableOnClick: true,
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          id: 'react-table-select-all',
          checked: table.getIsAllRowsSelected(),
          // indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler()
        }}
      />
    ),
    cell: ({ row }) => (
      <IndeterminateCheckbox
        checked={row.getIsSelected()}
        // disabled={!row.getCanSelect()}
        // indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    )
  },
  FAVORED_BY_USER: {
    accessorKey: 'favored_by',
    sortingFn: (rowA) => {
      const { isFavored } = rowA.original.favored_by

      return isFavored ? 1 : -1
    },
    header: null,
    cell: ({ getValue, cell }) => {
      const { isFavored, action } = getValue()

      const tooltip_content = t('favored_space.tooltip')

      return (
        <motion.div
          className='inline-flex'
          whileHover={{ scale: 1.1 }}
          // whileTap={isFavored ? undefined : { scale: 1.2, rotate: 45 }}
        >
          {isFavored ? (
            <button onClick={() => actions[action](cell.row.original)}>
              <StarIconSolid className='h-6 w-6 cursor-pointer text-amber-400' />
            </button>
          ) : (
            <NativeTooltip className='' text={tooltip_content} delay={1000 * 3}>
              <button onClick={() => actions[action](cell.row.original)}>
                <StarIconOutline className='h-6 w-6 cursor-pointer text-gray-500 hover:text-gray-600' />
              </button>
            </NativeTooltip>
          )}
        </motion.div>
      )
    },
    disableOnClick: true
  },
  ADMIN_INSIGHTS: {
    id: 'admin-insights',
    accessorKey: 'id',
    enableSorting: false,
    disableOnClick: true,
    header: <span className='text-sm font-semibold text-gray-900'>ID</span>,
    cell: ({ getValue, row }) => (
      <>
        <span className='block text-sm font-medium text-gray-900'>
          {getValue()}
        </span>
        <JSONTree data={row.original} shouldExpandNodeInitially={() => false} />
      </>
    )
  },
  IDENTIFICATION: {
    accessorKey: 'identification',
    accessorFn: (row) => row.identification || row.email,
    enableSorting: false,
    header: (
      <span className='text-sm font-semibold text-gray-900'>
        {t('talents.column_header_identification')}
      </span>
    ),
    cell: ({ getValue, cell }) => {
      const identification = getValue()
      const { person, via, partner_user_id } = cell.row.original

      const name = getName({
        firstname: person?.firstname || person?.invitation_firstname,
        lastname: person?.lastname || person?.invitation_lastname
      })

      return (
        <div>
          <p className='truncate text-sm font-medium text-blue-700'>
            {via === 'EMAIL' ? identification : t('invitation_via_link')}
          </p>
          {(!!name || partner_user_id) && (
            <div className='mt-2 flex items-center gap-x-2'>
              {!!name && (
                <p
                  className='flex items-center text-sm text-gray-500'
                  title='Talent Name'
                >
                  <UserIcon
                    className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                  />
                  <span className='truncate'>{name}</span>
                </p>
              )}
              {partner_user_id && (
                <p
                  className='flex items-center text-sm text-gray-500'
                  title='Talent ID'
                >
                  <IdentificationIcon
                    className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                  />
                  <span className='truncate'>{partner_user_id}</span>
                </p>
              )}
            </div>
          )}
        </div>
      )
    }
  },
  PARTNER_USER_ID: {
    invisible: true,
    accessorKey: 'partner_user_id',
    accessorFn: (row) => row.partner_user_id || '-',
    header: null,
    cell: () => <div />
  },
  NAME: {
    invisible: true,
    accessorKey: 'name',
    accessorFn: (row) => row.name || '-',
    header: null,
    cell: () => <div />
  },
  SOURCE: {
    accessorKey: 'assessment_id',
    enableSorting: false,
    header: (
      <span className='text-sm font-semibold text-gray-900'>
        {t('talents.column_header_career')}
      </span>
    ),
    cell: ({ getValue }) => {
      const { title, external_custom_id, archived } =
        careers_map.get(getValue()) || {}

      const career_title_with_archived = archived
        ? t('talents.career_archived_title', { career_title: title })
        : title

      const career_title = external_custom_id
        ? `${career_title_with_archived} | ${external_custom_id}`
        : career_title_with_archived

      return (
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
            colorByString(career_title)?.backgroundColor
          } ${colorByString(career_title)?.text}`}
        >
          {career_title}
        </span>
      )
    },
    filter: {
      id: 'assessment_id',
      filterKey: 'computed',
      operator: 'in',
      fn: filter,
      name: t('talents.filter_title_sources'),
      column: 'assessment_id',
      options: [
        { id: 'ATTRACT', title: 'Attract' },
        { id: 'TALENTPOOL', title: t('talents.talentpool_title') },
        ...(data ? data.careers : [])
      ]
        .filter(({ archived }) => !archived)
        .map(({ id, title, external_custom_id }) => ({
          value: id,
          label: external_custom_id ? `${title} | ${external_custom_id}` : title
        }))
    }
  },
  UPDATED_AT: {
    accessorKey: 'updatedAt',
    sortDescFirst: true,
    header: (
      <span className='text-sm font-semibold text-gray-900'>
        {t('talents.column_header_last_activity')}
      </span>
    ),
    cell: ({ getValue }) => (
      <span className='text-sm text-gray-500'>
        {t('talents.updated_at', {
          datetime: format(new Date(getValue()), 'dd.MM.yyyy | HH:mm', {
            locale: locale[language]
          })
        })}
      </span>
    )
  },
  CREATED_STATUS: {
    accessorKey: 'created_status',
    sortDescFirst: true,
    header: (
      <span className='text-sm font-semibold text-gray-900'>
        {t('talents.column_header_created_at')}
      </span>
    ),
    cell: ({ getValue }) => (
      <span className='text-sm text-gray-500'>
        {format(new Date(getValue().createdAt), 'dd.MM.yyyy', {
          locale: locale[language]
        })}
      </span>
    )
  },
  ID: {
    accessorKey: 'id',
    header: null,
    cell: () => <div />,
    invisible: true
  },
  OWNER: {
    accessorKey: 'owner',
    header: null,
    cell: () => <div />,
    invisible: true
  },
  MORE: {
    accessorKey: 'more',
    header: null,
    cell: () => <div />,
    invisible: true,
    filter: {
      id: 'more',
      filterKey: 'computed',
      operator: 'in',
      fn: filterMore,
      name: t('talents.filter_title_more'),
      column: 'more',
      options: [
        {
          label: t('talents.filter_value_6_month'),
          value: 'BEFORE_6_MONTH'
        },
        {
          label: t('talents.filter_value_favorites'),
          value: 'IS_FAVORED'
        }
      ]
    }
  }
})

export const createTalentTableColumns = ({
  adminInsights,
  t,
  careers_map,
  data,
  language,
  partner,
  locale,
  handleNavigate,
  actions,
  displayIntegrationFilterOptions
}) => {
  const {
    SELECT,
    FAVORED_BY_USER,
    ADMIN_INSIGHTS,
    IDENTIFICATION,
    PARTNER_USER_ID,
    NAME,
    SOURCE,
    UPDATED_AT,
    CREATED_STATUS,
    ID,
    OWNER,
    MORE
  } = COLUMNS({ t, careers_map, data, language, locale, actions })

  return [
    SELECT,
    adminInsights && ADMIN_INSIGHTS,
    IDENTIFICATION,
    FAVORED_BY_USER,
    SOURCE,
    {
      accessorKey: 'progress',
      sortDescFirst: true,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_progress')}
        </span>
      ),
      cell: ({ getValue }) => {
        const progress = getValue() || 0

        return (
          <div style={{ height: 48, width: 48 }}>
            <CircularProgressbar
              strokeWidth={4}
              value={progress}
              text={`${progress}% `}
              className='font-semibold'
              styles={buildStyles({
                pathColor: '#29CCC7',
                textColor: '#29CCC7',
                trailColor: '#F5F7FA',
                textSize: '24px'
              })}
            />
          </div>
        )
      },
      sortingFn: (rowA, rowB) => {
        const progressA = rowA.original.progress || 0
        const progressB = rowB.original.progress || 0

        return progressA < progressB ? 1 : -1
      }
    },
    {
      accessorKey: 'status',
      sortDescFirst: true,
      disableOnClick: true,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_status')}
        </span>
      ),
      cell: ({ cell }) => {
        const { assessment_status, smart_predict_status, progress } =
          cell.row.original

        const { status, action } =
          progress === 100 ? smart_predict_status : assessment_status

        const onClick = action
          ? () => actions[action](cell.row.original)
          : () => handleNavigate(cell)

        return (
          <span
            onClick={onClick}
            className={classNames(
              'text-sm',
              action
                ? 'cursor-pointer font-medium text-blue-700 hover:text-blue-600'
                : 'text-gray-500'
            )}
          >
            {action
              ? t(`talents.actions.${action}`)
              : t(status.label, { progress })}
          </span>
        )
      },
      sortingFn: (rowA, rowB, columnId) => {
        // '291' < '3'
        const a_progress = rowA.original.progress
        const b_progress = rowB.original.progress

        const { sortWeight: a_sortWeight } =
          a_progress === 100
            ? rowA.getValue(columnId).smart_predict_status.status
            : rowA.getValue(columnId).assessment_status.status

        const { sortWeight: b_sortWeight } =
          b_progress === 100
            ? rowB.getValue(columnId).smart_predict_status.status
            : rowB.getValue(columnId).assessment_status.status

        return a_sortWeight < b_sortWeight ? 1 : -1
      }
    },
    {
      invisible: true,
      accessorKey: 'assessment_status',
      header: null,
      cell: () => <div />,
      filter: {
        id: 'assessment_status',
        filterKey: 'computed',
        operator: 'in',
        fn: filterStatus,
        name: t('talents.filter_title_assessment_status'),
        column: 'assessment_status',
        options: Object.keys(ASSESSMENT_SPACE_STATES)
          .filter(
            displayIntegrationFilterOptions
              ? () => true // use all ASSESSMENT_SPACE_STATES
              : (k) => !k.includes('INTEGRATION_')
          )
          .map((key) => ({
            value: ASSESSMENT_SPACE_STATES[key].filterValue,
            label: t(ASSESSMENT_SPACE_STATES[key].filterValue),
            sortWeight: ASSESSMENT_SPACE_STATES[key].sortWeight
          }))
          .sort((a, b) => (a.sortWeight < b.sortWeight ? -1 : 1))
      }
    },
    {
      invisible: true,
      accessorKey: 'smart_predict_status',
      header: null,
      cell: () => <div />,
      filter: {
        id: 'smart_predict_status',
        filterKey: 'computed',
        operator: 'in',
        fn: filterStatus,
        name: t('talents.filter_title_smart_predict_status'),
        column: 'smart_predict_status',
        options: Object.keys(SMART_PREDICT_SPACE_STATES)
          .map((key) => ({
            value: SMART_PREDICT_SPACE_STATES[key].filterValue,
            label: t(SMART_PREDICT_SPACE_STATES[key].filterValue),
            sortWeight: SMART_PREDICT_SPACE_STATES[key].sortWeight
          }))
          .sort((a, b) => (a.sortWeight < b.sortWeight ? -1 : 1))
      }
    },
    {
      accessorKey: 'score',
      sortDescFirst: true,
      disableOnClick: true,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_score')}
        </span>
      ),
      onClick: async (cell) => {
        const {
          owner,
          career_id,
          id: space_id,
          partner_user_id,
          matching_score_to_space_career
        } = cell.row.original

        if (typeof matching_score_to_space_career !== 'number') return

        const career = careers_map.get(career_id)
        const variables = { career_id, language }

        if (partner_user_id) variables.partner_user_id = partner_user_id
        else variables.user_id = owner

        if (career.diagnostic_version === 2) {
          variables.space_id = space_id
          variables.type = 'MATCHINGPROFILE'
        }

        const pdfDocument = window.open('', '_blank')
        pdfDocument.document.write(t('pages.talent.pdf_preparation'))

        try {
          const pdf = await query({ query: 'generatePdf', variables })

          pdfDocument.location.href = JSON.parse(pdf).headers.Location
        } catch (err) {
          console.error(err)
          pdfDocument.close()
        }
      },
      cell: ({ getValue }) => {
        const score = getValue()
        const scoreAvailable = typeof score === 'number'

        return (
          <div
            title={scoreAvailable ? 'Download' : undefined}
            style={{
              height: 32,
              width: 60,
              backgroundColor: getBackgroundColorByMatchingScore(score)
            }}
            className={`z-50 flex items-center justify-center rounded font-bold text-white ${
              scoreAvailable && 'hover:opacity-50'
            }`}
          >
            {partner.isScoreVisible === true ? score : ''}
            {scoreAvailable && (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
                className='ml-1 h-5 w-5'
              >
                <path d='M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z' />
                <path d='M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z' />
              </svg>
            )}
          </div>
        )
      },
      filter: {
        id: 'score',
        filterKey: 'computed',
        operator: 'in',
        fn: filterScore,
        name: t('talents.filter_title_fit'),
        column: 'score',
        options: [
          {
            label: t('talents.filter_high_fit'),
            value: 'HIGH_FIT'
          },
          {
            label: t('talents.filter_middle_fit'),
            value: 'MIDDLE_FIT'
          },
          {
            label: t('talents.filter_low_fit'),
            value: 'LOW_FIT'
          }
        ]
      }
    },
    UPDATED_AT,
    CREATED_STATUS,
    ID,
    OWNER,
    PARTNER_USER_ID,
    NAME,
    MORE,
    {
      accessorKey: 'view',
      enableSorting: false,
      header: '',
      cell: () => (
        <ArrowSmallRightIcon className='ml-auto h-5 w-5 text-gray-500 group-hover:text-gray-900' />
      )
    }
  ]
    .filter((item) => item)
    .map((item) => {
      if (item.disableOnClick) return item

      return {
        ...item,
        onClick: handleNavigate
      }
    })
}

export const createRecoverTalentsTableColumns = ({
  adminInsights,
  t,
  careers_map,
  data,
  language,
  locale
}) => {
  const {
    SELECT,
    ADMIN_INSIGHTS,
    IDENTIFICATION,
    PARTNER_USER_ID,
    NAME,
    SOURCE,
    CREATED_STATUS,
    ID,
    OWNER
  } = COLUMNS({ t, careers_map, data, language, locale })

  return [
    SELECT,
    adminInsights && ADMIN_INSIGHTS,
    {
      ...IDENTIFICATION,
      cell: ({ getValue }) => (
        <span className='text-sm font-medium text-gray-900'>{getValue()}</span>
      )
    },
    PARTNER_USER_ID,
    NAME,
    SOURCE,
    {
      accessorKey: 'soft_deleted',
      sortDescFirst: true,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_soft_deleted')}
        </span>
      ),
      cell: ({ getValue }) => (
        <span className='text-sm text-gray-500'>
          {t('talents.soft_deleted', {
            datetime: format(new Date(getValue()), 'dd.MM.yyyy | HH:mm', {
              locale: locale[language]
            })
          })}
        </span>
      )
    },
    {
      accessorKey: 'cron_ttl',
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_ttl')}
        </span>
      ),
      cell: ({ getValue }) => {
        const { ttl } = getValue() || {}

        if (!ttl) return null

        return (
          <span className='text-sm text-gray-500'>
            {t('talents.ttl', {
              datetime: format(new Date(ttl * 1000), 'dd.MM.yyyy | HH:mm', {
                locale: locale[language]
              })
            })}
          </span>
        )
      }
    },
    { ...CREATED_STATUS, filter: undefined },
    ID,
    OWNER
  ].filter((item) => item)
}
