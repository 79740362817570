import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'

import { classNames } from '../../helpers'

const Tabs = ({ tabs }) => {
  const navigate = useNavigate()

  return (
    <div>
      <div className='lg:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        {tabs.some(({ current }) => current) && (
          <select
            id='tabs'
            name='tabs'
            className='block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500'
            defaultValue={tabs.find((tab) => tab.current).to}
            onChange={(event) => navigate(event.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.to}>
                {tab.name}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className='hidden lg:block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs.map((tab, index) => (
              <Link
                key={index}
                to={tab.to}
                className={classNames(
                  tab.current
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium'
                )}
              >
                <tab.icon
                  className={classNames(
                    tab.current
                      ? 'text-blue-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden='true'
                />
                <span className='whitespace-nowrap'>{tab.name}</span>
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired
}

export default Tabs
