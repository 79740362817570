import { ClockIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import trackEvents from '../constants/track-events'
import { useLanguageContext } from '../context'
import { classNames, convertNumberToTime } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import ChallengeInfoModal from './challenge-info-modal'

const ChallengeMaterialCard = ({
  challenge,
  className = 'bg-slate-50 border-slate-200 border'
}) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  const [openInfoModal, setOpenInfoModal] = useState(false)

  const {
    title,
    measuring,
    timeToComplete,
    card,
    backgroundColor,
    dimensionSynonym,
    exam_id
  } = challenge

  const { minutes } = convertNumberToTime(timeToComplete / 60000)

  return (
    <>
      <div
        style={{ width: 352, height: 256, borderRadius: 16 }}
        className={classNames('flex flex-col p-4', className)}
      >
        <div className='flex min-w-0 items-center space-x-3'>
          <img
            className='h-12 w-12 rounded-full bg-gray-200 object-contain p-1'
            src={card.image}
            style={{ backgroundColor }}
            alt='challenge image'
          />
          <div className='flex flex-col justify-center'>
            <h3 className='font-medium text-gray-900'>{title[language]}</h3>
            <span className='text-xs text-gray-700'>
              {dimensionSynonym[language]}
            </span>
          </div>
        </div>
        <p className='mt-4 text-sm text-gray-700'>{measuring[language]}</p>
        <div className='mt-auto flex items-center'>
          <ClockIcon className='h-5 w-5 text-gray-900' />
          <span className='ml-1 text-xs font-medium text-gray-900'>
            {t('challenge_material_card.minutes', { minutes })}
          </span>
        </div>
        <div className='mt-6 flex gap-x-1'>
          <button
            type='button'
            onClick={() => {
              trackEvent(trackEvents.PRESSED_TRYOUT_CHALLENGE)
              window.open(`http://webapp.aivy.app/?challenge=${exam_id}`)
            }}
            className='rounded-full bg-white px-3 py-2 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-700 hover:bg-gray-50'
          >
            {t('challenge_material_card.tryout_action')}
          </button>
          <button
            type='button'
            onClick={() => setOpenInfoModal(true)}
            className='rounded-full bg-white px-3 py-2 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-700 hover:bg-gray-50'
          >
            {t('challenge_material_card.details_action')}
          </button>
        </div>
      </div>
      <ChallengeInfoModal
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        challenge={challenge}
      />
    </>
  )
}

ChallengeMaterialCard.propTypes = {
  challenge: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default ChallengeMaterialCard
