import { useEffect, useRef } from 'react'

export const useEventListener = ({
  event,
  handler,
  element = window,
  options = {}
}) => {
  const savedHandler = useRef()
  const { capture, passive, once } = options

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener

    if (!isSupported) {
      throw new Error('event not supported!')
    }

    const eventListener = (event) => {
      savedHandler.current(event)
    }

    const opts = { capture, passive, once }
    element.addEventListener(event, eventListener, opts)

    return () => {
      element.removeEventListener(event, eventListener, opts)
    }
  }, [event, element, capture, passive, once])
}
